<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="analystRating" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Analyst-Rating</title>
<path d="M2 1.4c0.331 0 0.6 0.269 0.6 0.6v18.333c0 0.585 0.481 1.067 1.067 1.067h18.333c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-18.333c-1.248 0-2.267-1.019-2.267-2.267v-18.333c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M14.833 2.6c-2.016 0-3.65 1.634-3.65 3.65s1.634 3.65 3.65 3.65c2.016 0 3.65-1.634 3.65-3.65s-1.634-3.65-3.65-3.65zM9.983 6.25c0-2.679 2.171-4.85 4.85-4.85s4.85 2.171 4.85 4.85c0 2.679-2.171 4.85-4.85 4.85s-4.85-2.171-4.85-4.85z"></path>
<path d="M17.428 8.94c0.309-0.316 0.816-0.321 1.131-0.012l4 3.917c0.316 0.309 0.321 0.816 0.012 1.131s-0.816 0.321-1.131 0.012l-4-3.917c-0.316-0.309-0.321-0.816-0.012-1.131z"></path>
<path d="M6 12.2c0.442 0 0.8 0.358 0.8 0.8v7c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-7c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M10 9.2c0.442 0 0.8 0.358 0.8 0.8v10c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-10c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M14 15.2c0.442 0 0.8 0.358 0.8 0.8v4c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-4c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M18 12.2c0.442 0 0.8 0.358 0.8 0.8v7c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-7c0-0.442 0.358-0.8 0.8-0.8z"></path>
</svg>