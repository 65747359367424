import { bouleArrayStore } from "/@/boule/control/boule-array-store.js"
import boulePropertyStore, {
  bouleWritablePropertyStore,
} from "/@/boule/control/boule-property-store.js"
import goog, {
  proTableManager,
  selectedPositionsManager,
  tradeSession,
} from "/@lib/boulangerie"
import { derived, get, writable } from "svelte/store"

import { getColumnPxWidth, getCustomColumnCount } from "./table-dimensions.js"

const j_u_List = goog.module.get("java.util.List")
const SequencedAccountShapeUtil = goog.module.get(
  "tasty.ui.account.SequencedAccountShapeUtil"
)
const ProTableTradingColumnType = goog.module.get(
  "tasty.ui.boule.table.ProTableTradingColumnType"
)

export const strikesAscending = bouleWritablePropertyStore(
  proTableManager.strikePricesAscendingProperty()
)

const FIXED_COLUMNS = [
  ProTableTradingColumnType.ASK,
  ProTableTradingColumnType.BID,
]

export const USER_COLUMNS = ProTableTradingColumnType.values().filter(
  (c) => !FIXED_COLUMNS.includes(c)
)

export function getColumnTitle(column, titleType) {
  return proTableManager.getTitleLookup(column.bouleField).getTitle(titleType)
}

export const customQuoteColumns = bouleWritablePropertyStore(
  proTableManager.customQuoteColumnsProperty(),
  {
    read: ($columns) => $columns?.jsArray ?? [],
    write: (columns) => j_u_List.of(...columns),
  }
)

export const clientWidth = writable(0)

export const customColumnsCount = derived(
  [clientWidth, customQuoteColumns],
  ([$clientWidth, $customQuoteColumns]) =>
    getCustomColumnCount($clientWidth, $customQuoteColumns.length)
)

export const customColumnsPageCount = derived(
  [customQuoteColumns, customColumnsCount],
  ([$customQuoteColumns, $customColumnsCount]) => {
    const count = Math.ceil($customQuoteColumns.length / $customColumnsCount)
    const last = Math.max(count - 1, 0)
    // Make sure the active page exists.
    customColumnsActivePage.set(Math.min(get(customColumnsActivePage), last))
    return count
  }
)

export const customColumnsActivePage = writable(0)

export const customColumnsStartIndex = derived(
  [customColumnsActivePage, customColumnsCount],
  ([$customColumnsActivePage, $customColumnsCount]) =>
    $customColumnsActivePage * $customColumnsCount
)

export const customColumns = derived(
  [customColumnsCount, customColumnsStartIndex, customQuoteColumns],
  ([$customColumnsCount, $customColumnsStartIndex, $customQuoteColumns]) =>
    $customQuoteColumns.slice(
      $customColumnsStartIndex,
      $customColumnsStartIndex + $customColumnsCount
    )
)

export const columnWidth = derived(customColumns, ($customColumns) =>
  getColumnPxWidth(2 + $customColumns.length)
)

export const selectedPositions = bouleArrayStore(
  selectedPositionsManager.selectedPositions
)

export function togglePosition(positionEntry) {
  selectedPositionsManager.toggle(positionEntry)
}

export const currentAccount = boulePropertyStore(
  tradeSession.currentAccountProperty()
)

export function getAccountColor(account) {
  const color = SequencedAccountShapeUtil.mapAccountToColor(
    account,
    tradeSession.accounts
  )
  return `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`
}

// XXX: Nasty hack to get around layout/context things.
export const jumpToTheMoney = writable(() => {})
