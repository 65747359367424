export class QuoteStreamerTokenAuth {
  static onInitialize = (_obj: QuoteStreamerTokenAuth) => {
    /* no-op */
  }

  constructor() {
    QuoteStreamerTokenAuth.onInitialize(this)
  }

  token = ''
  streamerUrl = ''
  websocketUrl = ''
  level = ''

  get isDelayed() {
    return this.level.toLowerCase().includes('delayed')
  }
}
