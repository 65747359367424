<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="loginBiometricsFace" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Login-Biometrics-Face</title>
<path d="M7.58 1.245c2.895-1.457 6.377-1.387 9.219 0.189l0.926 0.514c2.712 1.512 4.304 4.408 4.048 7.446l-0.476 5.635c-0 0 0-0 0 0-0.213 2.54-1.633 4.832-3.828 6.226l-2.782 1.768c-1.634 1.036-3.741 1.037-5.374 0l-2.783-1.769c-2.195-1.393-3.615-3.685-3.827-6.225-0-0 0 0 0 0l-0.477-5.637c-0.138-1.689 0.288-3.337 1.17-4.727 0.237-0.373 0.731-0.484 1.104-0.247s0.484 0.731 0.247 1.104c-0.7 1.102-1.036 2.404-0.926 3.738 0 0 0 0.001 0 0.001l0.476 5.633c0.169 2.023 1.302 3.873 3.091 5.009l2.782 1.768c1.111 0.705 2.549 0.705 3.66 0l2.782-1.768c1.788-1.135 2.922-2.985 3.091-5.008l0.477-5.636c0-0 0 0 0 0 0.201-2.386-1.047-4.695-3.232-5.914-0-0 0 0 0 0l-0.923-0.513c-2.38-1.32-5.302-1.378-7.724-0.159-0.395 0.199-0.876 0.040-1.074-0.355s-0.040-0.876 0.355-1.074z"></path>
<path d="M10.357 14.82c0.23-0.239 0.609-0.246 0.848-0.016l0.553 0.532c0.139 0.134 0.363 0.134 0.502 0l0.553-0.532c0.239-0.23 0.619-0.222 0.848 0.016s0.222 0.619-0.016 0.848l-0.553 0.532c-0.604 0.581-1.562 0.581-2.166 0l-0.553-0.532c-0.239-0.23-0.246-0.61-0.016-0.848z"></path>
<path d="M9.526 18.057c0.229-0.24 0.609-0.249 0.848-0.020 0.903 0.861 2.367 0.859 3.258 0.002 0.239-0.23 0.619-0.222 0.848 0.016s0.222 0.619-0.017 0.848c-1.358 1.306-3.553 1.304-4.918 0.002-0.24-0.229-0.249-0.609-0.020-0.848z"></path>
<path d="M13.492 11.32c0.23-0.239 0.609-0.246 0.848-0.017 0.702 0.675 1.84 0.675 2.541 0 0.239-0.23 0.619-0.222 0.848 0.017s0.222 0.619-0.016 0.848c-1.166 1.122-3.039 1.122-4.205 0-0.239-0.23-0.246-0.609-0.016-0.848z"></path>
<path d="M6.269 11.32c0.23-0.239 0.61-0.246 0.848-0.017 0.702 0.675 1.84 0.675 2.541 0 0.239-0.23 0.619-0.222 0.848 0.017s0.222 0.619-0.017 0.848c-1.166 1.122-3.039 1.122-4.205 0-0.239-0.23-0.246-0.609-0.016-0.848z"></path>
<path d="M18.495 6.12c-0.846-0.324-1.902-0.068-2.579 0.54l-0 0c-2.859 2.566-7.28 2.687-10.094-0.019-0.318-0.306-0.328-0.813-0.022-1.131s0.813-0.328 1.131-0.022c2.161 2.078 5.629 2.034 7.916-0.019 1.058-0.95 2.736-1.412 4.22-0.843 1.565 0.6 2.636 2.221 2.698 4.875 0.010 0.442-0.339 0.808-0.781 0.818s-0.808-0.339-0.819-0.781c-0.052-2.24-0.906-3.125-1.671-3.418z"></path>
<path d="M5.191 2.652c0.306-0.318 0.813-0.328 1.131-0.022 1.491 1.435 3.908 1.435 5.399 0 0.318-0.306 0.825-0.296 1.131 0.022s0.297 0.825-0.022 1.131c-2.111 2.030-5.507 2.030-7.618 0-0.318-0.306-0.328-0.813-0.022-1.131z"></path>
</svg>