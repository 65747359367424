<script>
  import { createEventDispatcher } from "svelte"
  import PopoverDropdown from "/@/layout/popover/PopoverDropdown.svelte"

  export let label
  let optionsProp = undefined
  export { optionsProp as options }
  export let preferenceField = undefined
  export let preferenceOptions = undefined

  const dispatch = createEventDispatcher()

  $: preferenceOptions ??= preferenceField?.selectableValues?.toArray()

  // If options is not supplied, assume we have an Enum field and use its
  // selectable values.
  $: options =
    optionsProp?.map((value) => ({ label: value, value })) ||
    preferenceOptions?.map((value) => ({
      label: preferenceField.getLabel(value),
      value,
    }))

  $: value = options.find(
    (o) => preferenceField.getValue()?.toString() === o.value.toString()
  )

  function handleChange({ detail }) {
    preferenceField.setValue(detail.value)
    dispatch("change", detail.value)
  }
</script>

<div class="dropdown-field flex flex-col gap-double-extra-small">
  <span class="font-x-small-600-caps text-general-primary-label">
    {label}
  </span>
  <PopoverDropdown {options} {value} on:change={handleChange} />
</div>
