import { twApiClient } from "/@lib/tastyworks-rest"
import { writable } from "svelte/store"

export const webPushEnabled = writable(false)

export async function subscribeWebPush(): Promise<void> {
  const registration = await navigator.serviceWorker.ready
  const applicationServerKey = await twApiClient.webPushService.fetchPublicKey()
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey,
  })
  await twApiClient.webPushService.register(subscription)
  webPushEnabled.set(true)
}

export async function unsubscribeWebPush(): Promise<void> {
  const registration = await navigator.serviceWorker.ready
  const subscription = await registration.pushManager.getSubscription()
  if (!subscription) {
    return
  }

  await subscription.unsubscribe()
  await twApiClient.webPushService.unregister(subscription)
  webPushEnabled.set(false)
}
