import {
  ENTITY_SUITABILITY_DESER,
  EntitySuitability
} from '../../tastyworks/entity-suitability'
import type HttpClient from '../../tastyworks/http'

export class EntitySuitabilityService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly update = async (entityId: string, suitability: EntitySuitability) =>
    this.customerMeClient.update(
      suitability,
      ENTITY_SUITABILITY_DESER.serialize,
      ENTITY_SUITABILITY_DESER.update,
      '/entities/:entityId/suitability',
      { entityId }
    )
}
