import camelCase from "lodash.camelcase"

const jsReservedWords = [
  "async",
  "await",
  "break",
  "case",
  "catch",
  "class",
  "const",
  "continue",
  "debugger",
  "default",
  "delete",
  "do",
  "else",
  "export",
  "extends",
  "false",
  "finally",
  "for",
  "function",
  "if",
  "import",
  "in",
  "instanceof",
  "let",
  "new",
  "null",
  "return",
  "super",
  "static",
  "switch",
  "this",
  "throw",
  "true",
  "try",
  "typeof",
  "var",
  "void",
  "while",
  "with",
  "yield",
]

export function transformModuleName(string) {
  // preserve leading underscore
  const leadingChar = string.startsWith("_") ? "_" : ""
  let result = leadingChar + camelCase(string)
  if (jsReservedWords.includes(result)) {
    result = "_" + result
  }
  return result
}
