import type { Alpha3Code } from 'i18n-iso-countries'
import { ACCEPTED_COUNTRIES_OPTIONS } from './country'
import { EN_COUNTRY_LABELS } from './country-labels'

type CallingCode = Record<
  Alpha3Code,
  {
    callingCode: string
    displayName: string
    value: Alpha3Code
  }
>

export const EN_CALLING_CODES = {
  ABW: {
    callingCode: '+297',
    displayName: `${EN_COUNTRY_LABELS.ABW}`,
    value: 'ABW'
  },
  AFG: {
    callingCode: '+355',
    displayName: `${EN_COUNTRY_LABELS.AFG}`,
    value: 'AFG'
  },
  AGO: {
    callingCode: '+213',
    displayName: `${EN_COUNTRY_LABELS.AGO}`,
    value: 'AGO'
  },
  AIA: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.AIA}`,
    value: 'AIA'
  },
  ALA: {
    callingCode: '+358',
    displayName: `${EN_COUNTRY_LABELS.ALA}`,
    value: 'ALA'
  },
  ALB: {
    callingCode: '+355',
    displayName: `${EN_COUNTRY_LABELS.ALB}`,
    value: 'ALB'
  },
  AND: {
    callingCode: '+376',
    displayName: `${EN_COUNTRY_LABELS.AND}`,
    value: 'AND'
  },
  ARE: {
    callingCode: '+971',
    displayName: `${EN_COUNTRY_LABELS.ARE}`,
    value: 'ARE'
  },
  ARG: {
    callingCode: '+54',
    displayName: `${EN_COUNTRY_LABELS.ARG}`,
    value: 'ARG'
  },
  ARM: {
    callingCode: '+374',
    displayName: `${EN_COUNTRY_LABELS.ARM}`,
    value: 'ARM'
  },
  ASM: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.ASM}`,
    value: 'ASM'
  },
  ATA: {
    callingCode: '+672',
    displayName: `${EN_COUNTRY_LABELS.ATA}`,
    value: 'ATA'
  },
  ATF: {
    callingCode: '+264',
    displayName: `${EN_COUNTRY_LABELS.ATF}`,
    value: 'ATF'
  },
  ATG: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.ATG}`,
    value: 'ATG'
  },
  AUS: {
    callingCode: '+61',
    displayName: `${EN_COUNTRY_LABELS.AUS}`,
    value: 'AUS'
  },
  AUT: {
    callingCode: '+43',
    displayName: `${EN_COUNTRY_LABELS.AUT}`,
    value: 'AUT'
  },
  AZE: {
    callingCode: '+994',
    displayName: `${EN_COUNTRY_LABELS.AZE}`,
    value: 'AZE'
  },
  BDI: {
    callingCode: '+257',
    displayName: `${EN_COUNTRY_LABELS.BDI}`,
    value: 'BDI'
  },
  BEL: {
    callingCode: '+32',
    displayName: `${EN_COUNTRY_LABELS.BEL}`,
    value: 'BEL'
  },
  BEN: {
    callingCode: '+229',
    displayName: `${EN_COUNTRY_LABELS.BEN}`,
    value: 'BEN'
  },
  BES: {
    callingCode: '+599',
    displayName: `${EN_COUNTRY_LABELS.BES}`,
    value: 'BES'
  },
  BFA: {
    callingCode: '+226',
    displayName: `${EN_COUNTRY_LABELS.BFA}`,
    value: 'BFA'
  },
  BGD: {
    callingCode: '+880',
    displayName: `${EN_COUNTRY_LABELS.BGD}`,
    value: 'BGD'
  },
  BGR: {
    callingCode: '+359',
    displayName: `${EN_COUNTRY_LABELS.BGR}`,
    value: 'BGR'
  },
  BHR: {
    callingCode: '+973',
    displayName: `${EN_COUNTRY_LABELS.BHR}`,
    value: 'BHR'
  },
  BHS: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.BHS}`,
    value: 'BHS'
  },
  BIH: {
    callingCode: '+387',
    displayName: `${EN_COUNTRY_LABELS.BIH}`,
    value: 'BIH'
  },
  BLM: {
    callingCode: '+590',
    displayName: `${EN_COUNTRY_LABELS.BLM}`,
    value: 'BLM'
  },
  BLR: {
    callingCode: '+375',
    displayName: `${EN_COUNTRY_LABELS.BLR}`,
    value: 'BLR'
  },
  BLZ: {
    callingCode: '+501',
    displayName: `${EN_COUNTRY_LABELS.BLZ}`,
    value: 'BLZ'
  },
  BMU: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.BMU}`,
    value: 'BMU'
  },
  BOL: {
    callingCode: '+591',
    displayName: `${EN_COUNTRY_LABELS.BOL}`,
    value: 'BOL'
  },
  BRA: {
    callingCode: '+55',
    displayName: `${EN_COUNTRY_LABELS.BRA}`,
    value: 'BRA'
  },
  BRB: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.BRB}`,
    value: 'BRB'
  },
  BRN: {
    callingCode: '+673',
    displayName: `${EN_COUNTRY_LABELS.BRN}`,
    value: 'BRN'
  },
  BTN: {
    callingCode: '+975',
    displayName: `${EN_COUNTRY_LABELS.BTN}`,
    value: 'BTN'
  },
  BWA: {
    callingCode: '+267',
    displayName: `${EN_COUNTRY_LABELS.BWA}`,
    value: 'BWA'
  },
  CAF: {
    callingCode: '+263',
    displayName: `${EN_COUNTRY_LABELS.CAF}`,
    value: 'CAF'
  },
  CAN: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.CAN}`,
    value: 'CAN'
  },
  CCK: {
    callingCode: '+61',
    displayName: `${EN_COUNTRY_LABELS.CCK}`,
    value: 'CCK'
  },
  CHE: {
    callingCode: '+41',
    displayName: `${EN_COUNTRY_LABELS.CHE}`,
    value: 'CHE'
  },
  CHL: {
    callingCode: '+56',
    displayName: `${EN_COUNTRY_LABELS.CHL}`,
    value: 'CHL'
  },
  CHN: {
    callingCode: '+86',
    displayName: `${EN_COUNTRY_LABELS.CHN}`,
    value: 'CHN'
  },
  CIV: {
    callingCode: '+225',
    displayName: `${EN_COUNTRY_LABELS.CIV}`,
    value: 'CIV'
  },
  CMR: {
    callingCode: '+237',
    displayName: `${EN_COUNTRY_LABELS.CMR}`,
    value: 'CMR'
  },
  COD: {
    callingCode: '+243',
    displayName: `${EN_COUNTRY_LABELS.COD}`,
    value: 'COD'
  },
  COG: {
    callingCode: '+242',
    displayName: `${EN_COUNTRY_LABELS.COG}`,
    value: 'COG'
  },
  COK: {
    callingCode: '+682',
    displayName: `${EN_COUNTRY_LABELS.COK}`,
    value: 'COK'
  },
  COL: {
    callingCode: '+57',
    displayName: `${EN_COUNTRY_LABELS.COL}`,
    value: 'COL'
  },
  COM: {
    callingCode: '+269',
    displayName: `${EN_COUNTRY_LABELS.COM}`,
    value: 'COM'
  },
  CPV: {
    callingCode: '+238',
    displayName: `${EN_COUNTRY_LABELS.CPV}`,
    value: 'CPV'
  },
  CRI: {
    callingCode: '+506',
    displayName: `${EN_COUNTRY_LABELS.CRI}`,
    value: 'CRI'
  },
  CUB: {
    callingCode: '+53',
    displayName: `${EN_COUNTRY_LABELS.CUB}`,
    value: 'CUB'
  },
  CUW: {
    callingCode: '+599',
    displayName: `${EN_COUNTRY_LABELS.CUW}`,
    value: 'CUW'
  },
  CXR: {
    callingCode: '+61',
    displayName: `${EN_COUNTRY_LABELS.CXR}`,
    value: 'CXR'
  },
  CYM: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.CYM}`,
    value: 'CYM'
  },
  CYP: {
    callingCode: '+357',
    displayName: `${EN_COUNTRY_LABELS.CYP}`,
    value: 'CYP'
  },
  CZE: {
    callingCode: '+420',
    displayName: `${EN_COUNTRY_LABELS.CZE}`,
    value: 'CZE'
  },
  DEU: {
    callingCode: '+49',
    displayName: `${EN_COUNTRY_LABELS.DEU}`,
    value: 'DEU'
  },
  DJI: {
    callingCode: '+253',
    displayName: `${EN_COUNTRY_LABELS.DJI}`,
    value: 'DJI'
  },
  DMA: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.DMA}`,
    value: 'DMA'
  },
  DNK: {
    callingCode: '+45',
    displayName: `${EN_COUNTRY_LABELS.DNK}`,
    value: 'DNK'
  },
  DOM: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.DOM}`,
    value: 'DOM'
  },
  DZA: {
    callingCode: '+213',
    displayName: `${EN_COUNTRY_LABELS.DZA}`,
    value: 'DZA'
  },
  ECU: {
    callingCode: '+593',
    displayName: `${EN_COUNTRY_LABELS.ECU}`,
    value: 'ECU'
  },
  EGY: {
    callingCode: '+20',
    displayName: `${EN_COUNTRY_LABELS.EGY}`,
    value: 'EGY'
  },
  ERI: {
    callingCode: '+291',
    displayName: `${EN_COUNTRY_LABELS.ERI}`,
    value: 'ERI'
  },
  ESH: {
    callingCode: '+212',
    displayName: `${EN_COUNTRY_LABELS.ESH}`,
    value: 'ESH'
  },
  ESP: {
    callingCode: '+34',
    displayName: `${EN_COUNTRY_LABELS.ESP}`,
    value: 'ESP'
  },
  EST: {
    callingCode: '+372',
    displayName: `${EN_COUNTRY_LABELS.EST}`,
    value: 'EST'
  },
  ETH: {
    callingCode: '+251',
    displayName: `${EN_COUNTRY_LABELS.ETH}`,
    value: 'ETH'
  },
  FIN: {
    callingCode: '+358',
    displayName: `${EN_COUNTRY_LABELS.FIN}`,
    value: 'FIN'
  },
  FJI: {
    callingCode: '+679',
    displayName: `${EN_COUNTRY_LABELS.FJI}`,
    value: 'FJI'
  },
  FLK: {
    callingCode: '+500',
    displayName: `${EN_COUNTRY_LABELS.FLK}`,
    value: 'FLK'
  },
  FRA: {
    callingCode: '+33',
    displayName: `${EN_COUNTRY_LABELS.FRA}`,
    value: 'FRA'
  },
  FRO: {
    callingCode: '+298',
    displayName: `${EN_COUNTRY_LABELS.FRO}`,
    value: 'FRO'
  },
  FSM: {
    callingCode: '+691',
    displayName: `${EN_COUNTRY_LABELS.FSM}`,
    value: 'FSM'
  },
  GAB: {
    callingCode: '+241',
    displayName: `${EN_COUNTRY_LABELS.GAB}`,
    value: 'GAB'
  },
  GBR: {
    callingCode: '+44',
    displayName: `United Kingdom`,
    value: 'GBR'
  },
  GEO: {
    callingCode: '+995',
    displayName: `${EN_COUNTRY_LABELS.GEO}`,
    value: 'GEO'
  },
  GGY: {
    callingCode: '+44',
    displayName: `${EN_COUNTRY_LABELS.GGY}`,
    value: 'GGY'
  },
  GHA: {
    callingCode: '+233',
    displayName: `${EN_COUNTRY_LABELS.GHA}`,
    value: 'GHA'
  },
  GIB: {
    callingCode: '+350',
    displayName: `${EN_COUNTRY_LABELS.GIB}`,
    value: 'GIB'
  },
  GIN: {
    callingCode: '+224',
    displayName: `${EN_COUNTRY_LABELS.GIN}`,
    value: 'GIN'
  },
  GLP: {
    callingCode: '+590',
    displayName: `${EN_COUNTRY_LABELS.GLP}`,
    value: 'GLP'
  },
  GMB: {
    callingCode: '+220',
    displayName: `${EN_COUNTRY_LABELS.GMB}`,
    value: 'GMB'
  },
  GNB: {
    callingCode: '+245',
    displayName: `${EN_COUNTRY_LABELS.GNB}`,
    value: 'GNB'
  },
  GNQ: {
    callingCode: '+240',
    displayName: `${EN_COUNTRY_LABELS.GNQ}`,
    value: 'GNQ'
  },
  GRC: {
    callingCode: '+30',
    displayName: `${EN_COUNTRY_LABELS.GRC}`,
    value: 'GRC'
  },
  GRD: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.GRD}`,
    value: 'GRD'
  },
  GRL: {
    callingCode: '+299',
    displayName: `${EN_COUNTRY_LABELS.GRL}`,
    value: 'GRL'
  },
  GTM: {
    callingCode: '+502',
    displayName: `${EN_COUNTRY_LABELS.GTM}`,
    value: 'GTM'
  },
  GUF: {
    callingCode: '+594',
    displayName: `${EN_COUNTRY_LABELS.GUF}`,
    value: 'GUF'
  },
  GUM: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.GUM}`,
    value: 'GUM'
  },
  GUY: {
    callingCode: '+592',
    displayName: `${EN_COUNTRY_LABELS.GUY}`,
    value: 'GUY'
  },
  HKG: {
    callingCode: '+852',
    displayName: `${EN_COUNTRY_LABELS.HKG}`,
    value: 'HKG'
  },
  HND: {
    callingCode: '+502',
    displayName: `${EN_COUNTRY_LABELS.HND}`,
    value: 'HND'
  },
  HRV: {
    callingCode: '+385',
    displayName: `${EN_COUNTRY_LABELS.HRV}`,
    value: 'HRV'
  },
  HTI: {
    callingCode: '+509',
    displayName: `${EN_COUNTRY_LABELS.HTI}`,
    value: 'HTI'
  },
  HUN: {
    callingCode: '+36',
    displayName: `${EN_COUNTRY_LABELS.HUN}`,
    value: 'HUN'
  },
  IDN: {
    callingCode: '+62',
    displayName: `${EN_COUNTRY_LABELS.IDN}`,
    value: 'IDN'
  },
  IMN: {
    callingCode: '+44',
    displayName: `${EN_COUNTRY_LABELS.IMN}`,
    value: 'IMN'
  },
  IND: {
    callingCode: '+91',
    displayName: `${EN_COUNTRY_LABELS.IND}`,
    value: 'IND'
  },
  IOT: {
    callingCode: '+246',
    displayName: `${EN_COUNTRY_LABELS.IOT}`,
    value: 'IOT'
  },
  IRL: {
    callingCode: '+353',
    displayName: `${EN_COUNTRY_LABELS.IRL}`,
    value: 'IRL'
  },
  IRN: {
    callingCode: '+98',
    displayName: `${EN_COUNTRY_LABELS.IRN}`,
    value: 'IRN'
  },
  IRQ: {
    callingCode: '+964',
    displayName: `${EN_COUNTRY_LABELS.IRQ}`,
    value: 'IRQ'
  },
  ISL: {
    callingCode: '+354',
    displayName: `${EN_COUNTRY_LABELS.ISL}`,
    value: 'ISL'
  },
  ISR: {
    callingCode: '+972',
    displayName: `${EN_COUNTRY_LABELS.ISR}`,
    value: 'ISR'
  },
  ITA: {
    callingCode: '+39',
    displayName: `${EN_COUNTRY_LABELS.ITA}`,
    value: 'ITA'
  },
  JAM: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.JAM}`,
    value: 'JAM'
  },
  JEY: {
    callingCode: '+44',
    displayName: `${EN_COUNTRY_LABELS.JEY}`,
    value: 'JEY'
  },
  JOR: {
    callingCode: '+962',
    displayName: `${EN_COUNTRY_LABELS.JOR}`,
    value: 'JOR'
  },
  JPN: {
    callingCode: '+81',
    displayName: `${EN_COUNTRY_LABELS.JPN}`,
    value: 'JPN'
  },
  KAZ: {
    callingCode: '+7',
    displayName: `${EN_COUNTRY_LABELS.KAZ}`,
    value: 'KAZ'
  },
  KEN: {
    callingCode: '+254',
    displayName: `${EN_COUNTRY_LABELS.KEN}`,
    value: 'KEN'
  },
  KGZ: {
    callingCode: '+996',
    displayName: `${EN_COUNTRY_LABELS.KGZ}`,
    value: 'KGZ'
  },
  KHM: {
    callingCode: '+855',
    displayName: `${EN_COUNTRY_LABELS.KHM}`,
    value: 'KHM'
  },
  KIR: {
    callingCode: '+686',
    displayName: `${EN_COUNTRY_LABELS.KIR}`,
    value: 'KIR'
  },
  KNA: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.KNA}`,
    value: 'KNA'
  },
  KOR: {
    callingCode: '+82',
    displayName: `${EN_COUNTRY_LABELS.KOR}`,
    value: 'KOR'
  },
  KWT: {
    callingCode: '+965',
    displayName: `${EN_COUNTRY_LABELS.KWT}`,
    value: 'KWT'
  },
  LAO: {
    callingCode: '+856',
    displayName: `${EN_COUNTRY_LABELS.LAO}`,
    value: 'LAO'
  },
  LBN: {
    callingCode: '+961',
    displayName: `${EN_COUNTRY_LABELS.LBN}`,
    value: 'LBN'
  },
  LBR: {
    callingCode: '+231',
    displayName: `${EN_COUNTRY_LABELS.LBR}`,
    value: 'LBR'
  },
  LBY: {
    callingCode: '+218',
    displayName: `${EN_COUNTRY_LABELS.LBY}`,
    value: 'LBY'
  },
  LCA: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.LCA}`,
    value: 'LCA'
  },
  LIE: {
    callingCode: '+423',
    displayName: `${EN_COUNTRY_LABELS.LIE}`,
    value: 'LIE'
  },
  LKA: {
    callingCode: '+94',
    displayName: `${EN_COUNTRY_LABELS.LKA}`,
    value: 'LKA'
  },
  LSO: {
    callingCode: '+266',
    displayName: `${EN_COUNTRY_LABELS.LSO}`,
    value: 'LSO'
  },
  LTU: {
    callingCode: '+370',
    displayName: `${EN_COUNTRY_LABELS.LTU}`,
    value: 'LTU'
  },
  LUX: {
    callingCode: '+352',
    displayName: `${EN_COUNTRY_LABELS.LUX}`,
    value: 'LUX'
  },
  LVA: {
    callingCode: '+371',
    displayName: `${EN_COUNTRY_LABELS.LVA}`,
    value: 'LVA'
  },
  MAC: {
    callingCode: '+853',
    displayName: `${EN_COUNTRY_LABELS.MAC}`,
    value: 'MAC'
  },
  MAF: {
    callingCode: '+590',
    displayName: `${EN_COUNTRY_LABELS.MAF}`,
    value: 'MAF'
  },
  MAR: {
    callingCode: '+212',
    displayName: `${EN_COUNTRY_LABELS.MAR}`,
    value: 'MAR'
  },
  MCO: {
    callingCode: '+377',
    displayName: `${EN_COUNTRY_LABELS.MCO}`,
    value: 'MCO'
  },
  MDA: {
    callingCode: '+373',
    displayName: `${EN_COUNTRY_LABELS.MDA}`,
    value: 'MDA'
  },
  MDG: {
    callingCode: '+261',
    displayName: `${EN_COUNTRY_LABELS.MDG}`,
    value: 'MDG'
  },
  MDV: {
    callingCode: '+960',
    displayName: `${EN_COUNTRY_LABELS.MDV}`,
    value: 'MDV'
  },
  MEX: {
    callingCode: '+52',
    displayName: `${EN_COUNTRY_LABELS.MEX}`,
    value: 'MEX'
  },
  MHL: {
    callingCode: '+692',
    displayName: `${EN_COUNTRY_LABELS.MHL}`,
    value: 'MHL'
  },
  MKD: {
    callingCode: '+389',
    displayName: `${EN_COUNTRY_LABELS.MKD}`,
    value: 'MKD'
  },
  MLI: {
    callingCode: '+223',
    displayName: `${EN_COUNTRY_LABELS.MLI}`,
    value: 'MLI'
  },
  MLT: {
    callingCode: '+356',
    displayName: `${EN_COUNTRY_LABELS.MLT}`,
    value: 'MLT'
  },
  MMR: {
    callingCode: '+95',
    displayName: `${EN_COUNTRY_LABELS.MMR}`,
    value: 'MMR'
  },
  MNE: {
    callingCode: '+382',
    displayName: `${EN_COUNTRY_LABELS.MNE}`,
    value: 'MNE'
  },
  MNG: {
    callingCode: '+976',
    displayName: `${EN_COUNTRY_LABELS.MNG}`,
    value: 'MNG'
  },
  MNP: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.MNP}`,
    value: 'MNP'
  },
  MOZ: {
    callingCode: '+258',
    displayName: `${EN_COUNTRY_LABELS.MOZ}`,
    value: 'MOZ'
  },
  MRT: {
    callingCode: '+222',
    displayName: `${EN_COUNTRY_LABELS.MRT}`,
    value: 'MRT'
  },
  MSR: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.MSR}`,
    value: 'MSR'
  },
  MTQ: {
    callingCode: '+596',
    displayName: `${EN_COUNTRY_LABELS.MTQ}`,
    value: 'MTQ'
  },
  MUS: {
    callingCode: '+230',
    displayName: `${EN_COUNTRY_LABELS.MUS}`,
    value: 'MUS'
  },
  MWI: {
    callingCode: '+265',
    displayName: `${EN_COUNTRY_LABELS.MWI}`,
    value: 'MWI'
  },
  MYS: {
    callingCode: '+60',
    displayName: `${EN_COUNTRY_LABELS.MYS}`,
    value: 'MYS'
  },
  MYT: {
    callingCode: '+262',
    displayName: `${EN_COUNTRY_LABELS.MYT}`,
    value: 'MYT'
  },
  NAM: {
    callingCode: '+264',
    displayName: `${EN_COUNTRY_LABELS.NAM}`,
    value: 'NAM'
  },
  NCL: {
    callingCode: '+687',
    displayName: `${EN_COUNTRY_LABELS.NCL}`,
    value: 'NCL'
  },
  NER: {
    callingCode: '+227',
    displayName: `${EN_COUNTRY_LABELS.NER}`,
    value: 'NER'
  },
  NFK: {
    callingCode: '+672',
    displayName: `${EN_COUNTRY_LABELS.NFK}`,
    value: 'NFK'
  },
  NGA: {
    callingCode: '+234',
    displayName: `${EN_COUNTRY_LABELS.NGA}`,
    value: 'NGA'
  },
  NIC: {
    callingCode: '+505',
    displayName: `${EN_COUNTRY_LABELS.NIC}`,
    value: 'NIC'
  },
  NIU: {
    callingCode: '+683',
    displayName: `${EN_COUNTRY_LABELS.NIU}`,
    value: 'NIU'
  },
  NLD: {
    callingCode: '+31',
    displayName: `${EN_COUNTRY_LABELS.NLD}`,
    value: 'NLD'
  },
  NOR: {
    callingCode: '+47',
    displayName: `${EN_COUNTRY_LABELS.NOR}`,
    value: 'NOR'
  },
  NPL: {
    callingCode: '+977',
    displayName: `${EN_COUNTRY_LABELS.NPL}`,
    value: 'NPL'
  },
  NRU: {
    callingCode: '+674',
    displayName: `${EN_COUNTRY_LABELS.NRU}`,
    value: 'NRU'
  },
  NZL: {
    callingCode: '+64',
    displayName: `${EN_COUNTRY_LABELS.NZL}`,
    value: 'NZL'
  },
  OMN: {
    callingCode: '+968',
    displayName: `${EN_COUNTRY_LABELS.OMN}`,
    value: 'OMN'
  },
  PAK: {
    callingCode: '+92',
    displayName: `${EN_COUNTRY_LABELS.PAK}`,
    value: 'PAK'
  },
  PAN: {
    callingCode: '+507',
    displayName: `${EN_COUNTRY_LABELS.PAN}`,
    value: 'PAN'
  },
  PCN: {
    callingCode: '+64',
    displayName: `${EN_COUNTRY_LABELS.PCN}`,
    value: 'PCN'
  },
  PER: {
    callingCode: '+51',
    displayName: `${EN_COUNTRY_LABELS.PER}`,
    value: 'PER'
  },
  PHL: {
    callingCode: '+63',
    displayName: `${EN_COUNTRY_LABELS.PHL}`,
    value: 'PHL'
  },
  PLW: {
    callingCode: '+680',
    displayName: `${EN_COUNTRY_LABELS.PLW}`,
    value: 'PLW'
  },
  PNG: {
    callingCode: '+675',
    displayName: `${EN_COUNTRY_LABELS.PNG}`,
    value: 'PNG'
  },
  POL: {
    callingCode: '+48',
    displayName: `${EN_COUNTRY_LABELS.POL}`,
    value: 'POL'
  },
  PRI: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.PRI}`,
    value: 'PRI'
  },
  PRK: {
    callingCode: '+850',
    displayName: `${EN_COUNTRY_LABELS.PRK}`,
    value: 'PRK'
  },
  PRT: {
    callingCode: '+351',
    displayName: `${EN_COUNTRY_LABELS.PRT}`,
    value: 'PRT'
  },
  PRY: {
    callingCode: '+595',
    displayName: `${EN_COUNTRY_LABELS.PRY}`,
    value: 'PRY'
  },
  PSE: {
    callingCode: '+970',
    displayName: `${EN_COUNTRY_LABELS.PSE}`,
    value: 'PSE'
  },
  PYF: {
    callingCode: '+689',
    displayName: `${EN_COUNTRY_LABELS.PYF}`,
    value: 'PYF'
  },
  QAT: {
    callingCode: '+974',
    displayName: `${EN_COUNTRY_LABELS.QAT}`,
    value: 'QAT'
  },
  REU: {
    callingCode: '+262',
    displayName: `${EN_COUNTRY_LABELS.REU}`,
    value: 'REU'
  },
  ROU: {
    callingCode: '+40',
    displayName: `${EN_COUNTRY_LABELS.ROU}`,
    value: 'ROU'
  },
  RUS: {
    callingCode: '+7',
    displayName: `${EN_COUNTRY_LABELS.RUS}`,
    value: 'RUS'
  },
  RWA: {
    callingCode: '+250',
    displayName: `${EN_COUNTRY_LABELS.RWA}`,
    value: 'RWA'
  },
  SAU: {
    callingCode: '+966',
    displayName: `${EN_COUNTRY_LABELS.SAU}`,
    value: 'SAU'
  },
  SDN: {
    callingCode: '+249',
    displayName: `${EN_COUNTRY_LABELS.SDN}`,
    value: 'SDN'
  },
  SEN: {
    callingCode: '+221',
    displayName: `${EN_COUNTRY_LABELS.SEN}`,
    value: 'SEN'
  },
  SGP: {
    callingCode: '+65',
    displayName: `${EN_COUNTRY_LABELS.SGP}`,
    value: 'SGP'
  },
  SGS: {
    callingCode: '+500',
    displayName: `${EN_COUNTRY_LABELS.SGS}`,
    value: 'SGS'
  },
  SHN: {
    callingCode: '+290',
    displayName: `${EN_COUNTRY_LABELS.SHN}`,
    value: 'SHN'
  },
  SJM: {
    callingCode: '+47',
    displayName: `${EN_COUNTRY_LABELS.SJM}`,
    value: 'SJM'
  },
  SLB: {
    callingCode: '+677',
    displayName: `${EN_COUNTRY_LABELS.SLB}`,
    value: 'SLB'
  },
  SLE: {
    callingCode: '+232',
    displayName: `${EN_COUNTRY_LABELS.SLE}`,
    value: 'SLE'
  },
  SLV: {
    callingCode: '+503',
    displayName: `${EN_COUNTRY_LABELS.SLV}`,
    value: 'SLV'
  },
  SMR: {
    callingCode: '+378',
    displayName: `${EN_COUNTRY_LABELS.SMR}`,
    value: 'SMR'
  },
  SOM: {
    callingCode: '+252',
    displayName: `${EN_COUNTRY_LABELS.SOM}`,
    value: 'SOM'
  },
  SPM: {
    callingCode: '+508',
    displayName: `${EN_COUNTRY_LABELS.SPM}`,
    value: 'SPM'
  },
  SRB: {
    callingCode: '+381',
    displayName: `${EN_COUNTRY_LABELS.SRB}`,
    value: 'SRB'
  },
  SSD: {
    callingCode: '+211',
    displayName: `${EN_COUNTRY_LABELS.SSD}`,
    value: 'SSD'
  },
  STP: {
    callingCode: '+239',
    displayName: `${EN_COUNTRY_LABELS.STP}`,
    value: 'STP'
  },
  SUR: {
    callingCode: '+597',
    displayName: `${EN_COUNTRY_LABELS.SUR}`,
    value: 'SUR'
  },
  SVK: {
    callingCode: '+421',
    displayName: `${EN_COUNTRY_LABELS.SVK}`,
    value: 'SVK'
  },
  SVN: {
    callingCode: '+386',
    displayName: `${EN_COUNTRY_LABELS.SVN}`,
    value: 'SVN'
  },
  SWE: {
    callingCode: '+46',
    displayName: `${EN_COUNTRY_LABELS.SWE}`,
    value: 'SWE'
  },
  SWZ: {
    callingCode: '+268',
    displayName: `${EN_COUNTRY_LABELS.SWZ}`,
    value: 'SWZ'
  },
  SXM: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.SXM}`,
    value: 'SXM'
  },
  SYC: {
    callingCode: '+248',
    displayName: `${EN_COUNTRY_LABELS.SYC}`,
    value: 'SYC'
  },
  SYR: {
    callingCode: '+963',
    displayName: `${EN_COUNTRY_LABELS.SYR}`,
    value: 'SYR'
  },
  TCA: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.TCA}`,
    value: 'TCA'
  },
  TCD: {
    callingCode: '+235',
    displayName: `${EN_COUNTRY_LABELS.TCD}`,
    value: 'TCD'
  },
  TGO: {
    callingCode: '+228',
    displayName: `${EN_COUNTRY_LABELS.TGO}`,
    value: 'TGO'
  },
  THA: {
    callingCode: '+66',
    displayName: `${EN_COUNTRY_LABELS.THA}`,
    value: 'THA'
  },
  TJK: {
    callingCode: '+992',
    displayName: `${EN_COUNTRY_LABELS.TJK}`,
    value: 'TJK'
  },
  TKL: {
    callingCode: '+690',
    displayName: `${EN_COUNTRY_LABELS.TKL}`,
    value: 'TKL'
  },
  TKM: {
    callingCode: '+993',
    displayName: `${EN_COUNTRY_LABELS.TKM}`,
    value: 'TKM'
  },
  TLS: {
    callingCode: '+670',
    displayName: `${EN_COUNTRY_LABELS.TLS}`,
    value: 'TLS'
  },
  TON: {
    callingCode: '+676',
    displayName: `${EN_COUNTRY_LABELS.TON}`,
    value: 'TON'
  },
  TTO: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.TTO}`,
    value: 'TTO'
  },
  TUN: {
    callingCode: '+216',
    displayName: `${EN_COUNTRY_LABELS.TUN}`,
    value: 'TUN'
  },
  TUR: {
    callingCode: '+90',
    displayName: `${EN_COUNTRY_LABELS.TUR}`,
    value: 'TUR'
  },
  TUV: {
    callingCode: '+688',
    displayName: `${EN_COUNTRY_LABELS.TUV}`,
    value: 'TUV'
  },
  TWN: {
    callingCode: '+886',
    displayName: `${EN_COUNTRY_LABELS.TWN}`,
    value: 'TWN'
  },
  TZA: {
    callingCode: '+255',
    displayName: `${EN_COUNTRY_LABELS.TZA}`,
    value: 'TZA'
  },
  UGA: {
    callingCode: '+256',
    displayName: `${EN_COUNTRY_LABELS.UGA}`,
    value: 'UGA'
  },
  UKR: {
    callingCode: '+380',
    displayName: `${EN_COUNTRY_LABELS.UKR}`,
    value: 'UKR'
  },
  URY: {
    callingCode: '+598',
    displayName: `${EN_COUNTRY_LABELS.URY}`,
    value: 'URY'
  },
  USA: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.USA}`,
    value: 'USA'
  },
  UZB: {
    callingCode: '+998',
    displayName: `${EN_COUNTRY_LABELS.UZB}`,
    value: 'UZB'
  },
  VAT: {
    callingCode: '+379',
    displayName: `${EN_COUNTRY_LABELS.VAT}`,
    value: 'VAT'
  },
  VCT: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.VCT}`,
    value: 'VCT'
  },
  VEN: {
    callingCode: '+58',
    displayName: `${EN_COUNTRY_LABELS.VEN}`,
    value: 'VEN'
  },
  VGB: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.VGB}`,
    value: 'VGB'
  },
  VIR: {
    callingCode: '+1',
    displayName: `${EN_COUNTRY_LABELS.VIR}`,
    value: 'VIR'
  },
  VNM: {
    callingCode: '+84',
    displayName: `${EN_COUNTRY_LABELS.VNM}`,
    value: 'VNM'
  },
  VUT: {
    callingCode: '+678',
    displayName: `${EN_COUNTRY_LABELS.VUT}`,
    value: 'VUT'
  },
  WLF: {
    callingCode: '+681',
    displayName: `${EN_COUNTRY_LABELS.WLF}`,
    value: 'WLF'
  },
  WSM: {
    callingCode: '+685',
    displayName: `${EN_COUNTRY_LABELS.WSM}`,
    value: 'WSM'
  },
  YEM: {
    callingCode: '+967',
    displayName: `${EN_COUNTRY_LABELS.YEM}`,
    value: 'YEM'
  },
  ZAF: {
    callingCode: '+27',
    displayName: `${EN_COUNTRY_LABELS.ZAF}`,
    value: 'ZAF'
  },
  ZMB: {
    callingCode: '+260',
    displayName: `${EN_COUNTRY_LABELS.ZMB}`,
    value: 'ZMB'
  },
  ZWE: {
    callingCode: '+263',
    displayName: `${EN_COUNTRY_LABELS.ZWE}`,
    value: 'ZWE'
  }
} as const as CallingCode

export const AVAILABLE_COUNTRIES_CALLING_CODES = ACCEPTED_COUNTRIES_OPTIONS.map(
  country => EN_CALLING_CODES[country]
)
