import type HttpClient from '../../tastyworks/http'
import {
  BaseResponse,
  parseErrorContainer,
  parseSingleResponse,
  SingleResponse
} from '../../tastyworks/response'
import {
  AUTHENTICACTOR_CONFIRMATION_DESER,
  AuthenticatorConfirmation
} from '../../tastyworks/user'

export class UserAuthenticatorService {
  constructor(private readonly usersMeClient: HttpClient) {}

  async updateConfirmation(confirmationCode: string): Promise<BaseResponse> {
    this.usersMeClient.session.oneTimePassword = confirmationCode
    const helper = await this.usersMeClient.patch(
      '/authenticator/confirmation',
      {}
    )

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }

  async createConfirmation(): Promise<
    SingleResponse<AuthenticatorConfirmation>
  > {
    const helper = await this.usersMeClient.postJson(
      '/authenticator/confirmation',
      {}
    )

    return parseSingleResponse(
      helper,
      AUTHENTICACTOR_CONFIRMATION_DESER.toParser(AuthenticatorConfirmation)
    )
  }
}
