import _ from 'lodash'
import type HttpClient from '../../tastyworks/http'
import type { Params } from '../../tastyworks/request'
import type { ItemsResponse } from '../../tastyworks/response'
import {
  WITHDRAWAL_FEE_DESER,
  WithdrawalFee
} from '../../tastyworks/withdrawal-fee'

export interface WithdrawalFeeParams extends Params {
  bankAccountType?: string
  deliveryMethod?: string
  transferMethod: string
}

export class WithdrawalFeeService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params: WithdrawalFeeParams
  ): Promise<ItemsResponse<WithdrawalFee>> =>
    this.accountNumberClient.indexSimple(
      WITHDRAWAL_FEE_DESER.toParser(WithdrawalFee),
      'withdrawal-fees',
      _.merge({ accountNumber }, params)
    )
}
