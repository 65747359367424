import type { JsonHelper } from '../../../tastyworks'
import {
  ItemsResponse,
  ORDER_EVENT_PARSER,
  orderEvent,
  parseErrorContainer
} from '../../../tastyworks'
import HttpClient from '../../../tastyworks/http'

export class AdminOrderEventsService {
  private readonly adminOrderEventsClient: HttpClient

  constructor(adminOrderEventsClient: HttpClient) {
    this.adminOrderEventsClient = adminOrderEventsClient.nested('accounts')
  }

  readonly getOrderEvents = async (
    accountNumber: string,
    orderId: string
  ): Promise<ItemsResponse<orderEvent>> => {
    return this.adminOrderEventsClient
      .nested(accountNumber)
      .nested('orders')
      .nested(orderId)
      .get('events')
      .then(res => this.populateResult(res))
  }

  private readonly populateResult = (
    helper: JsonHelper
  ): ItemsResponse<orderEvent> => {
    const response = new ItemsResponse<orderEvent>()

    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }

    const data = helper.getChild('data')
    response.items = data.parseArray('items', ORDER_EVENT_PARSER)

    return response
  }
}
