import type { Alpha2Code } from 'i18n-iso-countries'
import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js/max'

export function isValidPhoneNumber(value: string | undefined) {
  const phone = value ?? ''
  return phone ? parsePhoneNumber(phone).isValid() : true
}

const FLAG_UNICODE_POSITION = 127397
export function getFlagEmoji(countryCode: Alpha2Code | CountryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => FLAG_UNICODE_POSITION + char.charCodeAt(0))

  return String.fromCodePoint(...codePoints)
}
