import { readable } from "svelte/store"

/**
 * Return a readable svelte-store observing a BouleArray.
 *
 * Really only suitable for small collections (like BouleSmallCollectionView).
 * Probably works best with a stable (java-final) array reference, but if
 * you're passing a BouleArray around, you can probably do something like:
 *
 *   $: things = bouleArrayStore(thingsBouleArray)
 *
 * Initial intention is to track small stable collections on managers.
 */
export function bouleArrayStore(bouleArray) {
  const initial = bouleArray.jsArray
  return readable(initial, (set) => {
    set(bouleArray.jsArray)
    const tearDown = bouleArray.addSimpleArrayListener((e) => set(e.jsArray))
    return () => tearDown.tearDown()
  })
}
