<script lang="ts">
  import { createEventDispatcher, tick } from "svelte"

  import ModalDialog from "./ModalDialog.svelte"

  export let message: string
  export let title = "Confirm"
  export let cancelLabel = "Cancel"
  export let confirmLabel = "OK"
  export let html = false

  export let show = false
  export let lightbox = false
  export let height = "25vh"
  export let width = "50vw"
  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()

  function cancel() {
    dispatch("close", false)
  }

  function confirm() {
    dispatch("close", true)
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (!show) return

    switch (event.code) {
      case "Escape":
        event.preventDefault()
        // Caller's choice; asyncConfirm will close and destroy.
        cancel()
        break
      case "Tab":
        // No escape from the modal.
        event.preventDefault()
        if (document.activeElement !== cancelButton) {
          cancelButton.focus()
        } else {
          confirmButton.focus()
        }
        break
      default:
        break
    }
  }

  let cancelButton: HTMLButtonElement, confirmButton: HTMLButtonElement

  async function stealFocus(open: boolean) {
    if (open) {
      await tick()
      cancelButton.focus()
    }
  }

  $: stealFocus(show)
</script>

<svelte:window on:keydown={handleKeyDown} />

<ModalDialog
  class="modal-confirm {cssClass}"
  {show}
  {lightbox}
  {height}
  {width}
>
  <div
    slot="title"
    class="
      flex
      flex-grow
      flex-row
      items-center
      justify-between"
  >
    <span class="font-x-large-600 text-general-secondary-text">
      {title}
    </span>
    <button
      class="
        tw-icon-Close
        font-x-large-600
        text-general-secondary-text"
      on:click={cancel}
    />
  </div>

  <svelte:fragment slot="body">
    <span class="whitespace-pre-line">
      {#if html}
        {@html message}
      {:else}
        {message}
      {/if}
    </span>
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <button
      bind:this={cancelButton}
      class="cancel button-base form-action button"
      style:height="calc(1.25 * var(--vertical-meter))"
      style:width="calc(2.5 * var(--horizontal-meter))"
      on:click={cancel}
    >
      {cancelLabel}
    </button>
    <button
      bind:this={confirmButton}
      class="confirm button-base form-action button button-continue"
      style:height="calc(1.25 * var(--vertical-meter))"
      style:width="calc(2.5 * var(--horizontal-meter))"
      on:click={confirm}
    >
      {confirmLabel}
    </button>
  </svelte:fragment>
</ModalDialog>
