import {
  EXCHANGE_AFFILITIONS_DESER,
  ExchangeAffiliation
} from '../../tastyworks/exchange-affiliations'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class ExchangeAffiliationService {
  constructor(private readonly customerClient: HttpClient) {}

  readonly index = async (): Promise<ItemsResponse<ExchangeAffiliation>> =>
    this.customerClient.indexSimple(
      EXCHANGE_AFFILITIONS_DESER.toParser(ExchangeAffiliation),
      'exchange-affiliations'
    )
}
