import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class Referral {
  category = ''
  code = ''
  id = 0
  isActive = false
  referralOwnerType = ''
  startDate = new Date()
  totalQualifiedUsers = 0
  totalUsers = 0

  get pendingCredits() {
    return this.totalUsers - this.totalQualifiedUsers
  }
}

export class ReferralUser extends RestResource {
  credits = 0
  isQualified = false
  promotionalCode = ''
  referral = new Referral()
  referralUsed: Referral | null = null
}

export class CreateReferralUserPayload {
  referralCode = ''
  promotionalCode = ''
}

export const REFERRAL_DESER: SchemaDeSer<Referral> = new SchemaDeSerBuilder(
  Referral
)
  .ofString('category')
  .ofString('code')
  .ofInt('id')
  .ofBoolean('isActive')
  .ofString('referralOwnerType')
  .ofDateTime('startDate')
  .ofInt('totalQualifiedUsers')
  .ofInt('totalUsers')
  .toDeSer()

export const REFERRAL_USER_DESER: SchemaDeSer<ReferralUser> =
  new SchemaDeSerBuilder(ReferralUser)
    .ofInt('credits')
    .ofBoolean('isQualified')
    .ofString('promotionalCode')
    .ofNested('referral', REFERRAL_DESER, Referral)
    .ofNested('referralUsed', REFERRAL_DESER, Referral)
    .toDeSer()

export const CREATE_REFERRAL_USER_PAYLOAD_DESER: SchemaDeSer<CreateReferralUserPayload> =
  new SchemaDeSerBuilder(CreateReferralUserPayload)
    .ofString('referralCode')
    .ofString('promotionalCode')
    .toDeSer()
