import _ from 'lodash'
import {
  DIGITAL_ASSET_WITHDRAWAL_AVAILABILITY_DESER,
  DIGITAL_ASSET_WITHDRAWAL_DESER,
  DigitalAssetWithdrawal,
  DigitalAssetWithdrawalAvailability,
  type DigitalAssetWithdrawalParams
} from '../../tastyworks/digital-asset-withdrawal'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'

export class DigitalAssetWithdrawalService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params?: DigitalAssetWithdrawalParams
  ): Promise<ItemsResponse<DigitalAssetWithdrawal>> =>
    this.accountNumberClient.indexSimple(
      DIGITAL_ASSET_WITHDRAWAL_DESER.toParser(DigitalAssetWithdrawal),
      '/digital-asset-withdrawals',
      _.merge({ accountNumber }, params)
    )

  readonly show = async (
    digitalAssetWithdrawal: DigitalAssetWithdrawal
  ): Promise<SingleResponse<DigitalAssetWithdrawal>> =>
    this.accountNumberClient.show(
      new DigitalAssetWithdrawal(),
      DIGITAL_ASSET_WITHDRAWAL_DESER.update,
      '/digital-asset-withdrawals/:digitalAssetWithdrawalId',
      {
        accountNumber: digitalAssetWithdrawal.accountNumber,
        digitalAssetWithdrawalId: digitalAssetWithdrawal.id
      }
    )

  readonly showAvailability = async (
    asset: string,
    accountNumber: string
  ): Promise<SingleResponse<DigitalAssetWithdrawalAvailability>> =>
    this.accountNumberClient.show(
      new DigitalAssetWithdrawalAvailability(),
      DIGITAL_ASSET_WITHDRAWAL_AVAILABILITY_DESER.update,
      '/digital-asset-withdrawals/availability',
      {
        accountNumber,
        asset
      }
    )

  readonly create = async (
    digitalAssetWithdrawal: DigitalAssetWithdrawal
  ): Promise<SingleResponse<DigitalAssetWithdrawal>> =>
    this.accountNumberClient.create(
      digitalAssetWithdrawal,
      DIGITAL_ASSET_WITHDRAWAL_DESER.serialize,
      DIGITAL_ASSET_WITHDRAWAL_DESER.update,
      '/digital-asset-withdrawals',
      {
        accountNumber: digitalAssetWithdrawal.accountNumber,
        digitalAssetCustomerAddressId:
          digitalAssetWithdrawal.digitalAssetCustomerAddress.id
      }
    )

  readonly execution = async (
    digitalAssetWithdrawal: DigitalAssetWithdrawal
  ): Promise<SingleResponse<DigitalAssetWithdrawal>> =>
    this.accountNumberClient.create(
      new DigitalAssetWithdrawal(),
      DIGITAL_ASSET_WITHDRAWAL_DESER.serialize,
      DIGITAL_ASSET_WITHDRAWAL_DESER.update,
      '/digital-asset-withdrawals/:digitalAssetWithdrawalId/execution',
      {
        accountNumber: digitalAssetWithdrawal.accountNumber,
        digitalAssetWithdrawalId: digitalAssetWithdrawal.id
      }
    )

  readonly refresh = async (
    digitalAssetWithdrawal: DigitalAssetWithdrawal
  ): Promise<SingleResponse<DigitalAssetWithdrawal>> =>
    this.accountNumberClient.create(
      new DigitalAssetWithdrawal(),
      DIGITAL_ASSET_WITHDRAWAL_DESER.serialize,
      DIGITAL_ASSET_WITHDRAWAL_DESER.update,
      '/digital-asset-withdrawals/:digitalAssetWithdrawalId/refresh-quote',
      {
        accountNumber: digitalAssetWithdrawal.accountNumber,
        digitalAssetWithdrawalId: digitalAssetWithdrawal.id
      }
    )
}
