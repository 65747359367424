import {
  SessionErrorHandler,
  TastyworksRest,
  TwRestSession,
} from "@tastyworks/tastyworks-api"
const { Client } = TastyworksRest

import logger from "./logger"

export default (
  baseUrl: string,
  vastBaseUrl: string,
  sessionErrorHandler: SessionErrorHandler,
  twRestSession: TwRestSession
) =>
  new Client(
    baseUrl,
    vastBaseUrl,
    logger,
    fetch.bind(window),
    sessionErrorHandler,
    twRestSession
  )
