import { localStorageStore } from "@tastyworks/ui-library"
import { location } from "svelte-spa-router"
import { cubicOut } from "svelte/easing"
import { tweened } from "svelte/motion"
import { derived, get, writable } from "svelte/store"

import media from "./media"

function toggle() {
  return this.update((state) => !state)
}

function restoreForMedia() {
  return this.set(!get(media).large ? true : false)
}

export const leftSidebarCollapsed = Object.assign(
  localStorageStore("tw-layout-left-sidebar-collapsed"),
  { toggle }
)

export const rightSidebarCollapsed = Object.assign(
  localStorageStore("tw-layout-right-sidebar-collapsed"),
  { restoreForMedia, toggle }
)

export const rightSidebarVisible = Object.assign(
  localStorageStore("tw-layout-right-sidebar-visible", true),
  { toggle }
)

export const rightSidebarHasPopover = writable(false)

export const rightSidebarPinned = Object.assign(writable(false), { toggle })

export const helpCenterPanelVisible = writable(false)

export const drawerOffset = tweened(0, {
  easing: cubicOut,
})

export const isCam = derived(location, ($location) =>
  $location.startsWith("/manage")
)
export const isDashboard = derived(location, ($location) =>
  $location.startsWith("/dashboard")
)
export const isTrading = derived(location, ($location) =>
  $location.startsWith("/trading")
)

export const showNewMenuButtonHighlight = Object.assign(
  localStorageStore("tw-layout-show-new-menu-button-highlight", true)
)

export { default as Layout } from "./Layout.svelte"
export { default as LeftSidebar } from "./LeftSidebar.svelte"
export { default as NotFound } from "./NotFound.svelte"
export { default as SectionRouter } from "./SectionRouter.svelte"
export { default as Header } from "./header/Header.svelte"
export { default as SideNav } from "./side-nav/SideNav.svelte"
export { default as SideNavLink } from "./side-nav/SideNavLink.svelte"
export { media }
