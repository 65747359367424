import _ from 'lodash'
import type { INVESTMENT_OBJECTIVES } from './account'
import { ACCOUNT_BENEFICIARY_DESER, AccountBeneficiary } from './account'
import {
  ACCOUNT_TYPES,
  BENEFICIARY_ACCOUNT_TYPES,
  ENTITY_ACCOUNT_TYPES,
  JOINT_ACCOUNT_TYPES,
  MARGIN_TYPES,
  RETIREMENT_ACCOUNT_TYPES
} from './account-type'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import type { OPTIONS_LEVEL } from './options-level'
import toStringValues from './util/enum'

// AO1
export const EMBER_AUTH_COOKIE_KEY = 'ember_simple_auth-session'
// AO2
export const AO2_AUTH_SESSION_COOKIE_KEY = 'ao2_auth-session'

export enum STATUSES {
  IN_PROGRESS = 'In Progress',
  SUBMITTED = 'Submitted',
  APPROVED = 'Approved',
  SEND_FAILED = 'Send Failed',
  REJECTED = 'Rejected',
  NEEDS_REVIEW = 'Needs Review',
  SUPPORTING_DOCUMENTS_REQUIRED = 'Supporting Documents Required',
  ACTION_REQUIRED = 'Action Required',
  CANCELED = 'Canceled',
  COMPLETE = 'Complete',
  SUPPORTING_DOCUMENTS_APPROVED = 'Supporting Documents Approved',
  NEEDS_PRINCIPAL_APPROVAL = 'Needs Principal Approval',
  IN_REVIEW = 'In Review'
}

export const APPLICATION_STATUS_VALUES = toStringValues(STATUSES)

export const MARGIN_TYPE_VALUES = toStringValues(MARGIN_TYPES)

export enum ACCOUNT_ACTIVITY_TYPES {
  ACTIVE = 'Active Trading',
  LONG_TERM = 'Long Term Investing',
  SHORT_TERM = 'Short Term Investing'
}

export enum EXPECTED_WITHDRAWAL_TYPES {
  FREQUENT = 'FREQUENT',
  OCCASIONAL = 'OCCASIONAL',
  RARE = 'RARE'
}

export enum INITIAL_DEPOSIT_TYPES {
  ACCOUNT_TRANSFER = 'Account Transfer',
  WIRE = 'Wire',
  ACH = 'ACH',
  CHECK = 'Check'
}

export const FOREIGN_INITIAL_DEPOSIT_TYPES = [
  INITIAL_DEPOSIT_TYPES.ACCOUNT_TRANSFER,
  INITIAL_DEPOSIT_TYPES.WIRE
]

export const ENTITY_INITIAL_DEPOSIT_TYPES = Object.values(INITIAL_DEPOSIT_TYPES)

export enum LIQUIDITY_NEEDS {
  VERY_IMPORTANT = 'VERY_IMPORTANT',
  SOMEWHAT_IMPORTANT = 'SOMEWHAT_IMPORTANT',
  NOT_IMPORTANT = 'NOT_IMPORTANT'
}

export enum REFERRAL_SOURCES {
  TASTYLIVE = 'TASTYLIVE',
  INTERNET = 'INTERNET_SEARCH',
  LIVE_EVENTS = 'LIVE_EVENTS',
  PERSONAL_REFERRAL = 'PERSONAL_REFERRAL'
}

export class ApplicantData {
  static onInitialize = (_obj: ApplicantData) => {
    /* no-op */
  }

  constructor() {
    ApplicantData.onInitialize(this)
  }

  acceptedAgreements = false
  email = ''
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  estatePercent = 0.5
  firstName = ''
  lastName = ''
  middleName = ''
  prefixName = ''
  suffixName = ''

  get isComplete() {
    return this.acceptedAgreements
  }

  get fullName() {
    return [
      this.prefixName,
      this.firstName,
      this.middleName,
      this.lastName,
      this.suffixName
    ]
      .filter(n => n)
      .join(' ')
  }
}

export const APPLICANT_DATA_DESER: SchemaDeSer<ApplicantData> =
  new SchemaDeSerBuilder(ApplicantData)
    .ofBoolean('acceptedAgreements')
    .ofString('email')
    .ofFloat('estatePercent')
    .ofString('firstName')
    .ofString('lastName')
    .ofString('middleName')
    .ofString('prefixName')
    .ofString('suffixName')
    .toDeSer()

export class Applicant {
  static onInitialize = (_obj: Applicant) => {
    /* no-op */
  }

  constructor() {
    Applicant.onInitialize(this)
  }

  applicantData = new ApplicantData()

  get isComplete() {
    return this.applicantData.isComplete
  }

  get fullName() {
    return this.applicantData.fullName
  }
}

export const APPLICANT_DESER: SchemaDeSer<Applicant> = new SchemaDeSerBuilder(
  Applicant
)
  .ofNested('applicantData', APPLICANT_DATA_DESER, ApplicantData)
  .toDeSer()

export class AccountOpeningApplication {
  static onInitialize = (_obj: AccountOpeningApplication) => {
    /* no-op */
  }

  constructor() {
    AccountOpeningApplication.onInitialize(this)
  }

  accountTypeName = ACCOUNT_TYPES.INDIVIDUAL
  id = '0'
  investmentObjective: INVESTMENT_OBJECTIVES | null = null
  marginOrCash = 'Margin'
  optionsLevel: OPTIONS_LEVEL | null = null
  status = STATUSES.IN_PROGRESS
  entityId: number | null | undefined = null
  externalReason = ''
  accountNumber = ''
  applicants: Applicant[] = []
  accountBeneficiaries: AccountBeneficiary[] = []
  decedentName: string | null = null
  isForeign = false

  accountActivityType: ACCOUNT_ACTIVITY_TYPES | null = null
  primaryBanking: string | null = null
  initialDepositType: INITIAL_DEPOSIT_TYPES | null = null
  valueInitialDeposit: number | null = null
  expectedWithdrawals: EXPECTED_WITHDRAWAL_TYPES | null = null
  letterOfExplanation: string | null = null
  liquidityNeeds: LIQUIDITY_NEEDS | null = null
  source = ''
  initialContact: REFERRAL_SOURCES | null = null
  referredName: string | null = null
  referredRelationship: string | null = null
  isReferredToBroker: boolean | null = null

  get isRetirement(): boolean {
    return _.includes(RETIREMENT_ACCOUNT_TYPES, this.accountTypeName)
  }

  get isJoint(): boolean {
    return _.includes(JOINT_ACCOUNT_TYPES, this.accountTypeName)
  }

  get isBeneficiary(): boolean {
    return _.includes(BENEFICIARY_ACCOUNT_TYPES, this.accountTypeName)
  }

  get isEntity(): boolean {
    return _.includes(ENTITY_ACCOUNT_TYPES, this.accountTypeName)
  }

  get isInProgress(): boolean {
    return this.status === STATUSES.IN_PROGRESS
  }

  get isSubmitted(): boolean {
    return this.status === STATUSES.SUBMITTED
  }

  get isApproved(): boolean {
    return this.status === STATUSES.APPROVED
  }

  get isSendFailed(): boolean {
    return this.status === STATUSES.SEND_FAILED
  }

  get isRejected(): boolean {
    return this.status === STATUSES.REJECTED
  }

  get isNeedsReview(): boolean {
    return this.status === STATUSES.NEEDS_REVIEW
  }

  get isSupportingDocumentsRequired(): boolean {
    return this.status === STATUSES.SUPPORTING_DOCUMENTS_REQUIRED
  }

  get isActionRequired(): boolean {
    return this.status === STATUSES.ACTION_REQUIRED
  }

  get isCanceled(): boolean {
    return this.status === STATUSES.CANCELED
  }

  get isComplete(): boolean {
    return this.status === STATUSES.COMPLETE
  }

  get isProcessing(): boolean {
    return (
      !this.isCanceled &&
      !this.isRejected &&
      !this.isInProgress &&
      !this.isComplete &&
      !this.isSendFailed
    )
  }
}

export const ACCOUNT_OPENING_APPLICATION_DESER: SchemaDeSer<AccountOpeningApplication> =
  new SchemaDeSerBuilder(AccountOpeningApplication)
    .ofString('accountTypeName')
    .ofString('id')
    .ofString('investmentObjective')
    .ofString('marginOrCash')
    .ofString('optionsLevel')
    .ofString('status')
    .ofInt('entityId')
    .ofString('externalReason')
    .ofString('accountNumber')
    .ofString('letterOfExplanation')
    .ofArray('applicants', APPLICANT_DESER, Applicant)
    .ofBoolean('isForeign')
    .ofArray(
      'accountBeneficiaries',
      ACCOUNT_BENEFICIARY_DESER,
      AccountBeneficiary
    )
    .ofString('decedentName')
    .ofString('accountActivityType')
    .ofString('primaryBanking')
    .ofString('initialContact')
    .ofString('referredName')
    .ofString('referredRelationship')
    .ofBoolean('isReferredToBroker')
    .ofString('initialDepositType')
    .ofFloat('valueInitialDeposit')
    .ofString('expectedWithdrawals')
    .ofString('liquidityNeeds')
    .ofString('source')
    .toDeSer()

export const ACCOUNT_OPENING_APPLICATION_PARSER =
  ACCOUNT_OPENING_APPLICATION_DESER.toParser(AccountOpeningApplication)
