import { Balance, BALANCE_DESER } from '../../tastyworks/balance'
import type HttpClient from '../../tastyworks/http'

export class BalanceService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = async (accountNumber: string) =>
    this.accountNumberClient.show(
      new Balance(),
      BALANCE_DESER.update,
      'balances',
      { accountNumber }
    )
}
