import { recursiveDasherizeKeys } from '../tastyworks'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import type { JsonMap } from './util/json'
import { JsonHelper } from './util/json'

export const VALID_MESSAGE_ORIGINS = [
  'https://tastyworks.web.amer-1.jumio.ai',
  'https://tastyworks.web.emea-1.jumio.ai',
  'https://tastyworks.web.apac-1.jumio.ai'
]
export const JUMIO_TIMEOUT_ERROR_CODE = 9210
export const JUMIO_LOADING_TIMEOUT = 10_000
export const NO_MATCHING_SLUG_ERROR =
  'No matching slug for document verification type'

export enum IdentityVerificationTypes {
  DIGITAL_ASSET_TRANSFERS = 'digital-asset-transfers'
}

export enum SUPPORTING_DOCUMENT_VERIFICATION_STATUSES {
  COMPLETE = 'Complete',
  FAILED = 'Failed',
  PENDING = 'Pending',
  UPLOADED = 'Uploaded',
  UPLOAD_FAILED = 'Upload Failed'
}

export enum SUPPORTING_DOCUMENT_VERIFICATION_TYPES {
  ADDRESS = 'AddressVerification',
  IDENTITY = 'IdentityVerification'
}

enum SUPPORTING_DOCUMENT_MESSAGE_STATUSES {
  ERROR = 'error',
  LOADED = 'loaded',
  SUCCESS = 'success'
}

export enum ADDRESS_DOCUMENT_TYPE {
  BankStatement = 'Bank Statement',
  CreditCardStatement = 'Credit Card Statement',
  UtilityBill = 'Utility Bill'
}

export class SupportingDocumentVerificationUrl {
  iframeUrl = ''
  transactionReference = ''
}

export const SUPPORTING_DOCUMENT_VERIFICATION_URL_DESER: SchemaDeSer<SupportingDocumentVerificationUrl> =
  new SchemaDeSerBuilder(SupportingDocumentVerificationUrl)
    .ofString('iframeUrl')
    .ofString('transactionReference')
    .toDeSer()

/**
 * Jumio iframe posts messages to a window message event listener. The possible events posted are SUPPORTING_DOCUMENT_MESSAGE_STATUSES (see above)
 * Work based on HR's CAM1.0 work. ref: https://github.com/tastyworks/tastyworks-api-ember/blob/master/addon/models/document-verification-message.js
 */
export class SupportingDocumentMessage {
  customerInternalReference = ''
  eventType = 0
  dateTime = ''
  transactionReference = ''
  payload: SupportingDocumentMessagePayload =
    new SupportingDocumentMessagePayload()

  get hasLoadedStatus() {
    return this.payload.value === SUPPORTING_DOCUMENT_MESSAGE_STATUSES.LOADED
  }

  get hasSuccessStatus() {
    return this.payload.value === SUPPORTING_DOCUMENT_MESSAGE_STATUSES.SUCCESS
  }

  get hasErrorStatus() {
    return this.payload.value === SUPPORTING_DOCUMENT_MESSAGE_STATUSES.ERROR
  }

  get hasTimeoutError() {
    return this.payload.metainfo.code === JUMIO_TIMEOUT_ERROR_CODE
  }
}

class SupportingDocumentMessageMetaInfo {
  code = 0
}

export const SUPPORTING_DOCUMENT_MESSAGE_METAINFO_DESER: SchemaDeSer<SupportingDocumentMessageMetaInfo> =
  new SchemaDeSerBuilder(SupportingDocumentMessageMetaInfo)
    .ofInt('code')
    .toDeSer()

class SupportingDocumentMessagePayload {
  value = ''
  metainfo: SupportingDocumentMessageMetaInfo =
    new SupportingDocumentMessageMetaInfo()
}

export const SUPPORTING_DOCUMENT_MESSAGE_PAYLOAD_DESER: SchemaDeSer<SupportingDocumentMessagePayload> =
  new SchemaDeSerBuilder(SupportingDocumentMessagePayload)
    .ofString('value')
    .ofNested(
      'metainfo',
      SUPPORTING_DOCUMENT_MESSAGE_METAINFO_DESER,
      SupportingDocumentMessageMetaInfo
    )
    .toDeSer()

export const SUPPORTING_DOCUMENT_MESSAGE_DESER: SchemaDeSer<SupportingDocumentMessage> =
  new SchemaDeSerBuilder(SupportingDocumentMessage)
    .ofString('customerInternalReference')
    .ofInt('eventType')
    .ofString('dateTime')
    .ofString('transactionReference')
    .ofNested(
      'payload',
      SUPPORTING_DOCUMENT_MESSAGE_PAYLOAD_DESER,
      SupportingDocumentMessagePayload
    )
    .toDeSer()

/**
 * parses supporting document message string payload
 *
 * @param data - string (please pass serialized json strings only)
 * @returns SupportingDocumentMesage
 */
export function parseSupportingDocumentMessage(
  data: string
): SupportingDocumentMessage {
  const payload: JsonMap = JSON.parse(data) as JsonMap
  const jsonHelper = new JsonHelper(recursiveDasherizeKeys(payload))

  const parser = SUPPORTING_DOCUMENT_MESSAGE_DESER.toParser(
    SupportingDocumentMessage
  )
  return parser(jsonHelper)
}

/**
 * checks if origin is from an acceptable source
 * @param messageOrigin
 * @returns
 */
export function isValidOrigin(messageOrigin: string): boolean {
  return VALID_MESSAGE_ORIGINS.some(origin => messageOrigin.includes(origin))
}
