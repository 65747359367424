// https://github.com/christsim/multicoin-address-validator/blob/master/src/bch_validator.js

import BTCValidator from "./bitcoin_validator"
import bech32 from "./crypto/bech32"
import cryptoUtils from "./crypto/utils"

function validateAddress(address, currency, opts) {
  const networkType = opts ? opts.networkType : ""
  let prefix = "bitcoincash"
  const regexp = new RegExp(currency.regexp)
  let raw_address

  const res = address.split(":")
  if (res.length === 1) {
    raw_address = address
  } else {
    if (res[0] !== "bitcoincash") {
      return false
    }
    raw_address = res[1]
  }

  if (!regexp.test(raw_address)) {
    return false
  }

  if (
    raw_address.toLowerCase() !== raw_address &&
    raw_address.toUpperCase() !== raw_address
  ) {
    return false
  }

  const decoded = cryptoUtils.base32.b32decode(raw_address)
  if (networkType === "testnet") {
    prefix = "bchtest"
  }

  try {
    if (bech32.verifyChecksum(prefix, decoded, bech32.encodings.BECH32)) {
      return false
    }
  } catch (e) {
    return false
  }
  return true
}

export default {
  isValidAddress: function (address, currency, networkType) {
    return (
      validateAddress(address, currency, networkType) ||
      BTCValidator.isValidAddress(address, currency, networkType)
    )
  },
}
