import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { RestResource } from './common'
import { SchemaDeSerBuilder, type SchemaDeSer } from './deser'
import {
  DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER,
  DigitalAssetCustomerAddress
} from './digital-asset-customer-address'
import type { PaginationParams } from './request'

export enum DIGITAL_ASSET_WITHDRAWAL_STATUSES {
  AWAITING_EXECUTION = 'Awaiting Execution',
  EXPIRED = 'Expired',
  CLEARING_APPROVED = 'Clearing Approved',
  CLEARING_PROCESSED = 'Clearing Processed',
  CLEARING_REJECTED = 'Clearing Rejected',
  CLEARING_SETTLED = 'Clearing Settled',
  SUBMITTED_TO_CLEARING = 'Submitted to Clearing'
}

export enum ON_CHAIN_STATUS {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING'
}

export interface DigitalAssetWithdrawalParams extends PaginationParams {
  status?: DIGITAL_ASSET_WITHDRAWAL_STATUSES
  asset?: string
}

export class DigitalAssetWithdrawalAvailability extends RestResource {
  quantityAvailable = new BigNumber(0)
}

export const DIGITAL_ASSET_WITHDRAWAL_AVAILABILITY_DESER: SchemaDeSer<DigitalAssetWithdrawalAvailability> =
  new SchemaDeSerBuilder(DigitalAssetWithdrawalAvailability)
    .ofBigNumber('quantityAvailable')
    .toDeSer()

const QUOTE_EXPIRY_INTERVAL = 25
export class DigitalAssetWithdrawal extends RestResource {
  accountNumber = ''
  asset = ''
  createdAt?: Date
  digitalAssetCustomerAddress = new DigitalAssetCustomerAddress()
  feeNotionalValue = new BigNumber(0)
  feeQuantity = new BigNumber(0)
  netNotionalValue = new BigNumber(0)
  netQuantity = new BigNumber(0)
  notionalValue = new BigNumber(0)
  onChainStatus = ON_CHAIN_STATUS.PENDING
  onChainTransactionHash = ''
  quantity = new BigNumber(0)
  status = DIGITAL_ASSET_WITHDRAWAL_STATUSES.AWAITING_EXECUTION
  updatedAt?: Date

  /**
   * Calculate the remaining time left on the quote before it expires. It bases
   * this on the updatedAt time and applies a buffer/interval to calculate the expected
   * expiry time in ZeroHash. This isn't going to be exactly accurate because we don't
   * have access to the expiry time, however it should return a number that is lower
   * than the actual time to expiry in ZH (in order to account for latency and to prevent
   * the user from having their withdrawal rejected due to timeouts).
   *
   * @param expiryInterval An interval which is added to the updatedAt time to calculate
   *                       the updatedAt time to estimate the actual expiry time. Default to
   *                       25 to account for network latency etc.
   * @returns The number of seconds left until the quote expires
   */
  secondsToExpiry(expiryInterval: number = QUOTE_EXPIRY_INTERVAL) {
    const updatedAtHelper = dayjs(this.updatedAt)
    const expiresAt = updatedAtHelper.add(expiryInterval, 'second')

    return Math.max(0, expiresAt.diff(dayjs(), 'second'))
  }

  get isClearingSettled() {
    return this.status === DIGITAL_ASSET_WITHDRAWAL_STATUSES.CLEARING_SETTLED
  }
}

export const DIGITAL_ASSET_WITHDRAWAL_DESER: SchemaDeSer<DigitalAssetWithdrawal> =
  new SchemaDeSerBuilder(DigitalAssetWithdrawal)
    .ofString('id')
    .ofString('accountNumber')
    .ofString('asset')
    .ofDateTime('createdAt')
    .ofNested(
      'digitalAssetCustomerAddress',
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER,
      DigitalAssetCustomerAddress
    )
    .ofBigNumber('feeNotionalValue')
    .ofBigNumber('feeQuantity')
    .ofBigNumber('netNotionalValue')
    .ofBigNumber('netQuantity')
    .ofBigNumber('notionalValue')
    .ofString('onChainStatus')
    .ofString('onChainTransactionHash')
    .ofBigNumber('quantity')
    .ofString('status')
    .ofDateTime('updatedAt')
    .toDeSer()
