import type { SupportingDocumentRequest } from '../../tastyworks/customer'
import { SUPPORTING_DOCUMENT_REQUEST_PARSER } from '../../tastyworks/customer'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class SupportingDocumentRequestService {
  private readonly supportingDocumentRequestsClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.supportingDocumentRequestsClient = customerMeClient.nested(
      'supporting-document-requests'
    )
  }

  readonly index = async (): Promise<
    ItemsResponse<SupportingDocumentRequest>
  > =>
    this.supportingDocumentRequestsClient.indexSimple(
      SUPPORTING_DOCUMENT_REQUEST_PARSER
    )
}
