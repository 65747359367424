import { RestResource } from './common'
import { SchemaDeSerBuilder } from './deser'
import type { SchemaDeSer } from './deser'

export enum ExpirationMonitoringStatus {
  Active = 'Active',
  Available = 'Available',
  Inactive = 'Inactive',
  Unavailable = 'Unavailable'
}

export class ExpirationMonitoringEligibility extends RestResource {
  accountNumber = ''
  currentExpirationMonitoringEntryId = ''
  status: ExpirationMonitoringStatus = ExpirationMonitoringStatus.Unavailable
}

export const EXPIRATION_MONITORING_ELIGIBILITY_DESER: SchemaDeSer<ExpirationMonitoringEligibility> =
  new SchemaDeSerBuilder(ExpirationMonitoringEligibility)
    .ofString('accountNumber')
    .ofString('currentExpirationMonitoringEntryId')
    .ofString('status')
    .toDeSer()

export class ExpirationMonitoringEntry extends RestResource {
  accountNumber = ''
  active = false
  createdAt = new Date()
  monitoringDate = new Date()
  updatedAt = new Date()
}

export const EXPIRATION_MONITORING_ENTRY_DESER: SchemaDeSer<ExpirationMonitoringEntry> =
  new SchemaDeSerBuilder(ExpirationMonitoringEntry)
    .ofString('accountNumber')
    .ofBoolean('active')
    .ofDateTime('createdAt')
    .ofDate('monitoringDate')
    .ofDateTime('updatedAt')
    .toDeSer()
