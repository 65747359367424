import {
  CustomerSuitability,
  CustomerSuitabilityOptionsLevelChange,
  SUITABILITY_DESER,
  SUITABILITY_OPTIONS_LEVEL_CHANGE_PARSER
} from '../../tastyworks/customer'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { parseItemsResponse } from '../../tastyworks/response'
import { JsonBuilder } from '../../tastyworks/util/json'

export class SuitabilityService {
  private readonly suitabilityClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.suitabilityClient = customerMeClient.nested('suitability')
  }

  readonly show = async (): Promise<SingleResponse<CustomerSuitability>> =>
    this.suitabilityClient.show(
      new CustomerSuitability(),
      SUITABILITY_DESER.update
    )

  readonly create = async (
    suitability: CustomerSuitability
  ): Promise<SingleResponse<CustomerSuitability>> =>
    this.suitabilityClient.create(
      suitability,
      SUITABILITY_DESER.serialize,
      SUITABILITY_DESER.update
    )

  readonly update = async (
    suitability: CustomerSuitability
  ): Promise<SingleResponse<CustomerSuitability>> =>
    this.suitabilityClient.update(
      suitability,
      SUITABILITY_DESER.serialize,
      SUITABILITY_DESER.update
    )

  readonly calculateOptionsLevelChanges = async (
    suitability: CustomerSuitability
  ): Promise<ItemsResponse<CustomerSuitabilityOptionsLevelChange>> => {
    const builder = new JsonBuilder()
    SUITABILITY_DESER.serialize(suitability, builder)
    const helper = await this.suitabilityClient.postJson(
      'calculate-options-level-changes',
      builder.json
    )
    return parseItemsResponse<CustomerSuitabilityOptionsLevelChange>(
      helper,
      SUITABILITY_OPTIONS_LEVEL_CHANGE_PARSER
    )
  }
}
