import type HttpClient from '../../tastyworks/http'

const SUBSCRIPTION_PATH = 'web-push-subscriptions'
const PUBLIC_KEY_PATH = 'web-push-public-key'

interface TwWebPushSubscription {
  endpoint: string
  p256dh: string
  auth: string
}

const toJson = (subscription: PushSubscription): TwWebPushSubscription => {
  const json = subscription.toJSON()
  const keys = json.keys
  if (!keys) {
    throw new Error('No keys provided')
  }
  return {
    endpoint: subscription.endpoint,
    p256dh: keys.p256dh,
    auth: keys.auth
  }
}

export class WebPushService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly fetchPublicKey = async (): Promise<Uint8Array> => {
    const json = await this.customerMeClient.get(PUBLIC_KEY_PATH)
    const base64 = json.getChild('data').getString('public-key')
    const binary = atob(base64)
    const buffer = new Uint8Array(binary.length)
    new TextEncoder().encodeInto(binary, buffer)
    return buffer
  }

  readonly register = async (subscription: PushSubscription): Promise<void> => {
    const body = toJson(subscription)
    await this.customerMeClient.postJson(SUBSCRIPTION_PATH, body)
  }

  readonly unregister = async (
    subscription: PushSubscription
  ): Promise<void> => {
    const body = toJson(subscription)
    await this.customerMeClient.doDelete(
      SUBSCRIPTION_PATH,
      undefined,
      undefined,
      body
    )
  }
}
