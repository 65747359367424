import type { Account } from '../../tastyworks/account'
import type HttpClient from '../../tastyworks/http'
import {
  ACCOUNT_OPTIONS_LEVEL_DESER,
  AccountOptionsLevel,
  OPTIONS_LEVEL_RESPONSE_DESER,
  OptionsLevelResponse,
  type OPTIONS_LEVEL
} from '../../tastyworks/options-level'
import { recursiveDasherizeKeys } from '../../tastyworks/request'
import { SingleResponse, updateSingleResponse } from '../../tastyworks/response'
import type { AccountService } from './account'

export class OptionsLevelService {
  constructor(
    private readonly accountNumberClient: HttpClient,
    private readonly accountService: AccountService,
    private readonly baseHttpClient: HttpClient
  ) {}

  readonly showAvailable = async (
    accountNumber: string
  ): Promise<SingleResponse<OptionsLevelResponse>> =>
    this.accountNumberClient.show(
      new OptionsLevelResponse(),
      OPTIONS_LEVEL_RESPONSE_DESER.update,
      '/available-option-level',
      { accountNumber }
    )

  readonly update = async (
    account: Account,
    optionsLevel: OPTIONS_LEVEL
  ): Promise<SingleResponse<Account>> =>
    this.accountService.update(
      account,
      recursiveDasherizeKeys({
        tradingStatus: { optionsLevel }
      })
    )

  readonly determineOptionsLevel = async (
    annualNetIncome: number,
    netWorth: number,
    liquidNetWorth: number,
    coveredOptionsTradingExperience: string,
    uncoveredOptionsTradingExperience: string,
    stockTradingExperience: string,
    futuresTradingExperience: string,
    investmentObjective: string
  ): Promise<SingleResponse<OptionsLevelResponse>> => {
    const result = await this.baseHttpClient.postJson(
      '/determine-options-level',
      recursiveDasherizeKeys({
        annualNetIncome,
        netWorth,
        liquidNetWorth,
        coveredOptionsTradingExperience,
        uncoveredOptionsTradingExperience,
        stockTradingExperience,
        futuresTradingExperience,
        investmentObjective
      })
    )

    return updateSingleResponse(
      result,
      new OptionsLevelResponse(),
      OPTIONS_LEVEL_RESPONSE_DESER.update
    )
  }

  readonly calculateOptionsLevelChange = async (
    accountNumber: string,
    investmentObjective: string
  ) => {
    const result = await this.accountNumberClient.postJson(
      '/calculate-options-level-change',
      { 'investment-objective': investmentObjective },
      { accountNumber }
    )

    return updateSingleResponse(
      result,
      new AccountOptionsLevel(),
      ACCOUNT_OPTIONS_LEVEL_DESER.update
    )
  }
}
