<svelte:options immutable />

<script context="module">
  /**
   * BoulePropertyWrapper
   *
   * Useful for wrapping UI elements whose state is dependent on a
   * BouleProperty. Manages listeners (observers) on the caller's behalf.
   *
   * Note: this only works with newer-style BouleProperty objects; older KVO
   * events using BouleObject.addObserver(...) are not supported (though they
   * may be transitioned to using BouleProperty objects).
   *
   * Example use:
   *
   *   <BoulePropertyWrapper bouleProperty={...} let:value={disabled}>
   *     <button {disabled} on:click={...}>
   *       ...
   *     </button>
   *   </BoulePropertyWrapper>
   *
   * More complex use (eg. where you need both old and new value) requires use
   * of the change event:
   *
   *   function handleChange(event) {
   *     const { oldValue, newValue } = event.detail
   *     ...
   *   }
   *
   *   <BoulePropertyWrapper bouleProperty={...} on:change={handleChange}>
   *     ...
   *   </BoulePropertyWrapper>
   */
  import goog from "/@lib/boulangerie"
  const BouleIntegerReadOnlyProperty = goog.module.get(
    "com.dough.boule.BouleIntegerReadOnlyProperty"
  )
</script>

<script>
  import { createEventDispatcher, onDestroy } from "svelte"

  const dispatch = createEventDispatcher()

  export let bouleProperty

  // Need to use the primitive addIntegerListener and .value (.getValue())
  // interfaces to BouleIntegerProperty objects since J2CL handles Integer
  // autoboxing differently to Boolean and Double (doesn't unbox --
  // listenerHandler receives a java.lang.Integer object).

  function addListener(func) {
    if (bouleProperty instanceof BouleIntegerReadOnlyProperty) {
      return bouleProperty.addIntegerListener(func)
    }

    // Assumes BouleReadOnlyProperty<T>.addListener sends something usable.
    // This sends (oldValue, newValue); strip the oldValue arg -- assume func
    // only receives the new value.
    return bouleProperty.addListener((_oldValue, newValue) => func(newValue))
  }

  function getValue() {
    if (bouleProperty instanceof BouleIntegerReadOnlyProperty) {
      return bouleProperty.value
    }

    return bouleProperty.get()
  }

  function listenerHandler(newValue) {
    dispatch("change", { newValue, oldValue: value })
    value = newValue
  }

  function onUpdateProperty(property) {
    listener?.tearDown()
    return property ? [getValue(), addListener(listenerHandler)] : [null, null]
  }

  $: [value, listener] = onUpdateProperty(bouleProperty)

  onDestroy(() => {
    listener?.tearDown()
  })
</script>

<slot {value} />
