import { cva } from "class-variance-authority";
import Body from "./Body.svelte";
import BodyCell from "./BodyCell.svelte";
import BodyRow from "./BodyRow.svelte";
import BodyRowEmpty from "./BodyRowEmpty.svelte";
import Header from "./Header.svelte";
import HeaderCell from "./HeaderCell.svelte";
import { default as TableRoot } from "./Table.svelte";
export const Table = {
    Root: TableRoot,
    Body,
    BodyCell,
    BodyRow,
    BodyRowEmpty,
    Header,
    HeaderCell,
};
/**
 * decomposing is when we break a whole 'number' apart into whole numbers, that can be used for grid spans
 * @param expectedParts = 4
 * @param whole availableColsSpace = 12 - 4 = 8
 */
export function decomposeNumberEqually(expectedParts, whole) {
    if (expectedParts === 0) {
        return [];
    }
    const quotient = Math.floor(whole / expectedParts); /** 8 / 3 = 2 */
    const remainder = whole % expectedParts; /** 8 % 3 = 2 */
    const compose = Array(expectedParts).fill(quotient); /** [2, 2, 2, 2] */
    if (remainder !== 0) {
        // just equally distribute the remainder
        for (let i = 0; i < remainder; i++) {
            compose[i % expectedParts] += 1;
        } /** [3, 3, 2, 2] */
    }
    return compose;
}
/**
 * derives the span of each cell from the node's data-fixed-span attribute; otherwise distributes the available space equally
 * @param elementCells cells in a given row
 * @param availableSpace the number of columns available in the row
 * @returns an array of numbers representing the span of each cell
 */
export function autoAllocateGridSpans(elementCells, availableSpace) {
    if (elementCells?.length === 0) {
        return [];
    }
    let expectedParts = elementCells.length;
    const colSpans = elementCells.map((cell) => {
        const spanAttr = cell.getAttribute("data-fixed-span");
        const fixedGridSpan = parseInt(spanAttr);
        if (!Number.isNaN(fixedGridSpan)) {
            availableSpace -= fixedGridSpan;
            expectedParts -= 1;
            return fixedGridSpan;
        }
        // zero will be a placeholder for the remainder calculated in the next step
        return 0;
    }); /** [0, 0, 2, 0, 2] */
    const equallyDistributedRemainingSpans = decomposeNumberEqually(expectedParts, availableSpace); /** [3,3,2] */
    let pointer = 0;
    // insert back fixed span
    for (let index = 0; index < colSpans.length; index++) {
        const fixedSpan = colSpans[index];
        if (fixedSpan === 0) {
            /** [3, 3, _2, 2], 2nd: [3, 3, _2, 2, _2] */
            colSpans[index] = equallyDistributedRemainingSpans[pointer++];
        }
    }
    return colSpans; /** [4,3,3,2] */
}
// in-source test suites
if (import.meta.vitest) {
    const { it, expect, beforeEach } = import.meta.vitest;
    let fakeElementCells;
    const availableSpace = 12;
    beforeEach(() => {
        fakeElementCells = [...Array(4)]
            .fill(1)
            .map(() => document.createElement("div"));
    });
    it("autoAllocateGridSpans - all spans equal", () => {
        expect(autoAllocateGridSpans(fakeElementCells, availableSpace)).toEqual([
            3, 3, 3, 3,
        ]);
    });
    it("autoAllocateGridSpans - one fixed", () => {
        fakeElementCells[0].setAttribute("data-fixed-span", String(4));
        expect(autoAllocateGridSpans(fakeElementCells, availableSpace)).toEqual([
            4, 3, 3, 2,
        ]);
    });
    it("autoAllocateGridSpans - two fixed", () => {
        fakeElementCells[0].setAttribute("data-fixed-span", String(4));
        fakeElementCells[1].setAttribute("data-fixed-span", String(2));
        expect(autoAllocateGridSpans(fakeElementCells, availableSpace)).toEqual([
            4, 2, 3, 3,
        ]);
    });
    it("autoAllocateGridSpans - all fixed", () => {
        fakeElementCells[0].setAttribute("data-fixed-span", String(3));
        fakeElementCells[1].setAttribute("data-fixed-span", String(2));
        fakeElementCells[2].setAttribute("data-fixed-span", String(3));
        fakeElementCells[3].setAttribute("data-fixed-span", String(4));
        expect(autoAllocateGridSpans(fakeElementCells, availableSpace)).toEqual([
            3, 2, 3, 4,
        ]);
    });
    it("autoAllocateGridSpans - no elements", () => {
        expect(autoAllocateGridSpans([], availableSpace)).toEqual([]);
    });
}
export const tableVariants = cva("w-full", {
    variants: {
        variant: {
            default: "",
            compact: "",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
export const bodyCellVariants = cva("body-cell p-extra-small font-small-500 text-ellipsis whitespace-nowrap", {
    variants: {
        variant: {
            default: "leading-10",
            compact: "leading-7",
        },
        responsive: {
            true: "max-md:leading-8",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
export const bodyRowVariants = cva(`body-row grid border-b border-divider-horizontal-end [&>*:first-child]:pl-2`, {
    variants: {
        variant: {
            default: "leading-10",
            compact: "leading-7",
        },
        responsive: {
            true: "max-md:!grid-cols-1 max-md:leading-8 max-md:[&>*:first-child]:!pl-0",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
export const headerVariants = cva(`header grid text-general-default-text [&>*:first-child]:pl-2`, {
    variants: {
        variant: {
            default: "leading-4",
            compact: "",
        },
        responsive: {
            true: " max-md:hidden",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
export const headerCellVariants = cva(`header-cell p-extra-small font-x-small-600-caps truncate bg-table-column-header py-2 text-general-primary-label`, {
    variants: {
        variant: {
            default: "",
            compact: "",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
export const bodyRowEmptyVariant = cva("p-extra-small col-span-full border-b border-divider-horizontal-end text-center ", {
    variants: {
        variant: {
            default: "leading-10",
            compact: "leading-4",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
export const bodyVariant = cva("", {
    variants: {
        variant: {
            default: "",
            oddPrimary: "[&>*:nth-child(odd)]:bg-general-primary-surface",
            oddSecondary: "[&>*:nth-child(odd)]:bg-general-secondary-surface",
            evenPrimary: "[&>*:nth-child(even)]:bg-general-primary-surface",
            evenSecondary: "[&>*:nth-child(even)]:bg-general-secondary-surface",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
