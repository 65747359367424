<script context="module">
  import goog from "/@lib/boulangerie"
  const OrderEntryViewPreferences = goog.module.get(
    "com.dough.preference.ui.OrderEntryViewPreferences"
  )
  const DELAY_BEFORE_SENDING_ORDERS =
    OrderEntryViewPreferences.CONFIRMATION_PAUSE_LENGTH_OPTIONS.jsArray
</script>

<script>
  import { appSession } from "/@lib/boulangerie"
  import DropdownField from "/@/trading/settings/control/DropdownField.svelte"
  import CheckBoxField from "/@/trading/settings/control/CheckBoxField.svelte"

  const { orderEntryViewPreferences, tradingPreferences } = appSession
</script>

<div class="trade-order grid grid-cols-1 gap-small">
  <div
    class="
      grid
      grid-cols-2
      gap-x-extra-large
      gap-y-medium"
  >
    <DropdownField
      label="Single Leg Order Default Price"
      preferenceField={tradingPreferences.optionSingleLegDefaultPriceField()}
    />
    <DropdownField
      label="Millisecond Delay before Send Order enabled"
      options={DELAY_BEFORE_SENDING_ORDERS}
      preferenceField={orderEntryViewPreferences.confrmationPauseLengthProperty()}
    />
    <DropdownField
      label="Default Order Time-in-Force"
      preferenceField={tradingPreferences.defaultOrderTimeInForceField()}
    />
    <DropdownField
      label="Default Bracket Order Time-in-Force"
      preferenceField={tradingPreferences.defaultBracketOrderTimeInForceField()}
    />
    <DropdownField
      label="Default Order Type"
      preferenceField={tradingPreferences.defaultOrderTypeField()}
    />
  </div>
  <div class="grid grid-cols-1">
    <CheckBoxField
      label="Orders ignore position size, always use default quantity"
      preferenceField={orderEntryViewPreferences.useDefaultQuantityToCloseProperty()}
    />
    <CheckBoxField
      label="Duplicate trade immediately after send"
      preferenceField={orderEntryViewPreferences.duplicateTradeAfterSuccessfulPlacementProperty()}
    />
  </div>
</div>
