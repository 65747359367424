<script context="module">
  import goog from "/@lib/boulangerie"
  const UiPositionEntry = goog.module.get("tasty.ui.boule.UiPositionEntry")
</script>

<script>
  import BoulePlainText from "/@/boule/control/BoulePlainText.svelte"
  import SecurityEntryPill from "./SecurityEntryPill.svelte"

  export let positionEntry
  export let alwaysVisible = false
  let cssClass = ""
  export { cssClass as class }
</script>

<SecurityEntryPill
  class="position-entry-pill {cssClass}"
  securityEntry={positionEntry}
  {alwaysVisible}
>
  <BoulePlainText
    slot="quantity"
    class="field quantity"
    source={positionEntry}
    field={UiPositionEntry.QUANTITY}
  />
  <BoulePlainText
    slot="expiration"
    class="field expiration"
    source={positionEntry}
    field={UiPositionEntry.EXPIRATION}
  />
  <BoulePlainText
    slot="days-to-expiration"
    class="field days-to-expiration"
    source={positionEntry}
    field={UiPositionEntry.DAYS_TO_EXPIRATION}
  />
  <BoulePlainText
    slot="strike"
    class="field strike"
    source={positionEntry}
    field={UiPositionEntry.STRIKE_PRICE}
  />
  <BoulePlainText
    slot="call-or-put"
    class="field call-or-put"
    source={positionEntry}
    field={UiPositionEntry.OPTION_TYPE}
  />
</SecurityEntryPill>
