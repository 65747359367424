import type { PublicDocument } from '../../tastyworks/document'
import {
  CUSTOMER_ACCOUNT_AGREEMENT_DESER,
  CustomerAccountAgreement
} from '../../tastyworks/document'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'
import { parseItemsResponse } from '../../tastyworks/response'

export class CustomerAccountAgreementService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly index = async (): Promise<ItemsResponse<CustomerAccountAgreement>> =>
    this.customerMeClient.indexSimple(
      CUSTOMER_ACCOUNT_AGREEMENT_DESER.toParser(CustomerAccountAgreement),
      'customer-account-agreements'
    )

  readonly create = async (
    agreements: PublicDocument[]
  ): Promise<ItemsResponse<CustomerAccountAgreement>> =>
    this.customerMeClient
      .postJson('customer-account-agreements', {
        'acceptance-method': 'Click',
        agreements
      })
      .then(helper =>
        parseItemsResponse(
          helper,
          CUSTOMER_ACCOUNT_AGREEMENT_DESER.toParser(CustomerAccountAgreement)
        )
      )
}
