import {
  Account,
  AccountMap,
  Balance,
  createAccountMap,
} from "@tastyworks/tastyworks-api"
import isNil from "lodash.isnil"
import {
  Readable,
  Writable,
  derived,
  get,
  readonly,
  writable,
} from "svelte/store"
import { AccountOpeningStatus } from "/@/account-management/model/account-opening-status"
import { createBalanceStore } from "/@/account-management/store/balance"
import { customer as customerStore } from "/@/util/customer"
import {
  clearLastLocation,
  getCurrentAccountNumber,
  setCurrentAccountNumber,
} from "/@lib/shared"
import { twApiClient } from "/@lib/tastyworks-rest"

export interface AccountsStore extends Readable<AccountMap> {
  initialize: () => Promise<AccountMap>
  isInitialized: () => boolean
  updateAccount: (account: Account) => void
}

const { subscribe, update, set } = writable(createAccountMap())

export const accountsStore: AccountsStore = {
  async initialize() {
    const accounts = await fetchAccounts()
    set(accounts)
    return accounts
  },
  isInitialized() {
    return !get(accountsStore).isEmpty
  },
  subscribe,
  updateAccount(account: Account) {
    update((existingAccounts) => {
      existingAccounts.add(account)
      return existingAccounts
    })
  },
}
const challengeRegEx = /^1D[A-Z][0-9]{5}/

export function filterAccounts(accounts: AccountMap): AccountMap {
  const filteredAccounts = accounts.values.reduce((filteredMap, account) => {
    if (!challengeRegEx.test(account.accountNumber)) {
      filteredMap.add(account)
    }
    return filteredMap
  }, createAccountMap())

  return filteredAccounts
}

async function fetchAccounts(): Promise<AccountMap> {
  const [accounts, customer] = await Promise.all([
    twApiClient.accountService.indexByAccountNumber(),
    twApiClient.customerService.show(),
  ])
  const filteredAccounts = filterAccounts(accounts.data)

  const accountOpeningStatus = new AccountOpeningStatus()

  if (filteredAccounts.isEmpty) {
    accountOpeningStatus.hasAccounts = false
    clearLastLocation()

    if (customer.isNotFound) {
      accountOpeningStatus.hasCustomer = false

      writableAccountOpeningState.set(accountOpeningStatus)
      return createAccountMap()
    }

    accountOpeningStatus.hasCustomer = true

    writableAccountOpeningState.set(accountOpeningStatus)

    return createAccountMap()
  }

  customerStore.set(customer.data)

  accountOpeningStatus.hasAccounts = true
  accountOpeningStatus.hasCustomer = true

  writableAccountOpeningState.set(accountOpeningStatus)

  currentAccountNumber.update((existingAccountNumber) => {
    if (existingAccountNumber) {
      return existingAccountNumber
    }

    const storedAccountNumber = getCurrentAccountNumber()

    if (
      storedAccountNumber &&
      filteredAccounts.keys.includes(storedAccountNumber)
    ) {
      return storedAccountNumber
    }

    return filteredAccounts.values[0].accountNumber
  })
  return filteredAccounts
}

export const currentAccountNumber = writable<string | null>(null)
currentAccountNumber.subscribe((accountNumber) => {
  if (accountNumber) {
    setCurrentAccountNumber(accountNumber)
  }
})

export interface AccountSummary {
  account: Account
  accountNumber: string
  accountType: string
  balance: Writable<Balance>
  nickname: string
}

export const accountSummaries = derived([accountsStore], ([$accountsStore]) =>
  createAccountSummaries($accountsStore.values)
)

export const currentAccountSummary = derived(
  [currentAccountNumber, accountSummaries],
  ([$currentAccountNumber, $accountSummaries]) => {
    if (isNil($currentAccountNumber)) {
      return null
    }

    return $accountSummaries.get($currentAccountNumber)
  }
)

export const currentAccount = derived(
  currentAccountSummary,
  ($currentAccountSummary) => {
    if (isNil($currentAccountSummary)) {
      return null
    }

    return $currentAccountSummary.account
  }
)

export function updateCurrentAccount(fields: object) {
  return updateAccount(get(currentAccountNumber), fields)
}

export async function updateAccount(accountNumber: string, fields: object) {
  const account = get(accountSummaries).get(accountNumber)?.account

  if (!account) {
    throw new Error(`No account matches ${accountNumber}.`)
  }

  const { isError, errorContainer, data } =
    await twApiClient.accountService.update(account, fields)

  if (isError) {
    throw new Error(errorContainer.message)
  }

  accountsStore.updateAccount(data)
}

// Used by tests to set mock accounts.
export function createAccountSummaries(accounts: Account[]) {
  const summaries = new Map<string, AccountSummary>()

  for (const account of accounts) {
    const { accountNumber, accountType } = account

    summaries.set(accountNumber, {
      account,
      accountNumber,
      accountType,
      balance: createBalanceStore(),
      nickname: account.nickname,
    })
  }

  return summaries
}

const writableAccountOpeningState = writable<AccountOpeningStatus>(
  new AccountOpeningStatus()
)

export const accountOpeningState = readonly(writableAccountOpeningState)

export const hasAnyAccounts = derived(
  accountsStore,
  ($accountsStore) => !$accountsStore.isEmpty
)

export const hasOpenAccounts = derived(
  [accountsStore, hasAnyAccounts],
  ([$accountsStore, $hasAnyAccounts]) =>
    $hasAnyAccounts &&
    !$accountsStore.values.every((account) => account.isClosed)
)

export const hasOneAccount = derived(
  accountsStore,
  ($accountsStore) => $accountsStore.length === 1
)

export const hasEntityAccount = derived(accountsStore, ($accountsStore) =>
  $accountsStore.values.some((account) => account.isEntity)
)
