import { SchemaDeSerBuilder } from './deser'
import type { SchemaDeSer } from './deser'

export class AnalystConsensusReport {
  buy = 0
  currentMeanRating = ''
  hold = 0
  sell = 0
  strongBuy = 0
  strongSell = 0
  totalAnalysts = 0
}

export const ANALYST_CONSENSUS_REPORT_DESER: SchemaDeSer<AnalystConsensusReport> =
  new SchemaDeSerBuilder(AnalystConsensusReport)
    .ofInt('buy')
    .ofString('currentMeanRating')
    .ofInt('hold')
    .ofInt('sell')
    .ofInt('strongBuy')
    .ofInt('strongSell')
    .ofInt('totalAnalysts')
    .toDeSer()
