<script lang="ts">
  export let href: string
  export let hasUnderline = true

  let cssClass = "text-general-hyperlink"
  export { cssClass as class }

  let cssStyle: string = ""
  export { cssStyle as style }
</script>

<a
  {href}
  rel="noopener noreferrer"
  target="_blank"
  class:hover:no-underline={!hasUnderline}
  class="external-link {cssClass}"
  on:click
  style={cssStyle}
>
  <slot />
</a>
