import type HttpClient from '../../tastyworks/http'
import { updateSingleResponse } from '../../tastyworks/response'
import { TWO_FACTOR_METHOD_DESER, TwoFactorMethod } from '../../tastyworks/user'

export class TwoFactorMethodService {
  private readonly twoFactorMethodClient: HttpClient

  constructor(userMeClient: HttpClient) {
    this.twoFactorMethodClient = userMeClient.nested('two-factor-methods')
  }

  readonly showLatest = async () => {
    const helper = await this.twoFactorMethodClient.get('latest')
    const method = new TwoFactorMethod()
    return updateSingleResponse(helper, method, TWO_FACTOR_METHOD_DESER.update)
  }
}
