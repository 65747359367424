<script lang="ts">
  import { PlaidInstitutionResult } from "/@/account-management/control/institution-search"
  import { createEventDispatcher } from "svelte"

  export let institutionResults: PlaidInstitutionResult[]
  export let emptyMessage: string

  const dispatch = createEventDispatcher()

  function handleClick(institutionResult: PlaidInstitutionResult) {
    dispatch("didSelectInstitution", institutionResult.institutionId)
  }
</script>

<div class="institution-result-list divide-y divide-divider-horizontal">
  {#if institutionResults.length > 0}
    {#each institutionResults as institutionResult}
      <button
        type="button"
        class="
          institution-result-list-item
          flex
          cursor-pointer
          flex-row
          items-center
          gap-x-medium
          py-extra-small"
        on:click={() => handleClick(institutionResult)}
      >
        <div class="institution-logo h-9 w-9">
          {#if institutionResult.base64Logo !== null && institutionResult.base64Logo !== undefined}
            <img
              src="data:image/png;base64,{institutionResult.base64Logo}"
              alt="{institutionResult.name} logo"
            />
          {:else}
            <div
              class="
                institution-logo-placeholder
                h-full
                w-full
                rounded-full"
              style="background-color: {institutionResult.colors.primary}"
            />
          {/if}
        </div>

        <div class="institution-name">{institutionResult.name}</div>
      </button>
    {/each}
  {:else}
    <p class="institution-result-list-empty">{emptyMessage}</p>
  {/if}
</div>
