import _ from 'lodash'
import { RestResource } from './common'
import { SchemaDeSer, SchemaDeSerBuilder } from './deser'
import { isZero } from './util/number'

export enum ALLOCATION_STYLES {
  PER_TRADE = 'Per Trade',
  UNIT = 'Unit'
}

export enum ALLOCATION_METHODS {
  BUYING_POWER_PERCENTAGE = 'Buying Power Percentage',
  CONTRACT_QUANTITY = 'Contract Quantity',
  DOLLAR_AMOUNT = 'Dollar Amount',
  UNIT_COUNT = 'Unit Count'
}

export class ModelPortfolio extends RestResource {
  allocationMethods = []
  allocationStyle = ALLOCATION_STYLES.UNIT
  autotradeOperatorId = 0
  description?: string
  endDate?: Date
  isPortfolioMarginStrategy = false
  leaderAccountNumber = ''
  name = ''
  startDate?: Date
  unitValue = 0

  get isPerTradeAllocation() {
    return this.allocationStyle === ALLOCATION_STYLES.PER_TRADE
  }

  calculateUnitTotal(unitCount: number) {
    if (_.isNil(unitCount) || isZero(unitCount)) {
      return 0
    }
    return this.unitValue * unitCount
  }
}

export const MODEL_PORTFOLIO_DESER: SchemaDeSer<ModelPortfolio> =
  new SchemaDeSerBuilder(ModelPortfolio)
    .ofPrimitiveArray('allocationMethods')
    .ofString('allocationStyle')
    .ofInt('autotradeOperatorId')
    .ofString('description')
    .ofDate('endDate')
    .ofString('id')
    .ofBoolean('isPortfolioMarginStrategy')
    .ofString('leaderAccountNumber')
    .ofString('name')
    .ofDate('startDate')
    .ofBigNumber('unitValue')
    .toDeSer()
