import { object, string } from 'yup'
import { BANK_ACCOUNT_TYPES } from '../../cash-management'
import toStringValues from '../../util/enum'
import { BANK_ACCOUNT_NUMBER, ROUTING_NUMBER } from '../regex/constants'
import { buildSchema } from './util/build-schema'

const schema = object({
  bankAccountNumber: string().required().matches(BANK_ACCOUNT_NUMBER, {
    message: 'yup.wireWithdrawal.bankAccountNumber',
    excludeEmptyString: true
  }),
  bankAccountType: string()
    .required()
    .oneOf(toStringValues(BANK_ACCOUNT_TYPES)),
  bankRoutingNumber: string().required().matches(ROUTING_NUMBER, {
    message: 'yup.wireWithdrawal.bankRoutingNumber',
    excludeEmptyString: true
  }),
  nickname: string().required()
})

export const achRelationshipSchema = buildSchema('AchRelationship', schema)
