import type { AccountMap } from '../../tastyworks/account'
import {
  Account,
  ACCOUNT_DESER,
  ACCOUNT_KEY_EXTRACTOR,
  ACCOUNT_UPDATER,
  createAccountMap
} from '../../tastyworks/account'
import type HttpClient from '../../tastyworks/http'
import { recursiveDasherizeKeys } from '../../tastyworks/request'
import type { SingleResponse } from '../../tastyworks/response'
import { toArrayMap, updateSingleResponse } from '../../tastyworks/response'

export class AccountService {
  private readonly customerAccountClient: HttpClient
  private readonly customerMeClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.customerAccountClient = customerMeClient.nested(
      'accounts/:accountNumber'
    )
    this.customerMeClient = customerMeClient
  }

  readonly indexByAccountNumber = async (): Promise<
    SingleResponse<AccountMap>
  > => {
    const accountMap = createAccountMap()
    const response = await this.customerMeClient.index(
      accountMap,
      ACCOUNT_UPDATER,
      ACCOUNT_KEY_EXTRACTOR,
      'accounts'
    )
    return toArrayMap(response, accountMap)
  }

  readonly show = async (
    accountNumber: string
  ): Promise<SingleResponse<Account>> =>
    this.customerAccountClient.show(
      new Account(accountNumber),
      ACCOUNT_DESER.update,
      '',
      { accountNumber }
    )

  readonly update = async (
    account: Account,
    body: any
  ): Promise<SingleResponse<Account>> => {
    const jsonHelper = await this.customerAccountClient.patch('', body, {
      accountNumber: account.accountNumber
    })

    return updateSingleResponse(jsonHelper, account, ACCOUNT_DESER.update)
  }

  readonly updateInvestmentObjective = async (
    account: Account
  ): Promise<SingleResponse<Account>> =>
    this.update(
      account,
      recursiveDasherizeKeys({
        investmentObjective: account.investmentObjective
      })
    )
}
