<script context="module">
  import goog from "/@lib/boulangerie"

  const SortDirection = goog.module.get("com.dough.model.SortDirection")
  const TitleType = goog.module.get("tasty.ui.util.TitleType")
</script>

<script>
  import { getContext } from "svelte"
  import { nullableBoulePropertyStore } from "/@/boule"
  import { TABLE_SORT_STATE } from "/@/boule/table/BouleTable.svelte"
  import { toTitleType } from "/@/boule/table/BouleTableHeaderCell.svelte"

  export let viewWidth
  export let columnMeta
  let cssClass = ""
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }

  const sortable = columnMeta.sortable
  const tableSortState = getContext(TABLE_SORT_STATE)

  $: selectedSortColumn = sortable && $selectedColumn === columnMeta.columnType
  $: sortAsc = selectedSortColumn && $sortDirection === SortDirection.ASC
  $: sortDesc = selectedSortColumn && $sortDirection === SortDirection.DESC

  const selectedColumn = nullableBoulePropertyStore(
    tableSortState?.selectedColumnProperty()
  )

  const sortDirection = nullableBoulePropertyStore(
    tableSortState?.sortDirectionProperty()
  )
  function handleClick() {
    if (!sortable) return
    tableSortState?.onSelectedColumn(columnMeta)
  }

  $: sortString = selectedSortColumn
    ? $sortDirection === SortDirection.ASC
      ? "ascending"
      : "descending"
    : "none"

  $: fullTitle = columnMeta.titleLookup.getTitle(TitleType.NORMAL)
  $: title = columnMeta.titleLookup.getTitle(
    toTitleType(viewWidth ?? columnMeta.viewWidth)
  )
</script>

<button
  class={cssClass}
  class:sortable
  class:tw-icon-Arrow-Up={sortAsc}
  class:tw-icon-Arrow-Down={sortDesc}
  style={cssStyle}
  title={fullTitle}
  data-column={columnMeta.columnType.name()}
  on:click|preventDefault={handleClick}
  aria-sort={sortString}
  role="columnheader"
  tabindex={sortable ? 0 : -1}
>
  {title}
</button>
