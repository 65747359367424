import _ from 'lodash'
import { formatJsonDate, formatJsonDateTime } from './format/date'
import { dasherize } from './util/string'

export type ParamValue =
  | Date
  | SortDirection
  | string[]
  | readonly string[]
  | boolean
  | number
  | string
  | null
  | undefined

export type Params = Record<string, ParamValue>

export type FileParams = Record<string, File>

export interface PaginationParams extends Params {
  perPage?: number
  pageOffset?: number
}

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export interface SortParams extends Params {
  sortDirection: SortDirection
  sortProperty: string
}

function serializeDateParam(key: string, date: Date) {
  if (key.endsWith('Date')) {
    return formatJsonDate(date)
  }

  if (key.endsWith('At')) {
    return formatJsonDateTime(date)
  }

  throw new Error(
    `Don't know how to serialize date field ${key} because it doesn't endsWith Date or At`
  )
}

function serializeParamValue(
  key: string,
  value: ParamValue,
  append: (key: string, value: string, isArray?: boolean) => void
) {
  const valueType = typeof value
  switch (valueType) {
    case 'string':
      append(key, value as string)
      return
    case 'number':
    case 'boolean':
      append(key, String(value))
      return
    case 'undefined':
      // Skip
      return
    default:
      if (Array.isArray(value)) {
        const values: string[] = value
        for (const v of values) {
          append(key, v, true)
        }
      } else if (value instanceof Date) {
        append(key, serializeDateParam(key, value))
      } else if (value === null) {
        // Skip
        return
      } else {
        throw new Error(`Don't know how to serialize ${value}`)
      }
  }
}

export class UrlPathBuilder {
  readonly baseUrl: string
  readonly nestedPath: string
  readonly defaultParams: object

  public constructor($baseUrl: string, $nestedPath = '', $defaultParams = {}) {
    this.nestedPath = UrlPathBuilder.cleanPath($nestedPath)
    this.baseUrl = $baseUrl.endsWith('/')
      ? $baseUrl.substring(0, $baseUrl.length - 1)
      : $baseUrl
    this.defaultParams = $defaultParams
  }

  public static cleanPath(path: string): string {
    return path.startsWith('/') ? path.substring(1) : path
  }

  public nested(path: string, defaultParams = {}): UrlPathBuilder {
    const cleanPath = UrlPathBuilder.cleanPath(path)
    return new UrlPathBuilder(
      this.baseUrl,
      this.nestedPath ? `${this.nestedPath}/${cleanPath}` : cleanPath,
      defaultParams
    )
  }

  public toFullPath(path: string) {
    if (path.length === 0) {
      return this.nestedPath
    }

    const cleanPath = UrlPathBuilder.cleanPath(path)
    return this.nestedPath ? `${this.nestedPath}/${cleanPath}` : cleanPath
  }

  public toUrl(path: string, params?: Params): string {
    const mergedParams = _.merge({}, this.defaultParams, params)
    const fullPath = this.toFullPath(path)
    if (_.isEmpty(mergedParams)) {
      return `${this.baseUrl}/${fullPath}`
    }

    const parts = fullPath.split('/')
    const pathParamKeys: string[] = []
    const completedPathParts: string[] = [this.baseUrl]

    for (const rawPart of parts) {
      const extractedPart = UrlPathBuilder.extractPath(
        fullPath,
        rawPart,
        mergedParams,
        pathParamKeys
      )

      const encodedPart = encodeURIComponent(extractedPart)
      completedPathParts.push(encodedPart)
    }

    const completedPath = completedPathParts.join('/')

    // Only copy over keys not used in path
    const queryParams: Params = UrlPathBuilder.excludeFromParams(
      mergedParams,
      pathParamKeys
    )

    const queryString = UrlPathBuilder.toQueryString(queryParams)

    if (queryString) {
      return `${completedPath}?${queryString}`
    }

    return completedPath
  }

  public static excludeFromParams(params: Params, keys: string[]): Params {
    const queryParams: Params = {}

    // Only copy over keys not used in path
    // Also exclude keys with null or undefined value
    for (const key of Object.keys(params)) {
      if (
        keys.includes(key) ||
        params[key] === null ||
        params[key] === undefined
      ) {
        continue
      }

      queryParams[key] = params[key]
    }

    return queryParams
  }

  public static toQueryString(params: Params): string {
    const keys = Object.keys(params)
    if (keys.length === 0) {
      return ''
    }

    const pairs: string[] = []

    const append = (key: string, value: string, isArray = false): void => {
      let encodedKey = encodeURIComponent(dasherize(key))
      if (isArray) {
        encodedKey += '[]'
      }
      pairs.push(`${encodedKey}=${encodeURIComponent(value)}`)
    }

    for (const key of keys) {
      const value = params[key]
      serializeParamValue(key, value, append)
    }

    return pairs.join('&')
  }

  public static extractPath(
    cleanPath: string,
    part: string,
    params: Params,
    pathParamKeys: string[]
  ): string {
    if (part.startsWith(':')) {
      const key = part.substring(1)
      const value: ParamValue = params[key]
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!value) {
        throw new Error(
          `path ${cleanPath} requires path param ${part} but a value is not present in params: ${JSON.stringify(
            params
          )}`
        )
      }
      if (Array.isArray(value)) {
        throw new Error(
          `path ${cleanPath} requires path param ${part} but value is an array ${String(
            value
          )}, should be a string or number`
        )
      }
      pathParamKeys.push(key)
      return String(value)
    } else {
      return part
    }
  }
}

export function toFormData(params: Params): FormData {
  const keys = Object.keys(params)
  const formData: FormData = new FormData()

  const append = (key: string, value: string) => {
    formData.append(dasherize(key), value)
  }

  for (const key of keys) {
    const value = params[key]
    serializeParamValue(key, value, append)
  }

  return formData
}

/* eslint-disable @typescript-eslint/no-unsafe-return */
export function recursiveDasherizeKeys(body: any) {
  let dasherized = _.mapKeys(body, (_value, key) => dasherize(key))

  dasherized = _.mapValues(dasherized, (value: any) => {
    if (Array.isArray(value)) {
      return value.map(val => {
        return recursiveDasherizeKeys(val)
      })
    } else if (typeof value === 'object') {
      return recursiveDasherizeKeys(value)
    }

    return value
  })

  return dasherized
}
/* eslint-enable */
