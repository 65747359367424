<script context="module">
  import goog from "/@lib/boulangerie"
  const TitleType = goog.module.get("tasty.ui.util.TitleType")
</script>

<script>
  import { ItemArranger } from "/@/control"
  import { bouleArrayStore } from "./boule-array-store"

  export let columnManager
  export let columnTypes
  let cssClass = ""
  export { cssClass as class }

  const selected = bouleArrayStore(columnManager.userSelectedColumnTypes)

  function getTitle(column) {
    return columnManager.getTitleLookup(column).getTitle(TitleType.DESCRIPTIVE)
  }

  $: columns = [
    {
      activeBorderColor: "var(--color-border-general-positive-movement)",
      items: $selected.map((c) => ({
        label: getTitle(c),
        value: c,
      })),
      showCount: true,
      showIndexes: true,
      title: "Displayed",
    },
    {
      activeBorderColor: "var(--color-border-general-negative-movement)",
      items: columnTypes
        .filter((c) => !$selected.includes(c))
        .map((c) => ({
          label: getTitle(c),
          value: c,
        })),
      showReset: true,
      title: "Not Displayed",
    },
  ]

  function handleChange(event) {
    const userSelectedColumnTypes = event.detail[0].items.map((i) => i.value)
    columnManager.setUserSelectedColumnTypes(...userSelectedColumnTypes)
  }

  function handleReset() {
    columnManager.resetUserSelectedColumnTypes()
  }
</script>

<ItemArranger
  class="column-preference {cssClass}"
  {columns}
  on:change={handleChange}
  on:reset={handleReset}
/>
