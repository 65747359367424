<script context="module">
  import goog from "/@lib/boulangerie"
  const PositionRowColumnType = goog.module.get(
    "tasty.ui.boule.table.PositionRowColumnType"
  )
</script>

<script>
  import { lazyContext } from "/@lib/boulangerie"
  import ColumnPreference from "/@/boule/control/ColumnPreference.svelte"

  const { positionDetailManager } = lazyContext
</script>

<ColumnPreference
  class="positions-position-detail"
  columnManager={positionDetailManager.columnsManager}
  columnTypes={PositionRowColumnType.values()}
/>
