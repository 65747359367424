/*
  Slight modification of https://github.com/uNmAnNeR/imaskjs/blob/master/packages/svelte-imask/src/action.ts
  Used to expose maskRef to front end so we can sync masked value "manually"
  Shortcut: use pattern in MaskedInput.svelte to use svelte reactive statements 
  to keep values in sync instead of manually calling updateValue on value changes
*/
import type { ActionReturn } from "svelte/action"
import IMask, { type FactoryArg, type InputMask, type UpdateOpts } from "imask"
import { tick } from "svelte"

type Opts = FactoryArg

type MaskedAttributes = {
  maskRef?: undefined | InputMask<Opts> | Opts
  "on:confirm"?: (e: CustomEvent) => void | undefined
}

export type MaskedHTMLElement = HTMLElement & MaskedAttributes

function fireEvent(el: HTMLElement, eventName: string, data: InputMask<Opts>) {
  const e = new CustomEvent(eventName, { detail: data })
  el.dispatchEvent(e)
}

function initMask(el: HTMLElement, opts: Opts) {
  const maskRef =
    opts instanceof IMask.InputMask ? opts : IMask(el, opts as Opts)
  return maskRef
    .on("accept", () => fireEvent(el, "accept", maskRef))
    .on("complete", () => fireEvent(el, "complete", maskRef))
}

export function mask(
  el: MaskedHTMLElement,
  opts: Opts
): ActionReturn<Opts, MaskedAttributes> {
  let maskRef: InputMask<Opts> | undefined = opts && initMask(el, opts)

  el.maskRef = maskRef

  function destroy(): void {
    if (maskRef) {
      maskRef.destroy()
      maskRef = undefined
      el.maskRef = maskRef
    }
  }
  function update(opts: Opts) {
    if (!opts) {
      destroy()
      return
    }

    if (opts instanceof IMask.InputMask) maskRef = opts
    else if (maskRef) maskRef.updateOptions(opts as UpdateOpts<Opts>)
    else maskRef = initMask(el, opts as Opts)

    el.maskRef = maskRef
  }

  return {
    destroy,
    update,
  }
}

// using workaround from https://github.com/uNmAnNeR/imaskjs/issues/463
export async function updateValue(value, maskRef) {
  preserveCursorPosition(maskRef, () => {
    maskRef.value = value
    maskRef._onChange()
  })
}

export async function preserveCursorPosition(maskRef, cb: () => void) {
  const { selectionStart, selectionEnd } = maskRef.el
  cb()
  await tick()
  maskRef.el?.select(selectionStart, selectionEnd)
}
