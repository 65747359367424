import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class AdvancedTradingPermission {
  accountNumber = ''

  cryptocurrencyTradingApprovedAt?: Date

  equityOfferingApprovedAt?: Date

  isIntradayFuturesMarginApproved = false

  portfolioMarginApprovedAt?: Date

  portfolioMarginActivatedAt?: Date

  get isEquityOfferingApproved() {
    return this.equityOfferingApprovedAt !== undefined
  }

  get isPortfolioMarginActivated() {
    return this.portfolioMarginActivatedAt !== undefined
  }

  get isPortfolioMarginApproved() {
    return this.portfolioMarginApprovedAt !== undefined
  }

  get isPortfolioMarginPending() {
    return this.isPortfolioMarginApproved && !this.isPortfolioMarginActivated
  }
}

export const ADVANCED_TRADING_PERMISSION_DESER: SchemaDeSer<AdvancedTradingPermission> =
  new SchemaDeSerBuilder(AdvancedTradingPermission)
    .ofString('accountNumber')
    .ofDateTime('cryptocurrencyTradingApprovedAt')
    .ofDateTime('equityOfferingApprovedAt')
    .ofDateTime('isIntradayFuturesMarginApproved')
    .ofDateTime('portfolioMarginApprovedAt')
    .ofDateTime('portfolioMarginActivatedAt')
    .toDeSer()
