import { RestResource } from './common'
import { SchemaDeSerBuilder, type SchemaDeSer } from './deser'
import type { PaginationParams } from './request'

export enum DIGITAL_ASSET_CUSTOMER_ADDRESS_STATUSES {
  REJECTED = 'Rejected',
  REMOVED = 'Removed',
  REVIEWED = 'Reviewed',
  NEEDS_MANUAL_REVIEW = 'Needs Manual Review',
  PENDING_REVIEW = 'Pending Review',
  UNDER_REVIEW = 'Under Review'
}

export interface DigitalAssetCustomerAddressParams extends PaginationParams {
  depositsBlocked?: boolean
  status?: DIGITAL_ASSET_CUSTOMER_ADDRESS_STATUSES
  withdrawalsBlocked?: boolean
}

export class DigitalAssetCustomerAddress extends RestResource {
  address = ''
  asset = ''
  name = ''
  accountNumber = ''
  depositsBlocked = true
  status = DIGITAL_ASSET_CUSTOMER_ADDRESS_STATUSES.PENDING_REVIEW
  withdrawalsBlocked = true
}

export const DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER: SchemaDeSer<DigitalAssetCustomerAddress> =
  new SchemaDeSerBuilder(DigitalAssetCustomerAddress)
    .ofString('accountNumber')
    .ofString('id')
    .ofString('address')
    .ofString('asset')
    .ofBoolean('depositsBlocked')
    .ofString('name')
    .ofString('status')
    .ofBoolean('withdrawalsBlocked')
    .toDeSer()
