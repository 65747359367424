<script lang="ts">
  import { createEventDispatcher, SvelteComponent } from "svelte"
  import DefaultButtonGroupButton from "./DefaultButtonGroupButton.svelte"

  // FIXME: interface probably belongs elsewhere.
  export let buttons: {
    label: string
    id?: string
    value: any
    class?: string
  }[]
  export let selectedIndex = 0
  let cssClass = ""
  export { cssClass as class }
  export let vertical = false
  export let button: typeof SvelteComponent<any> = DefaultButtonGroupButton
  export let id: string = undefined
  export let disabled = false

  $: direction = vertical ? "flex-col" : "flex-row"
  $: buttons = buttons.map((button, index) => ({
    ...button,
    id: button.id ?? `${index}-${button.label ?? ""}`,
  }))

  const dispatch = createEventDispatcher()

  function select(index: number) {
    if (selectedIndex === index) return
    const label = buttons[index].label
    const value = buttons[index].value ?? label
    dispatch("click", { index, label, value })
    selectedIndex = index
  }

  function handleClick(event: CustomEvent) {
    if (disabled) return
    const index = Number(event.detail.currentTarget.dataset.index)
    select(index)
  }

  function handleKeyDown(event: KeyboardEvent) {
    let newIndex = selectedIndex
    if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
      newIndex = Math.max(0, selectedIndex - 1)
    } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
      newIndex = Math.min(buttons.length - 1, selectedIndex + 1)
    }
    select(newIndex)
  }
</script>

<div
  class="
    button-group
    flex
    grow
    items-stretch
    {direction}
    {cssClass}"
  class:disabled
  role="radiogroup"
  aria-activedescendant={buttons[selectedIndex]?.id}
  tabindex="0"
  on:keydown|stopPropagation={handleKeyDown}
  {id}
  on:focusin={(event) => {
    // HACK: [DG] this is included because the buttons remain focusable on click even with tabindex="-1"
    event.currentTarget.focus()
  }}
>
  {#each buttons as { label, id, value, class: cssClass }, index}
    <svelte:component
      this={button}
      class="{label ?? ''} {id} {cssClass} {disabled &&
        'disabled pointer-events-none'}"
      on:click={handleClick}
      {index}
      {label}
      {value}
      {id}
      selected={selectedIndex === index}
    />
  {/each}
</div>
