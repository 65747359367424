import { object, string } from 'yup'
import { buildSchema } from './util/build-schema'

const schema = object({
  subject: string().required('Note subject is required'),
  type: string().required('Note type is required'),
  subType: string(),
  body: string()
})

export const noteSchema = buildSchema('AdminNotes', schema)
