import {
  CUSTOMER_PORTFOLIO_ENTITLEMENT_DESER,
  CustomerPortfolioEntitlement
} from '../../tastyworks/customer-portfolio-entitlement'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class CustomerPortfolioEntitlementService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly index = async (): Promise<
    ItemsResponse<CustomerPortfolioEntitlement>
  > =>
    this.customerMeClient.indexSimple(
      CUSTOMER_PORTFOLIO_ENTITLEMENT_DESER.toParser(
        CustomerPortfolioEntitlement
      ),
      'portfolio-entitlements'
    )
}
