import type { AssetTransferExternalInstitutionParams } from '../../tastyworks/asset-transfer'
import {
  ASSET_TRANSFER_EXTERNAL_INSTITUTION_DESER,
  ASSET_TRANSFER_EXTERNAL_INSTITUTION_UPDATER,
  AssetTransferExternalInstitution
} from '../../tastyworks/asset-transfer'
import type HttpClient from '../../tastyworks/http'
import { ArrayMap } from '../../tastyworks/util/collection'
import type { JsonHelper } from '../../tastyworks/util/json'

export class AssetTransferExternalInstitutionService {
  private readonly assetTransferClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.assetTransferClient = customerMeClient.nested('asset-transfer')
  }

  readonly index = async () =>
    this.assetTransferClient.index(
      ArrayMap.stringKey(AssetTransferExternalInstitution, 'id'),
      ASSET_TRANSFER_EXTERNAL_INSTITUTION_UPDATER,
      (helper: JsonHelper) => helper.getString('id'),
      'external-institutions'
    )

  readonly create = async (params: AssetTransferExternalInstitutionParams) => {
    const institution = new AssetTransferExternalInstitution()
    return this.assetTransferClient.create(
      institution,
      ASSET_TRANSFER_EXTERNAL_INSTITUTION_DESER.serialize,
      ASSET_TRANSFER_EXTERNAL_INSTITUTION_UPDATER,
      'external-institutions',
      {
        'public-token': params.publicToken,
        'external-account-id': params.externalAccountId,
        'external-institution-id': params.externalInstitutionId
      }
    )
  }

  readonly delete = async (externalInstitutionId: number) =>
    this.assetTransferClient.destroy(
      'external-institutions/:externalInstitutionId',
      { externalInstitutionId }
    )
}
