import type BigNumber from 'bignumber.js'
import _ from 'lodash'
import type { ALLOCATION_METHODS } from '../../tastyworks'
import {
  ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER,
  ACCOUNT_SUBSCRIPTION_ELIGIBILITY_DESER,
  AccountPortfolioSubscription,
  AccountSubscriptionEligibility
} from '../../tastyworks/account-portfolio-subscription'
import type {
  AccountSubscriptionValueParams,
  PORTFOLIO_SUBSCRIPTION_STATUSES
} from '../../tastyworks/account-portfolio-subscription'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'

export class AccountPortfolioSubscriptionService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    status?: PORTFOLIO_SUBSCRIPTION_STATUSES
  ): Promise<ItemsResponse<AccountPortfolioSubscription>> =>
    this.accountNumberClient.indexSimple(
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.toParser(
        AccountPortfolioSubscription
      ),
      'portfolio-subscriptions',
      { accountNumber, status }
    )

  readonly optIn = async (
    portfolioSubscription: AccountPortfolioSubscription,
    modelPortfolioId: number
  ): Promise<SingleResponse<AccountPortfolioSubscription>> =>
    this.accountNumberClient.create(
      portfolioSubscription,
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.serialize,
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.update,
      'portfolio-subscriptions/opt-in',
      { accountNumber: portfolioSubscription.accountNumber, modelPortfolioId }
    )

  readonly optOut = async (
    portfolioSubscription: AccountPortfolioSubscription
  ): Promise<SingleResponse<AccountPortfolioSubscription>> =>
    this.accountNumberClient.create(
      portfolioSubscription,
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.serialize,
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.update,
      'portfolio-subscriptions/:id/opt-out',
      {
        accountNumber: portfolioSubscription.accountNumber,
        id: portfolioSubscription.id
      }
    )

  readonly showEligibility = async (
    accountNumber: string,
    modelPortfolioId: number,
    params: AccountSubscriptionValueParams
  ): Promise<SingleResponse<AccountSubscriptionEligibility>> =>
    this.accountNumberClient.show(
      new AccountSubscriptionEligibility(),
      ACCOUNT_SUBSCRIPTION_ELIGIBILITY_DESER.update,
      'portfolio-subscriptions/eligibility',
      _.merge(
        {
          accountNumber,
          modelPortfolioId
        },
        params
      )
    )

  readonly updatePerTrade = async (
    portfolioSubscription: AccountPortfolioSubscription,
    method: ALLOCATION_METHODS,
    amount: BigNumber
  ): Promise<any> => {
    portfolioSubscription.buyingPowerPercentage = null
    portfolioSubscription.contractQuantity = null
    portfolioSubscription.dollarAmount = null
    portfolioSubscription.unitCount = null

    portfolioSubscription.setAllocationValue(method, amount)

    return this.accountNumberClient.update(
      portfolioSubscription,
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.serialize,
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.update,
      'portfolio-subscriptions/:id/update',
      {
        accountNumber: portfolioSubscription.accountNumber,
        id: portfolioSubscription.id
      }
    )
  }
}
