import _ from 'lodash'
import type { AccountMap } from './account'
import { createAccountMap } from './account'
import type { AccountOpeningApplication } from './account-opening'
import { Customer } from './customer'
import { SchemaDeSer, SchemaDeSerBuilder, type Parser } from './deser'
import { QuoteStreamerTokenAuth } from './quote-streamer-token'
import TwUser, { NO_USER, USER_DESER } from './user'
import type { JsonHelper } from './util/json'

export enum ClientDomain {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Customer = 'tastyworks_customers',
  Employee = 'tastyworks_employees'
}

export default class TwSession {
  static onInitialize = (_obj: TwSession) => {
    /* no-op */
  }

  private _user = NO_USER
  private _sessionToken = ''

  valid = false
  accounts: AccountMap = createAccountMap()
  customer: Customer | null = new Customer()
  accountOpeningApplications: AccountOpeningApplication[] = []
  quoteStreamerTokenAuth: QuoteStreamerTokenAuth = new QuoteStreamerTokenAuth()
  quoteSnapshotToken: QuoteStreamerTokenAuth = new QuoteStreamerTokenAuth()
  oneTimePassword = ''

  async getCastleHeaders(): Promise<Record<string, string>> {
    const token = await this.createCastleIoRequestToken()
    return token ? { 'X-Castle-Request-Token': token } : {}
  }

  // hook for castle integration. override to enable
  // eslint-disable-next-line @typescript-eslint/require-await
  protected async createCastleIoRequestToken() {
    return ''
  }

  constructor(readonly source: string) {
    TwSession.onInitialize(this)
  }

  public clear(): void {
    this._sessionToken = ''
    this._user = NO_USER
    this.valid = false
    this.accounts = createAccountMap()
    this.accountOpeningApplications = []
    this.quoteStreamerTokenAuth = new QuoteStreamerTokenAuth()
    this.customer = new Customer()
    this.oneTimePassword = ''
  }

  get user() {
    return this._user
  }

  set user(user: TwUser) {
    if (user.sessionToken) {
      this._sessionToken = user.sessionToken
      this._user = user
      this.valid = true
    } else {
      this.clear()
    }
  }

  get isCustomerDomain(): boolean {
    return !_.isNil(this._sessionToken) && _.endsWith('+C')
  }

  get isEmployeeDomain(): boolean {
    return !_.isNil(this._sessionToken) && _.endsWith('+E')
  }

  get sessionToken(): string {
    return this._sessionToken
  }

  set sessionToken(value: string) {
    this._sessionToken = value
  }
}

export const parseUser = (helper: JsonHelper): TwUser => {
  const user = new TwUser()
  USER_DESER.update(user, helper)
  return user
}

export class TwLogin {
  sessionToken = ''
  user = new TwUser()

  /**
   * Only set for login attempts when user's email is unconfirmed
   */
  email = ''
}

export const LOGIN_PARSER: Parser<TwLogin> = (helper: JsonHelper) => {
  const twLogin = new TwLogin()

  twLogin.sessionToken = helper.getString('session-token')
  twLogin.user = parseUser(helper.getChild('user'))
  twLogin.user.sessionToken = twLogin.sessionToken
  twLogin.email = helper.getString('email')
  return twLogin
}

export const LOGIN_DESER: SchemaDeSer<TwLogin> = new SchemaDeSerBuilder(TwLogin)
  .ofString('sessionToken')
  .ofNested('user', USER_DESER, TwUser)
  .toDeSer()

class CrossDomainCookieData {
  authenticator = ''
  context = ''
  data: TwLogin | TwSession = new TwLogin()
}

export const CROSS_DOMAIN_COOKIE_DATA_DESER: SchemaDeSer<CrossDomainCookieData> =
  new SchemaDeSerBuilder<CrossDomainCookieData>(CrossDomainCookieData)
    .ofString('context')
    .ofString('authenticator')
    .ofNested('data', LOGIN_DESER, TwLogin)
    .toDeSer()

export class CrossDomainCookie {
  authenticated: CrossDomainCookieData

  constructor(
    twUser: TwLogin | TwSession = new TwLogin(),
    context = '/sessions',
    authenticator = 'authenticator:twucs'
  ) {
    this.authenticated = new CrossDomainCookieData()
    this.authenticated.context = context
    this.authenticated.authenticator = authenticator
    this.authenticated.data = twUser
  }
}

export const CROSS_DOMAIN_COOKIE_DESER: SchemaDeSer<CrossDomainCookie> =
  new SchemaDeSerBuilder<CrossDomainCookie>(CrossDomainCookie.bind(null))
    .ofNested(
      'authenticated',
      CROSS_DOMAIN_COOKIE_DATA_DESER,
      CrossDomainCookieData
    )
    .toDeSer()
