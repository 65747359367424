import {
  IRA_DISTRIBUTION_REASONS,
  IraDetail,
  TRANSFER_METHODS,
  WITHHOLDING_TYPES,
  Withdrawal,
  toStringValues,
} from "@tastyworks/tastyworks-api"
import { WithdrawalTransaction } from "./withdrawal-transaction"

export enum WITHDRAWAL_FREQUENCIES {
  ONE_TIME = "One Time",
}

export const WITHDRAWAL_FREQUENCY_VALUES = toStringValues(
  WITHDRAWAL_FREQUENCIES
)

export class AchWithdrawal implements WithdrawalTransaction {
  achRelationshipId?: number
  amount: number | null = null
  federalWithholding: number | null = null
  federalWithholdingEnabled = false
  federalWithholdingType: WITHHOLDING_TYPES | null = null
  frequency = WITHDRAWAL_FREQUENCIES.ONE_TIME
  isAnyIra = false
  hasConfirmedReviewed = false
  hasViewedIraDistributionRequest = false
  reason: IRA_DISTRIBUTION_REASONS | null = null
  stateWithholding: number | null = null
  stateWithholdingEnabled = false
  stateWithholdingType: WITHHOLDING_TYPES | null = null

  toWithdrawal(accountNumber: string) {
    const withdrawal = new Withdrawal()
    withdrawal.accountNumber = accountNumber
    withdrawal.amount = this.amount
    withdrawal.achRelationshipId = this.achRelationshipId
    withdrawal.transferMethod = TRANSFER_METHODS.ACH

    if (this.isAnyIra) {
      const iraDetail = new IraDetail()
      iraDetail.distributionReason = this.reason

      if (this.federalWithholdingEnabled) {
        iraDetail.federalWithholding = this.federalWithholding
        iraDetail.federalWithholdingType = this.federalWithholdingType
      }

      if (this.stateWithholdingEnabled) {
        iraDetail.stateWithholding = this.stateWithholding
        iraDetail.stateWithholdingType = this.stateWithholdingType
      }

      withdrawal.iraDetail = iraDetail
    }

    return withdrawal
  }

  get isDryRunnable() {
    return this.amount !== null
  }
}
