import { ItemsResponse, SecurityQuestion } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'
import { parseItemsResponse } from '../../tastyworks/response'
import { SECURITY_QUESTION_DESER } from '../../tastyworks/user'

export class SecurityQuestionService {
  constructor(private readonly userMeClient: HttpClient) {}

  readonly index = async (): Promise<ItemsResponse<SecurityQuestion>> =>
    this.userMeClient.indexSimple(
      SECURITY_QUESTION_DESER.toParser(SecurityQuestion),
      'security-questions'
    )

  readonly update = async (
    securityQuestions: SecurityQuestion[],
    currentPassword: string
  ): Promise<ItemsResponse<SecurityQuestion>> => {
    const response = await this.userMeClient.put('security-questions', {
      'security-questions': securityQuestions,
      'current-password': currentPassword
    })

    return parseItemsResponse(
      response,
      SECURITY_QUESTION_DESER.toParser(SecurityQuestion)
    )
  }

  readonly create = async (
    securityQuestions: SecurityQuestion[]
  ): Promise<ItemsResponse<SecurityQuestion>> => {
    const response = await this.userMeClient.postJson('security-questions', {
      'security-questions': securityQuestions
    })

    return parseItemsResponse(
      response,
      SECURITY_QUESTION_DESER.toParser(SecurityQuestion)
    )
  }
}
