<script>import { useActions } from "../../..";
import { cn } from "../../../utils";
import { buttonVariants } from ".";
let className = void 0;
export { className as class };
export let href = void 0;
export let type = "button";
export let variant = "default";
export let target = href ? "_blank" : void 0;
</script>

<svelte:element
  this={href ? "a" : "button"}
  type={href ? undefined : type}
  {href}
  class={cn(buttonVariants({ variant, className }))}
  use:useActions={$$restProps.use}
  {...$$restProps}
  role={href ? "link" : "button"}
  {target}
  on:click
  on:change
  on:keydown
  on:keyup
  on:mouseenter
  on:mouseleave
>
  <slot />
</svelte:element>
