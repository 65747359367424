import type { SessionErrorHandler } from '../tastyworks'
import HttpClient from '../tastyworks/http'
import type Logger from '../tastyworks/logger'
import AnalystConsensusService from './services/analyst-consensus'
import FinancialStatementService from './services/financial-statement'
import NewsService from './services/news'
import SnapshotService from './services/snapshot'
import type { TwRestSession } from './session'

export class VastClient {
  private readonly baseHttpClient: HttpClient

  private readonly refinitivHttpClient: HttpClient

  readonly fetcher: typeof fetch

  readonly analystConsensusService: AnalystConsensusService

  readonly financialStatementService: FinancialStatementService

  readonly newsService: NewsService

  readonly snapshotService: SnapshotService

  constructor(
    vastBaseUrl: string,
    logger: Logger,
    fetcher: typeof fetch,
    sessionErrorHandler: SessionErrorHandler,
    twSession: TwRestSession
  ) {
    this.fetcher = fetcher
    this.baseHttpClient = new HttpClient(
      logger,
      fetcher,
      vastBaseUrl,
      twSession,
      sessionErrorHandler
    )

    this.refinitivHttpClient = this.baseHttpClient.nested(
      '/refinitiv-proxy/tasty'
    )

    this.analystConsensusService = new AnalystConsensusService(
      this.refinitivHttpClient
    )
    this.financialStatementService = new FinancialStatementService(
      this.refinitivHttpClient
    )
    this.newsService = new NewsService(this.refinitivHttpClient)
    this.snapshotService = new SnapshotService(this.refinitivHttpClient)
  }
}
