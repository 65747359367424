import { RestResource } from './common'
import { SchemaDeSerBuilder, type SchemaDeSer } from './deser'

export class CashRestriction extends RestResource {
  accountNumber = ''
  isDepositDisabled = false
  isWithdrawalDisabled = false
}

export const CASH_RESTRICTION_DESER: SchemaDeSer<CashRestriction> =
  new SchemaDeSerBuilder(CashRestriction)
    .ofString('accountNumber')
    .ofBoolean('isDepositDisabled')
    .ofBoolean('isWithdrawalDisabled')
    .toDeSer()
