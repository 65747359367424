<script>
  import SecurityEntryPill from "./SecurityEntryPill.svelte"

  export let grow = false
  export let actionSecurityEntry
  let cssClass = ""
  export { cssClass as class }
  export let expirationClass = ""

  $: action = actionSecurityEntry?.action
</script>

<!--
  Despite slots being lazily rendered, conditional slots trigger runtime warnings.
  This seems to be a svelte bug. GH issue:
  https://github.com/sveltejs/svelte/issues/6325
  FIXME: I could not find a good way to ignore the warnings for now.
-->
<SecurityEntryPill
  class="security-entry-action-pill-view {cssClass}"
  {expirationClass}
  securityEntry={actionSecurityEntry}
  {grow}
>
  <slot name="before" slot="before" />

  <slot name="quantity" slot="quantity" />
  <slot name="expiration" slot="expiration" />
  <slot name="days-to-expiration" slot="days-to-expiration" />
  <slot name="strike" slot="strike" />
  <slot name="call-or-put" slot="call-or-put" />

  <svelte:fragment slot="after">
    <slot name="action" clazz="action {action?.positionType}" />
    <slot name="after" />
  </svelte:fragment>
</SecurityEntryPill>

<style lang="postcss" global>
  .pill.security-entry-action-pill-view {
    > .action {
      &.LONG {
        color: var(--color-text-general-positive-tick);
      }
      &.SHORT {
        color: var(--color-text-general-negative-tick);
      }
      /* &.NO_POSITION/&.UNKNOWN should inherit color */
    }
  }
</style>
