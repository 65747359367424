import type { ReferralUsed, SingleResponse } from '../../tastyworks'
import {
  CREATE_REFERRAL_USER_PAYLOAD_DESER,
  CreateReferralUserPayload,
  JsonBuilder,
  REFERRAL_USED_DESER,
  REFERRAL_USER_DESER,
  ReferralUser
} from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'
import { updateSingleResponse } from '../../tastyworks/response'

const PATH = 'referral-users'

export class ReferralService {
  private readonly referralClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.referralClient = baseHttpClient.nested(PATH)
  }

  async show(): Promise<SingleResponse<ReferralUser>> {
    return this.referralClient.show(
      new ReferralUser(),
      REFERRAL_USER_DESER.update,
      'me'
    )
  }

  async create(
    payload: CreateReferralUserPayload
  ): Promise<SingleResponse<ReferralUser>> {
    const builder = new JsonBuilder()
    CREATE_REFERRAL_USER_PAYLOAD_DESER.serialize(payload, builder)
    const result = await this.referralClient.postJson('', builder.json)

    return updateSingleResponse(
      result,
      new ReferralUser(),
      REFERRAL_USER_DESER.update
    )
  }

  async updatePromoCode(promo: string): Promise<SingleResponse<ReferralUser>> {
    const payload: Record<string, string> = {}

    if (promo) {
      payload['promotional-code'] = promo
    }
    const jsonHelper = await this.referralClient.patch('/me', payload)

    return updateSingleResponse(
      jsonHelper,
      new ReferralUser(),
      REFERRAL_USER_DESER.update
    )
  }

  async updateReferCode(
    referral: ReferralUsed
  ): Promise<SingleResponse<ReferralUser>> {
    const builder = new JsonBuilder()
    REFERRAL_USED_DESER.serialize(referral, builder)
    const jsonHelper = await this.referralClient.patch('/me', builder.json)
    return updateSingleResponse(
      jsonHelper,
      new ReferralUser(),
      REFERRAL_USER_DESER.update
    )
  }
}
