import _ from 'lodash'
import {
  EXTERNAL_TRANSACTION_DESER,
  ExternalTransaction
} from '../../tastyworks/cash-management'
import type HttpClient from '../../tastyworks/http'
import {
  RECURRING_DEPOSIT_DESER,
  RecurringDeposit
} from '../../tastyworks/recurring-deposit'
import type { PaginationParams } from '../../tastyworks/request'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { updateSingleResponse } from '../../tastyworks/response'

export class RecurringDepositService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params?: PaginationParams
  ): Promise<ItemsResponse<RecurringDeposit>> =>
    this.accountNumberClient.indexSimple(
      RECURRING_DEPOSIT_DESER.toParser(RecurringDeposit),
      'recurring-deposits',
      _.merge({ accountNumber }, params)
    )

  readonly destroy = async (
    recurringDeposit: RecurringDeposit
  ): Promise<SingleResponse<RecurringDeposit>> => {
    const helper = await this.accountNumberClient.doDelete(
      'recurring-deposits/:recurringDepositId',
      {
        accountNumber: recurringDeposit.accountNumber,
        recurringDepositId: recurringDeposit.id
      }
    )

    return updateSingleResponse(
      helper,
      recurringDeposit,
      RECURRING_DEPOSIT_DESER.update
    )
  }

  readonly indexDeposits = async (
    recurringDeposit: RecurringDeposit,
    params?: PaginationParams
  ): Promise<ItemsResponse<ExternalTransaction>> =>
    this.accountNumberClient.indexSimple(
      EXTERNAL_TRANSACTION_DESER.toParser(ExternalTransaction),
      'recurring-deposits/:recurringDepositId/deposits',
      _.merge(
        {
          accountNumber: recurringDeposit.accountNumber,
          recurringDepositId: recurringDeposit.id
        },
        params
      )
    )

  readonly create = async (
    recurringDeposit: RecurringDeposit
  ): Promise<SingleResponse<RecurringDeposit>> =>
    this.accountNumberClient.create(
      recurringDeposit,
      RECURRING_DEPOSIT_DESER.serialize,
      RECURRING_DEPOSIT_DESER.update,
      'recurring-deposits',
      { accountNumber: recurringDeposit.accountNumber }
    )
}
