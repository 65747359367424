import {
  ItemsResponse,
  JsonHelper,
  parseErrorContainer,
  Position,
  POSITIONS_DESER,
  type AdminPositionParams
} from '../../../tastyworks'
import HttpClient from '../../../tastyworks/http'

export class AdminPositionsService {
  private readonly adminPositionsClient: HttpClient

  constructor(adminAccountClient: HttpClient) {
    this.adminPositionsClient = adminAccountClient.nested('accounts')
  }

  readonly index = async (
    accountNumber: string,
    params: AdminPositionParams
  ): Promise<ItemsResponse<Position>> =>
    this.adminPositionsClient
      .nested(accountNumber)
      .get('positions', params)
      .then((res: JsonHelper) => this.parsePositions(res))

  readonly parsePositions = (helper: JsonHelper): ItemsResponse<Position> => {
    const response = new ItemsResponse<Position>()

    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')
    response.items = AdminPositionsService.parsePositionsList(data)

    return response
  }

  static parsePositionsList = (data: JsonHelper): Position[] => {
    const positions: Position[] = []

    data.parseArray('items', json => {
      positions.push(POSITIONS_DESER.toParser()(json))
    })

    return positions
  }
}
