import { RunLoop, RunLoopHelper } from "@tastyworks/tastyworks-api"

export const runLoop: RunLoop = {
  next: (delegate) => queueMicrotask(delegate),
  later: (time, delegate) => {
    let timeout = setTimeout(delegate, time)
    return () => {
      clearTimeout(timeout)
      timeout = null
    }
  },
}

// NOTE: [KT] not to be used with boulangerie since it has it's own runloop management
export const runLoopHelper = new RunLoopHelper(runLoop)
export default runLoop
