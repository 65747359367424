import { RestResource } from './common'
import { SchemaDeSer, SchemaDeSerBuilder } from './deser'
import { MODEL_PORTFOLIO_DESER, ModelPortfolio } from './model-portfolio'

export class CustomerPortfolioEntitlement extends RestResource {
  modelPortfolio?: ModelPortfolio
}

export const CUSTOMER_PORTFOLIO_ENTITLEMENT_DESER: SchemaDeSer<CustomerPortfolioEntitlement> =
  new SchemaDeSerBuilder(CustomerPortfolioEntitlement)
    .ofNested('modelPortfolio', MODEL_PORTFOLIO_DESER, ModelPortfolio)
    .toDeSer()
