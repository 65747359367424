<svelte:options immutable />

<script>
  import { getContext, onDestroy } from "svelte"
  import { TABLE_CELL_FACTORY } from "./BouleTable.svelte"
  import { defaultTableCellFactory } from "./common.js"

  export let item
  export let empty = false
  export let columnMeta

  // Provide default for testing; {set,get}Context don't work in tests.
  const cellFactory = getContext(TABLE_CELL_FACTORY) || defaultTableCellFactory

  let leftOffset = 0
  let viewWidth = 0

  $: textAlignment = columnMeta.textAlignment
  $: bouleField = columnMeta.bouleField
  $: bouleComponent = cellFactory(columnMeta)
  $: columnType = columnMeta.columnType

  $: tearDown = updateColumnMeta(columnMeta)
  function updateColumnMeta(newColumnMeta) {
    tearDown?.()

    const viewWidthTearDown = newColumnMeta
      .viewWidthProperty()
      .addDoubleListener((newViewWidth) => {
        viewWidth = newViewWidth
      })
    viewWidth = newColumnMeta.viewWidth

    const viewLayoutXTearDown = newColumnMeta
      .viewLayoutXProperty()
      .addDoubleListener((newLeftOffset) => {
        leftOffset = newLeftOffset
      })
    leftOffset = newColumnMeta.viewLayoutX

    return () => {
      viewWidthTearDown.tearDown()
      viewLayoutXTearDown.tearDown()
    }
  }

  onDestroy(() => {
    tearDown?.()
  })
</script>

<svelte:component
  this={bouleComponent}
  class={`boule-table-cell boule-table-cell-align-${textAlignment.name()} ${
    empty ? "empty" : ""
  } font-small-500`}
  style={`left: ${leftOffset}px; width: ${viewWidth}px`}
  source={item}
  field={bouleField}
  {columnType}
/>
