import _ from 'lodash'
import type HttpClient from '../../tastyworks/http'
import {
  INTERNAL_CASH_TRANSFER_DESER,
  INTERNAL_CASH_TRANSFER_ELIGIBILITY_DESER,
  InternalCashTransfer,
  InternalCashTransferEligibility
} from '../../tastyworks/internal-cash-transfer'
import {
  IRA_CONSTRAINTS_DESER,
  IraConstraints
} from '../../tastyworks/ira-constraint'
import type { PaginationParams } from '../../tastyworks/request'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { updateSingleResponse } from '../../tastyworks/response'

export class InternalCashTransferService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params?: PaginationParams
  ): Promise<ItemsResponse<InternalCashTransfer>> =>
    this.accountNumberClient.indexSimple(
      INTERNAL_CASH_TRANSFER_DESER.toParser(InternalCashTransfer),
      'internal-cash-transfers',
      _.merge({ accountNumber }, params)
    )

  readonly show = async (
    accountNumber: string,
    id: string
  ): Promise<SingleResponse<InternalCashTransfer>> =>
    this.accountNumberClient.show(
      new InternalCashTransfer(),
      INTERNAL_CASH_TRANSFER_DESER.update,
      'internal-cash-transfers/:id',
      { id, accountNumber }
    )

  readonly create = async (
    internalCashTransfer: InternalCashTransfer
  ): Promise<SingleResponse<InternalCashTransfer>> =>
    this.accountNumberClient.create(
      internalCashTransfer,
      INTERNAL_CASH_TRANSFER_DESER.serialize,
      INTERNAL_CASH_TRANSFER_DESER.update,
      'internal-cash-transfers',
      { accountNumber: internalCashTransfer.accountNumber }
    )

  readonly destroy = async (
    internalCashTransfer: InternalCashTransfer
  ): Promise<SingleResponse<InternalCashTransfer>> => {
    const id = internalCashTransfer.id
    const accountNumber = internalCashTransfer.accountNumber

    const helper = await this.accountNumberClient.doDelete(
      'internal-cash-transfers/:id',
      { accountNumber, id }
    )
    return updateSingleResponse(
      helper,
      internalCashTransfer,
      INTERNAL_CASH_TRANSFER_DESER.update
    )
  }

  readonly showEligibility = async (
    accountNumber: string,
    destinationAccountNumber: string
  ): Promise<SingleResponse<InternalCashTransferEligibility>> =>
    this.accountNumberClient.show(
      new InternalCashTransferEligibility(),
      INTERNAL_CASH_TRANSFER_ELIGIBILITY_DESER.update,
      'internal-cash-transfers/eligibility',
      { accountNumber, destinationAccountNumber }
    )

  readonly showIraConstraints = async (
    accountNumber: string,
    destinationAccountNumber: string
  ): Promise<SingleResponse<IraConstraints>> =>
    this.accountNumberClient.show(
      new IraConstraints(),
      IRA_CONSTRAINTS_DESER.update,
      'internal-cash-transfers/ira-constraints',
      { accountNumber, destinationAccountNumber }
    )
}
