import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class Ohlc {
  open = 0
  high = 0
  low = 0
  close = 0
  totalOpen = 0
  totalHigh = 0
  totalLow = 0
  totalClose = 0
  pendingCashOpen = 0
  pendingCashHigh = 0
  pendingCashLow = 0
  pendingCashClose = 0
  time: Date | null = null
}

export const TIMESCALE_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ssZ'

const OHLC_DESER: SchemaDeSer<Ohlc> = new SchemaDeSerBuilder(Ohlc)
  .ofFloat('open')
  .ofFloat('high')
  .ofFloat('low')
  .ofFloat('close')
  .ofFloat('totalOpen')
  .ofFloat('totalHigh')
  .ofFloat('totalLow')
  .ofFloat('totalClose')
  .ofFloat('pendingCashOpen')
  .ofFloat('pendingCashHigh')
  .ofFloat('pendingCashLow')
  .ofFloat('pendingCashClose')
  .ofDateTime('time', TIMESCALE_DATE_FORMAT)
  .toDeSer()

export const OHLC_PARSER = OHLC_DESER.toParser(Ohlc)

export type TIME_BACK = '1d' | '1m' | '1w' | '1y' | '3m' | '6m' | 'ytd'
