import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum AwardStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ORDERED = 'ORDERED',
  FILLED = 'FILLED',
  SETTLING = 'SETTLING',
  COMPLETE = 'COMPLETE'
}

export enum StockAwardSourceType {
  REFERRAL_CODE_OWNER = 'REFERRAL_CODE_OWNER',
  REFERRAL_CODE_REDEEMER = 'REFERRAL_CODE_REDEEMER'
}

export abstract class Award {
  id = 0
  userId = 0
  monetaryValue = 0
  status: AwardStatus = AwardStatus.PENDING
  destinationAccountNumber = ''
  notes = ''
  createdAt = new Date()
  updatedAt = new Date()
}

export class StockAward extends Award {
  static onInitialize = (_obj: StockAward) => {
    /* no-op */
  }

  constructor() {
    super()

    StockAward.onInitialize(this)
  }

  symbol = ''
  orderId = 0
  orderedAt = new Date()
  filledAt = new Date()
  estimatedOrderTime = new Date()
  numberOfShares = 0
  referralCustomerName = ''
  sourceType: StockAwardSourceType | '' = ''
}

export const STOCK_AWARD_DESER: SchemaDeSer<StockAward> =
  new SchemaDeSerBuilder(StockAward)
    .ofString('status')
    .ofString('symbol')
    .ofFloat('monetaryValue')
    .ofInt('orderId')
    .ofInt('userId')
    .ofString('notes')
    .ofString('destinationAccountNumber')
    .ofDateTime('estimatedOrderTime')
    .ofDateTime('orderedAt')
    .ofDateTime('filledAt')
    .ofInt('id')
    .ofDateTime('createdAt')
    .ofDateTime('updatedAt')
    .ofInt('numberOfShares')
    .ofString('referralCustomerName')
    .ofString('sourceType')
    .toDeSer()

export class BlacklistResponse {
  blacklisted = true
}

export const BLACKLISTED_RESPONSE_DESER: SchemaDeSer<BlacklistResponse> =
  new SchemaDeSerBuilder(BlacklistResponse).ofBoolean('blacklisted').toDeSer()
