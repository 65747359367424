<script>
  import { createEventDispatcher } from "svelte"
  import BouleIcon from "/@/boule/control/BouleIcon.svelte"
  import InlineSpinner from "/@/control/InlineSpinner.svelte"

  export let result
  export let highlighted = false

  const dispatch = createEventDispatcher()

  function validLogoUrl(url) {
    const img = new Image()
    img.src = url
    return new Promise((resolve) => {
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
    })
  }
</script>

<button
  type="button"
  class="
    symbol-search-result
    flex
    h-12
    w-full
    items-center
    gap-x-small
    border
    px-small
    py-extra-small"
  class:highlighted
  class:border-transparent={!highlighted}
  class:border-general-focused-state={highlighted}
  class:bg-transparent={!highlighted}
  on:click={() => dispatch("close", result)}
  on:mouseenter
  title="{result.symbol} -- {result.description ?? ''}"
>
  <div
    class="font-xx-large-600 flex h-double-extra-large w-double-extra-large items-center justify-center"
  >
    {#await validLogoUrl(result.logoUrl)}
      <InlineSpinner />
    {:then response}
      {#if response === true}
        <img
          class="company-logo"
          src={result.logoUrl}
          alt={`${result.symbol} logo`}
        />
      {:else}
        <BouleIcon
          src={result.instrumentTypeIcon}
          color="var(--color-text-general-secondary-text-contrast)"
          class="asset-icon font-extra-large-500"
        />
      {/if}
    {/await}
  </div>
  <div class="flex flex-1 flex-col overflow-hidden text-left">
    <div class="symbol font-small-600 truncate">{result.symbol}</div>
    <div
      class="description font-x-small-600 truncate text-general-secondary-text"
    >
      {result.description || ""}
    </div>
  </div>
  <div
    class="
      font-x-small-500-caps
      flex
      items-center
      gap-x-extra-small
      text-general-secondary-text"
  >
    <span class="asset-type-badge">
      {result.etf ? "ETF" : result.assetType?.toString()}
    </span>
    {#if result.hasOptions}
      <span class="options-badge">OPTIONS</span>
    {/if}
  </div>
</button>

<style lang="postcss">
  .loading-spinner {
    --color: var(--loading-spinner-color, #ff3e00);

    animation: 0.75s linear 0s infinite normal none running rotate;
    border-color: var(--color) transparent var(--color) var(--color);
    border-image: initial;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    height: 32px;
    width: 32px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
