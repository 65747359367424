<script context="module">
  import goog from "/@lib/boulangerie"

  const AccountColumnType = goog.module.get(
    "tasty.ui.boule.table.AccountColumnType"
  )
</script>

<script>
  import { lazyContext } from "/@lib/boulangerie"
  import ColumnPreference from "/@/boule/control/ColumnPreference.svelte"

  const { accountTableManager } = lazyContext
  const { columnsManager } = accountTableManager
</script>

<ColumnPreference
  class="accounts-header"
  columnManager={columnsManager}
  columnTypes={AccountColumnType.values()}
/>
