import { array, number, object, string } from 'yup'
import { ACCOUNT_TYPES } from '../../account-type'
import { TransferType } from '../../asset-transfer'
import toStringValues from '../../util/enum'
import { acatHoldingSchema } from './acat-holding'
import { buildSchema } from './util/build-schema'

const schema = object({
  accountNumber: string().required(),
  deliveringAccountNumber: string().required(),
  deliveringAccountTitle: string().required(),
  deliveringAccountType: string()
    .required()
    .oneOf(toStringValues(ACCOUNT_TYPES)),
  externalAccountId: number().required(),
  holdings: array().of(acatHoldingSchema.pick(['amount', 'initialAmount'])),
  transferType: string()
    .required()
    .oneOf(toStringValues(TransferType))
    .test(
      'holdingsIsNotEmpty',
      'yup.acat.transferType.holdingsIsEmpty',
      (value: string | undefined, context: any) =>
        TransferType.PARTIAL === value
          ? (context?.parent?.holdings?.length ?? 0) > 0
          : true
    )
})

export const acatTransferParamsSchema = buildSchema(
  'AcatTransferParams',
  schema
)
