/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { runLoopHelper } from "/@lib/tastyworks-rest/run-loop"

export class DebounceTask<T> {
  private readonly debounce: Function
  private readonly cancel: Function
  private resolve: Function
  private reject: Function

  constructor(timeout, task) {
    const { schedule, cancel } = runLoopHelper.debounce(timeout, async () => {
      try {
        const response = await task()

        this.resolve(response)
      } catch (e) {
        this.reject(e)
      }
    })
    this.debounce = schedule
    this.cancel = cancel
  }

  scheduleAsPromise() {
    this.debounce()

    return new Promise<T>((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  tearDown() {
    this.cancel()
  }
}
