<script>
  export let color = "var(--loading-spinner-color, #ff3e00)"
  export let size = "var(--vertical-meter)"
</script>

<div
  class="inline-spinner bg-transparent"
  style:border-color="{color} transparent {color}
  {color}"
  style:border-width="calc({size} / 16)"
  style:height={size}
  style:width={size}
/>

<style lang="postcss">
  /* TODO: Dry up InlineSpinner/LoadingOverlay logic. */
  .inline-spinner {
    animation: 0.75s linear 0s infinite normal none running rotate;
    border-image: initial;
    border-radius: 50%;
    border-style: solid;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
