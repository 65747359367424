<script lang="ts">
  import SymbolSearchEmptyResults from "./SymbolSearchEmptyResults.svelte"
  import SymbolSearchResult from "./SymbolSearchResult.svelte"

  export let highlightedIndex
  export let searchFieldName
  export let filter
  export let results

  function handleFilterChange(filter) {
    // NOTE: Focuses back on the symbol seach bar, otherwise panel closes when
    //   user reselects symbol search bar after selecting filter
    ;(
      document.getElementsByClassName(searchFieldName)[0] as HTMLElement
    )?.focus()
    const snowplowTag = "Symbol_Search_" + filter
    window.dataLayer?.push({
      event: snowplowTag,
    })
  }

  $: handleFilterChange(filter)
</script>

<div
  class="
    results
    flex
    max-h-96
    min-h-52
    flex-col
    items-stretch
    divide-y
    divide-general-main-panel
    overflow-y-auto
    overflow-x-hidden
    rounded-b
    bg-general-primary-surface"
>
  {#each results as result, i}
    <div class="option">
      <SymbolSearchResult
        {result}
        highlighted={$highlightedIndex === i}
        on:close
        on:mouseenter={() => ($highlightedIndex = i)}
      />
    </div>
  {:else}
    <SymbolSearchEmptyResults />
  {/each}
</div>
