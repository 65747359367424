import { SessionErrorHandler } from "@tastyworks/tastyworks-api"
import { handleHttpNetworkError, handleInvalidSession } from "../shared"

const sessionErrorHandler: SessionErrorHandler = {
  onInvalidSession: handleInvalidSession,

  // Probably don't want to handle these here (limits what we can do in UI).
  // Guard calls with twoFactor.perform (src/account-management/two-factor)
  // instead.
  onOTPRequired: (_response: Response) => {},

  onHttpNetworkError: handleHttpNetworkError,
}

export default sessionErrorHandler
