import type { DocumentMap } from '../../tastyworks/document'
import {
  createDocumentMap,
  DOCUMENT_DESER,
  documentJsonKeyExtractor
} from '../../tastyworks/document'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'
import { toArrayMap } from '../../tastyworks/response'

export class GeneralDocumentService {
  constructor(private readonly baseHttpClient: HttpClient) {}

  readonly indexBySlug = async (): Promise<SingleResponse<DocumentMap>> => {
    const documentMap = createDocumentMap()
    const response = await this.baseHttpClient.index(
      documentMap,
      DOCUMENT_DESER.update,
      documentJsonKeyExtractor,
      'documents'
    )

    return toArrayMap(response, documentMap)
  }
}
