import {
  ANALYST_CONSENSUS_REPORT_DESER,
  AnalystConsensusReport
} from '../../tastyworks/analyst-consensus'
import type HttpClient from '../../tastyworks/http'
import type { Params } from '../../tastyworks/request'
import type { SingleResponse } from '../../tastyworks/response'
import { parseSingleResponse } from '../../tastyworks/response'

export default class AnalystConsensusService {
  readonly baseHttpClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.baseHttpClient = baseHttpClient
  }

  readonly show = async (
    symbol: string,
    exchange = '',
    instrumentType = ''
  ): Promise<SingleResponse<AnalystConsensusReport>> => {
    const params: Params = {
      exchange,
      'instrument-type': instrumentType
    }

    const helper = await this.baseHttpClient.get(
      `/consensus/${symbol.toUpperCase()}`,
      params
    )

    return parseSingleResponse(
      helper,
      ANALYST_CONSENSUS_REPORT_DESER.toParser(AnalystConsensusReport)
    )
  }
}
