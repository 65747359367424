import { writable, derived } from "svelte/store"

const system = "system"

// Default light and dark themes, used in System sync
const defaultDark = "dark"
const defaultLight = "light"

// Default theme is dark
const defaultTheme = defaultDark

// NOTE: Key is used as the class name, Value as descriptor
const DARK_THEMES = {
  [defaultDark]: "Dark",
}

const LIGHT_THEMES = {
  [defaultLight]: "Light",
}

class Theme {
  constructor(name, description) {
    this.name = name
    this.description = description
  }

  get label() {
    return this.description
  }

  get class() {
    return this.name
  }
}

export const themes = setThemes()

function setThemes() {
  const themes = {}
  Object.entries({ ...DARK_THEMES, ...LIGHT_THEMES }).forEach(
    ([key, value]) => (themes[key] = new Theme(key, value))
  )

  themes[system] = new Theme(system, "Sync with system")

  return themes
}

const systemPrefersLightMediaQuery = "(prefers-color-scheme: light)"
const systemPrefersDarkMediaQuery = "(prefers-color-scheme: dark)"

// Default to dark
export function updateTheme() {
  const userPreferredTheme = localStorage.userPreferredTheme
  const isSystemLight = window.matchMedia(systemPrefersLightMediaQuery).matches

  const currentTheme =
    userPreferredTheme === system
      ? isSystemLight
        ? defaultLight
        : defaultDark
      : userPreferredTheme

  // Remove existing theme classes
  Object.values(themes).forEach((theme) => {
    document.body.classList.remove(theme.name)
  })
  // Add current theme class
  document.body.classList.add(currentTheme)
}

export const userPreferredThemeStore = writable(
  localStorage.userPreferredTheme in themes
    ? localStorage.userPreferredTheme
    : defaultTheme
)

export const isDark = derived(
  userPreferredThemeStore,
  ($userPreferredThemeStore) => {
    if ($userPreferredThemeStore === system) {
      return window.matchMedia(systemPrefersDarkMediaQuery).matches
    }
    return $userPreferredThemeStore in DARK_THEMES
  }
)

export const styleColorScheme = derived(userPreferredThemeStore, ($theme) =>
  system === $theme ? "light dark" : $theme
)

userPreferredThemeStore.subscribe((userPreferredTheme) => {
  localStorage.userPreferredTheme = userPreferredTheme
  updateTheme()
})

window
  .matchMedia(systemPrefersDarkMediaQuery)
  .addEventListener("change", updateTheme)
