<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="profileLight" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Profile-Light</title>
<path d="M5.465 7.512c1.267 0 2.293-1.048 2.293-2.341s-1.027-2.341-2.293-2.341c-1.267 0-2.293 1.048-2.293 2.341s1.027 2.341 2.293 2.341z"></path>
<path d="M5.465 3.661c-0.817 0-1.48 0.676-1.48 1.51s0.662 1.51 1.48 1.51c0.817 0 1.48-0.676 1.48-1.51s-0.662-1.51-1.48-1.51zM2.359 5.171c0-1.751 1.391-3.171 3.107-3.171s3.107 1.42 3.107 3.171c0 1.751-1.391 3.171-3.107 3.171s-3.107-1.42-3.107-3.171z"></path>
<path d="M0.814 12.25c0-2.607 2.069-4.729 4.633-4.729s4.633 2.112 4.633 4.729h-9.266z"></path>
<path d="M1.714 11.42h7.466c-0.371-1.757-1.898-3.068-3.733-3.068-1.833 0-3.36 1.316-3.733 3.068zM-0 12.25c0-3.065 2.433-5.559 5.447-5.559 3.013 0 5.447 2.484 5.447 5.559 0 0.459-0.364 0.83-0.814 0.83h-9.266c-0.449 0-0.814-0.372-0.814-0.83z"></path>
<path d="M14.188 5.809c0-0.459 0.364-0.83 0.814-0.83h8.185c0.449 0 0.814 0.372 0.814 0.83s-0.364 0.83-0.814 0.83h-8.185c-0.449 0-0.814-0.372-0.814-0.83z"></path>
<path d="M-0 16.275c0-0.459 0.364-0.83 0.814-0.83h22.373c0.449 0 0.814 0.372 0.814 0.83s-0.364 0.83-0.814 0.83h-22.373c-0.449 0-0.814-0.372-0.814-0.83z"></path>
<path d="M-0 21.509c0-0.459 0.364-0.83 0.814-0.83h11.186c0.449 0 0.813 0.372 0.813 0.83s-0.364 0.83-0.813 0.83h-11.186c-0.449 0-0.814-0.372-0.814-0.83z"></path>
<path d="M14.785 21.509c0-0.459 0.364-0.83 0.814-0.83h7.588c0.449 0 0.814 0.372 0.814 0.83s-0.364 0.83-0.814 0.83h-7.588c-0.449 0-0.814-0.372-0.814-0.83z"></path>
<path d="M14.188 11.042c0-0.459 0.364-0.83 0.814-0.83h8.185c0.449 0 0.814 0.372 0.814 0.83s-0.364 0.83-0.814 0.83h-8.185c-0.449 0-0.814-0.372-0.814-0.83z"></path>
</svg>