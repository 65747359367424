import type { ItemsResponse } from '../../tastyworks'
import {
  ENTITY_AUTHORITY_DESER,
  EntityAuthority
} from '../../tastyworks/entity-authority'
import type HttpClient from '../../tastyworks/http'

export class EntityAuthorityService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly index = async (): Promise<ItemsResponse<EntityAuthority>> =>
    this.customerMeClient.indexSimple(
      ENTITY_AUTHORITY_DESER.toParser(EntityAuthority),
      '/entities'
    )
}
