import {
  ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER,
  AccountPortfolioSubscription
} from '../../tastyworks/account-portfolio-subscription'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class CustomerAccountPortfolioSubscriptionService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly index = async (): Promise<
    ItemsResponse<AccountPortfolioSubscription>
  > =>
    this.customerMeClient.indexSimple(
      ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER.toParser(
        AccountPortfolioSubscription
      ),
      'portfolio-subscriptions'
    )
}
