<script lang="ts">
  import StackedLayout from "/@/account-management/control/input-field-label/StackedLayout.svelte"
  import { SvelteComponent } from "svelte"

  export let variant: typeof SvelteComponent<any> = StackedLayout
  let cssClass = ""
  export { cssClass as class }
  export let showLabel = true
</script>

<svelte:component this={variant} class={cssClass} {showLabel}>
  <slot name="label" slot="label" />
  <slot name="input" slot="input" />
  <slot name="error" slot="error" />
</svelte:component>
