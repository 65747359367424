<svelte:options immutable />

<script context="module">
  import goog from "/@lib/boulangerie"
  const UiViewAdapter = goog.module.get("tasty.ui.boule.UiViewAdapter")

  class SvelteAdapter extends UiViewAdapter {
    constructor(callback) {
      super()
      this.callback = callback
    }

    setText(text) {
      this.callback(text ?? "")
    }
  }
</script>

<script>
  import { onDestroy } from "svelte"
  import { uiBouleRendererFactory } from "/@lib/boulangerie"

  export let source
  export let field

  export let text = ""

  const adapter = new SvelteAdapter((content) => (text = content))
  const renderer = uiBouleRendererFactory.createPlain(adapter)

  $: renderer.source = source
  $: renderer.field = field

  onDestroy(() => renderer.tearDown())
</script>

<slot {text} />
