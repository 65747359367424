import type { Delegate, Disposer, RunLoop, RunLooper } from './runloop'

/**
 * The default initial interval value in milliseconds
 */
const INITIAL_INTERVAL = 1000

/**
 * The default randomization factor (0.5 which results in a random period ranging between 50%
 * below and 50% above the retry interval).
 */
const RANDOMIZATION_FACTOR = 0.5

/**
 * The default multiplier value (1.5 which is 50% increase per back off).
 */
const MULTIPLIER = 1.5

/**
 * The default maximum back off time in milliseconds
 */
const MAX_INTERVAL = 60000 // 1 minute

const calcNextRandomInterval = (currentInterval: number): number => {
  const delta = RANDOMIZATION_FACTOR * currentInterval
  const minInterval = currentInterval - delta
  const maxInterval = currentInterval + delta
  const random = Math.random()
  // Get a random value from the range [minInterval, maxInterval].
  // The formula used below has a +1 because if the minInterval is 1 and the maxInterval is 3 then
  // we want a 33% chance for selecting either 1, 2 or 3.
  const nextInterval = minInterval + random * (maxInterval - minInterval + 1)
  return nextInterval
}

const calcNextBaseInterval = (
  currentInterval: number,
  maxIntervalAdjusted: number
): number => {
  if (currentInterval >= maxIntervalAdjusted) {
    return maxIntervalAdjusted
  }

  return currentInterval * MULTIPLIER
}

export class EndlessExponentialRetry implements RunLooper {
  private scheduled: Disposer | null = null

  private readonly later: Delegate

  private readonly maxIntervalAdjusted: number

  private currentInterval: number

  private randomizedInterval = -1

  constructor(
    private readonly runLoop: RunLoop,
    private readonly delegate: Delegate,
    readonly initialInterval = INITIAL_INTERVAL,
    readonly maxInterval = MAX_INTERVAL
  ) {
    this.later = () => {
      if (this.scheduled === null) {
        return // Cancel requested but already scheduled to run
      }

      delegate()
      this.scheduled = null
    }

    this.maxIntervalAdjusted = Math.floor(maxInterval / MULTIPLIER)
    this.currentInterval = initialInterval
  }

  readonly schedule = () => {
    if (this.scheduled !== null) {
      return
    }

    this.randomizedInterval = calcNextRandomInterval(this.currentInterval)
    this.scheduled = this.runLoop.later(this.randomizedInterval, this.later)

    this.currentInterval = calcNextBaseInterval(
      this.currentInterval,
      this.maxIntervalAdjusted
    )
  }

  readonly cancel = () => {
    this.currentInterval = this.initialInterval
    this.randomizedInterval = -1

    if (this.scheduled !== null) {
      this.scheduled()
      this.scheduled = null
    }
  }

  readonly now = () => {
    this.delegate()
  }

  get stats() {
    return {
      currentInterval: this.currentInterval,
      randomizedInterval: this.randomizedInterval
    }
  }
}
