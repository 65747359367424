<svelte:options immutable />

<script context="module">
  export const HighlightState = Object.freeze({
    HIGHLIGHTED: "highlighted",
    MUTED: "muted",
    NONE: null,
  })
</script>

<script>
  import { createEventDispatcher, getContext } from "svelte"
  import {
    HAS_BORDER_BOTTOM_FN,
    VISIBLE_COLUMN_METAS,
  } from "./BouleTable.svelte"
  import BouleTableCell from "./BouleTableCell.svelte"
  import { BouleSmallCollectionView } from "/@/boule/control"

  const dispatch = createEventDispatcher()

  export let item
  export let index
  export let empty
  export let highlight = HighlightState.NONE
  let cssClass = ""
  export { cssClass as class }

  const visibleColumnMetas = getContext(VISIBLE_COLUMN_METAS)
  const hasBorderBottom = getContext(HAS_BORDER_BOTTOM_FN) ?? (() => true)

  function handleClick(event) {
    dispatch("click", {
      ctrlKey: event.ctrlKey,
      index,
      item,
      shiftKey: event.shiftKey,
      x: event.clientX,
      y: event.clientY,
    })
  }

  function handleContextMenu(event) {
    dispatch("contextmenu", { item, x: event.clientX, y: event.clientY })
  }
</script>

<button
  role="row"
  type="button"
  class="boule-table-row {cssClass}"
  class:empty
  class:has-border-bottom={hasBorderBottom(item)}
  class:highlighted={HighlightState.HIGHLIGHTED === highlight}
  class:muted={HighlightState.MUTED === highlight}
  data-index={index}
  on:click={handleClick}
  on:contextmenu|preventDefault={handleContextMenu}
>
  <BouleSmallCollectionView
    bouleArray={visibleColumnMetas}
    let:item={columnMeta}
  >
    <BouleTableCell {item} {empty} {columnMeta} />
  </BouleSmallCollectionView>

  <slot {item} />
</button>

<style lang="postcss">
  .boule-table-row {
    width: 100%;
    display: flex;

    &.has-border-bottom {
      border-bottom: var(--border-divider-horizontal);
    }

    &.separator {
      border-bottom: 1px solid var(--color-text-general-secondary-text);
    }

    &.highlighted {
      border-radius: var(--small-border-radius);
      outline: var(--small-border-solid)
        var(--color-border-general-row-selection);
      outline-offset: calc(-1 * var(--small-border-width));
    }

    &.muted {
      opacity: 0.7;
    }
  }
</style>
