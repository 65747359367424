import type { SingleResponse } from '../../tastyworks'
import {
  FUTURES_AGREEMENT_RECORD_DESER,
  FuturesAgreementRecord
} from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class FuturesAgreementRecordService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly show = async (): Promise<SingleResponse<FuturesAgreementRecord>> =>
    this.customerMeClient.show(
      new FuturesAgreementRecord(),
      FUTURES_AGREEMENT_RECORD_DESER.update,
      '/futures-agreement-record'
    )

  readonly update = async (
    futuresAgreementRecord: FuturesAgreementRecord
  ): Promise<SingleResponse<FuturesAgreementRecord>> =>
    this.customerMeClient.update(
      futuresAgreementRecord,
      FUTURES_AGREEMENT_RECORD_DESER.serialize,
      FUTURES_AGREEMENT_RECORD_DESER.update,
      '/futures-agreement-record'
    )

  readonly create = async (
    futuresAgreementRecord: FuturesAgreementRecord
  ): Promise<SingleResponse<FuturesAgreementRecord>> =>
    this.customerMeClient.create(
      futuresAgreementRecord,
      FUTURES_AGREEMENT_RECORD_DESER.serialize,
      FUTURES_AGREEMENT_RECORD_DESER.update,
      '/futures-agreement-record'
    )
}
