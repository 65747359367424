import { boolean, number, object } from 'yup'
import { MINIMUM_SPECIFIC_MARGIN_BALANCE } from '../../advanced-trading-request'
import { buildSchema } from './util/build-schema'

const schema = object({
  equityTradeSize: number().required(),
  isDiversifyingSinglePosition: boolean().required(),
  isFlatEndOfDay: boolean().required(),
  isHighConcentrationIndividualEquity: boolean().required(),
  isLongShortBias: boolean().required(),
  isMergerArbitrage: boolean().required(),
  isRiskArbitrage: boolean().required(),
  isTradingComplexStrategies: boolean().required(),
  isVolatilityArbitrage: boolean().required(),
  hasCalendarSpreads: boolean().required(),
  hasCoveredSpreads: boolean().required(),
  hasMultiLegSpreads: boolean().required(),
  hasMultiProductSpreads: boolean().required(),
  hasNakedSpreads: boolean().required(),
  hasOvernightPositions: boolean().required(),
  hasPreviousPortfolioMargin: boolean().required(),
  hasTypicalMarginBalance: boolean().required(),
  hasVerticalSpreads: boolean().required(),
  marginBalanceRange: number()
    .nullable()
    .when('hasTypicalMarginBalance', {
      is: true,
      then: fieldSchema => fieldSchema.required()
    }),
  monthlyTrades: number().required(),
  openingAccountBalance: number().required(),
  optionTradeSize: number().required(),
  specificMarginBalance: number()
    .nullable()
    .when('marginBalanceRange', {
      is: (value: number) => value >= MINIMUM_SPECIFIC_MARGIN_BALANCE,
      then: fieldSchema =>
        fieldSchema.required().min(MINIMUM_SPECIFIC_MARGIN_BALANCE)
    })
})

export const portfolioMarginQuestionnaireSchema = buildSchema(
  'PortfolioMarginQuestionnaire',
  schema
)
