import {
  CASH_RESTRICTION_DESER,
  CashRestriction
} from '../../tastyworks/cash-restriction'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'

export class CashRestrictionService {
  private readonly client: HttpClient

  constructor(accountNumberClient: HttpClient) {
    this.client = accountNumberClient.nested('cash-restrictions')
  }

  readonly show = async (
    accountNumber: string
  ): Promise<SingleResponse<CashRestriction>> =>
    this.client.show(new CashRestriction(), CASH_RESTRICTION_DESER.update, '', {
      accountNumber
    })
}
