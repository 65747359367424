import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum RecaptchaVersion {
  V2 = 'v2',
  V3 = 'v3',
  ENTERPRISE = 'enterprise'
}

export enum RecaptchaAction {
  REGISTER = 'register'
}

export enum RecaptchaClientType {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web'
}

export enum MOBILE_CONFIRMATION_STATES {
  CONFIRMED = 'confirmed',
  UNCONFIRMED = 'unconfirmed',
  RECONFIRM = 'reconfirm',
  UNKNOWN = 'unknown'
}

export const PRESET_SECURITY_QUESTIONS = Object.freeze([
  'I want to write my own security question',
  'What was your childhood nickname?',
  'In what city did you meet your spouse or significant other?',
  'What is the name of your childhood best friend?',
  'What street did you live on in the third grade?',
  'What is your oldest siblings birthday month and year?',
  'What is the middle name of your youngest child?',
  "What is your oldest sibling's middle name?",
  'What was your middle school mascot?'
])

export default class TwUser extends RestResource {
  static onInitialize = (_obj: TwUser) => {
    /* no-op */
  }

  constructor() {
    super()
    TwUser.onInitialize(this)
  }

  email = ''
  username = ''
  nickname = ''
  sessionToken = ''
  externalId = ''
  mobileConfirmationState = ''
  isConfirmed = false
  confirmedAt?: Date
  lastSignInAt?: Date
  isLocked = false
  failedAttempts = 0
  securityQuestions: SecurityQuestion[] = []
}

export const NO_USER = new TwUser()

export const USER_DESER: SchemaDeSer<TwUser> = new SchemaDeSerBuilder(TwUser)
  .ofString('email')
  .ofString('username')
  .ofString('externalId')
  .ofString('mobileConfirmationState')
  .ofBoolean('isConfirmed')
  .ofDateTime('confirmedAt')
  .ofDateTime('lastSignInAt')
  .ofBoolean('isLocked')
  .ofInt('failedAttempts')
  .ofString('id')
  .toDeSer()

export enum TwoFactorType {
  AUTHENTICATOR = 'authenticator',
  SMS = 'sms'
}

export class AuthenticatorConfirmation {
  otpProvisioningUri = ''
  secret = ''
}

export const AUTHENTICACTOR_CONFIRMATION_DESER: SchemaDeSer<AuthenticatorConfirmation> =
  new SchemaDeSerBuilder(AuthenticatorConfirmation)
    .ofString('otpProvisioningUri')
    .toDeSer()

export class SecurityQuestion {
  static onInitialize = (_obj: SecurityQuestion) => {
    /* no-op */
  }

  constructor() {
    SecurityQuestion.onInitialize(this)
  }

  question = ''
  answer = ''
}

export const SECURITY_QUESTION_DESER: SchemaDeSer<SecurityQuestion> =
  new SchemaDeSerBuilder(SecurityQuestion)
    .ofString('question')
    .ofString('answer')
    .toDeSer()

export class TwoFactorMethod {
  static onInitialize = (_obj: TwoFactorMethod) => {
    /* no-op */
  }

  constructor() {
    TwoFactorMethod.onInitialize(this)
  }

  isActive: boolean | null = null
  type: TwoFactorType | null = null

  get isSms() {
    return this.type === TwoFactorType.SMS
  }

  get isAuthenticator() {
    return this.type === TwoFactorType.AUTHENTICATOR
  }
}

export const TWO_FACTOR_METHOD_DESER: SchemaDeSer<TwoFactorMethod> =
  new SchemaDeSerBuilder(TwoFactorMethod)
    .ofString('type')
    .ofBoolean('isActive')
    .toDeSer()

export class TwoFactorStatus {
  isTwoFactorOptOut: boolean | null = null
}

export const TWO_FACTOR_STATUS_DESER: SchemaDeSer<TwoFactorStatus> =
  new SchemaDeSerBuilder(TwoFactorStatus)
    .ofBoolean('isTwoFactorOptOut')
    .toDeSer()

export class MobileConfirmation {
  static onInitialize = (_obj: MobileConfirmation) => {
    /* no-op */
  }

  constructor() {
    MobileConfirmation.onInitialize(this)
  }

  mobileConfirmationState = ''
  mobileNumber = ''
  unconfirmedMobileNumber = ''
}

export const MOBILE_CONFIRMATION_DESER: SchemaDeSer<MobileConfirmation> =
  new SchemaDeSerBuilder(MobileConfirmation)
    .ofString('mobileConfirmationState')
    .ofString('mobileNumber')
    .ofString('unconfirmedMobileNumber')
    .toDeSer()
