<script context="module" lang="ts">
  import { PopoverOption } from "./index"

  export interface PopoverLabelOptionProp extends PopoverOption {
    label: string
    labelCssClass?: string
  }
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte"

  import PopoverChildOptionsIndicator from "./PopoverChildOptionsIndicator.svelte"

  export let option: PopoverLabelOptionProp
  export let highlighted = false

  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()
</script>

<button
  type="button"
  class="
    popover-label-option
    flex
    w-full
    cursor-pointer
    flex-row
    items-center
    justify-between
    gap-large
    p-double-extra-small
    {cssClass}"
  class:highlighted
  class:bg-toggle-background-active={highlighted}
  class:bg-transparent={!highlighted}
  on:click={() => {
    // Only leaf options emit close.
    if (option.options) return
    dispatch("close", option)
  }}
>
  <slot>
    <span
      class="text truncate {option.labelCssClass ?? ''}"
      title={option.label}
    >
      {option.label}
    </span>
  </slot>

  {#if !!option.options}
    <PopoverChildOptionsIndicator {highlighted} />
  {/if}
</button>
