const VITE_PRODUCTION_MODE = "production"

export function isProduction() {
  // NOTE: [KT] avoiding import.meta.env.PROD as custom environments,
  // environments not defined by vite, default to true, e.g. staging
  return import.meta.env.MODE === VITE_PRODUCTION_MODE
}

export function isNotProduction() {
  return !isProduction()
}
