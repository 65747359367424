import { Bond, BOND_DESER } from '../../tastyworks/bond'
import type HttpClient from '../../tastyworks/http'
import type { Params } from '../../tastyworks/request'
import type { ItemsResponse } from '../../tastyworks/response'

interface BondParams extends Params {
  symbol?: string[]
}

export class BondService {
  constructor(private readonly baseHttpClient: HttpClient) {}

  readonly index = async (params?: BondParams): Promise<ItemsResponse<Bond>> =>
    this.baseHttpClient.indexSimple(
      BOND_DESER.toParser(Bond),
      'instruments/bonds',
      params
    )
}
