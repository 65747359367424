<script context="module" lang="ts">
  export const BUTTON_CLASS = `
    form-submit-button
    border-0
    button-base
    gap-x-extra-small
    w-full
    button-form-action
    `
</script>

<script lang="ts">
  import { FormContext } from "/@/account-management/form"
  import { SubmitButtonVariant } from "./submit-button-variant"
  import { key } from "@tastyworks/svelte-forms-lib"
  import { getContext } from "svelte"

  export let disabled = false
  export let variant: SubmitButtonVariant = SubmitButtonVariant.CONFIRM
  let cssClass = ""
  export { cssClass as class }

  const buttonClass = `${BUTTON_CLASS} ${cssClass}`
  const { isValid, isSubmitting } = getContext<FormContext>(key)

  function cssClassBackground(variant: SubmitButtonVariant): string {
    if (variant === SubmitButtonVariant.CONTINUE) {
      return "button-continue"
    } else if (variant === SubmitButtonVariant.CONFIRM) {
      return "button-confirmation"
    } else if (variant === SubmitButtonVariant.CANCEL) {
      return "button-cancel"
    } else if (variant === SubmitButtonVariant.DEFAULT) {
      return "button-default"
    }
    return ""
  }
</script>

{#if $isSubmitting}
  <button class="button {buttonClass} {cssClassBackground(variant)}" disabled>
    <slot name="submitting-label">Updating...</slot>
  </button>
{:else if $isValid}
  <button
    class="button {buttonClass} {cssClassBackground(variant)}"
    type="submit"
    {disabled}
  >
    <slot name="submit-label">Submit <span class="icon tw-icon-Export" /></slot>
  </button>
{:else}
  <button class="button {buttonClass} {cssClassBackground(variant)}" disabled>
    <slot name="invalid-form-label">Complete all fields</slot>
  </button>
{/if}
