<script>
  let cssClass = ""
  export { cssClass as class }
</script>

<div role="presentation" class="pill {cssClass}" on:click on:dblclick>
  <slot />
</div>

<style lang="postcss" global>
  .pill {
    display: flex;
    flex-direction: row;

    color: var(--color-text-general-default-text);
    font-size: var(--extra-small-font-size);

    > div,
    > span,
    > .field {
      padding: var(--double-extra-small-spacing);
      white-space: nowrap;

      &:first-of-type {
        border-radius: var(--small-border-radius) 0 0 var(--small-border-radius);
      }
      &:last-of-type {
        border-radius: 0 var(--small-border-radius) var(--small-border-radius) 0;
      }

      &:nth-child(even) {
        background-color: var(--color-background-security-pill-primary);
        &:empty {
          background-color: var(--color-background-security-pill-secondary);
        }
      }
      &:nth-child(odd) {
        background-color: var(--color-background-security-pill-secondary);
        &:empty {
          background-color: var(--color-background-security-pill-primary);
        }
      }
    }
  }
</style>
