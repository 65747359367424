<!-- 
  When using two-way binding, default imask implementation fails to
  stay in sync when programatically setting an input element's
  `value`. This component handles the wiring to keep everything synced
-->
<script lang="ts">
  import { mask, updateValue } from "/@/util/mask"

  export let value
  export let inputElem
  export let maskOptions
  export let placeholder = ""
  let cssClass
  export { cssClass as class }
  let _type = "text"
  export { _type as type }

  let maskRef

  // workaround for no dynamic input types error
  // note attempting to change the type may have undesired effects
  function inputType(el) {
    el.setAttribute("type", _type)
  }

  $: if (inputElem) ({ maskRef } = inputElem)
  $: if (maskRef) updateValue(value, maskRef)
</script>

<!-- FIXME: search is non-standard (may break on firefox) -->
<input
  class={cssClass}
  {placeholder}
  use:mask={maskOptions}
  use:inputType
  bind:this={inputElem}
  bind:value
  on:focus
  on:input
  on:keydown
  on:click
  on:accept
  on:confirm
  on:submit
  on:search
/>
