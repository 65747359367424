import { IRA_DETAIL_DESER, IraDetail } from '../tastyworks/ira-detail'
import {
  ACH_RELATIONSHIP_DESER,
  AchRelationship,
  IRA_CONTRIBUTION_REASONS
} from './cash-management'
import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import toStringValues from './util/enum'

export enum RECURRING_DEPOSIT_STATUSES {
  ACTIVE = 'Active',
  CANCELED = 'Canceled',
  COMPLETE = 'Complete',
  PRE_SEND = 'Pre-Send',
  SEND_FAILED = 'Send Failed'
}

export enum RECURRING_PERIODS {
  DAY_OF_MONTH = 'Day of Month',
  DAYS = 'Days',
  ONE_TIME_TRANSACTION = 'One Time Transaction',
  WEEKS = 'Weeks'
}

export const RECURRING_PERIOD_VALUES = toStringValues(RECURRING_PERIODS)

export enum DEPOSIT_FREQUENCIES {
  ONE_TIME = 'One Time',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  BIWEEKLY = 'Biweekly',
  MONTHLY = 'Monthly'
}

export const DEPOSIT_FREQUENCY_VALUES = toStringValues(DEPOSIT_FREQUENCIES)

const RECURRING_FREQUENCY_DATA = {
  [DEPOSIT_FREQUENCIES.ONE_TIME]: {
    period: null,
    interval: 1
  },
  [DEPOSIT_FREQUENCIES.DAILY]: {
    period: RECURRING_PERIODS.DAYS,
    interval: 1
  },
  [DEPOSIT_FREQUENCIES.WEEKLY]: {
    period: RECURRING_PERIODS.WEEKS,
    interval: 1
  },
  [DEPOSIT_FREQUENCIES.BIWEEKLY]: {
    period: RECURRING_PERIODS.WEEKS,
    interval: 2
  },
  [DEPOSIT_FREQUENCIES.MONTHLY]: {
    period: RECURRING_PERIODS.DAY_OF_MONTH,
    interval: 1
  }
}

export const RECURRING_DEPOSIT_ELIGIBLE_REASONS = [
  IRA_CONTRIBUTION_REASONS.REGULAR,
  IRA_CONTRIBUTION_REASONS.EMPLOYEE,
  IRA_CONTRIBUTION_REASONS.EMPLOYER,
  IRA_CONTRIBUTION_REASONS.TRANSFER
]
export function getPeriodFromFrequency(frequency: DEPOSIT_FREQUENCIES) {
  const frequencyData = RECURRING_FREQUENCY_DATA[frequency]
  return frequencyData.period
}

export function getIntervalFromFrequency(frequency: DEPOSIT_FREQUENCIES) {
  const frequencyData = RECURRING_FREQUENCY_DATA[frequency]
  return frequencyData.interval
}

export class RecurringDeposit extends RestResource {
  achRelationship?: AchRelationship
  achRelationshipId = 0
  accountNumber = ''
  amount = 0
  createdAt?: Date
  interval = 0
  iraDetail?: IraDetail
  isCancelable = false
  period?: RECURRING_PERIODS
  startDate?: Date
  state = RECURRING_DEPOSIT_STATUSES.PRE_SEND

  get isCanceled() {
    return this.state === RECURRING_DEPOSIT_STATUSES.CANCELED
  }

  get frequency() {
    return Object.keys(RECURRING_FREQUENCY_DATA).find(frequency => {
      const frequencyData =
        RECURRING_FREQUENCY_DATA[frequency as DEPOSIT_FREQUENCIES]

      return (
        frequencyData.period === this.period &&
        frequencyData.interval === this.interval
      )
    })
  }
}

export const RECURRING_DEPOSIT_DESER: SchemaDeSer<RecurringDeposit> =
  new SchemaDeSerBuilder(RecurringDeposit)
    .ofNested('achRelationship', ACH_RELATIONSHIP_DESER, AchRelationship)
    .ofInt('achRelationshipId')
    .ofString('accountNumber')
    .ofFloat('amount')
    .ofDateTime('createdAt')
    .ofString('id')
    .ofInt('interval')
    .ofNested('iraDetail', IRA_DETAIL_DESER, IraDetail)
    .ofBoolean('isCancelable')
    .ofString('period')
    .ofDate('startDate')
    .ofString('state')
    .toDeSer()
