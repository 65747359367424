import Contentstack from "contentstack"

/**
 * Moved contentstack initialisation to a separate file to get around some
 * mocking issues.
 */
export default Contentstack.Stack({
  api_key: import.meta.env.VITE_CONTENTSTACK_API_KEY,
  delivery_token: import.meta.env.VITE_CONTENTSTACK_DELIVERY_TOKEN,
  environment: import.meta.env.VITE_CONTENTSTACK_ENVIRONMENT,
})
