import { writable } from "svelte/store"
import boulePropertyStore from "/@/boule/control/boule-property-store"
import { symbolSearchManager } from "/@lib/boulangerie"

export const resultsPanelOpen = writable(false)

export const results = boulePropertyStore(
  symbolSearchManager.symbolSearchResultsProperty()
)

export async function searchForSymbol(symbol) {
  return symbolSearchManager.search(symbol)?.toPromise()
}

export function submitSymbol(symbol) {
  // SymbolSearchResult-ish.
  return { security: symbolSearchManager.submit(symbol), symbol }
}

export const searchValue = writable(null)
