<script lang="ts">
  import { key as formKey } from "@tastyworks/svelte-forms-lib"
  import { ValidateYup } from "@tastyworks/tastyworks-api"
  import { createEventDispatcher, getContext } from "svelte"
  import { _, json } from "svelte-i18n"
  import Block5050Layout from "/@/account-management/control/input-field-label/Block5050Layout.svelte"
  import InputFieldLabel from "/@/account-management/control/input-field-label/InputFieldLabel.svelte"
  import LabelText from "/@/account-management/control/LabelText.svelte"
  import {
    ErrorMessage,
    FormContext,
    OptionEntry,
    ReadOnly,
  } from "/@/account-management/form"
  import {
    findFieldTranslationKey,
    getByPath,
    getOptionText,
  } from "/@/account-management/form/util"
  import ButtonGroup from "/@/control/button-group/ButtonGroup.svelte"

  const dispatch = createEventDispatcher()

  const { form, isReadOnly, validationSchema, updateValidateField } =
    getContext<FormContext>(formKey)

  let cssClass = ""
  export { cssClass as class }
  export let name: string
  export let fieldTranslationKey = findFieldTranslationKey(
    validationSchema,
    name
  )
  export let label = $_(fieldTranslationKey)
  export let optionEntries: OptionEntry[] = []
  export let variant = Block5050Layout
  export let buttonGroupClass = ""

  let selectedIndex = null

  const { optionTranslationKey } = ValidateYup
  const optionsPath = optionTranslationKey(fieldTranslationKey)
  const buttons = buildButtons(optionEntries)

  $: value = getByPath($form, name)
  $: selectedIndex = findSelectedIndex(value)

  function buildButtons(optionEntries: OptionEntry[]) {
    return optionEntries.map((optionEntry) => {
      return {
        label: getOptionText(optionEntry, $json(optionsPath)),
        value: optionEntry.value,
      }
    })
  }

  function findSelectedIndex(selectedValue: any) {
    return buttons.findIndex(
      (button) =>
        button.value === selectedValue ||
        button.value === selectedValue?.toString()
    )
  }

  function handleChange({ detail }) {
    updateValidateField(name, detail.value)
    dispatch("change")
  }
</script>

<div class="form-button-group {cssClass}">
  <InputFieldLabel {variant}>
    <LabelText slot="label" {label} />

    <span slot="input" class="form-button-group-input flex grow">
      {#if $isReadOnly}
        <ReadOnly
          class="flex grow justify-end bg-transparent"
          {name}
          {fieldTranslationKey}
        />
      {:else}
        <ButtonGroup
          {buttons}
          {selectedIndex}
          class={buttonGroupClass}
          on:click={handleChange}
        />
      {/if}
    </span>
    <ErrorMessage slot="error" {name} />
  </InputFieldLabel>
</div>
