<svelte:options immutable />

<script context="module">
  import goog from "/@lib/boulangerie"
  import { uiBouleRendererFactory } from "/@lib/boulangerie"
  const UiViewNumericDirectionAdapter = goog.module.get(
    "tasty.ui.boule.UiViewNumericDirectionAdapter"
  )
  const NumericDirection = goog.module.get(
    "com.dough.trade.model.NumericDirection"
  )

  class SvelteAdapter extends UiViewNumericDirectionAdapter {
    constructor(setText, clearNumericDirection, setNumericDirection) {
      super()
      this._setText = setText
      this._clearNumericDirection = clearNumericDirection
      this._setNumericDirection = setNumericDirection
    }

    setText(text) {
      this._setText(text)
    }

    clearNumericDirection() {
      this._clearNumericDirection()
    }

    setNumericDirection(numericDirection) {
      this._setNumericDirection(numericDirection)
    }
  }
</script>

<script>
  import { onDestroy } from "svelte"
  export let source
  export let field
  let cssClass = ""
  export { cssClass as class }
  export let style = ""

  let text
  let numericDirection = null

  const adapter = new SvelteAdapter(
    (nextText) => (text = nextText),
    () => (numericDirection = null),
    (nextNumericDirection) => (numericDirection = nextNumericDirection)
  )

  const renderer = uiBouleRendererFactory.createNumericDirection(adapter)

  $: renderer.source = source
  $: renderer.field = field

  onDestroy(() => renderer.tearDown())
</script>

<span
  class="numeric-direction-text {cssClass}"
  class:numeric-direction-text-up={NumericDirection.UP === numericDirection}
  class:numeric-direction-text-down={NumericDirection.DOWN === numericDirection}
  class:empty={!text}
  {style}
>
  {text}
  <span
    class="icon"
    class:tw-icon-Tick-Up={NumericDirection.UP === numericDirection}
    class:tw-icon-Tick-Down={NumericDirection.DOWN === numericDirection}
    class:tw-icon-Tick-Same={NumericDirection.SAME === numericDirection}
  />
</span>
