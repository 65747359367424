<script context="module">
  import goog from "/@lib/boulangerie"
  const SecurityFormatter = goog.module.get("com.dough.text.SecurityFormatter")
  const TwAmBadgesUtil = goog.module.get("com.dough.tw.util.TwAmBadgesUtil")
</script>

<script>
  import { _ } from "svelte-i18n"
  import { ZeroHashBadge } from "/@/trading/control"
  import Pill from "/@/control/Pill.svelte"
  import { roundArrow } from "tippy.js"
  import { tippy } from "svelte-tippy"
  import { getCssPropertyValueNumeric, px } from "/@/util"

  let cssClass = ""
  export let securityEntry
  export { cssClass as class }
  export let expirationClass = ""
  export let alwaysVisible = false // for cases where an empty pill even if there is no securityEntry
  export let grow = false

  const fixedWidth = px(3.5 * getCssPropertyValueNumeric("--horizontal-meter"))

  const tippyOptions = {
    animation: "fade",
    arrow: roundArrow,
    content: TwAmBadgesUtil.AM_BADGES_TOOLTIP_INFO,
    duration: 250,
  }

  $: security = securityEntry?.security
  $: isNonStandard =
    security?.optionChainType && !security.optionChainType.isStandard()
  $: isAmSettled = security?.amSettled
  $: widthClass = grow ? "w-full grow" : `w-[${fixedWidth}]`
</script>

<Pill
  class="
    security-entry-pill-view
    font-x-small-500
    relative
    inline-flex
    max-w-full
    leading-[1.125em]
    {widthClass}
    {cssClass}"
>
  {#if securityEntry || alwaysVisible}
    <slot name="before" />

    {#if securityEntry?.optionLike}
      <slot name="quantity" />
      <div class="field expiration {expirationClass}">
        <slot name="expiration" />
        {#if isAmSettled}
          <span
            class="
              am-badge
              font-xxx-small-800-caps
              absolute
              bottom-small
              rounded-small
              bg-button-order-status-working
              px-triple-extra-small
              py-0"
            use:tippy={tippyOptions}
          >
            {$_("content.badges.amExpiration")}
          </span>
        {/if}
      </div>
      <slot name="days-to-expiration" />

      <slot name="strike" />
      <slot name="call-or-put" />
    {:else}
      <span class="underlying-asset">
        {SecurityFormatter.unitDescription(security)}
      </span>
      <span class="underlying-quantity">
        <slot name="quantity" />
      </span>
    {/if}

    <slot name="after" />
  {/if}
  {#if isNonStandard}
    <span
      class="
        non-standard-badge
        font-xxx-small-800-caps
        absolute
        left-triple-extra-small
        top-0
        rounded-small
        bg-indicator-itm
        px-triple-extra-small
        py-0
        text-general-inverted-text"
    >
      {$_("content.badges.nonStandard")}
    </span>
  {/if}
  {#if security?.assetType.isCrypto()}
    <ZeroHashBadge class="absolute left-0 top-0" />
  {/if}
</Pill>

<style lang="postcss" global>
  .security-entry-pill-view {
    > .underlying-asset {
      flex-basis: 35%;
      display: flex;
      align-items: center;
      justify-content: right;
    }
    > .underlying-quantity {
      flex-basis: 65%;
      display: flex;
      align-items: center;
      justify-content: right;
    }

    /* span is used to override the specificity from Pill.svelte styles. 
    @layer might be an alternative worth consideration */
    > .field {
      padding: var(--double-extra-small-spacing);
      flex-grow: 1;
      text-align: center;

      &.quantity {
        text-align: right;
        flex-basis: 25%;
      }
      &.expiration {
        flex-basis: 25%;
      }
      &.days-to-expiration {
        flex-basis: 20%;
      }
      &.strike {
        flex-basis: 22%;
      }
      &.call-or-put {
        flex-basis: 8%;
      }
    }
  }
</style>
