<script context="module">
  import goog from "/@lib/boulangerie"
  const CostFormatUtil = goog.module.get("com.dough.trade.util.CostFormatUtil")
  const TradeViewIdentifier = goog.module.get(
    "tasty.analytics.TradeViewIdentifier"
  )
  const CostEffect = goog.module.get("com.dough.trade.model.CostEffect")

  /**
   * TODO: consider extracting
   * FIXME: probably needs debounce
   * listen for pointer pointer move until cursor is released
   * @param node
   * @param listener
   */
  function cursorDrag(node, params) {
    function setup({ listener, container }) {
      if (!container) {
        return () => {}
      }

      function wrappedListener(event) {
        event.preventDefault()
        event.stopPropagation()
        const { left } = node.getBoundingClientRect()
        const position = event.pageX - left
        listener(position)
      }

      function blur() {
        container.removeEventListener("pointermove", wrappedListener)
        container.removeEventListener("pointerup", blur)
      }

      function focus(event) {
        wrappedListener(event)
        container.setPointerCapture?.(event.pointerId)
        container.addEventListener("pointermove", wrappedListener)
        container.addEventListener("pointerup", blur)
      }

      node.addEventListener("pointerdown", focus)
      return () => {
        node.removeEventListener("pointerdown", focus)
        blur()
      }
    }

    let tearDown = setup(params)
    return {
      destroy() {
        tearDown()
      },
      update(params) {
        tearDown = setup(params)
      },
    }
  }
</script>

<script>
  import { createEventDispatcher } from "svelte"
  import { formatStyle, px } from "/@/util"
  import userAnalytics from "/@/util/user-analytics.ts"

  export let cost
  export let midNatBarHelper
  export let security
  export let disabled = false

  let cssClass = ""
  export { cssClass as class }

  let barWidth
  let container

  const dispatch = createEventDispatcher()

  $: offset = midNatBarHelper?.toOffset(cost, barWidth)
  $: overlayType = midNatBarHelper?.isTowardsNatural(cost) ? "nat" : "opp"

  function deriveCostClass(offset, cost) {
    if (midNatBarHelper.isPastLeft(cost) || midNatBarHelper.isPastRight(cost)) {
      return CostEffect.NO_COST
    }

    const target =
      offset < barWidth / 2 ? midNatBarHelper.left : midNatBarHelper.right

    return target.effect
  }

  function deriveOverlayStyle(offset) {
    const halfWidth = barWidth / 2
    const isTowardsLeft = offset < halfWidth

    return formatStyle({
      left: px(isTowardsLeft ? offset : halfWidth),
      width: px(Math.abs(halfWidth - offset)),
    })
  }

  function handleBarDrag(position) {
    const cost = midNatBarHelper.toCost(position, barWidth)
    setLimitCost(cost)
  }

  function setLimitCost(cost) {
    if (disabled) return
    dispatch("change", cost)
  }
</script>

{#if midNatBarHelper}
  <div
    class="mid-nat-bar {cssClass}"
    class:mid-nat-disabled={disabled}
    bind:this={container}
  >
    <div class="header">
      <button
        type="button"
        class="tick-label"
        on:click={() => setLimitCost(midNatBarHelper.left)}
      >
        {midNatBarHelper.natLeft ? "nat" : "opp"}
      </button>
      <button
        type="button"
        class="tick-label"
        on:click={() => setLimitCost(midNatBarHelper.pricing.midCost)}
      >
        {midNatBarHelper.theoreticalMid ? "theo mid" : "mid"}
      </button>
      <button
        type="button"
        class="tick-label"
        on:click={() => setLimitCost(midNatBarHelper.right)}
      >
        {midNatBarHelper.natLeft ? "opp" : "nat"}
      </button>
    </div>
    <div
      class="background-bar"
      bind:clientWidth={barWidth}
      use:cursorDrag={{ container, listener: handleBarDrag }}
    >
      {#if !disabled}
        <div
          class="tick left-0"
          class:tick-warn={midNatBarHelper.isPastLeft(cost)}
        />
        <div class="tick left-1/2" />
        <div
          class="tick left-full"
          class:tick-warn={midNatBarHelper.isPastRight(cost)}
        />
        <div
          class="overlay {overlayType}-overlay {deriveCostClass(offset, cost)}"
          class:natLeft={midNatBarHelper.natLeft}
          style={deriveOverlayStyle(offset)}
        />
      {/if}
    </div>
    <div class="footer truncate">
      <button
        type="button"
        class="tick-cost"
        use:userAnalytics={TradeViewIdentifier.MID_NAT_LEFT_VALUE_BUTTON}
        on:click={() => setLimitCost(midNatBarHelper.left)}
      >
        {CostFormatUtil.withShortCode(midNatBarHelper.left, security)}
      </button>
      <button
        type="button"
        class="tick-cost {midNatBarHelper.pricing.midCost.effect}"
        use:userAnalytics={TradeViewIdentifier.MID_NAT_MID_VALUE_BUTTON}
        on:click={() => setLimitCost(midNatBarHelper.pricing.midCost)}
      >
        {CostFormatUtil.withShortCode(
          midNatBarHelper.pricing.midCost,
          security
        )}
      </button>
      <button
        type="button"
        class="tick-cost"
        use:userAnalytics={TradeViewIdentifier.MID_NAT_RIGHT_VALUE_BUTTON}
        on:click={() => setLimitCost(midNatBarHelper.right)}
      >
        {CostFormatUtil.withShortCode(midNatBarHelper.right, security)}
      </button>
    </div>
  </div>
{/if}

<style lang="postcss">
  .mid-nat-bar {
    --bar-height: 8px;
    /* shrink the bar width by the size of a tick to account for the half tick that sticks out */
    --bar-width: calc(100% - var(--bar-height));
    position: relative;
    touch-action: none;
    z-index: 0;
  }

  .header,
  .footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--normal-spacing);
  }

  .header,
  .background-bar,
  .footer {
    margin: 0 auto;
  }

  .background-bar {
    width: var(--bar-width);
    height: var(--bar-height);
    position: relative;
    background-color: var(--color-background-mid-nat-range-neutral);
  }

  .overlay {
    height: var(--bar-height);
    position: absolute;
    z-index: var(--mid-nat-bar-overlay-z-index);

    &.DEBIT {
      background-color: var(
        --color-cost-debit,
        var(--color-background-mid-nat-range-positive)
      );
    }

    &.CREDIT {
      background-color: var(
        --color-cost-credit,
        var(--color-background-mid-nat-range-negative)
      );
    }

    &.NO_COST {
      background-color: var(--color-background-mid-nat-range-neutral);
      background-image: url("/@/assets/image/profitability/premium-dk.png");
    }
  }

  .nat-overlay {
    background-image: url("/@/assets/image/profitability/premium-dk.png");
    background-blend-mode: difference;
  }

  .tick {
    transform: translate(-50%);
    position: absolute;
    width: var(--bar-height);
    height: var(--bar-height);
    background-color: white;
    border-radius: 50%;
    z-index: var(--mid-nat-bar-tick-z-index);
  }

  .tick-label {
    bottom: var(--large-spacing);
    text-transform: uppercase;
    color: var(--color-text-general-secondary-label);
    font-size: var(--small-font-size);
    font-weight: var(--heavy-font-weight);
  }

  .tick-cost {
    color: var(--color-text-general-secondary-text);

    /* XXX: Intentionally flipped. */
    &.DEBIT {
      color: var(--color-cost-credit, var(--color-text-general-positive-tick));
    }

    &.CREDIT {
      color: var(--color-cost-debit, var(--color-text-general-negative-tick));
    }
  }

  .tick-warn {
    /* TODO: replace placeholder token when available */
    background-color: var(--color-border-alert-error);
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "!";
      font-size: var(--bar-height);
      line-height: var(--bar-height);
    }
  }

  .mid-nat-bar:not(.mid-nat-disabled) {
    > .header > .tick-label,
    > .footer > .tick-cost,
    > .background-bar {
      cursor: pointer;
    }
  }
</style>
