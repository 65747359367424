import type HttpClient from '../../tastyworks/http'
import {
  PDT_RESET_ELIGIBILITY_RESPONSE_DESER,
  PDT_RESET_RESPONSE_DESER,
  PdtResetEligibilityResponse,
  PdtResetResponse
} from '../../tastyworks/pdt-reset'
import type { SingleResponse } from '../../tastyworks/response'
import { updateSingleResponse } from '../../tastyworks/response'
import {
  TRADING_STATUS_DESER,
  TradingStatus
} from '../../tastyworks/trading-status'

export class PdtResetRequestService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  async removeDayTradeCall(
    pdtResetResponse: PdtResetResponse
  ): Promise<SingleResponse<TradingStatus>> {
    const tradingStatus = new TradingStatus()
    const response = await this.accountNumberClient.patch(
      'pdt-reset-requests/:id/remove-day-trade-call',
      {},
      { accountNumber: pdtResetResponse.accountNumber, id: pdtResetResponse.id }
    )
    return updateSingleResponse(
      response,
      tradingStatus,
      TRADING_STATUS_DESER.update
    )
  }

  async showEligibility(
    accountNumber: string
  ): Promise<SingleResponse<PdtResetEligibilityResponse>> {
    const elegibilityResponse = new PdtResetEligibilityResponse()
    const response = await this.accountNumberClient.show(
      elegibilityResponse,
      PDT_RESET_ELIGIBILITY_RESPONSE_DESER.update,
      'pdt-reset-requests/eligibility',
      { accountNumber }
    )
    return response
  }

  async create(
    pdtResetResponse: PdtResetResponse
  ): Promise<SingleResponse<PdtResetResponse>> {
    const { accountNumber } = pdtResetResponse
    return this.accountNumberClient.create(
      pdtResetResponse,
      PDT_RESET_RESPONSE_DESER.serialize,
      PDT_RESET_RESPONSE_DESER.update,
      'pdt-reset-requests',
      { accountNumber }
    )
  }
}
