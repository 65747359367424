import {
  ACCOUNT_TYPE_KEY_EXTRACTOR,
  ACCOUNT_TYPE_PARSER,
  ACCOUNT_TYPE_UPDATER,
  AccountType
} from '../../tastyworks/account-type'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { toArrayMap } from '../../tastyworks/response'
import { ArrayMap } from '../../tastyworks/util/collection'

export class AccountTypeService {
  private readonly accountTypeClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.accountTypeClient = baseHttpClient.nested('account-types')
  }

  readonly index = async (): Promise<ItemsResponse<AccountType>> =>
    this.accountTypeClient.indexSimple(ACCOUNT_TYPE_PARSER)

  readonly indexByName = async (): Promise<
    SingleResponse<ArrayMap<string, AccountType>>
  > => {
    const accountTypeMap = ArrayMap.stringKey(AccountType, 'name')
    const response = await this.accountTypeClient.index(
      accountTypeMap,
      ACCOUNT_TYPE_UPDATER,
      ACCOUNT_TYPE_KEY_EXTRACTOR
    )
    return toArrayMap(response, accountTypeMap)
  }
}
