import { boolean, number, object, string } from 'yup'
import { ALLOCATION_STYLES } from '../../model-portfolio'
import toStringValues from '../../util/enum'
import { bigNumber } from './schema/big-number'
import { buildSchema } from './util/build-schema'

const ALLOCATION_STYLES_VALUES = toStringValues(ALLOCATION_STYLES)

const schema = object({
  allocationStyle: string().oneOf(ALLOCATION_STYLES_VALUES),
  autotradeOperatorId: number(),
  id: string(),
  isPortfolioMarginStrategy: boolean(),
  leaderAccountNumber: string(),
  name: string(),
  unitValue: bigNumber()
})

export const modelPortfolioSchema = buildSchema('ModelPortfolio', schema)
