import { date, number, object, string } from 'yup'
import { ACCOUNT_BENEFICIARY_TYPES } from '../../account'
import { TAX_NUMBER_TYPES } from '../../customer'
import toStringValues from '../../util/enum'
import { addressSchema } from './address'
import { buildSchema } from './util/build-schema'

const schema = object({
  address: addressSchema.required(),
  beneficiaryPercent: number(),
  beneficiaryRelationship: string().required(),
  beneficiaryType: string().oneOf(toStringValues(ACCOUNT_BENEFICIARY_TYPES)),
  birthDate: date().required(),
  firstName: string().required(),
  lastName: string().required(),
  middleName: string(),
  taxNumber: string(),
  taxNumberType: string().oneOf(toStringValues(TAX_NUMBER_TYPES))
})

export const accountBeneficiarySchema = buildSchema(
  'AccountBeneficiary',
  schema
)
