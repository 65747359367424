<script context="module">
  export const HAS_BORDER_BOTTOM_FN = Symbol.for("HAS_BORDER_BOTTOM_FN")
  export const TABLE_CELL_FACTORY = Symbol.for("TABLE_CELL_FACTORY")
  export const TABLE_HEADER_CELL_FACTORY = Symbol.for(
    "TABLE_HEADER_CELL_FACTORY"
  )
  export const TABLE_SORT_STATE = Symbol.for("TABLE_SORT_STATE")
  export const VISIBLE_COLUMN_METAS = Symbol.for("VISIBLE_COLUMN_METAS")
</script>

<script>
  import { setContext } from "svelte"
  import {
    BouleRecyclerFlexColumnContainer,
    BouleRecyclerView,
  } from "/@/boule/recycler-view"
  import BouleTableRow from "./BouleTableRow.svelte"
  import BouleTableHeader from "./BouleTableHeader.svelte"
  import {
    defaultTableCellFactory,
    defaultTableHeaderCellFactory,
  } from "./common.js"
  import { getCssPropertyValueNumeric } from "/@/util"

  export let bouleArray // :BouleArray
  export let columnManager // :BoulePaginatedColumnsManager
  export let tableSortState // :BouleTableSortState
  export let rowHeight = getCssPropertyValueNumeric("--vertical-meter")
  export let headerRowHeight = rowHeight
  export let headerCssClass = ""
  let cssClass = ""
  export { cssClass as class }
  export let RowRenderer = BouleTableRow
  export let tableCellFactory = defaultTableCellFactory
  export let tableHeaderCellFactory = defaultTableHeaderCellFactory
  export let hasBorderBottom = null // :(item) => boolean
  export let placeholderText = "No items to show"

  function handleViewBreadthChanged(event) {
    columnManager.width = event.detail
  }

  setContext(VISIBLE_COLUMN_METAS, columnManager.currentVisibleColumns)
  setContext(TABLE_SORT_STATE, tableSortState)
  setContext(TABLE_CELL_FACTORY, tableCellFactory)
  setContext(TABLE_HEADER_CELL_FACTORY, tableHeaderCellFactory)
  setContext(HAS_BORDER_BOTTOM_FN, hasBorderBottom)
</script>

<slot name="table-footer" />
<BouleRecyclerFlexColumnContainer class={`boule-table ${cssClass}`}>
  <slot name="table-header">
    <BouleTableHeader rowHeight={headerRowHeight} class={headerCssClass} />
  </slot>
  <BouleRecyclerView
    {bouleArray}
    cellSize={rowHeight}
    CellRenderer={RowRenderer}
    class="boule-table-body"
    on:viewBreadthChanged={handleViewBreadthChanged}
    on:viewBreadthChanged
    on:approachingListEnd
    {placeholderText}
  />
</BouleRecyclerFlexColumnContainer>

<style lang="postcss">
  :global(.boule-table-body) {
    overflow-x: hidden;
  }
  :global(.boule-table-cell-align-LEFT) {
    text-align: left;
  }
  :global(.boule-table-cell-align-RIGHT) {
    text-align: right;
  }
  :global(.boule-table-cell-align-CENTER) {
    text-align: center;
  }
</style>
