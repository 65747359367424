import {
  ItemsResponse,
  JsonHelper,
  parseErrorContainer
} from '../../../tastyworks'
import {
  ADMIN_NOTES_DESER_PARSER,
  AdminNotes
} from '../../../tastyworks/admin/admin-notes'
import HttpClient from '../../../tastyworks/http'

export class AdminNotesService {
  private readonly adminNotesClient: HttpClient

  constructor(adminNotesClient: HttpClient) {
    this.adminNotesClient = adminNotesClient.nested('accounts')
  }

  readonly index = async (
    accountNumber: string,
    excludeTypes: string[]
  ): Promise<ItemsResponse<AdminNotes>> => {
    return this.adminNotesClient
      .nested(accountNumber)
      .get('notes', { excludeTypes })
      .then(res => this.populateResult(res))
  }

  private readonly populateResult = (
    helper: JsonHelper
  ): ItemsResponse<AdminNotes> => {
    const response = new ItemsResponse<AdminNotes>()

    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }

    const data = helper.getChild('data')
    response.items = data.parseArray('items', ADMIN_NOTES_DESER_PARSER)

    return response
  }

  readonly createNewNote = async (
    accountNumber: string,
    subject: string,
    type: string,
    subType: string,
    body: string
  ): Promise<ItemsResponse<AdminNotes>> => {
    const helper = await this.adminNotesClient.nested(accountNumber).postJson(
      '/notes',
      {
        body: body,
        subject: subject,
        'note-type': type,
        'note-subtype': subType
      },
      {}
    )
    const response = new ItemsResponse<AdminNotes>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')
    response.items = data.parseArray('items', ADMIN_NOTES_DESER_PARSER)
    return response
  }
}
