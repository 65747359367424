import {
  ACH_RELATIONSHIP_DESER,
  AchRelationship
} from '../../tastyworks/cash-management'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'
import { updateSingleResponse } from '../../tastyworks/response'

export class AchRelationshipService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (accountNumber: string) =>
    this.accountNumberClient.indexSimple(
      ACH_RELATIONSHIP_DESER.toParser(AchRelationship),
      'ach-relationships',
      { accountNumber }
    )

  readonly showActive = async (
    accountNumber: string
  ): Promise<SingleResponse<AchRelationship>> =>
    this.accountNumberClient.show(
      new AchRelationship(),
      ACH_RELATIONSHIP_DESER.update,
      'ach-relationships/active',
      { accountNumber }
    )

  readonly create = async (
    achRelationship: AchRelationship
  ): Promise<SingleResponse<AchRelationship>> =>
    this.accountNumberClient.create(
      achRelationship,
      ACH_RELATIONSHIP_DESER.serialize,
      ACH_RELATIONSHIP_DESER.update,
      'ach-relationships',
      { accountNumber: achRelationship.accountNumber }
    )

  readonly cancel = async (achRelationship: AchRelationship) =>
    this.accountNumberClient.patch(
      'ach-relationships/:id/cancel',
      { comment: 'User requested cancellation' },
      { accountNumber: achRelationship.accountNumber, id: achRelationship.id }
    )

  readonly approval = async (
    relationship: AchRelationship,
    amountOne: number,
    amountTwo: number
  ) => {
    const { accountNumber, id } = relationship
    const result = await this.accountNumberClient.postJson(
      'ach-relationships/:id/approval',
      { 'amount-one': amountOne, 'amount-two': amountTwo },
      { accountNumber, id }
    )
    return updateSingleResponse(
      result,
      relationship,
      ACH_RELATIONSHIP_DESER.update
    )
  }

  readonly reissue = async (relationship: AchRelationship) => {
    const { accountNumber, id } = relationship
    const result = await this.accountNumberClient.postJson(
      'ach-relationships/:id/reissue',
      {},
      { accountNumber, id }
    )
    return updateSingleResponse(
      result,
      relationship,
      ACH_RELATIONSHIP_DESER.update
    )
  }
}
