import { number, object, string } from 'yup'
import { buildSchema } from './util/build-schema'
import { isValidWallet } from './util/cryptocurrency-wallet-address'

const schema = object({
  asset: string().required(),
  address: string()
    .test(
      'address',
      'yup.digitalAssetCustomerAddress.address.invalid',
      function (value, context) {
        const { valid, message } = isValidWallet(
          // eslint-disable-next-line @typescript-eslint/no-invalid-this
          this.parent.asset as string,
          value ?? ''
        )
        if (message) {
          return context.createError({ message })
        }

        return valid
      }
    )
    .required(),
  name: string().required(),
  id: number().required()
})

export const digitalAssetCustomerAddressSchema = buildSchema(
  'DigitalAssetCustomerAddress',
  schema
)
