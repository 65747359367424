import { SchemaDeSer, SchemaDeSerBuilder } from '../../tastyworks/deser'
import type { Params } from '../request'

export class Position {
  averageOpenPrice = ''
  costEffect = ''
  expiresAt = ''
  instrumentType = ''
  quantity = ''
  symbol = ''
  underlyingSymbol = ''
  quantityDirection = ''
}

export interface AdminPositionParams extends Params {
  symbol?: string
}

export const POSITIONS_DESER: SchemaDeSer<Position> = new SchemaDeSerBuilder(
  Position
)
  .ofStrings(
    'symbol',
    'quantity',
    'expiresAt',
    'instrumentType',
    'averageOpenPrice',
    'costEffect',
    'underlyingSymbol',
    'quantityDirection'
  )
  .toDeSer()
