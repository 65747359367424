import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum Exchanges {
  SMALLS = 'SMALLS'
}

export enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export class ExchangeAffiliation extends RestResource {
  static onInitialize = (_obj: ExchangeAffiliation) => {
    /* no-op */
  }

  constructor() {
    super()

    ExchangeAffiliation.onInitialize(this)
  }

  exchange: Exchanges = Exchanges.SMALLS
  status: Status = Status.INACTIVE
  exchangeExternalId = ''

  get isSmalls() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.exchange === Exchanges.SMALLS
  }

  get isActive() {
    return this.status === Status.ACTIVE
  }
}

export const EXCHANGE_AFFILITIONS_DESER: SchemaDeSer<ExchangeAffiliation> =
  new SchemaDeSerBuilder(ExchangeAffiliation)
    .ofString('exchangeExternalId')
    .ofString('exchange')
    .ofString('status')
    .toDeSer()
