<script context="module">
  import goog from "/@lib/boulangerie"

  const BracketOrderPreferences = goog.module.get(
    "com.dough.preference.trading.BracketOrderPreferences"
  )
  const PreferenceUtil = goog.module.get("com.dough.preference.PreferenceUtil")
</script>

<script>
  import { appSession } from "/@lib/boulangerie"

  import TradeAssetType from "./partial/TradeAssetType.svelte"
  import TradeOptionsTableColumns from "./partial/TradeOptionsTableColumns.svelte"

  const { tradingPreferences } = appSession
  const { bracketOrderOption } = tradingPreferences

  const defaultQuantity = {
    label: "Default option order quantity",
    options: PreferenceUtil.OPTION_ORDER_QUANTITIES.jsArray,
    preferenceField: tradingPreferences.defaultOptionOrderQuantityField(),
  }
  const quantityIncrement = {
    label: "Option order quantity increment",
    options: PreferenceUtil.OPTION_ORDER_QUANTITY_INCREMENTS.jsArray,
    preferenceField: tradingPreferences.optionOrderQuantityIncrementField(),
  }
  const maxQuantity = {
    label: "Option order maximum quantity",
    options: PreferenceUtil.OPTION_ORDER_MAX_QUANTITIES.jsArray,
    preferenceField: tradingPreferences.optionOrderMaxQuantityField(),
  }
  const profitTarget = {
    label: "Default Close at Profit %",
    options: PreferenceUtil.OPTION_PERCENT_PROFIT_INCREMENTS.jsArray,
    preferenceField: tradingPreferences.optionProfitTargetPercentField(),
  }
  const bracketProfitTarget = {
    label: "Default option bracket order profit target %",
    options: BracketOrderPreferences.PERCENT_INCREMENTS.jsArray,
    preferenceField: bracketOrderOption.profitTargetPercentField(),
  }
  const bracketStopLoss = {
    label: "Default option bracket order stop loss %",
    options: BracketOrderPreferences.PERCENT_INCREMENTS.jsArray,
    preferenceField: bracketOrderOption.stopLossPercentField(),
  }
</script>

<div class="trade-options">
  <TradeAssetType
    class="trade-options"
    {defaultQuantity}
    {quantityIncrement}
    {maxQuantity}
    {profitTarget}
    {bracketProfitTarget}
    {bracketStopLoss}
  />

  <div class="-mx-2 mb-2 mt-4 border-b border-b-divider-horizontal" />

  <span class="font-x-small-600-caps text-general-primary-label">
    table columns
  </span>
  <TradeOptionsTableColumns />
</div>
