<script context="module"></script>

<script>import { useActions, useCollection } from "../../../internal/helpers";
import { cn } from "../../../utils";
import { createEventDispatcher } from "svelte";
import { TAB_STATES } from ".";
import { getTriggerCollection } from "./TabsList.svelte";
import { getTabsRootContext } from "./TabsRoot.svelte";
let className = void 0;
export { className as class };
export let value;
export let disabled = false;
const rootCtx = getTabsRootContext();
const triggerCollection = getTriggerCollection();
const dispatch = createEventDispatcher();
$:
  selected = $rootCtx.value === value;
function activate(event) {
  if (event.type === "click" || event.type === "focus") {
    if (event.type === "click" || $rootCtx.activateOn === "focus") {
      $rootCtx.value = value;
      dispatch("select", { target: event.target, value });
    }
  }
}
</script>

<button
  class={cn(
    "focus-visible:ring-ring inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm",
    className
  )}
  data-state={selected ? TAB_STATES.ACTIVE : TAB_STATES.INACTIVE}
  data-orientation={$rootCtx.orientation}
  role="tab"
  tabindex={selected ? 0 : -1}
  on:click={activate}
  on:focus={activate}
  use:useActions={$$restProps.use}
  use:useCollection={{ collection: triggerCollection }}
  {disabled}
  data-disabled={disabled || undefined}
  {...$$restProps}
>
  <slot />
</button>
