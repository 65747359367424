import type HttpClient from '../../tastyworks/http'
import { TWO_FACTOR_STATUS_DESER, TwoFactorStatus } from '../../tastyworks/user'

export class TwoFactorStatusService {
  private readonly twoFactorStatusClient: HttpClient

  constructor(userMeClient: HttpClient) {
    this.twoFactorStatusClient = userMeClient.nested('two-factor-status')
  }

  readonly show = async () =>
    this.twoFactorStatusClient.show(
      new TwoFactorStatus(),
      TWO_FACTOR_STATUS_DESER.update,
      ''
    )
}
