<script context="module" lang="ts">
  const SymbolSearchFilter = goog.module.get(
    "com.dough.service.SymbolSearchFilter"
  )
</script>

<script lang="ts">
  import { Tabs } from "@tastyworks/ui-library"
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"
  import SymbolSearchWatchlistHeader from "./SymbolSearchWatchlistHeader.svelte"
  import SymbolSearchResultsSection from "./SymbolSearchResultsSection.svelte"
  import { bouleWritablePropertyStore } from "/@/boule/control/boule-property-store"
  import Popover from "/@/layout/popover/Popover.svelte"
  import goog, { symbolSearchManager } from "/@lib/boulangerie"
  import {
    results as resultsStore,
    searchValue,
    resultsPanelOpen,
  } from "./store"

  export let highlightedIndex
  export let inset = "auto"
  export let maxHeight = null
  export let searchFieldName = ""

  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()

  function close(e) {
    $resultsPanelOpen = false
    dispatch("close", e.detail)
  }

  const filterStore = bouleWritablePropertyStore(
    symbolSearchManager.filterProperty()
  )

  $: results = $resultsStore.jsArray

  const SYMBOL_SEARCH_FILTER_BUTTONS = SymbolSearchFilter.values().map(
    (filter) => ({
      label: $_(`content.symbolSearch.buttonNames.${filter.toString()}`),
      value: filter,
    })
  )
</script>

<Popover
  class="symbol-search-results-panel mt-1 min-w-96 rounded border border-general-default shadow-lg {cssClass}"
  {inset}
  on:close={close}
>
  <div
    class="
      flex
      flex-col
      rounded
      bg-general-secondary-surface"
    style:max-height={maxHeight ?? "auto"}
  >
    <div class="rounded bg-general-primary-surface p-small">
      <Tabs.Root bind:value={$filterStore} class="w-full">
        <Tabs.List
          class="
            w-full 
            justify-start 
            gap-extra-small 
            rounded-t 
            p-0 
            data-[orientation=horizontal]:h-full
            data-[orientation=vertical]:w-fit 
            data-[orientation=vertical]:flex-col"
        >
          {#each SYMBOL_SEARCH_FILTER_BUTTONS as button}
            <Tabs.Trigger
              class={`
                font-xxx-small-700-caps
                rounded 
                border
                border-general-default
                py-triple-extra-small
                text-general-secondary-text
                ${
                  button.value === $filterStore &&
                  "border-table-standard-deviation bg-button-group-selected text-general-primary-text"
                }`}
              value={button.value}
            >
              {button.label}
            </Tabs.Trigger>
          {/each}
        </Tabs.List>
      </Tabs.Root>
    </div>

    <SymbolSearchWatchlistHeader
      {searchValue}
      filter={$filterStore.toString()}
    />
    <SymbolSearchResultsSection
      {results}
      on:close={close}
      {highlightedIndex}
      {searchFieldName}
      filter={$filterStore}
    />
  </div>
</Popover>
