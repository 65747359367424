import {
  ACCOUNT_OPENING_APPLICATION_DESER,
  ACCOUNT_OPENING_APPLICATION_PARSER,
  AccountOpeningApplication
} from '../../tastyworks/account-opening'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'

export class AccountOpeningApplicationService {
  private readonly customerMeApplicationsClient: HttpClient
  private readonly applicationsClient: HttpClient

  constructor(baseHttpClient: HttpClient, customerMeClient: HttpClient) {
    this.customerMeApplicationsClient = customerMeClient.nested(
      'account-opening-applications'
    )
    this.applicationsClient = baseHttpClient.nested(
      'account-opening-applications'
    )
  }

  async index(): Promise<ItemsResponse<AccountOpeningApplication>> {
    return this.customerMeApplicationsClient.indexSimple(
      ACCOUNT_OPENING_APPLICATION_PARSER
    )
  }

  async show(id: string): Promise<SingleResponse<AccountOpeningApplication>> {
    return this.customerMeApplicationsClient.show(
      new AccountOpeningApplication(),
      ACCOUNT_OPENING_APPLICATION_DESER.update,
      `/${id}`
    )
  }

  async createCustomerAccountOpeningApplication(
    accountOpeningApplication: AccountOpeningApplication,
    id: string
  ) {
    return this.customerMeApplicationsClient.create(
      accountOpeningApplication,
      ACCOUNT_OPENING_APPLICATION_DESER.serialize,
      ACCOUNT_OPENING_APPLICATION_DESER.update,
      '/:id/submit',
      { id }
    )
  }

  async create(accountOpeningApplication: AccountOpeningApplication) {
    accountOpeningApplication.source = this.applicationsClient.session.source
    return this.applicationsClient.create(
      accountOpeningApplication,
      ACCOUNT_OPENING_APPLICATION_DESER.serialize,
      ACCOUNT_OPENING_APPLICATION_DESER.update
    )
  }
}
