import {
  SUPPORTING_DOCUMENT_VERIFICATION_DESER,
  SUPPORTING_DOCUMENT_VERIFICATION_KEY_EXTRACTOR,
  SUPPORTING_DOCUMENT_VERIFICATION_PARSER,
  SUPPORTING_DOCUMENT_VERIFICATION_UPDATER,
  SupportingDocumentVerification
} from '../../tastyworks/customer'
import type HttpClient from '../../tastyworks/http'
import { recursiveDasherizeKeys } from '../../tastyworks/request'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { parseSingleResponse, toArrayMap } from '../../tastyworks/response'
import {
  SUPPORTING_DOCUMENT_VERIFICATION_URL_DESER,
  SupportingDocumentVerificationUrl
} from '../../tastyworks/supporting-document-verification'
import type { IdentityVerificationTypes } from '../../tastyworks/supporting-document-verification'
import { ArrayMap } from '../../tastyworks/util/collection'

export class SupportingDocumentVerificationService {
  private readonly supportingDocumentVerificationsClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.supportingDocumentVerificationsClient = customerMeClient.nested(
      'supporting-document-verifications'
    )
  }

  readonly create = async (
    slug: string,
    documentCountry?: string,
    documentType?: string,
    identityVerificationType?: IdentityVerificationTypes
  ): Promise<SingleResponse<SupportingDocumentVerificationUrl>> => {
    const jsonHelper =
      await this.supportingDocumentVerificationsClient.postJson(
        '',
        recursiveDasherizeKeys({
          slug,
          documentCountry,
          documentType,
          ...(identityVerificationType ? { identityVerificationType } : {})
        })
      )

    return parseSingleResponse(
      jsonHelper,
      SUPPORTING_DOCUMENT_VERIFICATION_URL_DESER.toParser(
        SupportingDocumentVerificationUrl
      )
    )
  }

  readonly update = async (
    verification: SupportingDocumentVerification
  ): Promise<SingleResponse<SupportingDocumentVerification>> => {
    const jsonHelper = await this.supportingDocumentVerificationsClient.patch(
      verification.extTransactionNumber,
      recursiveDasherizeKeys({
        transactionReference: verification.extTransactionNumber,
        status: verification.status
      })
    )

    return parseSingleResponse(
      jsonHelper,
      SUPPORTING_DOCUMENT_VERIFICATION_DESER.toParser(
        SupportingDocumentVerification
      )
    )
  }

  readonly index = async (): Promise<
    ItemsResponse<SupportingDocumentVerification>
  > =>
    this.supportingDocumentVerificationsClient.indexSimple(
      SUPPORTING_DOCUMENT_VERIFICATION_PARSER
    )

  readonly indexBySlug = async (): Promise<
    SingleResponse<ArrayMap<string, SupportingDocumentVerification>>
  > => {
    const supportingDocumentVerificationMap = ArrayMap.stringKey(
      SupportingDocumentVerification,
      'slug'
    )
    const response = await this.supportingDocumentVerificationsClient.index(
      supportingDocumentVerificationMap,
      SUPPORTING_DOCUMENT_VERIFICATION_UPDATER,
      SUPPORTING_DOCUMENT_VERIFICATION_KEY_EXTRACTOR
    )
    return toArrayMap(response, supportingDocumentVerificationMap)
  }
}
