// Meta-programming utils
export type Supplier<T> = () => T

export type Creator<T> = new () => T

export type CreatorArg1<T, A> = new (arg: A) => T

export type CreatorArg2<T, A1, A2> = new (arg1: A1, arg2: A2) => T

export type Constructor<T> = new (...args: any[]) => T

export function getProperty<T, K extends keyof T, V>(
  target: T,
  field: K,
  _valueClass?: Constructor<V>
): V {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value = target[field] as any
  return value as V
}

export function setProperty<T, K extends keyof T>(
  target: T,
  field: K,
  value: T[K]
): void {
  target[field] = value
}
