<script context="module"></script>

<script>import { useActions } from "../../../internal/helpers";
import { cn } from "../../../utils";
import { TAB_STATES } from ".";
import { getTabsRootContext } from "./TabsRoot.svelte";
let className = void 0;
export { className as class };
export let value;
const rootCtx = getTabsRootContext();
$:
  selected = $rootCtx.value === value;
</script>

{#if selected}
  <div
    class={cn(
      "focus-visible:ring-ring mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
      className
    )}
    {...$$restProps}
    use:useActions={$$restProps.use}
    data-state={selected ? TAB_STATES.ACTIVE : TAB_STATES.INACTIVE}
    data-orientation={$rootCtx.orientation}
    role="tabpanel"
    tabindex="0"
  >
    <slot />
  </div>
{/if}
