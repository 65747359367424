<script context="module">
  import { getColumnTitle } from "/@/trading/order-entry/table/store.js"
  import goog from "/@lib/boulangerie"

  const TitleType = goog.module.get("tasty.ui.util.TitleType")

  export function getTitle(column) {
    return getColumnTitle(column, TitleType.DESCRIPTIVE)
  }
</script>

<script>
  import ItemArranger from "/@/control/ItemArranger.svelte"
  import {
    USER_COLUMNS,
    customQuoteColumns,
  } from "/@/trading/order-entry/table/store.js"

  $: columns = [
    {
      activeBorderColor: "var(--color-border-general-positive-movement)",
      items: $customQuoteColumns.map((c) => ({
        label: getTitle(c),
        value: c,
      })),
      showCount: true,
      showIndexes: true,
      title: "Displayed",
    },
    {
      activeBorderColor: "var(--color-border-general-negative-movement)",
      items: USER_COLUMNS.filter((c) => !$customQuoteColumns.includes(c)).map(
        (c) => ({
          label: getTitle(c),
          value: c,
        })
      ),
      title: "Not Displayed",
    },
  ]
</script>

<ItemArranger
  class="trade-options-table-columns"
  {columns}
  on:change={({ detail }) => {
    const displayed = detail[0]
    $customQuoteColumns = displayed.items.map((i) => i.value)
  }}
/>
