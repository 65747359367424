<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="timeline" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Timeline</title>
<path d="M11.994 2.6c-0.382 0-0.692 0.31-0.692 0.692s0.31 0.692 0.692 0.692c0.382 0 0.692-0.31 0.692-0.692s-0.31-0.692-0.692-0.692zM10.103 3.292c0-1.045 0.847-1.892 1.892-1.892s1.892 0.847 1.892 1.892c0 1.045-0.847 1.892-1.892 1.892s-1.892-0.847-1.892-1.892z"></path>
<path d="M11.994 20.017c-0.382 0-0.692 0.31-0.692 0.692s0.31 0.692 0.692 0.692c0.382 0 0.692-0.31 0.692-0.692s-0.31-0.692-0.692-0.692zM10.103 20.708c0-1.045 0.847-1.892 1.892-1.892s1.892 0.847 1.892 1.892c0 1.045-0.847 1.892-1.892 1.892s-1.892-0.847-1.892-1.892z"></path>
<path d="M11.995 3.983c0.331 0 0.6 0.269 0.6 0.6v14.833c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-14.833c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M1.2 12c0-0.442 0.358-0.8 0.8-0.8h7.411c0.442 0 0.8 0.358 0.8 0.8v5c0 0.442-0.358 0.8-0.8 0.8h-7.411c-0.442 0-0.8-0.358-0.8-0.8v-5zM2.8 12.8v3.4h5.811v-3.4h-5.811z"></path>
<path d="M8.811 14.5c0-0.331 0.269-0.6 0.6-0.6h2.583c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-2.583c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M22.8 12c0 0.442-0.358 0.8-0.8 0.8h-7.411c-0.442 0-0.8-0.358-0.8-0.8v-5c0-0.442 0.358-0.8 0.8-0.8h7.411c0.442 0 0.8 0.358 0.8 0.8v5zM21.2 11.2v-3.4h-5.811v3.4h5.811z"></path>
<path d="M11.403 9.5c0-0.331 0.269-0.6 0.6-0.6h2.592c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-2.592c-0.331 0-0.6-0.269-0.6-0.6z"></path>
</svg>