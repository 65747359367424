import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { ArrayMap } from './util/collection'

export enum TRADING_REQUEST_TYPES {
  CRYPTOCURRENCY_TRADING = 'Cryptocurrency Trading',
  EQUITY_OFFERING = 'Equity Offering',
  INTRADAY_FUTURES_MARGIN = 'Intraday Futures Margin',
  IRA_SHORT_CALL = 'IRA Short Call',
  PORTFOLIO_MARGIN = 'Portfolio Margin'
}

export const MINIMUM_SPECIFIC_MARGIN_BALANCE = 20_000_001

export class PortfolioMarginQuestionnaire {
  // Intentionally default to null -- questionnaire requires user-input.
  equityTradeSize: number | null = null
  isDiversifyingSinglePosition: boolean | null = null
  isFlatEndOfDay: boolean | null = null
  isHighConcentrationIndividualEquity: boolean | null = null
  isLongShortBias: boolean | null = null
  isMergerArbitrage: boolean | null = null
  isRiskArbitrage: boolean | null = null
  isTradingComplexStrategies: boolean | null = null
  isVolatilityArbitrage: boolean | null = null
  hasCalendarSpreads: boolean | null = null
  hasCoveredSpreads: boolean | null = null
  hasMultiLegSpreads: boolean | null = null
  hasMultiProductSpreads: boolean | null = null
  hasNakedSpreads: boolean | null = null
  hasOvernightPositions: boolean | null = null
  hasPreviousPortfolioMargin: boolean | null = null
  hasTypicalMarginBalance: boolean | null = null
  hasVerticalSpreads: boolean | null = null
  marginBalanceRange: number | null = null
  monthlyTrades: number | null = null
  openingAccountBalance: number | null = null
  optionTradeSize: number | null = null
  specificMarginBalance: number | null = null

  get requiresSpecificMarginBalance() {
    return (
      this.hasTypicalMarginBalance &&
      (this.marginBalanceRange ?? 0) >= MINIMUM_SPECIFIC_MARGIN_BALANCE
    )
  }
}

export const PORTFOLIO_MARGIN_QUESTIONNAIRE_DESER: SchemaDeSer<PortfolioMarginQuestionnaire> =
  new SchemaDeSerBuilder(PortfolioMarginQuestionnaire)
    .ofInt('equityTradeSize')
    .ofBoolean('isDiversifyingSinglePosition')
    .ofBoolean('isFlatEndOfDay')
    .ofBoolean('isHighConcentrationIndividualEquity')
    .ofBoolean('isLongShortBias')
    .ofBoolean('isMergerArbitrage')
    .ofBoolean('isRiskArbitrage')
    .ofBoolean('isTradingComplexStrategies')
    .ofBoolean('isVolatilityArbitrage')
    .ofBoolean('hasCalendarSpreads')
    .ofBoolean('hasCoveredSpreads')
    .ofBoolean('hasMultiLegSpreads')
    .ofBoolean('hasMultiProductSpreads')
    .ofBoolean('hasNakedSpreads')
    .ofBoolean('hasOvernightPositions')
    .ofBoolean('hasPreviousPortfolioMargin')
    .ofBoolean('hasTypicalMarginBalance')
    .ofBoolean('hasVerticalSpreads')
    .ofInt('marginBalanceRange')
    .ofInt('monthlyTrades')
    .ofInt('openingAccountBalance')
    .ofInt('optionTradeSize')
    .ofInt('specificMarginBalance')
    .toDeSer()

export class AdvancedTradingRequest extends RestResource {
  constructor(readonly requestType: TRADING_REQUEST_TYPES) {
    super()
  }

  accountNumber = ''
  portfolioMarginQuestionnaire: PortfolioMarginQuestionnaire | null = null
}

export enum TRADING_REQUEST_ELIGIBILITY_STATUSES {
  ELIGIBLE = 'Eligible',
  NEEDS_OPTIONS_LEVEL_UPGRADE = 'Needs Options Level Upgrade',
  NEEDS_SUITABILITY_UPGRADE = 'Needs Suitability Upgrade',
  UNAVAILABLE = 'Unavailable'
}

export const ADVANCED_TRADING_REQUEST_DESER: SchemaDeSer<AdvancedTradingRequest> =
  new SchemaDeSerBuilder<AdvancedTradingRequest>(
    AdvancedTradingRequest.bind(
      null,
      TRADING_REQUEST_TYPES.CRYPTOCURRENCY_TRADING
    )
  )
    .ofString('accountNumber')
    .ofInt('id')
    .ofString('requestType')
    .ofNested(
      'portfolioMarginQuestionnaire',
      PORTFOLIO_MARGIN_QUESTIONNAIRE_DESER,
      PortfolioMarginQuestionnaire
    )
    .toDeSer()

export type AdvancedTradingRequestMap = ArrayMap<
  TRADING_REQUEST_TYPES,
  AdvancedTradingRequest
>

export function createAdvancedTradingRequestMap(): AdvancedTradingRequestMap {
  return ArrayMap.enumKey(AdvancedTradingRequest, 'requestType')
}

export class AdvancedTradingRequestEligibility {
  reason = ''
  status?: TRADING_REQUEST_ELIGIBILITY_STATUSES

  get isAvailable() {
    return !this.isUnavailable
  }

  get isEligible() {
    return this.status === TRADING_REQUEST_ELIGIBILITY_STATUSES.ELIGIBLE
  }

  get isUnavailable() {
    return this.status === TRADING_REQUEST_ELIGIBILITY_STATUSES.UNAVAILABLE
  }

  get needsSuitabilityUpgrade() {
    return (
      this.status ===
      TRADING_REQUEST_ELIGIBILITY_STATUSES.NEEDS_SUITABILITY_UPGRADE
    )
  }

  get needsOptionsLevelUpgrade() {
    return (
      this.status ===
      TRADING_REQUEST_ELIGIBILITY_STATUSES.NEEDS_OPTIONS_LEVEL_UPGRADE
    )
  }
}

export const ADVANCED_TRADING_REQUEST_ELIGIBILITY_DESER: SchemaDeSer<AdvancedTradingRequestEligibility> =
  new SchemaDeSerBuilder(AdvancedTradingRequestEligibility)
    .ofString('reason')
    .ofString('status')
    .toDeSer()
