<script lang="ts">
  const buildTag = __BUILD_TAG__
  const appVersion = __APP_VERSION__
</script>

<dl class="text-general-secondary-text">
  <dt>build</dt>
  <dd>{buildTag}</dd>

  <dt>version</dt>
  <dd>{appVersion}</dd>
</dl>

<style>
  dd {
    margin-bottom: var(--vertical-meter);
  }
</style>
