import { isZero } from './number'

const ONE_HUNDRED = 100

export default class Percent {
  static N_A_N = Object.freeze(new Percent(NaN, NaN))
  static ZERO = Object.freeze(new Percent(0, 0))
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  static ONE_HUNDRED = Object.freeze(new Percent(1, ONE_HUNDRED))

  static fromDecimal(decimal: number) {
    if (isNaN(decimal) || !isFinite(decimal)) {
      return Percent.N_A_N
    }

    if (isZero(decimal)) {
      return Percent.ZERO
    }

    return new Percent(decimal, decimal * ONE_HUNDRED)
  }

  static fromPercent(percent: number) {
    if (isNaN(percent) || !isFinite(percent)) {
      return Percent.N_A_N
    }

    if (isZero(percent)) {
      return Percent.ZERO
    }

    return new Percent(percent / ONE_HUNDRED, percent)
  }

  private constructor(readonly decimal: number, readonly percent: number) {}
}
