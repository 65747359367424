export default function (node, isOpen) {
  let prevIsOpen = isOpen
  let wasMouseDownInside = false

  const handleMouseDown = (event) => {
    if (node && !node.contains(event.target) && !event.defaultPrevented) {
      wasMouseDownInside = true
    }
  }

  const handleMouseUp = (event) => {
    if (
      node &&
      !node.contains(event.target) &&
      !event.defaultPrevented &&
      wasMouseDownInside
    ) {
      node.dispatchEvent(
        new CustomEvent("click-outside", {
          detail: { node, target: event.target },
        })
      )
    }
    wasMouseDownInside = false
  }

  if (isOpen) {
    document.addEventListener("mouseup", handleMouseUp, true)
    document.addEventListener("mousedown", handleMouseDown, true)
  }

  return {
    destroy() {
      document.removeEventListener("mouseup", handleMouseUp, true)
      document.removeEventListener("mousedown", handleMouseDown, true)
    },
    update(isOpen) {
      if (prevIsOpen === isOpen) {
        return
      } else if (isOpen) {
        document.addEventListener("mouseup", handleMouseUp, true)
        document.addEventListener("mousedown", handleMouseDown, true)
      } else {
        document.removeEventListener("mouseup", handleMouseUp, true)
        document.removeEventListener("mousedown", handleMouseDown, true)
      }
      prevIsOpen = isOpen
    },
  }
}
