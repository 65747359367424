<script context="module">
  import goog from "/@lib/boulangerie"

  const JsWebPage = goog.module.get("tasty.js.ui.model.JsWebPage")

  export const NOTIFICATION_VISIBLE_SECONDS_OPTIONS = [1, 2, 5, 10, 20, 30]
</script>

<script>
  import { FEATURE_ENABLED as BONDS_FEATURE_ENABLED } from "/@/trading/bonds/feature.js"
  import DropdownField from "/@/trading/settings/control/DropdownField.svelte"
  import ThemeField from "/@/trading/settings/control/ThemeField.svelte"
  import ToggleWebPushNotificationsButton from "/@/trading/settings/control/ToggleWebPushNotificationsButton.svelte"
  import { appTwSession } from "/@lib/boulangerie"
  import { flagStore } from "/@lib/launch-darkly"

  const { landingPage, notificationVisibleSeconds } =
    appTwSession.remoteWebPreferences

  $: landingPageOptions = $BONDS_FEATURE_ENABLED
    ? landingPage.selectableValues.toArray()
    : landingPage.selectableValues
        .toArray()
        .filter((value) => JsWebPage.BONDS !== value)

  const pushNotificationFlag = flagStore("web-push-notification-settings-btn", {
    defaultValue: false,
    realtime: true,
  })
</script>

<div
  class="
    general
    grid
    grid-cols-2
    gap-x-medium
    gap-y-extra-small"
>
  <ThemeField />
  <DropdownField
    label="how long to show notifications in seconds"
    options={NOTIFICATION_VISIBLE_SECONDS_OPTIONS}
    preferenceField={notificationVisibleSeconds}
  />

  <DropdownField
    label="landing page"
    preferenceField={landingPage}
    preferenceOptions={landingPageOptions}
  />

  {#if $pushNotificationFlag}
    <ToggleWebPushNotificationsButton />
  {/if}
</div>
