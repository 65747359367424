<script lang="ts">
  export let size: string = "60px"
  export let text: string = ""
  export let params: any = null // to support spa-router loadingParams
  export let backdropOpacity = 0.5

  $: message = params?.text ?? text

  // copied from https://github.com/Schum123/svelte-loading-spinners/blob/master/src/Circle.svelte
</script>

<div class="overlay">
  <div class="backdrop" style:opacity={backdropOpacity} />
  <div
    class="loading-spinner"
    style:height={size}
    style:width={size}
    style:border-width={`calc(${size} / 15)`}
  />
  {#if message}
    <div class="text">{message}</div>
  {/if}
</div>

<style>
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--medium-spacing);

    z-index: var(--loading-overlay-z-index);
  }
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
  }

  .text {
    color: var(--color-text-general-default-text);
  }

  .loading-spinner {
    --color: var(--loading-spinner-color, #ff3e00);
    border-color: var(--color) transparent var(--color) var(--color);
    border-style: solid;
    border-image: initial;
    border-radius: 50%;
    animation: 0.75s linear 0s infinite normal none running rotate;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
