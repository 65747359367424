import { isEqual as isEqualValue, isValid, isZero } from './number'

export enum CostEffect {
  Credit = 'Credit',
  Debit = 'Debit',
  NoCost = 'No Cost'
}

export function toOpposite(effect: CostEffect) {
  switch (effect) {
    case CostEffect.Credit:
      return CostEffect.Debit
    case CostEffect.Debit:
      return CostEffect.Credit
    default:
      return CostEffect.NoCost
  }
}

export function parseCostEffect(costEffect: string): CostEffect {
  switch (costEffect) {
    case 'c':
    case 'cr':
    case 'credit':
    case 'net_credit':
    case 'Credit':
      return CostEffect.Credit
    case 'd':
    case 'db':
    case 'debit':
    case 'net_debit':
    case 'Debit':
      return CostEffect.Debit
    default:
      return CostEffect.NoCost
  }
}

function costAdjuster(
  srcVal: number,
  srcEffect: CostEffect,
  adjVal: number,
  adjEffect: CostEffect
): [number, CostEffect] {
  if (adjVal < 0) {
    throw new Error('Cannot adjust by negative value')
  }

  if (srcEffect === adjEffect) {
    const nextVal = srcVal + adjVal
    if (srcEffect === CostEffect.NoCost && !isZero(nextVal)) {
      throw new Error('Unexpected NoCost with non-zero value')
    }

    return [nextVal, srcEffect]
  }

  if (srcEffect === CostEffect.NoCost) {
    return [adjVal, adjEffect]
  }

  if (adjEffect === CostEffect.NoCost) {
    return [srcVal, srcEffect]
  }

  if (srcEffect === CostEffect.Credit && adjEffect === CostEffect.Debit) {
    const nextVal = srcVal - adjVal
    return nextVal < 0 ? [-nextVal, CostEffect.Debit] : [nextVal, srcEffect]
  }

  if (srcEffect === CostEffect.Debit && adjEffect === CostEffect.Credit) {
    const nextVal = srcVal - adjVal
    return nextVal < 0 ? [-nextVal, CostEffect.Credit] : [nextVal, srcEffect]
  }

  throw new Error('Unsupported')
}

export class Cost {
  static ZERO = Object.freeze(new Cost(0, CostEffect.NoCost))

  static credit(value: number) {
    return Cost.create(value, CostEffect.Credit)
  }

  static debit(value: number) {
    return Cost.create(value, CostEffect.Debit)
  }

  static createOpposite(value: number, effect: CostEffect) {
    switch (effect) {
      case CostEffect.Credit:
        return Cost.create(value, CostEffect.Debit)
      case CostEffect.Debit:
        return Cost.create(value, CostEffect.Credit)
      default:
        return Cost.ZERO
    }
  }

  static create(value: number, effect: CostEffect) {
    if (isZero(value) || effect === CostEffect.NoCost) {
      return Cost.ZERO
    }

    return new Cost(value, effect)
  }

  private constructor(readonly value: number, readonly effect: CostEffect) {
    if (isNaN(value)) {
      throw new Error('Value cannot be NaN')
    }

    if (!isFinite(value)) {
      throw new Error('Value cannot be infinite')
    }

    if (value < 0) {
      throw new Error('Value cannot be negative')
    }

    if (isZero(value) && effect !== CostEffect.NoCost) {
      throw new Error('Cannot have a non-zero value with NoCost effect')
    }
  }

  get isCredit() {
    return this.effect === CostEffect.Credit
  }

  get isDebit() {
    return this.effect === CostEffect.Debit
  }

  isEqual(cost: Cost): boolean {
    return isEqualValue(this.value, cost.value) && this.effect === cost.effect
  }

  adjust = (cost: Cost): Cost => {
    if (this.effect === CostEffect.NoCost) {
      return cost
    }

    if (cost.effect === CostEffect.NoCost) {
      return this
    }

    const [nextVal, nextEffect] = costAdjuster(
      this.value,
      this.effect,
      cost.value,
      cost.effect
    )
    if (isZero(nextVal)) {
      return Cost.ZERO
    }
    return new Cost(nextVal, nextEffect)
  }

  get opposite(): Cost {
    return new Cost(this.value, toOpposite(this.effect))
  }

  safeDivide(quantity: number): Cost {
    if (isZero(this.value) || !isValid(quantity)) {
      return Cost.ZERO
    }

    const nextVal = this.value / quantity

    return new Cost(nextVal, this.effect)
  }

  multiply(quantity: number): Cost {
    return Cost.create(this.value * quantity, this.effect)
  }
}

export class CostBuilder {
  value: number
  effect: CostEffect

  constructor(cost = Cost.ZERO) {
    this.value = cost.value
    this.effect = cost.effect
  }

  adjust = (cost: Cost): this => this.adjustByValue(cost.value, cost.effect)

  adjustByValue = (value: number, effect: CostEffect): this => {
    const [nextVal, nextEffect] = costAdjuster(
      this.value,
      this.effect,
      value,
      effect
    )
    if (isZero(nextVal)) {
      this.value = 0
      this.effect = CostEffect.NoCost
    } else {
      this.value = nextVal
      this.effect = nextEffect
    }
    return this
  }

  get toCost(): Cost {
    return Cost.create(this.value, this.effect)
  }
}

const toMagnitude = (cost: Cost) => {
  if (cost.effect === CostEffect.Debit) {
    return -cost.value
  }

  return cost.value
}

// Debit before No Effect before Credit
export function costComparator(left: Cost, right: Cost): number {
  const leftMagnitude = toMagnitude(left)
  const rightMagnitude = toMagnitude(right)
  return leftMagnitude - rightMagnitude
}
