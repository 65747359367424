import dayjs from 'dayjs'
import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import toStringValues from './util/enum'
import Percent from './util/percent'

export const MIN_WITHHOLDING_AMOUNT = 0.01
const ONE_HUNDRED = 100

export enum WITHHOLDING_TYPES {
  FIXED = 'FIXED',
  PERCENT = 'PERCENTAGE'
}

export const WITHHOLDING_TYPES_VALUES = toStringValues(WITHHOLDING_TYPES)

export class IraDetail extends RestResource {
  static currentYear() {
    return dayjs().year()
  }
  static previousYear() {
    return dayjs().year() - 1
  }

  contributionReason = ''
  contributionYear = ''
  distributionReason = ''
  federalWithholding: number | null = null
  federalWithholdingType: WITHHOLDING_TYPES | null = null
  receivingInstitutionName = ''
  stateWithholding: number | null = null
  stateWithholdingType: WITHHOLDING_TYPES | null = null

  get isPreviousYearContribution() {
    return this.contributionYear === IraDetail.previousYear().toString()
  }
}

export function maxWithholdingAmount(
  transferAmount: number,
  withholdingAmount: number,
  withholdingType: WITHHOLDING_TYPES
) {
  let maxAmount = Infinity
  const isPercentType = withholdingType === WITHHOLDING_TYPES.PERCENT
  if (isPercentType) {
    maxAmount =
      ((transferAmount - withholdingAmount) / transferAmount) * ONE_HUNDRED
  } else {
    maxAmount = transferAmount - withholdingAmount
  }
  return maxAmount
}

export function calculateWithholdingAmount(
  amount: number,
  withholdingType: WITHHOLDING_TYPES,
  withholding: number
) {
  return WITHHOLDING_TYPES.PERCENT === withholdingType
    ? amount * Percent.fromPercent(withholding).decimal
    : withholding
}

export const IRA_DETAIL_DESER: SchemaDeSer<IraDetail> = new SchemaDeSerBuilder(
  IraDetail
)
  .ofString('contributionReason')
  .ofString('contributionYear')
  .ofString('distributionReason')
  .ofFloat('federalWithholding')
  .ofString('federalWithholdingType')
  .ofString('receivingInstitutionName')
  .ofFloat('stateWithholding')
  .ofString('stateWithholdingType')
  .toDeSer()
