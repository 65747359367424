import _ from 'lodash'
import {
  DIGITAL_ASSET_DEPOSIT_DESER,
  DigitalAssetDeposit
} from '../../tastyworks/digital-asset-deposit'
import type HttpClient from '../../tastyworks/http'
import type { PaginationParams } from '../../tastyworks/request'
import type { ItemsResponse } from '../../tastyworks/response'

export class DigitalAssetDepositService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params?: PaginationParams
  ): Promise<ItemsResponse<DigitalAssetDeposit>> =>
    this.accountNumberClient.indexSimple(
      DIGITAL_ASSET_DEPOSIT_DESER.toParser(DigitalAssetDeposit),
      '/digital-asset-deposits',
      _.merge({ accountNumber }, params)
    )
}
