import type { ExternalTransaction } from '../../tastyworks/cash-management'
import { EXTERNAL_TRANSACTION_DESER } from '../../tastyworks/cash-management'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'
import { updateSingleResponse } from '../../tastyworks/response'

export class ExternalTransactionService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly destroy = async (
    externalTransaction: ExternalTransaction
  ): Promise<SingleResponse<ExternalTransaction>> => {
    const helper = await this.accountNumberClient.doDelete(
      'external-transactions/:externalTransactionId',
      {
        accountNumber: externalTransaction.accountNumber,
        externalTransactionId: externalTransaction.id
      }
    )

    return updateSingleResponse(
      helper,
      externalTransaction,
      EXTERNAL_TRANSACTION_DESER.update
    )
  }
}
