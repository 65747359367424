import type { DocumentRecord } from '../../tastyworks/customer'
import { DOCUMENT_RECORD_PARSER } from '../../tastyworks/customer'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { parseSingleResponse } from '../../tastyworks/response'

export class CustomerDocumentService {
  private readonly customerMeDocumentsClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.customerMeDocumentsClient = customerMeClient.nested('documents')
  }

  readonly index = async (): Promise<ItemsResponse<DocumentRecord>> =>
    this.customerMeDocumentsClient.indexSimple(DOCUMENT_RECORD_PARSER)

  readonly upload = async (
    slug: string,
    file: File
  ): Promise<SingleResponse<DocumentRecord>> => {
    const helper = await this.customerMeDocumentsClient.postFiles(
      'async/:slug',
      {},
      { file },
      { slug }
    )
    return parseSingleResponse(helper, DOCUMENT_RECORD_PARSER)
  }
}
