<script context="module">
  const BOOLEAN_OPTION_ENTRIES = [{ value: true }, { value: false }]
  const REVERSED_BOOLEAN_OPTION_ENTRIES = [...BOOLEAN_OPTION_ENTRIES].reverse()
</script>

<script lang="ts">
  import Block7525Layout from "/@/account-management/control/input-field-label/Block7525Layout.svelte"
  import ButtonGroup from "/@/account-management/form/button-group/ButtonGroup.svelte"

  export let name: string
  export let fieldTranslationKey = undefined
  export let reversed = false
  let cssClass = ""
  export { cssClass as class }
  export let variant = Block7525Layout

  $: optionEntries = reversed
    ? REVERSED_BOOLEAN_OPTION_ENTRIES
    : BOOLEAN_OPTION_ENTRIES
</script>

<ButtonGroup
  class="
    form-boolean-button-group
    leading-extra-large
    my-medium
    {name}
    {cssClass}"
  {name}
  {fieldTranslationKey}
  {optionEntries}
  {variant}
  buttonGroupClass="w-40"
  on:change
/>
