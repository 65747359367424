import { TRADING_REQUEST_TYPES } from "@tastyworks/tastyworks-api"
import {
  AdditionalTradingPreferences,
  TradingPreferenceType,
} from "/@/account-management/model/advanced-trading-preference"

const IG_TRADING_PREFERENCES = [
  AdditionalTradingPreferences.FUTURES_TRADING,
  TRADING_REQUEST_TYPES.PORTFOLIO_MARGIN,
]

export function isIgEligibleTradingPreference(
  tradingPreference: TradingPreferenceType
) {
  return IG_TRADING_PREFERENCES.includes(tradingPreference)
}
