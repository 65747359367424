import type HttpClient from '../../tastyworks/http'
import {
  NEWS_ITEM_DESER,
  NEWS_URN_DESER,
  NewsItem,
  NewsUrn
} from '../../tastyworks/news'
import type { Params } from '../../tastyworks/request'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import {
  parseItemsResponse,
  parseSingleResponse
} from '../../tastyworks/response'

export default class NewsService {
  readonly baseHttpClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.baseHttpClient = baseHttpClient
  }

  readonly index = async (
    symbol: string,
    exchange: string,
    instrumentType?: string
  ): Promise<ItemsResponse<NewsItem>> => {
    const params: Params = {
      symbol,
      exchange,
      'instrument-type': instrumentType ?? ''
    }

    const helper = await this.baseHttpClient.get('/news', params)

    return parseItemsResponse(helper, NEWS_ITEM_DESER.toParser(NewsItem))
  }

  readonly show = async (urn: string): Promise<SingleResponse<NewsUrn>> => {
    const helper = await this.baseHttpClient.get(`/news/${urn}`)

    return parseSingleResponse(helper, NEWS_URN_DESER.toParser(NewsUrn))
  }
}
