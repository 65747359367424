// Source: https://github.com/joshnuss/svelte-local-storage-store
// https://github.com/joshnuss/svelte-local-storage-store/blob/master/index.ts
// Represents version v0.9.2 (2024-05-9)
import { writable as internal } from "svelte/store";
const stores = {
    local: {},
    session: {},
};
function getStorage(type) {
    return type === "local" ? localStorage : sessionStorage;
}
export function localStorageStore(key, initialValue, options) {
    const serializer = options?.serializer ?? JSON;
    const storageType = options?.storage ?? "local";
    const syncTabs = options?.syncTabs ?? true;
    const onWriteError = options?.onWriteError ??
        ((e) => console.error(`Error when writing value from persisted store "${key}" to ${storageType}`, e));
    const onParseError = options?.onParseError ??
        ((newVal, e) => console.error(`Error when parsing ${newVal ? '"' + newVal + '"' : "value"} from persisted store "${key}"`, e));
    const browser = typeof window !== "undefined" && typeof document !== "undefined";
    const storage = browser ? getStorage(storageType) : null;
    function updateStorage(key, value) {
        try {
            if (value === undefined) {
                storage?.removeItem(key);
            }
            else {
                storage?.setItem(key, serializer.stringify(value));
            }
        }
        catch (e) {
            onWriteError(e);
        }
    }
    function maybeLoadInitial() {
        const json = storage?.getItem(key);
        if (json) {
            try {
                return serializer.parse(json);
            }
            catch (e) {
                onParseError(json, e);
            }
        }
        return initialValue;
    }
    if (!stores[storageType][key]) {
        const initial = maybeLoadInitial();
        const store = internal(initial, (set) => {
            if (browser && storageType == "local" && syncTabs) {
                const handleStorage = (event) => {
                    if (event.key === key) {
                        let newVal;
                        try {
                            newVal = event.newValue ? serializer.parse(event.newValue) : null;
                        }
                        catch (e) {
                            onParseError(event.newValue, e);
                            return;
                        }
                        set(newVal);
                    }
                };
                window.addEventListener("storage", handleStorage);
                return () => window.removeEventListener("storage", handleStorage);
            }
        });
        const { subscribe, set } = store;
        stores[storageType][key] = {
            set(value) {
                set(value);
                updateStorage(key, value);
            },
            update(callback) {
                return store.update((last) => {
                    const value = callback(last);
                    updateStorage(key, value);
                    return value;
                });
            },
            subscribe,
        };
    }
    return stores[storageType][key];
}
