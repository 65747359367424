import _ from 'lodash'
import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum DividendReinvestmentRequestStatus {
  CANCELED = 'Canceled',
  ERROR = 'Error',
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  REJECTED = 'Rejected'
}

export enum DividendReinvestmentDirection {
  OPT_IN = 'Opt In',
  OPT_OUT = 'Opt Out'
}

export default class DividendReinvestmentRequest extends RestResource {
  static onInitialize = (_obj: DividendReinvestmentRequest) => {
    /* no-op */
  }

  constructor() {
    super()

    DividendReinvestmentRequest.onInitialize(this)
  }

  accountNumber = ''
  direction = ''
  status = ''
  createdAt = null
  isCancelable = false
}

export const DIVIDEND_REINVESTMENT_REQUEST_DESER: SchemaDeSer<DividendReinvestmentRequest> =
  new SchemaDeSerBuilder(DividendReinvestmentRequest)
    .ofString('id')
    .ofString('accountNumber')
    .ofString('direction')
    .ofString('status')
    .ofBoolean('isCancelable')
    .ofDateTime('createdAt')
    .toDeSer()

export enum AccountDripState {
  OPT_IN_REQUESTED = 'Opt In Requested',
  OPT_OUT_REQUESTED = 'Opt Out Requested',
  OPTED_IN = 'Opted In',
  OPTED_OUT = 'Opted Out'
}

// Combines direction and status to determine the UI status of the request
export function determineAccountDripState(
  direction: DividendReinvestmentDirection,
  status: DividendReinvestmentRequestStatus
): AccountDripState {
  if (_.isNil(direction) || _.isNil(status)) {
    return AccountDripState.OPTED_OUT
  } else if (direction === DividendReinvestmentDirection.OPT_IN) {
    return handleOptIn(status)
  } else {
    return handleOptOut(status)
  }
}

function handleOptIn(
  status: DividendReinvestmentRequestStatus
): AccountDripState {
  if (status === DividendReinvestmentRequestStatus.PENDING) {
    return AccountDripState.OPT_IN_REQUESTED
  } else if (status === DividendReinvestmentRequestStatus.COMPLETE) {
    return AccountDripState.OPTED_IN
  } else {
    return AccountDripState.OPTED_OUT
  }
}

function handleOptOut(
  status: DividendReinvestmentRequestStatus
): AccountDripState {
  if (status === DividendReinvestmentRequestStatus.PENDING) {
    return AccountDripState.OPT_OUT_REQUESTED
  } else if (status === DividendReinvestmentRequestStatus.COMPLETE) {
    return AccountDripState.OPTED_OUT
  } else {
    return AccountDripState.OPTED_IN
  }
}
