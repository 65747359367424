import { BaseResponse, parseErrorContainer } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'
import { recursiveDasherizeKeys } from '../../tastyworks/request'

export class PasswordService {
  constructor(private readonly httpClient: HttpClient) {}

  async requestReset(login: string): Promise<BaseResponse> {
    const helper = await this.httpClient.postJson('/password/reset', { login })

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }

  async updateWithToken(
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string
  ): Promise<BaseResponse> {
    const helper = await this.httpClient.postJson('/password', {
      password,
      'password-confirmation': passwordConfirmation,
      'reset-password-token': resetPasswordToken
    })

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }

  async updateWithCurrentPassword(
    currentPassword: string,
    password: string,
    passwordConfirmation: string
  ): Promise<BaseResponse> {
    const helper = await this.httpClient.postJson(
      '/password',
      recursiveDasherizeKeys({
        currentPassword,
        password,
        passwordConfirmation
      })
    )

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
