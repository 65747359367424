<script>
  export let active
</script>

<button
  class="
      trading-account-indicator
      inline-flex
      items-center
      justify-center"
  class:active
  class:text-button-inactive={!active}
  class:text-button-primary={active}
  class:tw-icon-Actions-Trade={!active}
  class:tw-icon-Actions-Trade-Filled={active}
  on:click|stopPropagation
/>
