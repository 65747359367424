export const EPS = 1e-9

export function isZero(value: number, eps = EPS) {
  return Math.abs(value) < eps
}

// Ensures string is parsed in base 10, leading zeros will not be interpreted as octal
export function parseInteger(s: string) {
  return parseInt(s, 10)
}

export function isValid(value: number) {
  return value !== 0 && !isNaN(value) && isFinite(value)
}

export function isEqual(a: number, b: number): boolean {
  return Math.abs(a - b) < EPS
}
