import {
  ACAT_REQUEST_DESER,
  ACAT_REQUEST_UPDATER,
  AcatRequest,
  PLAID_AGGREGATOR_SOURCE,
  type AcatHolding,
  type AcatTransferParams
} from '../../tastyworks/asset-transfer'
import type HttpClient from '../../tastyworks/http'
import type { SortParams } from '../../tastyworks/request'
import { updateSingleResponse } from '../../tastyworks/response'
import { ArrayMap } from '../../tastyworks/util/collection'
import type { JsonHelper } from '../../tastyworks/util/json'

export class AcatRequestService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (accountNumber: string, sortParams?: SortParams) =>
    this.accountNumberClient.index(
      ArrayMap.stringKey(AcatRequest, 'id'),
      ACAT_REQUEST_UPDATER,
      (helper: JsonHelper) => helper.getString('id'),
      'acat-requests',
      { accountNumber, ...(sortParams ?? {}) }
    )

  readonly create = async (params: AcatTransferParams) => {
    const holdings = params.holdings.map((holding: AcatHolding) => ({
      'transfer-amount': holding.amount,
      id: holding.id
    }))

    const helper = await this.accountNumberClient.postJson(
      'acat-requests',
      {
        'transfer-type': params.transferType,
        'external-account-id': params.externalAccountId,
        'aggregator-source': PLAID_AGGREGATOR_SOURCE,
        'delivering-account-number': params.deliveringAccountNumber,
        'delivering-account-title': params.deliveringAccountTitle,
        'delivering-account-type': params.deliveringAccountType,
        holdings
      },
      { accountNumber: params.accountNumber }
    )

    const request = new AcatRequest(helper.getString('id'))
    return updateSingleResponse(helper, request, ACAT_REQUEST_DESER.update)
  }
}
