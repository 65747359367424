<!-- TODO: replace with UICL tooltip -->
<script lang="ts">
  import { cn } from "@tastyworks/ui-library"
  import tippy from "svelte-tippy"
  import { roundArrow } from "tippy.js"
  import "tippy.js/dist/svg-arrow.css"
  import "tippy.js/dist/tippy.css"

  export let content = ""
  export let theme = "tasty-alert"
  let cssClass = ""
  export { cssClass as class }
  export let iconClass = "tw-icon-Help"
  export let maxWidth: string | undefined = undefined
  export let interactive = false
  export let allowHTML = false

  const tippyOptions = {
    allowHTML,
    animation: "fade",
    arrow: roundArrow,
    content,
    duration: 250,
    interactive,
    maxWidth,
    theme,
    trigger: "click",
  }
</script>

<span
  class={cn(
    `tooltip-button-icon
    font-x-small-500
    cursor-pointer
    text-general-secondary-text`,
    iconClass,
    cssClass
  )}
  use:tippy={tippyOptions}
/>

<style lang="postcss">
  :global {
    .tippy-box[data-theme~="tasty-alert"] {
      --tooltip-color: var(--color-background-order-summary-one-cancel-order);
      background-color: var(
        --tooltip-color
      ) !important; /* Overrides the default theme background */
      > .tippy-content {
        padding: var(--extra-small-spacing) !important;
        text-align: start;
      }
      > .tippy-svg-arrow {
        fill: var(--tooltip-color) !important;
      }
    }
  }
</style>
