import { ACCOUNT_TYPES } from './account-type'
import { Address, ADDRESS_DESER } from './customer'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class Entity {
  address = new Address()
  entityType: ACCOUNT_TYPES = ACCOUNT_TYPES.BUSINESS
  id = '0'
  legalName = ''
}

export const ENTITY_DESER: SchemaDeSer<Entity> = new SchemaDeSerBuilder(Entity)
  .ofNested('address', ADDRESS_DESER, Address)
  .ofString('entityType')
  .ofString('id')
  .ofString('legalName')
  .toDeSer()
