import { RestResource } from './common'
import { SchemaDeSer, SchemaDeSerBuilder } from './deser'

export enum OPTIONS_LEVEL {
  NOT_SUITABLE = 'Not Suitable',
  COVERED_AND_CASH_SECURED = 'Covered And Cash Secured',
  DEFINED_RISK = 'Defined Risk Spreads',
  DEFINED_RISK_PLUS_NAKED = 'Defined Risk Spreads Plus Naked',
  NO_RESTRICTIONS = 'No Restrictions'
}

export class OptionsLevelResponse extends RestResource {
  optionsLevel = OPTIONS_LEVEL.COVERED_AND_CASH_SECURED
}

export const OPTIONS_LEVEL_RESPONSE_DESER: SchemaDeSer<OptionsLevelResponse> =
  new SchemaDeSerBuilder(OptionsLevelResponse)
    .ofString('optionsLevel')
    .toDeSer()

export class AccountOptionsLevel extends RestResource {
  accountNumber = ''
  currentOptionsLevel = OPTIONS_LEVEL.NOT_SUITABLE
  suitableOptionsLevel = OPTIONS_LEVEL.NOT_SUITABLE

  get isDowngrade() {
    return (
      compareOptionsLevels(
        this.currentOptionsLevel,
        this.suitableOptionsLevel
      ) > 0
    )
  }
}

export const ACCOUNT_OPTIONS_LEVEL_DESER: SchemaDeSer<AccountOptionsLevel> =
  new SchemaDeSerBuilder(AccountOptionsLevel)
    .ofString('accountNumber')
    .ofString('currentOptionsLevel')
    .ofString('suitableOptionsLevel')
    .toDeSer()

// Sort of required due to es5 targeting -- Object.values() is unavailable.
// This is more flexible (and possibly reliable) than relying on Object.values(OPTIONS_LEVEL) order, though.
export const OPTIONS_LEVEL_ORDER = [
  OPTIONS_LEVEL.NOT_SUITABLE,
  OPTIONS_LEVEL.COVERED_AND_CASH_SECURED,
  OPTIONS_LEVEL.DEFINED_RISK,
  OPTIONS_LEVEL.DEFINED_RISK_PLUS_NAKED,
  OPTIONS_LEVEL.NO_RESTRICTIONS
]

// Works like a cmp function.
export function compareOptionsLevels(lhs: OPTIONS_LEVEL, rhs: OPTIONS_LEVEL) {
  return OPTIONS_LEVEL_ORDER.indexOf(lhs) - OPTIONS_LEVEL_ORDER.indexOf(rhs)
}
