import type HttpClient from '../../tastyworks/http'
import {
  BaseResponse,
  parseErrorContainer,
  SingleResponse,
  updateSingleResponse
} from '../../tastyworks/response'
import {
  MOBILE_CONFIRMATION_DESER,
  MobileConfirmation
} from '../../tastyworks/user'

export class MobileConfirmationService {
  constructor(
    private readonly userMeClient: HttpClient,
    private readonly customerMeClient: HttpClient
  ) {}

  async requestConfirmationCode(
    mobilePhoneNumber: string
  ): Promise<SingleResponse<MobileConfirmation>> {
    const helper = await this.userMeClient.postJson('mobile/confirmation', {
      'mobile-number': mobilePhoneNumber
    })
    return updateSingleResponse(
      helper,
      new MobileConfirmation(),
      MOBILE_CONFIRMATION_DESER.update
    )
  }

  async validateConfirmationCode(
    confirmationCode: string
  ): Promise<BaseResponse> {
    this.customerMeClient.session.oneTimePassword = confirmationCode
    const helper = await this.customerMeClient.patch('mobile-confirmation', {})

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }

  async updateMobileConfirmation(
    mobilePhoneNumber: string,
    password: string
  ): Promise<SingleResponse<MobileConfirmation>> {
    const helper = await this.userMeClient.put('mobile/confirmation', {
      'current-password': password,
      'mobile-number': mobilePhoneNumber
    })
    return updateSingleResponse(
      helper,
      new MobileConfirmation(),
      MOBILE_CONFIRMATION_DESER.update
    )
  }
}
