import type HttpClient from '../../tastyworks/http'
import type { Params } from '../../tastyworks/request'
import type { SingleResponse } from '../../tastyworks/response'
import { parseSingleResponse } from '../../tastyworks/response'
import {
  SNAPSHOT_REPORTS_DESER,
  SnapshotReports
} from '../../tastyworks/snapshots'

export default class SnapshotService {
  readonly baseHttpClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.baseHttpClient = baseHttpClient
  }

  readonly show = async (
    symbol: string,
    exchange = '',
    instrumentType = ''
  ): Promise<SingleResponse<SnapshotReports>> => {
    const params: Params = {
      exchange,
      'instrument-type': instrumentType
    }

    const helper = await this.baseHttpClient.get(
      `/snapshot-reports/${symbol.toUpperCase()}`,
      params
    )

    return parseSingleResponse(
      helper,
      SNAPSHOT_REPORTS_DESER.toParser(SnapshotReports)
    )
  }
}
