import BouleNumericDirectionText from "/@/boule/control/BouleNumericDirectionText.svelte"
import BoulePlainText from "/@/boule/control/BoulePlainText.svelte"
import DefaultTableHeaderCell from "./DefaultTableHeaderCell.svelte"

import goog from "/@lib/boulangerie"
const UiBouleDelegateField = goog.module.get(
  "tasty.ui.boule.UiBouleDelegateField"
)
const UiBouleDirectionField = goog.module.get(
  "tasty.ui.boule.UiBouleDirectionField"
)

export function defaultTableCellFactory(columnMeta) {
  const field = columnMeta.bouleField
  const actual = UiBouleDelegateField.$isInstance(field)
    ? field.underlyingField
    : field
  return UiBouleDirectionField.$isInstance(actual)
    ? BouleNumericDirectionText
    : BoulePlainText
}

export function defaultTableHeaderCellFactory() {
  return DefaultTableHeaderCell
}
