import { boolean, object } from 'yup'
import { buildSchema } from './util/build-schema'

const schema = object({
  email: boolean().required(),
  pushNotification: boolean().required()
})

export const communicationGroupPreferenceSchema = buildSchema(
  'CommunicationGroupPreference',
  schema
)
