import BigNumber from 'bignumber.js'
import _ from 'lodash'
import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import {
  ALLOCATION_METHODS,
  MODEL_PORTFOLIO_DESER,
  ModelPortfolio
} from './model-portfolio'
import type { Params } from './request'

const ONE_HUNDRED = 100

export enum PORTFOLIO_SUBSCRIPTION_STATUSES {
  ACTIVE = 'Active',
  CATCHUP_ELIGIBLE = 'Catchup Eligible',
  FUNDING_REQUIRED = 'Funding Required',
  INACTIVE = 'Inactive',
  NEEDS_REVIEW = 'Needs Review'
}

export enum SUBSCRIPTION_ELIGIBILITY_STATUSES {
  ELIGIBLE = 'Eligible',
  INELIGIBLE = 'Ineligible'
}

export interface AccountSubscriptionValueParams extends Params {
  unitCount?: number
  buyingPowerPercentage?: number
  contractQuantity?: number
  dollarAmount?: number
}

export class AccountPortfolioSubscription extends RestResource {
  accountNumber = ''
  allocationMethod = ALLOCATION_METHODS.UNIT_COUNT
  buyingPowerPercentage?: BigNumber | null
  contractQuantity?: number | null
  dollarAmount?: BigNumber | null
  modelPortfolio?: ModelPortfolio
  optInDate?: Date
  optOutDate?: Date
  status = PORTFOLIO_SUBSCRIPTION_STATUSES.NEEDS_REVIEW
  unitCount?: number | null

  get isActive() {
    return (
      this.status === PORTFOLIO_SUBSCRIPTION_STATUSES.ACTIVE ||
      this.status === PORTFOLIO_SUBSCRIPTION_STATUSES.CATCHUP_ELIGIBLE ||
      this.status === PORTFOLIO_SUBSCRIPTION_STATUSES.NEEDS_REVIEW
    )
  }

  get allocationValue() {
    switch (this.allocationMethod) {
      case ALLOCATION_METHODS.UNIT_COUNT: {
        if (_.isNil(this.unitCount)) {
          return new BigNumber(0)
        }
        return new BigNumber(this.unitCount)
      }
      case ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE: {
        if (_.isNil(this.buyingPowerPercentage)) {
          return new BigNumber(0)
        }
        return this.buyingPowerPercentage.multipliedBy(ONE_HUNDRED)
      }
      case ALLOCATION_METHODS.CONTRACT_QUANTITY: {
        if (_.isNil(this.contractQuantity)) {
          return new BigNumber(0)
        }
        return new BigNumber(this.contractQuantity)
      }
      case ALLOCATION_METHODS.DOLLAR_AMOUNT: {
        if (_.isNil(this.dollarAmount)) {
          return new BigNumber(0)
        }
        return this.dollarAmount
      }
      default:
        return new BigNumber(0)
    }
  }

  setAllocationValue(method: ALLOCATION_METHODS, amount: BigNumber) {
    switch (method) {
      case ALLOCATION_METHODS.UNIT_COUNT: {
        this.unitCount = amount.toNumber()
        break
      }
      case ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE: {
        this.buyingPowerPercentage = amount.dividedBy(ONE_HUNDRED)
        break
      }
      case ALLOCATION_METHODS.CONTRACT_QUANTITY: {
        this.contractQuantity = amount.toNumber()
        break
      }
      case ALLOCATION_METHODS.DOLLAR_AMOUNT: {
        this.dollarAmount = amount
        break
      }
      default:
        throw Error(`Unsupported allocation method ${method}`)
    }
  }
}

export class AccountSubscriptionEligibility {
  status = SUBSCRIPTION_ELIGIBILITY_STATUSES.ELIGIBLE
  reason = ''

  get isIneligible() {
    return this.status === SUBSCRIPTION_ELIGIBILITY_STATUSES.INELIGIBLE
  }

  get isEligible() {
    return this.status === SUBSCRIPTION_ELIGIBILITY_STATUSES.ELIGIBLE
  }
}

export const ACCOUNT_PORTFOLIO_SUBSCRIPTION_DESER: SchemaDeSer<AccountPortfolioSubscription> =
  new SchemaDeSerBuilder(AccountPortfolioSubscription)
    .ofString('accountNumber')
    .ofString('allocationMethod')
    .ofBigNumber('buyingPowerPercentage', { serializeEmpty: true })
    .ofInt('contractQuantity', { serializeEmpty: true })
    .ofBigNumber('dollarAmount', { serializeEmpty: true })
    .ofInt('id')
    .ofNested('modelPortfolio', MODEL_PORTFOLIO_DESER, ModelPortfolio)
    .ofDate('optInDate')
    .ofDate('optOutDate')
    .ofString('status')
    .ofInt('unitCount', { serializeEmpty: true })
    .toDeSer()

export const ACCOUNT_SUBSCRIPTION_ELIGIBILITY_DESER: SchemaDeSer<AccountSubscriptionEligibility> =
  new SchemaDeSerBuilder(AccountSubscriptionEligibility)
    .ofString('status')
    .ofString('reason')
    .toDeSer()
