<script>
  import { lazyContext } from "/@lib/boulangerie"
  import ColumnPreference from "/@/boule/control/ColumnPreference.svelte"

  const columnManager =
    lazyContext.leftSidebarWatchlistTableManager.columnManager

  const sortedSelectableColumns = columnManager.sortedSelectableColumns
</script>

<ColumnPreference
  class="watchlists-left-sidebar"
  {columnManager}
  columnTypes={sortedSelectableColumns.jsArray}
/>
