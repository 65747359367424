import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { Entity, ENTITY_DESER } from './entity'

export enum EntityAuthorityLevel {
  ADMINISTRATOR = 'administrator'
}

export class EntityAuthority extends RestResource {
  authorityLevel: EntityAuthorityLevel = EntityAuthorityLevel.ADMINISTRATOR
  entity = new Entity()
  ownerOfRecord = false
}

export const ENTITY_AUTHORITY_DESER: SchemaDeSer<EntityAuthority> =
  new SchemaDeSerBuilder(EntityAuthority)
    .ofString('authorityLevel')
    .ofBoolean('ownerOfRecord')
    .ofNested('entity', ENTITY_DESER, Entity)
    .toDeSer()
