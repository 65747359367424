<script context="module">import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
const DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_MIN_DATE = parseDate("1900-01-01");
export const DEFAULT_MAX_DATE = parseDate("2100-01-01");
function parseDate(value) {
  if (!value)
    return void 0;
  const djsDate = dayjs(value, DATE_FORMAT);
  if (!djsDate.isValid())
    return void 0;
  return djsDate.toDate();
}
function stringify(value) {
  if (!value)
    return "";
  return dayjs(value).utc(true).format(DATE_FORMAT);
}
</script>

<script>import DatePicker from "date-picker-svelte/DatePicker.svelte";
import { createEventDispatcher } from "svelte";
const dispatch = createEventDispatcher();
let inputValue = void 0;
export { inputValue as value };
export let min = void 0;
export let max = void 0;
$:
  value = inputValue ? parseDate(inputValue) : void 0;
function handleSelect(event) {
  dispatch("select", { value: stringify(event.detail) }, { cancelable: true });
}
</script>

<DatePicker
  {value}
  min={parseDate(min) ?? DEFAULT_MIN_DATE}
  max={parseDate(max) ?? DEFAULT_MAX_DATE}
  on:select={handleSelect}
  on:focusout
/>
