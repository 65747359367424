<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="dashboardLight" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Dashboard-Light</title>
<path d="M1.2 2c0-0.442 0.358-0.8 0.8-0.8h7.273c0.442 0 0.8 0.358 0.8 0.8v5.455c0 0.442-0.358 0.8-0.8 0.8h-7.273c-0.442 0-0.8-0.358-0.8-0.8v-5.455zM2.8 2.8v3.855h5.673v-3.855h-5.673z"></path>
<path d="M11.2 2c0-0.442 0.358-0.8 0.8-0.8h10c0.442 0 0.8 0.358 0.8 0.8v10c0 0.442-0.358 0.8-0.8 0.8h-10c-0.442 0-0.8-0.358-0.8-0.8v-10z"></path>
<path d="M1.2 10.182c0-0.442 0.358-0.8 0.8-0.8h7.273c0.442 0 0.8 0.358 0.8 0.8v11.818c0 0.442-0.358 0.8-0.8 0.8h-7.273c-0.442 0-0.8-0.358-0.8-0.8v-11.818z"></path>
<path d="M11.4 14.727c0-0.331 0.269-0.6 0.6-0.6h10c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-10c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M11.4 17.155c0-0.331 0.269-0.6 0.6-0.6h10c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-10c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M11.4 19.573c0-0.331 0.269-0.6 0.6-0.6h10c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-10c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M11.4 22c0-0.331 0.269-0.6 0.6-0.6h10c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-10c-0.331 0-0.6-0.269-0.6-0.6z"></path>
</svg>