<script context="module" lang="ts">
  // Don't allow modals to exceed the viewport size.
  // Use of vertical-meter in both directions is intentional.
  const MIN_MARGIN = "var(--vertical-meter)"

  export function calculateInset(height: string, width: string) {
    const x = `max(${MIN_MARGIN}, calc((100vw - ${width}) / 2))`
    const y = `max(${MIN_MARGIN}, calc((100vh - ${height}) / 2))`
    return `${y} ${x}`
  }
</script>

<script lang="ts">
  import media from "/@/layout/media"

  import clickOutside from "/@/util/click-outside"

  export let show = false
  export let lightbox = false
  export let height = "50vh"
  export let width = "50vw"

  let cssStyle = ""
  let cssClass = ""

  export { cssClass as class }
  export { cssStyle as style }

  $: inset = calculateInset(height, $media.tablet ? "95vw" : width)
</script>

<div
  class="modal fixed inset-0 {cssClass}"
  class:pointer-events-none={!lightbox}
  class:empty={!show}
  class:backdrop-blur-sm={lightbox}
  style:z-index="var(--modal-z-index)"
>
  <!-- FIXME: larger modal refactor required for more comprehensive styling/sizing, see ATP-2456 -->
  <div
    style={cssStyle}
    class="modal-content pointer-events-auto fixed"
    style:inset
    style:max-height="800px"
    style:max-width="800px"
    style:min-height={$media.mobile ? "450px" : "300px"}
    style:min-width={$media.mobile ? "300px" : "450px"}
    on:click-outside
    use:clickOutside={show}
  >
    <slot />
  </div>
</div>
