import {
  getByPath,
  isPlainObject,
  setByPath,
} from "/@/account-management/form/util"
import isNil from "lodash.isnil"

function setFromScope(existing: any, scope: any, basePath = null) {
  const entries = Object.entries(scope)

  for (const [key, value] of entries) {
    const path = basePath === null ? key : `${basePath}.${key}`

    if (isPlainObject(value)) {
      const nestedScope = getByPath(scope, path)

      if (isNil(getByPath(existing, path))) {
        setByPath(existing, path, {})
      }

      setFromScope(getByPath(existing, path), nestedScope)
    } else {
      setByPath(existing, path, value)
    }
  }

  return existing
}

export function createForm(form) {
  return {
    initializeScope(scope: any, onDidInitialize = (_formValues) => {}) {
      form.update((existing) => {
        const updated = setFromScope(existing, scope)

        onDidInitialize(updated)

        return updated
      })
    },
    set: form.set,
    subscribe: form.subscribe,
    update: form.update,
  }
}
