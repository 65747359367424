import {
  AdvancedTradingRequestMap,
  FuturesTradingRequest,
  RestResource,
  TRADING_REQUEST_TYPES,
} from "@tastyworks/tastyworks-api"

export enum AdditionalTradingPreferences {
  FUTURES_TRADING = "Futures Trading",
}

export type TradingPreferenceType =
  | AdditionalTradingPreferences
  | TRADING_REQUEST_TYPES

export const THIRD_PARTY_TRADING_REQUEST_TYPES: TradingPreferenceType[] = [
  TRADING_REQUEST_TYPES.CRYPTOCURRENCY_TRADING,
]

export class AdvancedTradingPreference extends RestResource {
  constructor(
    readonly tradingPreferenceType: TradingPreferenceType,
    readonly accountNumber: string,
    readonly advancedTradingRequests: AdvancedTradingRequestMap,
    readonly futuresTradingRequest: FuturesTradingRequest
  ) {
    super()

    if (
      Object.values(TRADING_REQUEST_TYPES).includes(
        tradingPreferenceType as TRADING_REQUEST_TYPES
      )
    ) {
      const tradingRequest = advancedTradingRequests?.findByKeyElseCreate(
        tradingPreferenceType as TRADING_REQUEST_TYPES
      )

      if (tradingRequest?.id) {
        this.id = tradingRequest.id
      }
    } else if (
      AdditionalTradingPreferences.FUTURES_TRADING === tradingPreferenceType
    ) {
      if (futuresTradingRequest?.id) {
        this.id = futuresTradingRequest.id
      }
    } else {
      throw new Error(`Invalid TradingPreferenceType=${tradingPreferenceType}`)
    }
  }
}
