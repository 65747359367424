import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

// more codes from https://github.com/tastyworks/customer-account-api/blob/master/app/models/equifax_result.rb#L1
// most error codes are probably reviewed on CAC, no need to check here
export const EQUIFAX_REASON_CODES = {
  INVALID_INPUT_ERROR_OR_MISC: '01',
  ADDRESS_MATCH_NOT_FOUND: '36'
}

export const EQUIFAX_IDENTITY_PROPERTIES = [
  'firstName',
  'middleName',
  'lastName',
  'taxNumber',
  'birthDate'
]

export enum VALIDATION_SERVICE_TYPE {
  EID_COMPARE = 'eIDcompare',
  EID_VERIFIER = 'eIDverifier'
}

export class EquifaxResult {
  static onInitialize = (_obj: EquifaxResult) => {
    /* no-op */
  }

  constructor() {
    EquifaxResult.onInitialize(this)
  }

  decision = ''
  idValidationServiceType: string = ''

  get isRejected() {
    return !this.isAccepted
  }

  get isAccepted() {
    return this.decision === 'Y'
  }
}

export class EquifaxQuizAnswer {
  static onInitialize = (_obj: EquifaxQuizAnswer) => {
    /* no-op */
  }

  constructor() {
    EquifaxQuizAnswer.onInitialize(this)
  }

  id = 0
  text = ''
}

export class EquifaxQuestion {
  static onInitialize = (_obj: EquifaxQuestion) => {
    /* no-op */
  }

  constructor() {
    EquifaxQuestion.onInitialize(this)
  }

  answers: EquifaxQuizAnswer[] = []
  id = 0
  selectedAnswerId = 0
  text = ''
}

export class EquifaxQuiz {
  static onInitialize = (_obj: EquifaxQuiz) => {
    /* no-op */
  }

  constructor() {
    EquifaxQuiz.onInitialize(this)
  }

  questions: EquifaxQuestion[] = []
  quizId = 0
  transactionKey = ''
}

export class EquifaxResponse {
  static onInitialize = (_obj: EquifaxResponse) => {
    /* no-op */
  }

  constructor() {
    EquifaxResponse.onInitialize(this)
  }

  completion: EquifaxResult | null = null
  quiz: EquifaxQuiz | null = null
}

export const EQUIFAX_RESULT_DESER: SchemaDeSer<EquifaxResult> =
  new SchemaDeSerBuilder(EquifaxResult)
    .ofString('decision')
    .ofString('idValidationServiceType')
    .toDeSer()

export const EQUIFAX_QUIZ_ANSWER_DESER: SchemaDeSer<EquifaxQuizAnswer> =
  new SchemaDeSerBuilder(EquifaxQuizAnswer)
    .ofInt('id')
    .ofString('text')
    .toDeSer()

export const EQUIFAX_QUESTION_DESER: SchemaDeSer<EquifaxQuestion> =
  new SchemaDeSerBuilder(EquifaxQuestion)
    .ofArray('answers', EQUIFAX_QUIZ_ANSWER_DESER, EquifaxQuizAnswer)
    .ofInt('id')
    .ofInt('selectedAnswerId')
    .ofString('text')
    .toDeSer()

export const EQUIFAX_QUIZ_DESER: SchemaDeSer<EquifaxQuiz> =
  new SchemaDeSerBuilder(EquifaxQuiz)
    .ofArray('questions', EQUIFAX_QUESTION_DESER, EquifaxQuestion)
    .ofInt('quizId')
    .ofString('transactionKey')
    .toDeSer()

export const EQUIFAX_RESPONSE_DESER: SchemaDeSer<EquifaxResponse> =
  new SchemaDeSerBuilder(EquifaxResponse)
    .ofNested('completion', EQUIFAX_RESULT_DESER, EquifaxResult)
    .ofNested('quiz', EQUIFAX_QUIZ_DESER, EquifaxQuiz)
    .toDeSer()
