<script>
  import { ItemArranger } from "/@/control"

  export let options
  export let preferenceField

  let selected = [...preferenceField.valueList.jsArray]

  $: columns = [
    {
      activeBorderColor: "var(--color-general-tile-positive-movement)",
      items: selected.map((c) => ({
        label: c.description,
        value: c,
      })),
      showCount: true,
      showIndexes: true,
      title: "Displayed",
    },
    {
      activeBorderColor: "var(--color-general-tile-negative-movement)",
      items: options.jsArray
        .filter((c) => !selected.includes(c))
        .map((c) => ({
          label: c.description,
          value: c,
        })),
      showReset: true,
      title: "Not Displayed",
    },
  ]

  function handleChange(event) {
    const selectedOptions = event.detail[0].items.map((i) => i.value)
    preferenceField.setValueArray(...selectedOptions)
  }

  function handleReset() {
    // [DK] setting preference field to null is analogous to passing in default values
    preferenceField.valueList = null

    selected = selected
  }
</script>

<ItemArranger
  class="statistic-preferences"
  {columns}
  on:change={handleChange}
  on:reset={handleReset}
/>
