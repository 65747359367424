enum Element {
  CHECKBOX = "checkbox",
  LINK = "link",
  LABEL = "label",
  BUTTON = "button",
  IMAGE = "image",
  ICON = "icon",
}

enum Scope {
  LOGIN_VIEW = "LoginView",
  HEADER_NAV = "HeaderNav",
  TRADING_PLATFORM_NAV = "TradingPlatformNav",
  MANAGE_ACCOUNTS_NAV = "ManageAccountsNav",
  COMMON_SIDE_NAV = "CommonSideNav",
}

interface ViewIdentifier {
  identifier: string
}

export type UserAnalyticsTag = ViewIdentifier | string

const createTag = (scope: Scope, context: string, element: Element): string =>
  `${scope}:${context}:${element}`

export const loginViewRememberMeCheckBox = createTag(
  Scope.LOGIN_VIEW,
  "remember-me",
  Element.CHECKBOX
)
export const loginViewForgotPasswordLink = createTag(
  Scope.LOGIN_VIEW,
  "forgot-password",
  Element.LINK
)
export const loginViewSignUpLink = createTag(
  Scope.LOGIN_VIEW,
  "sign-up",
  Element.LINK
)
export const loginViewErrorLabel = createTag(
  Scope.LOGIN_VIEW,
  "error",
  Element.LABEL
)
export const headerNavDashboardLink = createTag(
  Scope.HEADER_NAV,
  "dashbord",
  Element.LINK
)
export const headerNavTradingPlatformLink = createTag(
  Scope.HEADER_NAV,
  "trading-platform",
  Element.LINK
)
export const headerNavManageAccountsLink = createTag(
  Scope.HEADER_NAV,
  "manage-accounts",
  Element.LINK
)

export const sideNavCollapseButton = createTag(
  Scope.COMMON_SIDE_NAV,
  "collapse-button",
  Element.BUTTON
)

export const headerNavTastyworksLogoImage = createTag(
  Scope.HEADER_NAV,
  "tastyworks-logo",
  Element.IMAGE
)

export const headerNavConnectionStatusSummaryButton = createTag(
  Scope.HEADER_NAV,
  "connection-status-summary",
  Element.BUTTON
)

export const headerNavAccountsConnectionStatusTitle = createTag(
  Scope.HEADER_NAV,
  "accounts-connection-status-title",
  Element.LABEL
)

export const headerNavAccountsConnectionStatusIcon = createTag(
  Scope.HEADER_NAV,
  "accounts-connection-status-icon",
  Element.ICON
)

export const headerNavQuotesConnectionStatusTitle = createTag(
  Scope.HEADER_NAV,
  "quotes-connection-status-title",
  Element.LABEL
)

export const headerNavQuotesConnectionStatusIcon = createTag(
  Scope.HEADER_NAV,
  "quotes-connection-status-icon",
  Element.ICON
)

export const headerNavMarketConnectionStatusTitle = createTag(
  Scope.HEADER_NAV,
  "market-connection-status-title",
  Element.LABEL
)

export const headerNavMarketConnectionStatusIcon = createTag(
  Scope.HEADER_NAV,
  "market-connection-status-icon",
  Element.ICON
)

export const headerNavSettingsButton = createTag(
  Scope.HEADER_NAV,
  "settings",
  Element.BUTTON
)

export const headerNavLogoutButton = createTag(
  Scope.HEADER_NAV,
  "logout",
  Element.BUTTON
)

export const gettingStartedTourButton = createTag(
  Scope.COMMON_SIDE_NAV,
  "getting-started-tour-button",
  Element.BUTTON
)

export const createTradingPlatformNavLinkTag = (context: string): string =>
  createTag(Scope.TRADING_PLATFORM_NAV, context, Element.LINK)

export const createManageAccountsNavLinkTag = (context: string): string =>
  createTag(Scope.MANAGE_ACCOUNTS_NAV, context, Element.LINK)

export default function (node, tag: UserAnalyticsTag) {
  if (!tag) {
    return
  }
  node.dataset.elementId =
    typeof tag === "string" ? tag : (tag as ViewIdentifier).identifier
}
