<script lang="ts">
  export let highlighted: boolean

  let cssClass = ""
  export { cssClass as class }
</script>

<span
  class="
    popover-child-options-indicator
    tw-icon-Arrow-Right
    flex
    flex-row
    items-center
    justify-center
    {cssClass}"
  class:text-icon-expand-arrow-active={highlighted}
  class:text-icon-expand-arrow-inactive={!highlighted}
/>
