import { RestResource } from './common'
import type { INVESTMENT_EXPERIENCES } from './customer'
import { SchemaDeSer, SchemaDeSerBuilder } from './deser'

export class EntitySuitability extends RestResource {
  futuresTradingExperience?: INVESTMENT_EXPERIENCES
}

export const ENTITY_SUITABILITY_DESER: SchemaDeSer<EntitySuitability> =
  new SchemaDeSerBuilder(EntitySuitability)
    .ofString('futuresTradingExperience')
    .toDeSer()
