import {
  AVAILABLE_BUYING_POWER_DESER,
  AvailableBuyingPowerResponse
} from '../../tastyworks/cash-management'
import type HttpClient from '../../tastyworks/http'
import {
  BaseResponse,
  parseErrorContainer,
  SingleResponse
} from '../../tastyworks/response'

export class DepositAvailableBuyingPowerService {
  private readonly path = '/deposits/:depositId/available-buying-power'

  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = async (
    accountNumber: string,
    depositId: string
  ): Promise<SingleResponse<AvailableBuyingPowerResponse>> =>
    this.accountNumberClient.show(
      new AvailableBuyingPowerResponse(),
      AVAILABLE_BUYING_POWER_DESER.update,
      this.path,
      { accountNumber, depositId }
    )

  readonly create = async (
    accountNumber: string,
    depositId: string
  ): Promise<BaseResponse> => {
    const helper = await this.accountNumberClient.postJson(
      this.path,
      {},
      {
        accountNumber,
        depositId
      }
    )

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
