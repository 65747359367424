import _ from 'lodash'
import {
  ACCOUNT_TYPE_CHOICES,
  ACCOUNT_TYPES,
  BENEFICIARY_ACCOUNT_TYPES,
  ENTITY_ACCOUNT_TYPES,
  JOINT_ACCOUNT_CHOICES,
  JOINT_ACCOUNT_TYPES,
  RETIREMENT_ACCOUNT_CHOICES,
  RETIREMENT_ACCOUNT_TYPES,
  ROTH_IRA_ACCOUNT_TYPES,
  TRADITIONAL_IRA_ACCOUNT_TYPES
} from './account-type'
import { RestResource } from './common'
import { Address, ADDRESS_DESER, TAX_NUMBER_TYPES } from './customer'
import type { SchemaDeSer, Updater } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { OPTIONS_LEVEL } from './options-level'
import { ArrayMap } from './util/collection'
import toStringValues from './util/enum'
import type { JsonKeyExtractor } from './util/json'

export enum RiskTolerance {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export const RiskToleranceValues = toStringValues(RiskTolerance)

export enum TimeHorizon {
  AVERAGE = 'AVERAGE',
  LONGEST = 'LONGEST',
  SHORT = 'SHORT'
}

export const TimeHorizonValues = toStringValues(TimeHorizon)

const OPTIONS_LEVEL_CHOICES = [
  OPTIONS_LEVEL.COVERED_AND_CASH_SECURED,
  OPTIONS_LEVEL.DEFINED_RISK,
  OPTIONS_LEVEL.NO_RESTRICTIONS
]

const RETIREMENT_OPTIONS_LEVEL_CHOICES = [
  OPTIONS_LEVEL.COVERED_AND_CASH_SECURED,
  OPTIONS_LEVEL.DEFINED_RISK,
  OPTIONS_LEVEL.DEFINED_RISK_PLUS_NAKED
]

export function allAvailableOptionsLevels(accountType: ACCOUNT_TYPES) {
  if (isAnyIra(accountType)) {
    return RETIREMENT_OPTIONS_LEVEL_CHOICES
  }

  return OPTIONS_LEVEL_CHOICES
}

function isAnyIra(accountType: ACCOUNT_TYPES) {
  return RETIREMENT_ACCOUNT_TYPES.includes(accountType)
}

export const ACCOUNT_TYPE_VALUES = toStringValues(ACCOUNT_TYPES)

export const ACCOUNT_TYPE_CHOICE_VALUES = toStringValues(ACCOUNT_TYPE_CHOICES)

export const RETIREMENT_TYPE_CHOICE_VALUES = toStringValues(
  RETIREMENT_ACCOUNT_CHOICES
)

export const JOINT_TYPE_CHOICES_VALUES = toStringValues(JOINT_ACCOUNT_CHOICES)

export enum INVESTMENT_OBJECTIVES {
  SPECULATION = 'SPECULATION',
  GROWTH = 'GROWTH',
  INCOME = 'INCOME',
  CAPITAL_PRESERVATION = 'CAPITAL_PRESERVATION'
}

export enum MARGIN_CASH {
  MARGIN = 'margin',
  CASH = 'cash'
}

export enum ACCOUNT_BENEFICIARY_TYPES {
  PRIMARY = 'Primary',
  CONTINGENT = 'Contingent'
}

export enum CustomerAccountAuthorityLevels {
  OWNER = 'owner',
  READ_ONLY = 'read-only',
  TRADE_ONLY = 'trade-only'
}

export const INVESTMENT_OBJECTIVE_VALUES = toStringValues(INVESTMENT_OBJECTIVES)

export class AccountBeneficiary extends RestResource {
  static onInitialize = (_obj: AccountBeneficiary) => {
    /* no-op */
  }

  constructor() {
    super()
    AccountBeneficiary.onInitialize(this)
  }

  firstName = ''
  middleName = ''
  lastName = ''
  birthDate: Date | null = new Date()
  taxNumberType: TAX_NUMBER_TYPES = TAX_NUMBER_TYPES.SSN
  taxNumber = ''
  beneficiaryType: ACCOUNT_BENEFICIARY_TYPES = ACCOUNT_BENEFICIARY_TYPES.PRIMARY
  beneficiaryRelationship = ''
  beneficiaryPercent = 0
  address: Address = new Address()

  get isPrimary() {
    return this.beneficiaryType === ACCOUNT_BENEFICIARY_TYPES.PRIMARY
  }

  get isContingent() {
    return this.beneficiaryType === ACCOUNT_BENEFICIARY_TYPES.CONTINGENT
  }
}

export const ACCOUNT_BENEFICIARY_DESER: SchemaDeSer<AccountBeneficiary> =
  new SchemaDeSerBuilder(AccountBeneficiary)
    .ofString('firstName')
    .ofString('middleName')
    .ofString('lastName')
    .ofDateTime('birthDate')
    .ofString('taxNumberType')
    .ofString('taxNumber')
    .ofString('beneficiaryType')
    .ofString('beneficiaryRelationship')
    .ofInt('beneficiaryPercent')
    .ofNested('address', ADDRESS_DESER, Address)
    .ofString('id')
    .toDeSer()

export const UPDATE_ACCOUNT_BENEFICIARY_DESER: SchemaDeSer<AccountBeneficiary> =
  new SchemaDeSerBuilder(AccountBeneficiary)
    .ofNested('address', ADDRESS_DESER, Address)
    .ofInt('beneficiaryPercent')
    .ofString('beneficiaryRelationship')
    .ofNullableString('beneficiaryType')
    .ofDateTime('birthDate')
    .ofString('firstName')
    .ofString('id')
    .ofString('lastName')
    .ofString('middleName')
    .toDeSer()

export const NEW_ACCOUNT_BENEFICIARY_DESER: SchemaDeSer<AccountBeneficiary> =
  new SchemaDeSerBuilder(AccountBeneficiary)
    .ofNested('address', ADDRESS_DESER, Address)
    .ofInt('beneficiaryPercent')
    .ofString('beneficiaryRelationship')
    .ofNullableString('beneficiaryType')
    .ofDateTime('birthDate')
    .ofString('firstName')
    .ofString('lastName')
    .ofString('middleName')
    .ofNullableString('taxNumber')
    .ofString('taxNumberType')
    .toDeSer()

export type AccountBeneficiariesMap = ArrayMap<string, AccountBeneficiary>

export function createAccountBeneficiariesMap(): ArrayMap<
  string,
  AccountBeneficiary
> {
  return ArrayMap.stringKey(AccountBeneficiary, 'id')
}

export class AccountBeneficiariesHolder {
  static onInitialize = (_obj: AccountBeneficiariesHolder) => {
    /* no-op */
  }

  constructor() {
    AccountBeneficiariesHolder.onInitialize(this)
  }

  accountBeneficiaries: AccountBeneficiary[] = []
}

export const ACCOUNT_BENEFICIARIES_HOLDER_DESER: SchemaDeSer<AccountBeneficiariesHolder> =
  new SchemaDeSerBuilder(AccountBeneficiariesHolder)
    .ofArray(
      'accountBeneficiaries',
      ACCOUNT_BENEFICIARY_DESER,
      AccountBeneficiary
    )
    .toDeSer()

export class Account {
  static onInitialize = (_obj: Account) => {
    /* no-op */
  }

  constructor(readonly accountNumber: string) {
    Account.onInitialize(this)
  }

  authorityLevel = ''
  accountType = ''
  accountBeneficiaries = ArrayMap.idKey(AccountBeneficiary)
  closedAt: Date | null = null
  dayTraderStatus = false
  externalId = ''
  extAccountId: string | null = null
  fundingDate: Date | null = null
  investmentObjective = ''
  investmentTimeHorizon?: TimeHorizon
  lastStateTimestamp = 0
  marginOrCash = ''
  nickname = ''
  openedAt: Date | null = null
  riskTolerance?: RiskTolerance

  get isIndividual() {
    return this.accountType === ACCOUNT_TYPES.INDIVIDUAL
  }

  get isSepIra() {
    return this.accountType === ACCOUNT_TYPES.SEP_IRA
  }

  get isAnyBeneficiaryIra() {
    return BENEFICIARY_ACCOUNT_TYPES.includes(this.accountType as ACCOUNT_TYPES)
  }

  get isAnyRothIra() {
    return ROTH_IRA_ACCOUNT_TYPES.includes(this.accountType as ACCOUNT_TYPES)
  }

  get isAnyTraditionalIra() {
    return TRADITIONAL_IRA_ACCOUNT_TYPES.includes(
      this.accountType as ACCOUNT_TYPES
    )
  }

  get isAnyIra() {
    return isAnyIra(this.accountType as ACCOUNT_TYPES)
  }

  get isAnyJointAccount() {
    return JOINT_ACCOUNT_TYPES.includes(this.accountType as ACCOUNT_TYPES)
  }

  get isClosed() {
    return !_.isNil(this.closedAt)
  }

  get isOwner() {
    return this.authorityLevel === CustomerAccountAuthorityLevels.OWNER
  }

  get isReadOnly() {
    return this.authorityLevel === CustomerAccountAuthorityLevels.READ_ONLY
  }

  get isRetirement() {
    return _.includes(RETIREMENT_ACCOUNT_TYPES, this.accountType)
  }

  get isEntity() {
    return ENTITY_ACCOUNT_TYPES.includes(this.accountType as ACCOUNT_TYPES)
  }

  get isTradeOnly() {
    return this.authorityLevel === CustomerAccountAuthorityLevels.TRADE_ONLY
  }

  get isCash() {
    return isCash(this.marginOrCash as MARGIN_CASH)
  }

  get isMargin() {
    return isMargin(this.marginOrCash as MARGIN_CASH)
  }

  get isAccountTypeFuturesEligible() {
    return (
      this.isEntity ||
      this.isIndividual ||
      this.isAnyIra ||
      this.isAnyJointAccount
    )
  }

  get marginTypeDisplay() {
    if (this.isAnyIra) {
      return ''
    }

    return _.capitalize(this.marginOrCash)
  }

  get typeDisplay() {
    return `${this.accountType} ${this.marginTypeDisplay}`
  }

  get isJointOrBusinessType() {
    return (
      this.accountType === ACCOUNT_TYPES.BUSINESS ||
      JOINT_ACCOUNT_TYPES.includes(this.accountType as ACCOUNT_TYPES)
    )
  }
}

export const INVESTMENT_OBJECTIVE_DESER: SchemaDeSer<Account> =
  new SchemaDeSerBuilder<Account>(Account.bind(null, ''))
    .ofString('investmentObjective')
    .toDeSer()

export const NO_ACCOUNT = new Account('')

export const ACCOUNT_UPDATER: Updater<Account> = (account, helper) => {
  account.authorityLevel = helper.getString('authority-level')

  const acctHelper = helper.getChild('account')
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  ACCOUNT_DESER.update(account, acctHelper)

  acctHelper.getChildren('account-beneficiaries').forEach(beneficiaryHelper => {
    const accountBeneficiaryId = beneficiaryHelper.getString('id')
    const accountBeneficiary =
      account.accountBeneficiaries.findByKeyElseCreate(accountBeneficiaryId)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    ACCOUNT_BENEFICIARY_DESER.update(accountBeneficiary, beneficiaryHelper)
  })
}

export const ACCOUNT_DESER: SchemaDeSer<Account> =
  new SchemaDeSerBuilder<Account>(Account.bind(null, ''))
    .ofString('accountNumber')
    .ofString('accountType', 'account-type-name')
    .ofDateTime('closedAt')
    .ofBoolean('dayTraderStatus')
    .ofString('externalId')
    .ofString('extAccountId')
    .ofDate('fundingDate')
    .ofString('investmentObjective')
    .ofString('investmentTimeHorizon')
    .ofString('marginOrCash')
    .ofString('nickname')
    .ofDateTime('openedAt')
    .ofString('riskTolerance')
    .toDeSer()

export type AccountMap = ArrayMap<string, Account>

export function createAccountMap(): ArrayMap<string, Account> {
  return ArrayMap.stringKey(Account, 'accountNumber')
}

export const ACCOUNT_KEY_EXTRACTOR: JsonKeyExtractor<string> = helper =>
  helper.getChild('account').getString('account-number')

function isCash(marginOrCash: MARGIN_CASH) {
  return MARGIN_CASH.CASH === marginOrCash.toLowerCase()
}

function isMargin(marginOrCash: MARGIN_CASH) {
  return MARGIN_CASH.MARGIN === marginOrCash.toLowerCase()
}
