<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="watchlist" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Watchlist</title>
<path d="M17.092 5.711c2.144 1.214 3.713 2.885 5.069 4.872 0.622 0.836 0.938 1.788 0.938 2.832 0 2.721-2.199 4.92-4.92 4.92-2.039 0-3.676-1.379-4.477-2.969l-0.035-0.064c-1.365-2.473-2.423-4.391-2.423-6.97 0-0.899 0.337-1.808 1.023-2.494 1.454-1.454 3.416-0.968 4.825-0.126zM16.278 7.088c-1.22-0.732-2.232-0.767-2.879-0.12-0.366 0.366-0.555 0.859-0.555 1.363 0 2.128 0.848 3.702 2.267 6.275 0.005 0.009 0.010 0.019 0.015 0.029 0.604 1.208 1.768 2.1 3.053 2.1 1.837 0 3.319-1.482 3.319-3.32 0-0.703-0.207-1.324-0.629-1.887-0.007-0.010-0.014-0.019-0.021-0.029-1.269-1.861-2.675-3.338-4.553-4.4l-0.018-0.010z"></path>
<path d="M15.138 9.998c1.835-1.733 4.774-1.522 6.393 0.4 0.213 0.253 0.181 0.632-0.072 0.845s-0.632 0.181-0.845-0.072c-1.186-1.409-3.331-1.548-4.651-0.301-1.377 1.301-1.552 3.376-0.326 4.674 0.228 0.241 0.217 0.621-0.024 0.848s-0.621 0.217-0.848-0.024c-1.754-1.857-1.403-4.691 0.375-6.37z"></path>
<path d="M10.602 6.968c-0.647-0.647-1.659-0.612-2.879 0.12l-0.018 0.010c-1.878 1.062-3.285 2.539-4.553 4.4-0.007 0.010-0.014 0.020-0.021 0.029-0.422 0.563-0.629 1.183-0.629 1.887 0 1.837 1.482 3.319 3.32 3.319 1.364 0 2.444-0.881 3.053-2.1 0.005-0.010 0.010-0.019 0.015-0.029 1.419-2.573 2.267-4.147 2.267-6.275 0-0.503-0.189-0.997-0.555-1.363zM6.908 5.711c1.409-0.842 3.371-1.328 4.825 0.126 0.686 0.686 1.023 1.595 1.023 2.494 0 2.579-1.059 4.497-2.423 6.97l-0.035 0.064c-0.796 1.58-2.341 2.969-4.477 2.969-2.721 0-4.92-2.199-4.92-4.92 0-1.043 0.315-1.995 0.938-2.832 1.356-1.987 2.925-3.658 5.069-4.872z"></path>
<path d="M8.038 10.87c-1.32-1.247-3.465-1.108-4.651 0.301-0.213 0.253-0.592 0.286-0.845 0.072s-0.286-0.592-0.072-0.845c1.618-1.922 4.558-2.134 6.393-0.4 1.778 1.679 2.129 4.513 0.375 6.37-0.228 0.241-0.607 0.252-0.848 0.024s-0.252-0.607-0.024-0.848c1.226-1.298 1.051-3.373-0.326-4.674z"></path>
<path d="M18.355 12.261c-0.633 0-1.153 0.52-1.153 1.153 0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6c0-1.296 1.057-2.353 2.353-2.353 0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6z"></path>
<path d="M5.645 12.261c-0.633 0-1.153 0.52-1.153 1.153 0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6c0-1.296 1.057-2.353 2.353-2.353 0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6z"></path>
<path d="M14.63 16.472c-1.483-1.163-3.777-1.163-5.259 0-0.261 0.204-0.638 0.159-0.842-0.102s-0.159-0.638 0.102-0.842c1.917-1.504 4.823-1.504 6.741 0 0.261 0.204 0.306 0.582 0.102 0.842s-0.582 0.306-0.842 0.102z"></path>
</svg>