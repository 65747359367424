import { RestResource } from './common'
import { SchemaDeSerBuilder } from './deser'

export enum PDT_RESET_ELIGIBILITY_STATUS {
  ELIGIBLE = 'Eligible',
  NOT_CLASSIFIED_PDT = 'Not Classified PDT',
  PENDING = 'Pending',
  PENDING_JOINT_AGREEMENT = 'Pending Joint Agreement',
  UNAVAILABLE = 'Unavailable'
}

export class PdtResetEligibilityResponse {
  accountNumber = ''
  status = ''
  lastResetDate = new Date()
  nextAvailableResetDate = new Date()
  receivedPdtResetRequestId = 0

  get isEligible() {
    return this.status === PDT_RESET_ELIGIBILITY_STATUS.ELIGIBLE
  }

  get isNotPdt() {
    return this.status === PDT_RESET_ELIGIBILITY_STATUS.NOT_CLASSIFIED_PDT
  }

  get isPending() {
    return this.status === PDT_RESET_ELIGIBILITY_STATUS.PENDING
  }

  get isUnavailable() {
    return this.status === PDT_RESET_ELIGIBILITY_STATUS.UNAVAILABLE
  }

  get isPendingJointAgreement() {
    return this.status === PDT_RESET_ELIGIBILITY_STATUS.PENDING_JOINT_AGREEMENT
  }
}

export const PDT_RESET_ELIGIBILITY_RESPONSE_DESER = new SchemaDeSerBuilder(
  PdtResetEligibilityResponse
)
  .ofString('accountNumber')
  .ofString('status')
  .ofDateTime('lastResetDate')
  .ofDateTime('nextAvailableResetDate')
  .ofInt('receivedPdtResetRequestId')
  .toDeSer()

export enum PDT_RESET_REQUEST_STATUS {
  RECEIVED = 'Received',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed'
}

export class PdtResetResponse extends RestResource {
  accountNumber = ''
  requestedByCustomerId = 0
  status = ''
  completedAt = new Date()

  get isReceived() {
    return this.status === PDT_RESET_REQUEST_STATUS.RECEIVED
  }

  get isCancelled() {
    return this.status === PDT_RESET_REQUEST_STATUS.CANCELLED
  }

  get isCompleted() {
    return this.status === PDT_RESET_REQUEST_STATUS.COMPLETED
  }

  // NOTE: [JH] DEPRECATED - should be spelled "received", use isReceived
  get isRecieved(): boolean {
    return this.isReceived
  }
}

export const PDT_RESET_RESPONSE_DESER = new SchemaDeSerBuilder(PdtResetResponse)
  .ofString('accountNumber')
  .ofInt('id')
  .ofString('status')
  .ofDateTime('completedAt')
  .ofInt('requestedByCustomerId')
  .toDeSer()
