import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export interface TickSizeInstrument {
  symbol: string
  tickSize: number
}

export enum OptionExpirationType {
  EndOfMonth = 'End-of-Month',
  Leap = 'Leap',
  Quarterly = 'Quarterly',
  Regular = 'Regular',
  Weekly = 'Weekly'
}

export type SettlementType = 'AM' | 'PM'

export class EquityOffering extends RestResource {
  symbol = ''
  offeringType = ''
  marketplace = ''
  externalId = ''
  active = false
  isUpdateOnly = false
  activatedAt?: Date
  orderWindowClosesAt?: Date
  cancelledAt?: Date
  ticketType = ''
  minTicketSize?: number
  maxTicketSize?: number
  unitIncrement = 0
  name = ''
  description = ''
  industry = ''
  tradeDate?: Date
  settlementDate?: Date
  anticipatedShares = 0
  minPrice = 0
  maxPrice = 0
  prospectusUrl = ''
  brochureUrl = ''
  logoUrl = ''
}

export const EQUITY_OFFERING_DESER: SchemaDeSer<EquityOffering> =
  new SchemaDeSerBuilder(EquityOffering)
    .ofInt('id')
    .ofString('symbol')
    .ofString('offeringType')
    .ofString('marketplace')
    .ofString('externalId')
    .ofBoolean('active')
    .ofBoolean('isUpdateOnly')
    .ofDateTime('activatedAt')
    .ofDateTime('orderWindowClosesAt')
    .ofDateTime('cancelledAt')
    .ofString('ticketType')
    .ofFloat('minTicketSize')
    .ofFloat('maxTicketSize')
    .ofFloat('unitIncrement')
    .ofString('name')
    .ofString('description')
    .ofString('industry')
    .ofDate('tradeDate')
    .ofDate('settlementDate')
    .ofInt('anticipatedShares')
    .ofFloat('minPrice')
    .ofFloat('maxPrice')
    .ofString('prospectusUrl')
    .ofString('brochureUrl')
    .ofString('logoUrl')
    .toDeSer()
