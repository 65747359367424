import { SchemaDeSerBuilder } from './deser'
import type { SchemaDeSer } from './deser'

export class NewsUrn {
  headline = ''
  story = ''
}

export const NEWS_URN_DESER: SchemaDeSer<NewsUrn> = new SchemaDeSerBuilder(
  NewsUrn
)
  .ofString('headline')
  .ofString('story')
  .toDeSer()

export class NewsItem {
  attribution = ''
  createdAt = ''
  headline = ''
  id = ''
  language = ''
  provider = ''
  revision = 0
  updatedAt = ''
  urgency = ''
}

export const NEWS_ITEM_DESER: SchemaDeSer<NewsItem> = new SchemaDeSerBuilder(
  NewsItem
)
  .ofStrings(
    'attribution',
    'createdAt',
    'headline',
    'id',
    'language',
    'provider',
    'revision'
  )
  .ofInt('revision')
  .ofStrings('updatedAt', 'urgency')
  .toDeSer()
