import { number, object, string } from 'yup'
import { WITHHOLDING_TYPES } from '../../ira-detail'
import toStringValues from '../../util/enum'
import { buildSchema } from './util/build-schema'

const WITHHOLDING_TYPES_VALUES = toStringValues(WITHHOLDING_TYPES)

const schema = object({
  contributionReason: string(),
  contributionYear: string(),
  distributionReason: string(),
  federalWithholding: number(),
  federalWithholdingType: string().oneOf(WITHHOLDING_TYPES_VALUES),
  receivingInstitutionName: string(),
  stateWithholding: number(),
  stateWithholdingType: string().oneOf(WITHHOLDING_TYPES_VALUES)
})

export const iraDetailSchema = buildSchema('IraDetail', schema)
