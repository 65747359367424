import { date, object, string } from 'yup'
import { addressSchema } from './address'
import { buildSchema } from './util/build-schema'
import { isValidPhoneNumber } from './util/phone-number'

const schema = object({
  firstName: string().required(),
  lastName: string().required(),
  address: addressSchema.required(),
  phoneNumber: string().required().test('validPhoneNumber', isValidPhoneNumber),
  birthDate: date().required(),
  email: string().email().required()
})

export const trustedContactSchema = buildSchema('TrustedContact', schema)
