import {
  ACAT_HOLDING_UPDATER,
  AcatHolding
} from '../../tastyworks/asset-transfer'
import type HttpClient from '../../tastyworks/http'
import { ArrayMap } from '../../tastyworks/util/collection'
import type { JsonHelper } from '../../tastyworks/util/json'

export class AssetTransferExternalAccountHoldingsService {
  private readonly assetTransferClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.assetTransferClient = customerMeClient.nested('asset-transfer')
  }

  readonly index = async (externalAccountId: number) =>
    this.assetTransferClient.index(
      ArrayMap.stringKey(AcatHolding, 'id'),
      ACAT_HOLDING_UPDATER,
      (helper: JsonHelper) => helper.getString('id'),
      'external-accounts/:externalAccountId/holdings',
      { externalAccountId }
    )
}
