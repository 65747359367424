import { SchemaDeSerBuilder } from './deser'
import { OPTIONS_LEVEL } from './options-level'

export enum MARGIN_CALCULATION_TYPE {
  CASH = 'Cash',
  CASH_SECURED = 'Cash Secured Margin',
  IRA = 'IRA Margin',
  REG_T = 'Reg T',
  PORTFOLIO = 'Portfolio Margin',
  FUTURES = 'Futures',
  FUTURES_INTRA_DAY = 'Futures Intra Day'
}

export class TradingStatus {
  static onInitialize = (_obj: TradingStatus) => {
    /* no-op */
  }

  constructor() {
    TradingStatus.onInitialize(this)
  }

  accountNumber = ''
  isFrozen = false
  isClosed = false
  isClosingOnly = false
  isInMarginCall = false
  dayTradeCount = 0
  isCryptocurrencyEnabled = false
  isFuturesEnabled = false
  isPatternDayTrader = false
  isCryptocurrencyClosingOnly = false
  patternDayTraderAt = new Date()
  equitiesMarginCalculationType = ''
  optionsLevel = ''
  isFullEquityMarginRequired = false
  isEquityOfferingEnabled = false
  isInDayTradeEquityMaintenanceCall = false
  pdtResetOn = new Date()
  hasIntradayEquitiesMargin = false

  get isDefinedRiskEligible() {
    return (
      this.optionsLevel === OPTIONS_LEVEL.DEFINED_RISK ||
      this.optionsLevel === OPTIONS_LEVEL.DEFINED_RISK_PLUS_NAKED ||
      this.optionsLevel === OPTIONS_LEVEL.NO_RESTRICTIONS
    )
  }

  get isOptionsCashSecured() {
    return (
      this.optionsLevel === OPTIONS_LEVEL.COVERED_AND_CASH_SECURED ||
      this.isDefinedRiskEligible
    )
  }

  get isRegTMargin() {
    return this.equitiesMarginCalculationType === MARGIN_CALCULATION_TYPE.REG_T
  }

  get isCashSecured() {
    return (
      this.equitiesMarginCalculationType ===
      MARGIN_CALCULATION_TYPE.CASH_SECURED
    )
  }

  get isMargin() {
    return this.isCashSecured || this.isRegTMargin
  }

  // Returns true if their account qualifies for shorting permission, regardless of whether they are funded
  get isEligibleForShorting() {
    return (
      this.isRegTMargin && this.optionsLevel === OPTIONS_LEVEL.NO_RESTRICTIONS
    )
  }

  get isFuturesEligible() {
    return (
      this.optionsLevel === OPTIONS_LEVEL.NO_RESTRICTIONS ||
      this.optionsLevel === OPTIONS_LEVEL.DEFINED_RISK_PLUS_NAKED
    )
  }
}

export const TRADING_STATUS_DESER = new SchemaDeSerBuilder(TradingStatus)
  .ofString('accountNumber')
  .ofInt('dayTradeCount')
  .ofBoolean('isFrozen')
  .ofBoolean('isClosed')
  .ofBoolean('isClosingOnly')
  .ofBoolean('isInMarginCall')
  .ofBoolean('isCryptocurrencyEnabled')
  .ofBoolean('isFuturesEnabled')
  .ofBoolean('isCryptocurrencyEnabled')
  .ofBoolean('isCryptocurrencyClosingOnly')
  .ofString('equitiesMarginCalculationType')
  .ofBoolean('isPatternDayTrader')
  .ofDateTime('patternDayTraderAt')
  .ofBoolean('isFullEquityMarginRequired')
  .ofBoolean('isEquityOfferingEnabled')
  .ofBoolean('isInDayTradeEquityMaintenanceCall')
  .ofDateTime('pdtResetOn')
  .ofBoolean('hasIntradayEquitiesMargin')
  .ofString('optionsLevel')
  .toDeSer()
