<script context="module">
  import goog from "/@lib/boulangerie"

  const SymbolSearchManager = goog.module.get("tasty.ui.SymbolSearchManager")
</script>

<script>
  import Icons from "@tastyworks/icons"
  import { createEventDispatcher } from "svelte"
  import { writable } from "svelte/store"
  import SymbolSearchResultsPanel from "./SymbolSearchResultsPanel.svelte"
  import {
    resultsPanelOpen,
    results as resultsStore,
    searchForSymbol,
    searchValue,
    submitSymbol,
  } from "./store"
  import MaskedInput from "/@/control/MaskedInput.svelte"
  import { asyncPopover } from "/@/layout/popover/index"
  import { px } from "/@/util/style-dimensions"
  import userAnalytics from "/@/util/user-analytics"

  export let placeholder = SymbolSearchManager.SEARCH_FIELD_PLACEHOLDER
  export let above = false
  export let maxPanelHeight = null
  export let userAnalyticsTag = null
  export let value = ""
  export let clearValueOnSelect = false
  // out-binding for placing watchlist add button
  export let clientWidth
  export let searchFieldName

  let cssClass = ""
  export { cssClass as class }

  const symbolSearchService = goog.module.get(
    "com.dough.service.SymbolSearchService"
  )
  const allowedPattern = new RegExp(symbolSearchService.ALLOWED_SYMBOL_SEARCH)
  const maskOptions = {
    // using function not pattern to allow additional constraints (e.g. length)
    mask: (value) => allowedPattern.test(value),
  }

  const dispatch = createEventDispatcher()

  const highlightedIndex = writable(-1)

  $: results = $resultsStore.jsArray

  // Template bindings.
  let rootElem
  let inputElem
  let windowHeight, windowWidth

  $: $highlightedIndex = results.findIndex((r) => r.symbol === value)

  // actions
  async function searchAndOpenPanel() {
    $searchValue = value
    await search(value)
    open()
  }

  async function search() {
    await searchForSymbol(value)
  }

  function select(result) {
    if (result?.symbol) {
      submitSymbol(result.symbol)
    }
    dispatch("select", result)
    resetIndex()
    if (clearValueOnSelect) {
      clear()
    }
  }

  function resetIndex() {
    $highlightedIndex = -1
  }

  function clear() {
    value = ""
  }

  let controller = null
  function close() {
    controller?.abort()
  }

  async function open() {
    if (controller) return

    const { bottom, left, right, top } = rootElem.getBoundingClientRect()

    let inset
    let maxContentHeight

    if (above) {
      inset = `auto ${px(windowWidth - right)} ${px(windowHeight - top)} ${px(
        left
      )}`
      maxContentHeight = `${px(top)}`
    } else {
      inset = `${px(bottom + 1)} ${px(windowWidth - right)} auto ${px(left)}`
      maxContentHeight = `${px(windowHeight - bottom)}`
    }

    const props = {
      highlightedIndex,
      inset,
      maxHeight: maxPanelHeight ?? maxContentHeight,
      searchFieldName,
    }

    $resultsPanelOpen = true
    resetIndex()
    controller = new AbortController()

    const result = await asyncPopover(
      SymbolSearchResultsPanel,
      props,
      controller.signal
    )

    // FIXME: Hack around already-open trigger-click with a sleep. Not sure
    //        there's a good solution to this. Popover's click-outside (close:
    //        null) fires before the trigger-click, so we always think we're
    //        closed.
    await new Promise((resolve, _reject) => setTimeout(resolve, 10))

    controller = null
    resetIndex()

    if (result) {
      select(result)
    }
  }

  // handlers
  function onClick() {
    if (!$resultsPanelOpen) {
      searchAndOpenPanel()
    } else {
      inputElem?.focus()
    }
  }

  function onKeydown(event) {
    switch (event.key) {
      case "ArrowDown":
        if (controller) {
          if ($highlightedIndex < results.length - 1) {
            $highlightedIndex += 1
          }
        } else {
          open()
        }
        break
      case "ArrowUp":
        if (controller) {
          if ($highlightedIndex > 0) {
            $highlightedIndex -= 1
          }
        } else {
          open()
        }
        break
      case "Enter":
        onSearch(event)
        break
      case "Escape":
        clear()
        break
      case "Tab":
        close()
        break
      default:
        break
    }
  }

  async function onInput(_event) {
    searchAndOpenPanel()
  }

  async function onFocus(_event) {
    searchAndOpenPanel()
  }

  async function onSearch(event) {
    select(results[$highlightedIndex] ?? submitSymbol(event.target.value))
    close()
  }
</script>

<svelte:window bind:innerHeight={windowHeight} bind:innerWidth={windowWidth} />

<div
  class="
    symbol-search
    font-small-400
    flex
    flex-row
    items-center
    rounded
    bg-input-field-dropdown-secondary
    px-double-extra-small
    focus-within:ring-1
    {cssClass}"
  bind:this={rootElem}
  bind:clientWidth
  use:userAnalytics={userAnalyticsTag}
>
  <button
    class="m-double-extra-small cursor-default"
    tabindex="-1"
    on:click={onClick}
  >
    <Icons.search />
  </button>
  <MaskedInput
    class="
      flex 
      flex-grow 
      truncate 
      border-none 
      bg-transparent 
      outline-none 
      {searchFieldName}"
    type="search"
    {placeholder}
    {maskOptions}
    bind:inputElem
    bind:value
    on:focus={onFocus}
    on:input={onInput}
    on:click={onClick}
    on:keydown={onKeydown}
    on:search={onSearch}
  />
  <slot />
</div>
