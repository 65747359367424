import { selectedAccountsManager, tradeSession } from "/@lib/boulangerie"
import { bouleArrayStore } from "/@/boule/control/boule-array-store.js"
import { bouleWritablePropertyStore } from "/@/boule/control/boule-property-store.js"
import { IG_APP } from "/@lib/shared"

export const currentAccount = bouleWritablePropertyStore(
  tradeSession.currentAccountProperty()
)

export const focusedAccounts = Object.assign(
  bouleArrayStore(selectedAccountsManager.focusedAccounts),
  {
    toggle(account) {
      if (IG_APP) {
        return selectedAccountsManager.focusExclusively(account)
      } else {
        return selectedAccountsManager.toggleFocused(account)
      }
    },
  }
)

export const includedAccounts = bouleArrayStore(
  selectedAccountsManager.includedAccounts
)
