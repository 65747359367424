<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="calendar" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Calendar</title>
<path d="M3.583 3.933c-0.565 0-0.983 0.461-0.983 0.983v14.667c0 0.565 0.461 0.983 0.983 0.983h16.833c0.565 0 0.983-0.461 0.983-0.983v-14.667c0-0.565-0.461-0.983-0.983-0.983h-16.833zM1.4 4.917c0-1.144 0.915-2.183 2.183-2.183h16.833c1.144 0 2.183 0.915 2.183 2.183v14.667c0 1.144-0.915 2.183-2.183 2.183h-16.833c-1.144 0-2.183-0.915-2.183-2.183v-14.667z"></path>
<path d="M1.4 8.083c0-0.331 0.269-0.6 0.6-0.6h20c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-20c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M17 1.4c0.331 0 0.6 0.269 0.6 0.6v2.417c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-2.417c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M7 1.4c0.331 0 0.6 0.269 0.6 0.6v2.417c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-2.417c0-0.331 0.269-0.6 0.6-0.6z"></path>
<path d="M8.5 11.8c-0.392 0-0.7 0.309-0.7 0.7v3.583c0 0.391 0.308 0.7 0.7 0.7h1.25c0.392 0 0.7-0.309 0.7-0.7v-3.583c0-0.391-0.309-0.7-0.7-0.7h-1.25zM6.2 12.5c0-1.275 1.025-2.3 2.3-2.3h1.25c1.275 0 2.3 1.025 2.3 2.3v3.583c0 1.275-1.025 2.3-2.3 2.3h-1.25c-1.275 0-2.3-1.025-2.3-2.3v-3.583z"></path>
<path d="M15.639 10.344c0.299 0.124 0.494 0.416 0.494 0.739v5.7h0.45c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-1.25c-0.442 0-0.8-0.358-0.8-0.8v-4.57c-0.314 0.281-0.797 0.271-1.099-0.031-0.312-0.312-0.312-0.819 0-1.131l1.333-1.333c0.229-0.229 0.573-0.297 0.872-0.174z"></path>
<path d="M13.283 17.583c0-0.442 0.358-0.8 0.8-0.8h1.25c0.442 0 0.8 0.358 0.8 0.8s-0.358 0.8-0.8 0.8h-1.25c-0.442 0-0.8-0.358-0.8-0.8z"></path>
</svg>