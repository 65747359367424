import { object, string } from 'yup'
import { OPTIONS_LEVEL } from '../../options-level'
import toStringValues from '../../util/enum'
import { buildSchema } from './util/build-schema'

const schema = object({
  optionsLevel: string().required().oneOf(toStringValues(OPTIONS_LEVEL))
})

export const tradingStatusSchema = buildSchema('TradingStatus', schema)
