import { object, string } from 'yup'
import {
  INVESTMENT_OBJECTIVE_VALUES,
  RiskToleranceValues,
  TimeHorizonValues
} from '../../account'
import { buildSchema } from './util/build-schema'

const schema = object({
  investmentObjective: string().required().oneOf(INVESTMENT_OBJECTIVE_VALUES),
  nickname: string().required(),
  riskTolerance: string().required().oneOf(RiskToleranceValues),
  timeHorizon: string().required().oneOf(TimeHorizonValues)
})

export const accountSchema = buildSchema('Account', schema)
