<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { px } from "/@/util/style-dimensions"
  import userAnalytics from "/@/util/user-analytics"

  import Popover from "./Popover.svelte"
  import PopoverLabelOption from "./PopoverLabelOption.svelte"
  import { PopoverOption } from "./index"

  export let options: PopoverOption[]

  export let childrenLeft = false
  export let inset = "auto"
  export let maxHeight: string | null = null

  // Out bindings.
  export let offsetHeight = 0
  export let offsetWidth = 0
  export let windowHeight = 0
  export let windowWidth = 0

  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()

  let highlightedIndex = -1
  let contentElem: HTMLDivElement

  // Reset highlightedIndex of options changes (svelte can reuse the child).
  $: if (options) {
    highlightedIndex = -1
  }

  $: optionElems = contentElem?.querySelectorAll(".option")
  $: highlightedElem = optionElems?.item(highlightedIndex)
  $: highlightedTop = (highlightedElem as HTMLElement)?.offsetTop

  $: childTop = contentElem?.offsetTop - contentElem?.scrollTop + highlightedTop

  $: childInset = childrenLeft
    ? `${px(childTop)} ${px(offsetWidth)} auto auto`
    : `${px(childTop)} auto auto ${px(offsetWidth)}`

  $: maxContentHeight = maxHeight ?? px(windowHeight)
</script>

<Popover
  class="popover-options-panel {cssClass}"
  {inset}
  bind:offsetHeight
  bind:offsetWidth
  bind:windowHeight
  bind:windowWidth
  on:close
>
  <div
    class="
      content
      flex
      flex-col
      items-stretch
      overflow-y-auto
      overflow-x-hidden
      rounded
      bg-input-field-dropdown-primary"
    bind:this={contentElem}
    style:max-height={maxContentHeight}
  >
    {#each options as option, i}
      <!-- FIXME: button causing styling issues in curve mode -->
      <button
        type="button"
        class="
          option
          odd:bg-input-field-dropdown-secondary
          even:bg-input-field-dropdown-primary"
        class:disabled={option.disabled ?? false}
        class:border-t={option.separator}
        style:border-color="var(--color-text-general-inactive-text)"
        on:mouseover={() => (highlightedIndex = i)}
        on:focus={() => (highlightedIndex = i)}
        use:userAnalytics={option.userAnalyticsTag ?? null}
      >
        <svelte:component
          this={option.component ?? PopoverLabelOption}
          highlighted={i === highlightedIndex}
          {option}
          on:close
        />
      </button>
    {/each}
  </div>

  <!-- XXX: Child panels _must_ be within parent for event order. -->
  {#if options[highlightedIndex]?.options}
    <svelte:self
      {childrenLeft}
      inset={childInset}
      options={options[highlightedIndex].options}
      on:close={({ detail }) => {
        // XXX: Avoid forwarding unnecessary close: null child-panel events.
        if (!detail) return
        dispatch("close", detail)
      }}
    />
  {/if}
</Popover>
