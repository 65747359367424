import { addMethod, object, ref, string } from 'yup'
import { buildSchema } from './util/build-schema'

const minPasswordLength = 12

const myEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

addMethod(string, 'email', function validateEmail(message?: string) {
  // eslint-disable-next-line @typescript-eslint/no-invalid-this
  return this.matches(myEmailRegex, {
    message: message ?? 'yup.common.string.email',
    name: 'email',
    excludeEmptyString: true
  })
})

const schema = object({
  email: string().email().required(),
  password: string().required().min(minPasswordLength),
  passwordConfirmation: string()
    .nullable()
    .oneOf([ref('password'), null], 'yup.user.passwordConfirmation.mustMatch'),
  username: string()
    .required()
    .matches(/^[a-zA-Z0-9]+$/, {
      message: 'must contain only letters and numbers',
      excludeEmptyString: true
    })
})

export const userSchema = buildSchema('User', schema)
