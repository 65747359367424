import type { ExternalAffiliateCommission } from '../../tastyworks/external-affiliate-commission'
import { EXTERNAL_AFFILIATE_COMMISSION_DESER } from '../../tastyworks/external-affiliate-commission'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'

export class ExternalAffiliateCommissionService {
  constructor(private readonly userMeClient: HttpClient) {}

  readonly create = async (
    externalAffiliateCommission: ExternalAffiliateCommission
  ): Promise<SingleResponse<ExternalAffiliateCommission>> =>
    this.userMeClient.create(
      externalAffiliateCommission,
      EXTERNAL_AFFILIATE_COMMISSION_DESER.serialize,
      EXTERNAL_AFFILIATE_COMMISSION_DESER.update,
      'external-affiliate-commissions'
    )
}
