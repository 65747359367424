import _ from 'lodash'

export type ObjectKeyExtractor<K, V> = (value: V) => K

export type ValueCreator<K, V> = (key: K) => V

export type Stringifier<K> = (key: K) => string

export const STRING_IDENTITY: Stringifier<string> = key => key

export const GENERIC_STRINGIFYER: Stringifier<any> = key => `${key}`

export function createDynamicKeyExtractor<K, V>(
  objectKey: string
): ObjectKeyExtractor<K, V> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (model: any) => _.get(model as Record<string, any>, objectKey)
}

export class ArrayMap<K, V> {
  private static simple<K, V>(
    valueCreator: new (key: K) => V,
    objectKey: string,
    stringifier: Stringifier<K>
  ) {
    // prettier-ignore
    return new ArrayMap<K, V>(
      key => new valueCreator(key),
      createDynamicKeyExtractor(objectKey),
      stringifier
    )
  }

  static numberKey<V>(valueCreator: new (key: number) => V, objectKey: string) {
    return ArrayMap.simple(valueCreator, objectKey, GENERIC_STRINGIFYER)
  }

  static stringKey<V>(valueCreator: new (key: string) => V, objectKey: string) {
    return ArrayMap.simple(valueCreator, objectKey, STRING_IDENTITY)
  }

  static idKey<V>(valueCreator: new (key: string) => V) {
    return ArrayMap.simple(valueCreator, 'id', STRING_IDENTITY)
  }

  static enumKey<K, V>(valueCreator: new (key: K) => V, objectKey: string) {
    return ArrayMap.simple(valueCreator, objectKey, GENERIC_STRINGIFYER)
  }

  static onInitialize = (_obj: ArrayMap<any, any>) => {
    /* no-op */
  }

  constructor(
    readonly valueCreator: ValueCreator<K, V>,
    readonly objectKeyExtractor: ObjectKeyExtractor<K, V>,
    readonly stringifier: Stringifier<K>
  ) {
    ArrayMap.onInitialize(this)
  }

  protected readonly array: V[] = []
  protected readonly map: Map<string, V> = new Map()

  get keys(): K[] {
    return this.values.map(this.objectKeyExtractor)
  }

  get values(): V[] {
    return this.array
  }

  get length(): number {
    return this.array.length
  }

  get isPresent() {
    return this.length > 0
  }

  get isEmpty() {
    return this.length === 0
  }

  add(value: V): void {
    const rawKey = this.objectKeyExtractor(value)
    const stringKey = this.stringifier(rawKey)
    if (this.map.has(stringKey)) {
      // Replace
      const oldValue = this.map.get(stringKey)
      if (oldValue === undefined) {
        throw new Error('WTF')
      }
      const index = this.array.indexOf(oldValue)
      this.map.set(stringKey, value)
      this.array[index] = value
    } else {
      // Easy add
      this.map.set(stringKey, value)
      this.onAdd(value)
    }
  }

  addAll(values: V[]): void {
    values.forEach(value => {
      this.add(value)
    })
  }

  hasKey(key: K): boolean {
    const stringKey = this.stringifier(key)
    return this.map.has(stringKey)
  }

  findByKey(key: K): V | undefined {
    const stringKey = this.stringifier(key)
    return this.map.get(stringKey)
  }

  findByKeyElseCreate(rawKey: K): V {
    const stringKey = this.stringifier(rawKey)
    let value = this.map.get(stringKey)
    if (value === undefined) {
      value = this.valueCreator(rawKey)
      this.map.set(stringKey, value)
      this.onCreateAdd(value)
    }
    return value
  }

  protected onCreateAdd(value: V): void {
    this.array.push(value)
  }

  protected onAdd(value: V): void {
    this.array.push(value)
  }

  deleteByKey(key: K): boolean {
    const stringKey = this.stringifier(key)
    const value = this.map.get(stringKey)
    if (value === undefined) {
      return false
    }

    this.map.delete(stringKey)
    const index = this.array.indexOf(value)
    this.array.splice(index, 1)
    return true
  }

  deleteByKeys(keys: K[] | Set<K>): void {
    for (const key of keys) {
      this.deleteByKey(key)
    }
  }

  deleteByValue(value: V): boolean {
    const key = this.objectKeyExtractor(value)
    return this.deleteByKey(key)
  }

  clear() {
    this.array.splice(0, this.array.length)
    this.map.clear()
  }
}

export class ArrayMapReverse<K, V> extends ArrayMap<K, V> {
  constructor(
    readonly valueCreator: ValueCreator<K, V>,
    readonly objectKeyExtractor: ObjectKeyExtractor<K, V>,
    readonly stringifier: Stringifier<K>
  ) {
    super(valueCreator, objectKeyExtractor, stringifier)
  }

  protected onCreateAdd(value: V): void {
    this.array.unshift(value)
  }
}

export const EMPTY_MAP = Object.freeze(new Map())

export function emptyMap<K, V>(): Map<K, V> {
  return EMPTY_MAP as Map<K, V>
}
