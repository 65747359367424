import { push, replace } from "svelte-spa-router"

export default class Route {
  private readonly _parent: Route
  private readonly prefix: string
  public readonly path: string
  public readonly template: string

  constructor(prefix: string | Route, template: string) {
    this._parent = typeof prefix === "string" ? new Route(null, prefix) : prefix
    this.prefix = Route.derivePrefix(this._parent)
    this.template = template
    this.path = Route.stripParams(template)
  }

  get nested() {
    return this.path + "/*"
  }

  get href() {
    return this.prefix + this.path
  }

  push(params?: Record<string, string>) {
    const target = this._deriveTarget(params)
    push(target.href)
  }

  replace(params?: Record<string, string>) {
    const target = this._deriveTarget(params)
    replace(target.href)
  }

  interpolate(params?: Record<string, string>) {
    return new Route(this.prefix, this._interpolateParams(params))
  }

  private _interpolateParams(params?: Record<string, string>) {
    return this.template.replaceAll(/:([^/]+)/g, (_, key) => {
      const value = params[key]
      // partial interpolation - if a param is missing, return the original match
      if (value === undefined) {
        return `:${key}`
      }
      return value
    })
  }

  private _deriveTarget(params?: Record<string, string>) {
    return params ? this.interpolate(params) : this
  }

  private static derivePrefix(parent: Route) {
    const parts = []
    while (parent) {
      parts.push(parent.path)
      parent = parent._parent
    }
    return parts.reverse().join("")
  }

  private static stripParams(template: string) {
    return template.replaceAll(/(\/:[^/]+)/g, "")
  }

  // useful for computed property names i.e. `[routes.TRADING]: ...` shorthand for `[routes.TRADING.path]: ...` (params not included)
  toString() {
    return this.path
  }
}
