import { number, object } from 'yup'
import { ACH_DEPOSIT_MAXIMUM } from '../../cash-management'
import { iraDetailSchema } from './ira-detail'
import { buildSchema } from './util/build-schema'

const schema = object({
  achRelationshipId: number().required().positive().integer(),
  amount: number().required().positive().max(ACH_DEPOSIT_MAXIMUM),
  iraDetail: iraDetailSchema
})

export const depositSchema = buildSchema('Deposit', schema)
