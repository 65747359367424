import { createAccountStreamer } from "/@lib/tastyworks-rest/tw-streamer"
import createClient from "./create-client"
import session from "./session"
import sessionErrorHandler from "./session-error-handler"

export const twApiClient = createClient(
  import.meta.env.VITE_TW_API_BASE_URL,
  import.meta.env.VITE_TW_VAST_API_BASE_URL,
  sessionErrorHandler,
  session
)

export const accountStreamer = createAccountStreamer()
