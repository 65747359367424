import type { Logger } from "@tastyworks/tastyworks-api"

const logger: Logger = {
  warn(message?: any, ...optionalParams: any[]) {
    console.warn(message, optionalParams)
  },

  info(message?: any, ...optionalParams: any[]) {
    console.info(message, optionalParams)
  },

  error(message?: any, ...optionalParams: any[]) {
    console.error(message, optionalParams)
  },

  log(message?: any, ...optionalParams: any[]) {
    console.log(message, optionalParams)
  },
}

export default logger
