import type { ArrayMap } from '../../tastyworks'
import type {
  AdvancedTradingRequest,
  AdvancedTradingRequestMap,
  TRADING_REQUEST_TYPES
} from '../../tastyworks/advanced-trading-request'
import {
  ADVANCED_TRADING_REQUEST_DESER,
  ADVANCED_TRADING_REQUEST_ELIGIBILITY_DESER,
  AdvancedTradingRequestEligibility,
  createAdvancedTradingRequestMap
} from '../../tastyworks/advanced-trading-request'
import type HttpClient from '../../tastyworks/http'
import { toArrayMap, type SingleResponse } from '../../tastyworks/response'
import { stringJsonKeyExtractor } from '../../tastyworks/util/json'

export class AdvancedTradingRequestService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly indexByRequestType = async (
    accountNumber: string
  ): Promise<SingleResponse<AdvancedTradingRequestMap>> => {
    const advancedTradingRequestMap = createAdvancedTradingRequestMap()
    const response = await this.accountNumberClient.index(
      advancedTradingRequestMap as ArrayMap<string, AdvancedTradingRequest>,
      ADVANCED_TRADING_REQUEST_DESER.update,
      stringJsonKeyExtractor('request-type'),
      'advanced-trading-requests',
      { accountNumber }
    )
    return toArrayMap(response, advancedTradingRequestMap)
  }

  readonly create = async (
    advancedTradingRequest: AdvancedTradingRequest
  ): Promise<SingleResponse<AdvancedTradingRequest>> =>
    this.accountNumberClient.create(
      advancedTradingRequest,
      ADVANCED_TRADING_REQUEST_DESER.serialize,
      ADVANCED_TRADING_REQUEST_DESER.update,
      'advanced-trading-requests',
      { accountNumber: advancedTradingRequest.accountNumber }
    )

  readonly showEligibility = async (
    accountNumber: string,
    requestType: TRADING_REQUEST_TYPES
  ): Promise<SingleResponse<AdvancedTradingRequestEligibility>> =>
    this.accountNumberClient.show(
      new AdvancedTradingRequestEligibility(),
      ADVANCED_TRADING_REQUEST_ELIGIBILITY_DESER.update,
      'advanced-trading-requests/eligibility',
      { accountNumber, requestType }
    )
}
