<script context="module">
  import goog from "/@lib/boulangerie"
  const SequencedAccountShapeUtil = goog.module.get(
    "tasty.ui.account.SequencedAccountShapeUtil"
  )
</script>

<script>
  import { tradeSession } from "/@lib/boulangerie"
  import { BouleIcon } from "/@/boule/index.js"

  export let account
  let cssClass = ""
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }
  export let invertColor = false // Option to swap background with icon color, with updated width

  $: icon = SequencedAccountShapeUtil.getFontIcon(tradeSession, account)
  $: color = `rgba(${icon?.color.red}, ${icon?.color.green}, ${icon?.color.blue}, ${icon?.color.alpha})`
</script>

{#if icon}
  <div
    class="trading-account-icon-and-badge inline-block {cssClass}"
    style={cssStyle}
  >
    <BouleIcon
      class="trading-account-icon
      inline-flex
      h-full
      items-center
      justify-center
      {invertColor ? 'text-button-colored' : ''}
      "
      height=""
      src={icon}
      title={account?.description ?? ""}
      color={!invertColor && color}
      backgroundColor={invertColor && color}
      width={invertColor ? "var(--vertical-meter)" : "var(--normal-font-size)"}
    />
    <slot name="badge" />
  </div>
{/if}
