import type { JsonHelper } from '../../../tastyworks'
import { ItemsResponse, parseErrorContainer } from '../../../tastyworks'
import type { AdminOrderParams } from '../../../tastyworks/admin/admin-orders'
import HttpClient from '../../../tastyworks/http'
import {
  ORDER_STATUS_DESER,
  OrderStatus
} from '../../../tastyworks/order-status'
import { updatePagination } from '../../../tastyworks/response'

export class AdminOrdersService {
  private readonly adminOrdersClient: HttpClient

  constructor(adminOrdersClient: HttpClient) {
    this.adminOrdersClient = adminOrdersClient.nested('accounts')
  }

  static parseOrdersList = (data: JsonHelper): OrderStatus[] => {
    const orders: OrderStatus[] = []

    data.parseArray('items', json => {
      orders.push(
        ORDER_STATUS_DESER.toParser(
          OrderStatus.bind(
            '',
            json.getString('id'),
            json.getString('account-number')
          )
        )(json)
      )
    })

    return orders
  }

  readonly index = async (
    accountNumber: string,
    params: AdminOrderParams
  ): Promise<ItemsResponse<OrderStatus>> =>
    this.adminOrdersClient
      .nested(accountNumber)
      .get('orders', params)
      .then(res => this.populateResult(res))

  readonly cancel = async (
    accountNumber: string,
    orderId: string
  ): Promise<ItemsResponse<OrderStatus>> => {
    const helper = await this.adminOrdersClient
      .nested(accountNumber)
      .nested('orders')
      .nested(orderId)
      .doDelete('')
    const response = new ItemsResponse<OrderStatus>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')
    response.items = AdminOrdersService.parseOrdersList(data)

    return response
  }

  readonly populateResult = (
    helper: JsonHelper
  ): ItemsResponse<OrderStatus> => {
    const response = new ItemsResponse<OrderStatus>()

    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }

    const data = helper.getChild('data')
    response.items = AdminOrdersService.parseOrdersList(data)

    updatePagination(response.pagination, helper.getChild('pagination'))
    return response
  }
}
