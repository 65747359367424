import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { Award } from './stock-award'

export class CashAward extends Award {
  static onInitialize = (_obj: CashAward) => {
    /* no-op */
  }

  constructor() {
    super()

    CashAward.onInitialize(this)
  }

  customerId = 0
  reviewingUserId = 0
  apexFileId = 0
  description = ''
}

export const CASH_AWARD_DESER: SchemaDeSer<CashAward> = new SchemaDeSerBuilder(
  CashAward
)
  .ofInt('id')
  .ofInt('userId')
  .ofInt('customerId')
  .ofFloat('monetaryValue')
  .ofString('status')
  .ofString('destinationAccountNumber')
  .ofInt('reviewingUserId')
  .ofString('notes')
  .ofInt('apexFileId')
  .ofString('description')
  .ofDateTime('createdAt')
  .ofDateTime('updatedAt')
  .toDeSer()
