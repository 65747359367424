import type { RequestConfirmationEmailByEmail } from '../../tastyworks/customer'
import type HttpClient from '../../tastyworks/http'
import { recursiveDasherizeKeys } from '../../tastyworks/request'
import {
  BaseResponse,
  parseErrorContainer,
  SingleResponse,
  updateSingleResponse
} from '../../tastyworks/response'
import TwUser, {
  RecaptchaClientType,
  RecaptchaVersion,
  SecurityQuestion,
  USER_DESER
} from '../../tastyworks/user'

interface CreateUserParams {
  email: string
  username: string
  password: string
  recaptchaToken?: string
  recaptchaVersion?: RecaptchaVersion
  recaptchaClient?: RecaptchaClientType
  securityQuestions?: SecurityQuestion[]
}

export class UserService {
  constructor(
    private readonly baseHttpClient: HttpClient,
    private readonly userMeClient: HttpClient
  ) {}

  async changeEmail(
    email: string,
    currentPassword: string
  ): Promise<SingleResponse<TwUser>> {
    const helper = await this.userMeClient.patch(
      '',
      recursiveDasherizeKeys({
        currentPassword,
        email
      })
    )

    const response = new SingleResponse<TwUser>()
    parseErrorContainer(helper, response)

    return response
  }

  async requestConfirmationEmail(
    options: RequestConfirmationEmailByEmail
  ): Promise<BaseResponse> {
    const helper = await this.baseHttpClient.postJson('/confirmation', options)
    const response = new BaseResponse()
    parseErrorContainer(helper, response)
    return response
  }

  readonly create = async ({
    email,
    username,
    password,
    recaptchaToken,
    recaptchaVersion,
    recaptchaClient,
    securityQuestions
  }: CreateUserParams): Promise<SingleResponse<TwUser>> => {
    const helper = await this.baseHttpClient.postJson(
      '/users',
      recursiveDasherizeKeys({
        email,
        username,
        password,
        recaptchaToken,
        recaptchaVersion,
        recaptchaClient,
        securityQuestions
      })
    )

    const user = new TwUser()

    return updateSingleResponse(helper, user, USER_DESER.update)
  }

  readonly changeUsername = async (
    username: string,
    currentPassword: string
  ) => {
    const helper = await this.userMeClient.patch(
      '',
      recursiveDasherizeKeys({
        username,
        currentPassword
      })
    )

    return updateSingleResponse(helper, new TwUser(), USER_DESER.update)
  }
}
