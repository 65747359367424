import {
  ACCOUNT_DOCUMENT_DESER_PARSER,
  AccountDocument,
  type AccountDocumentParams
} from '../../tastyworks/account-documents'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class AccountDocumentService {
  constructor(private readonly baseHttpClient: HttpClient) {}

  readonly index = async (
    accountDocumentParams: AccountDocumentParams
  ): Promise<ItemsResponse<AccountDocument>> =>
    this.baseHttpClient.indexSimple(
      ACCOUNT_DOCUMENT_DESER_PARSER,
      '/account-documents',
      accountDocumentParams
    )
}
