import _ from 'lodash'
import {
  EXTERNAL_TRANSACTION_DESER,
  ExternalTransaction
} from '../../tastyworks/cash-management'
import type HttpClient from '../../tastyworks/http'
import type { PaginationParams } from '../../tastyworks/request'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'

export class DepositService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params?: PaginationParams
  ): Promise<ItemsResponse<ExternalTransaction>> =>
    this.accountNumberClient.indexSimple(
      EXTERNAL_TRANSACTION_DESER.toParser(ExternalTransaction),
      'deposits',
      _.merge({ accountNumber }, params)
    )

  readonly create = async (
    deposit: ExternalTransaction
  ): Promise<SingleResponse<ExternalTransaction>> =>
    this.accountNumberClient.create(
      deposit,
      EXTERNAL_TRANSACTION_DESER.serialize,
      EXTERNAL_TRANSACTION_DESER.update,
      'deposits',
      { accountNumber: deposit.accountNumber }
    )
}
