import { get } from "svelte/store"
import { _ } from "svelte-i18n"
import goog from "@tastyworks/boulangerie-bundle"
import { AlertSeverity, alertDetails, toastAlert } from "/@/control/alert"
import { notify } from "/@/trading/notification/notification.js"

const WarningOrderStatusHandler = goog.module.get(
  "tasty.ui.orderstatus.WarningOrderStatusHandler"
)

function warn(title, message, options) {
  toastAlert(AlertSeverity.IMPORTANT, message, {
    component: {
      props: {
        headingMessage: title,
        icon: alertDetails[AlertSeverity.IMPORTANT].icon,
      },
    },
    ...options,
  })
}

export default class WebWarningOrderStatusHandler extends WarningOrderStatusHandler {
  // boulangerie hooks
  showPreMarketCancelWarningMessage() {
    this._warnAndNotify(
      this.$_("preMarketCancelWarning.title"),
      this.$_("preMarketCancelWarning.message")
    )
  }

  showUnableToCancelOrderMessage(errorMessage) {
    this._warnAndNotify(
      this.$_("unableToCancelOrderWarning.title"),
      errorMessage,
      {
        theme: {
          float: "right",
          clear: "right",
          "--toastWidth": "calc(var(--horizontal-meter) * 7.5)", // Additional width to accomidate longer exceptions
        },
      }
    )
  }

  // private members
  _i18nPrefix = "content.trading.notification"

  _warnAndNotify(title, message, options) {
    warn(title, message, options)
    notify({
      title,
      message: `${this.$_("nativeWarningPrefix")} ${message}`,
    })
  }

  $_(path) {
    return get(_)(`${this._i18nPrefix}.${path}`)
  }
}
