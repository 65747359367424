<script>
  export let src
  export let rotate = false
  export let color = "inherit"
  export let height = "auto"
  export let width = "auto"
  export let title = ""
  export let backgroundColor = ""

  let cssClass = ""
  export { cssClass as class }
</script>

<span
  class="boule-icon icon {src?.cssClass} {cssClass} {src?.name?.() ?? ''}"
  class:is-blank={!src}
  class:rotate
  style:color
  style:height
  style:width
  data-content={src?.string}
  {title}
  style:background-color={backgroundColor}
/>

<style lang="postcss">
  .icon {
    /* Center the pseudo element within the span container. */
    display: inline-block;
    align: center;
    vertical-align: middle;

    /* to customize font size of icon we can wrap component in an element with desired font-size configured */
    font-size: inherit;
    font-style: normal;

    pointer-events: none;

    &.rotate {
      transform: rotate(90deg);
    }
  }

  .icon::before {
    content: attr(data-content);
  }
</style>
