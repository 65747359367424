import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum FullyPaidSecuritiesLendingRequestStatus {
  CANCELED = 'Canceled',
  ERROR = 'Error',
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  REJECTED = 'Rejected'
}

export enum FullyPaidSecuritiesLendingRequestDirection {
  OPT_IN = 'Opt In',
  OPT_OUT = 'Opt Out'
}

export class FullyPaidSecuritiesLendingRequest extends RestResource {
  accountNumber = ''
  createdAt = null
  direction = FullyPaidSecuritiesLendingRequestDirection.OPT_IN
  status = FullyPaidSecuritiesLendingRequestStatus.PENDING
  updatedAt = null

  get hasRequestedOptIn() {
    return FullyPaidSecuritiesLendingRequestDirection.OPT_IN === this.direction
  }

  get isComplete() {
    return FullyPaidSecuritiesLendingRequestStatus.COMPLETE === this.status
  }

  get isFullyOptedIn() {
    return this.hasRequestedOptIn && this.isComplete
  }
}

export const FULLY_PAID_SECURITIES_LENDING_REQUEST_DESER: SchemaDeSer<FullyPaidSecuritiesLendingRequest> =
  new SchemaDeSerBuilder(FullyPaidSecuritiesLendingRequest)
    .ofString('accountNumber')
    .ofDateTime('createdAt')
    .ofString('direction')
    .ofString('id')
    .ofString('status')
    .ofDateTime('updatedAt')
    .toDeSer()
