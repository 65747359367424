import { SchemaDeSerBuilder } from '../../tastyworks/deser'
import type { Params } from '../request'

export interface AdminOrderParams extends Params {
  startDate?: string
  endDate?: string
  underlyingSymbol?: string
  status?: string[]
  receivedAt?: string
  perPage?: number
}

export class orderEvent {
  orderId = ''
  occurredAt = ''
  eventName = ''
  eventValue = ''
}

export const ORDER_EVENT_DESER = new SchemaDeSerBuilder(orderEvent)
  .ofString('orderId')
  .ofString('occurredAt')
  .ofString('eventName')
  .ofString('eventValue')

  .toDeSer()

export const ORDER_EVENT_PARSER = ORDER_EVENT_DESER.toParser(orderEvent)
