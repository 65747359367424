import {
  FULLY_PAID_SECURITIES_LENDING_REQUEST_DESER,
  FullyPaidSecuritiesLendingRequest
} from '../../tastyworks/fully-paid-securities-lending-request'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'

export class FullyPaidSecuritiesLendingRequestService {
  private readonly client: HttpClient

  constructor(accountNumberClient: HttpClient) {
    this.client = accountNumberClient.nested(
      'fully-paid-securities-lending-requests'
    )
  }

  readonly showLatest = async (
    accountNumber: string
  ): Promise<SingleResponse<FullyPaidSecuritiesLendingRequest>> =>
    this.client.show(
      new FullyPaidSecuritiesLendingRequest(),
      FULLY_PAID_SECURITIES_LENDING_REQUEST_DESER.update,
      'latest',
      { accountNumber }
    )

  readonly create = async (
    fullyPaidSecuritiesLendingRequest: FullyPaidSecuritiesLendingRequest
  ): Promise<SingleResponse<FullyPaidSecuritiesLendingRequest>> =>
    this.client.create(
      fullyPaidSecuritiesLendingRequest,
      FULLY_PAID_SECURITIES_LENDING_REQUEST_DESER.serialize,
      FULLY_PAID_SECURITIES_LENDING_REQUEST_DESER.update,
      '',
      { accountNumber: fullyPaidSecuritiesLendingRequest.accountNumber }
    )

  readonly show = async (
    accountNumber: string,
    id: number
  ): Promise<SingleResponse<FullyPaidSecuritiesLendingRequest>> => {
    const fullyPaidSecuritiesLendingRequest =
      new FullyPaidSecuritiesLendingRequest()
    return this.client.show(
      fullyPaidSecuritiesLendingRequest,
      FULLY_PAID_SECURITIES_LENDING_REQUEST_DESER.update,
      ':id',
      { accountNumber, id }
    )
  }
}
