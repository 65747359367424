import type { PLATFORM_NAMES } from '../../tastyworks/cash-management'
import {
  PLAID_LINK_TOKEN_DESER,
  PlaidLinkToken
} from '../../tastyworks/cash-management'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'

export class AcatLinkTokenService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly create = async (
    platform: PLATFORM_NAMES
  ): Promise<SingleResponse<PlaidLinkToken>> =>
    this.customerMeClient.create(
      new PlaidLinkToken(),
      PLAID_LINK_TOKEN_DESER.serialize,
      PLAID_LINK_TOKEN_DESER.update,
      'acat-link-token',
      { 'platform-name': platform }
    )
}
