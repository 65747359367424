import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class SupportedDigitalAsset {
  symbol = ''
}

export const SUPPORTED_DIGITAL_ASSET_DESER: SchemaDeSer<SupportedDigitalAsset> =
  new SchemaDeSerBuilder(SupportedDigitalAsset).ofString('symbol').toDeSer()

export class SupportedWithdrawalAsset {
  customerAddressAsset = ''
  supportedWithdrawalAssets = []
}

export const SUPPORTED_WITHDRAWAL_ASSET_DESER: SchemaDeSer<SupportedWithdrawalAsset> =
  new SchemaDeSerBuilder(SupportedWithdrawalAsset)
    .ofString('customerAddressAsset')
    .ofArray(
      'supportedWithdrawalAssets',
      SUPPORTED_DIGITAL_ASSET_DESER,
      SupportedDigitalAsset
    )
    .toDeSer()
