<script context="module">
  import goog from "/@lib/boulangerie"

  const BracketOrderPreferences = goog.module.get(
    "com.dough.preference.trading.BracketOrderPreferences"
  )
  const PreferenceUtil = goog.module.get("com.dough.preference.PreferenceUtil")
</script>

<script>
  import { appSession } from "/@lib/boulangerie"

  import TradeAssetType from "./partial/TradeAssetType.svelte"

  const { tradingPreferences } = appSession
  const { bracketOrderStock } = tradingPreferences

  const defaultQuantity = {
    label: "Default stock order quantity",
    options: PreferenceUtil.STOCK_ORDER_QUANTITIES.jsArray,
    preferenceField: tradingPreferences.defaultStockOrderQuantityField(),
  }
  const quantityIncrement = {
    label: "Stock order quantity increment",
    options: PreferenceUtil.STOCK_ORDER_QUANTITY_INCREMENTS.jsArray,
    preferenceField: tradingPreferences.stockOrderQuantityIncrementField(),
  }
  const maxQuantity = {
    label: "Stock order maximum quantity",
    options: PreferenceUtil.STOCK_ORDER_MAX_QUANTITIES.jsArray,
    preferenceField: tradingPreferences.stockOrderMaxQuantityField(),
  }
  const bracketProfitTarget = {
    label: "Default stock bracket order profit target %",
    options: BracketOrderPreferences.PERCENT_INCREMENTS.jsArray,
    preferenceField: bracketOrderStock.profitTargetPercentField(),
  }
  const bracketStopLoss = {
    label: "Default stock bracket order stop loss %",
    options: BracketOrderPreferences.PERCENT_INCREMENTS.jsArray,
    preferenceField: bracketOrderStock.stopLossPercentField(),
  }
</script>

<TradeAssetType
  class="trade-stocks"
  {defaultQuantity}
  {quantityIncrement}
  {maxQuantity}
  {bracketProfitTarget}
  {bracketStopLoss}
/>
