import _ from 'lodash'

export function dasherize(target: string): string {
  // prettier-ignore
  return target
    .replace(/([A-Z])/g, (_match, p1: string, _offset, _whole) => `-${p1.toLowerCase()}`)
    .replace(/\s/g, '-')
}

export function capitalize(target: string): string {
  return target.charAt(0).toUpperCase() + target.slice(1).toLowerCase()
}

export function titleize(target: string): string {
  return target.split(' ').map(capitalize).join(' ')
}

export function isNilOrEmpty(value: any) {
  return _.isNil(value) || _.isEmpty(value)
}
