<script context="module" lang="ts">
  import { px } from "/@/util/style-dimensions"

  /** Best-effort move an HTMLElement into a viewport's defined boundaries. */
  export function moveIntoViewport(
    elem: HTMLElement,
    viewportHeight: number,
    viewportWidth: number
  ) {
    if (!elem) return

    const { bottom, left, right, top } = elem.getBoundingClientRect()

    let translateX = 0
    let translateY = 0

    if (bottom > viewportHeight) {
      translateY = viewportHeight - bottom
    } else if (top < 0) {
      translateY = -1 * top
    }

    if (right > viewportWidth) {
      translateX = viewportWidth - right
    } else if (left < 0) {
      translateX = -1 * left
    }

    return `translate(${px(translateX)}, ${px(translateY)})`
  }
</script>

<script lang="ts">
  import { createEventDispatcher, tick } from "svelte"
  import clickOutside from "/@/util/click-outside"

  // CSS inset. Slightly easier than dealing with height, width, x, y.
  export let inset: string

  // Out-bindings.
  export let offsetHeight = 0
  export let offsetWidth = 0
  export let windowHeight = 0
  export let windowWidth = 0

  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()

  let transform = ""

  async function move(..._unusedReactiveArgs: any) {
    // Reset any existing translation due to child-panel reuse.
    transform = "translate(0px, 0px)"
    // Wait for other adjustments.
    await tick()
    transform = moveIntoViewport(rootElem, windowHeight, windowWidth)
  }

  $: move(rootElem, windowHeight, windowWidth, inset)

  function cancel(..._unusedArgs: any) {
    dispatch("close", null)
  }

  let rootElem: HTMLDivElement

  function scrolled(event: Event) {
    // XXX: If something _outside_ the popover scrolls, dismiss the popover.
    //      This is pretty much a hack since popovers can't really be anchored
    //      in any sane fashion due to how they're created. Like resizing, we
    //      sort of just have to dismiss them if/when a container scrolls.
    // YYY: optional chain to prevent crash if scroll event is fired before rootElem is bound
    if (!rootElem?.contains(event.target as Node)) {
      cancel()
    }
  }
</script>

<svelte:window
  bind:innerHeight={windowHeight}
  bind:innerWidth={windowWidth}
  on:resize={cancel}
  on:scroll|capture={scrolled}
/>

<div
  class="popover fixed {cssClass}"
  bind:this={rootElem}
  bind:offsetHeight
  bind:offsetWidth
  style:inset
  style:transform
  style:z-index="var(--popover-z-index)"
  on:click-outside={cancel}
  use:clickOutside={true}
>
  <slot />
</div>
