<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import userAnalytics, { UserAnalyticsTag } from "/@/util/user-analytics"

  export let checked: boolean
  export let disabled = false
  export let label: string | null = null
  export let userAnalyticsTag: UserAnalyticsTag = null
  let cssClass = ""
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }

  const dispatch = createEventDispatcher()

  function toggle() {
    if (disabled) return

    checked = !checked // Supports binding.
    dispatch("click", checked)
  }
</script>

<div
  role="checkbox"
  aria-checked={checked}
  class="
    icon-checkbox
    flex
    flex-row
    items-center
    gap-extra-small
    {cssClass}"
  class:checked
  class:disabled
  style={cssStyle}
  use:userAnalytics={userAnalyticsTag}
>
  <button
    type="button"
    class="
      icon
      inline-flex
      cursor-pointer
      items-center
      justify-center"
    class:tw-icon-Checkbox-Checked={checked}
    class:tw-icon-Checkbox-Unchecked={!checked}
    on:click|stopPropagation={toggle}
  />
  <slot click={toggle}>
    {#if label}
      <button
        type="button"
        class="label font-small-500 cursor-pointer"
        class:text-general-default-text={checked}
        class:text-general-secondary-text={!checked}
        on:click|stopPropagation={toggle}
      >
        {label}
      </button>
    {/if}
  </slot>
</div>
