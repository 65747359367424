import { JsonHelper } from '../../tastyworks'
import type { Parser } from '../../tastyworks/deser'
import { type Creator } from '../../tastyworks/util/meta-prog'
import type { Params } from '../request'

export class AdminNotes {
  id = 0
  accountNumber = ''
  body = ''
  createdAt: Date | null = null
  createdBy = ''
  updatedBy = ''
  noteType = ''
  subject = ''
  subType = ''
  updatedAt: Date | null = null
}

const ADMIN_NOTES_DESER = {
  update: (target: AdminNotes, helper: JsonHelper): void => {
    target.id = helper.getInt('id')
    target.accountNumber = helper.getString('account-number')
    target.body = helper.getString('body')
    target.createdAt = helper.getDateTime('created-at')
    target.createdBy = helper.getString('created-by')
    target.updatedBy = helper.getString('updated-by')
    target.noteType = helper.getString('note-type')
    target.subject = helper.getString('subject')
    target.updatedAt = helper.getDateTime('updated-at')
    target.subType = helper.getString('note-subtype')
  },
  toParser: (creator: Creator<AdminNotes>): Parser<AdminNotes> => {
    return (helper: JsonHelper): AdminNotes => {
      const result = new creator()
      ADMIN_NOTES_DESER.update(result, helper)
      return result
    }
  }
}

export const ADMIN_NOTES_DESER_PARSER = ADMIN_NOTES_DESER.toParser(AdminNotes)
export interface notesParams extends Params {
  subject: string
  type: string
  subType: string
  body: string
}
