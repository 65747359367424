<script lang="ts">
  import { LoadingOverlay } from "/@/control"
  import { PromiseHashValue } from "/@/util/promise-hash"

  // eslint-disable-next-line no-undef
  type T = $$Generic<SuperType>

  export let promiseHash: PromiseHashValue<T>
  let cssClass = ""
  export { cssClass as class }
</script>

{#if promiseHash}
  {#await promiseHash}
    <div class="response-loading {cssClass}">
      <slot name="loading">
        <LoadingOverlay />
      </slot>
    </div>
  {:then responseHash}
    <div class="response-loaded {cssClass}">
      <slot {responseHash} />
    </div>
  {:catch error}
    <div class="promise-error {cssClass}">
      <slot name="promiseError">
        <p>{error}</p>
      </slot>
    </div>
  {/await}
{/if}
