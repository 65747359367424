import { OPTIONS_LEVEL } from "@tastyworks/tastyworks-api"

export interface PlanDetail {
  detail: string
  enabled: boolean
}

export interface PlanDetailOption {
  value: string
  disabled: boolean
}

// TODO: [KT] fetch plan details using content stack
export const PLAN_DETAILS = {
  [OPTIONS_LEVEL.COVERED_AND_CASH_SECURED]: [
    { detail: "Buy Stock", enabled: true },
    { detail: "Short Sell Stock", enabled: false },
    { detail: "Buy Options", enabled: true },
    { detail: "Sell Covered Calls", enabled: true },
    { detail: "Sell Cash-Secured Puts", enabled: true },
    { detail: "Defined-Risk Spreads", enabled: false },
    { detail: "Sell Naked Calls", enabled: false },
    { detail: "Sell Naked Puts", enabled: false },
    { detail: "Futures", enabled: false },
  ],
  [OPTIONS_LEVEL.DEFINED_RISK]: [
    { detail: "Buy Stock", enabled: true },
    { detail: "Short Sell Stock", enabled: false },
    { detail: "Buy Options", enabled: true },
    { detail: "Sell Covered Calls", enabled: true },
    { detail: "Sell Cash-Secured Puts", enabled: false },
    { detail: "Defined-Risk Spreads", enabled: true },
    { detail: "Sell Naked Calls", enabled: false },
    { detail: "Sell Naked Puts", enabled: true },
    { detail: "Futures", enabled: false },
  ],
  [OPTIONS_LEVEL.DEFINED_RISK_PLUS_NAKED]: [
    { detail: "Buy Stock", enabled: true },
    { detail: "Short Sell Stock", enabled: false },
    { detail: "Buy Options", enabled: true },
    { detail: "Sell Covered Calls", enabled: true },
    { detail: "Sell Cash-Secured Puts", enabled: true },
    { detail: "Defined-Risk Spreads", enabled: true },
    { detail: "Sell Naked Calls", enabled: true },
    { detail: "Sell Naked Puts", enabled: false },
    { detail: "Futures", enabled: true },
  ],
  [OPTIONS_LEVEL.NO_RESTRICTIONS]: [
    { detail: "Buy Stock", enabled: true },
    { detail: "Short Sell Stock", enabled: true },
    { detail: "Buy Options", enabled: true },
    { detail: "Sell Covered Calls", enabled: true },
    { detail: "Sell Cash-Secured Puts", enabled: false },
    { detail: "Defined-Risk Spreads", enabled: true },
    { detail: "Sell Naked Calls", enabled: true },
    { detail: "Sell Naked Puts", enabled: true },
    { detail: "Futures", enabled: true },
  ],
}
