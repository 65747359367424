import type { ItemsResponse, SingleResponse } from '../../tastyworks'
import { Customer, CUSTOMER_DESER } from '../../tastyworks'
import {
  customerIdParam,
  REQUIRED_DOCUMENT_DESER,
  RequiredDocument,
  SUPPORTING_DOCUMENT_DESER,
  SupportingDocument
} from '../../tastyworks/customer'
import type HttpClient from '../../tastyworks/http'

export class CustomerService {
  constructor(
    private readonly baseHttpClient: HttpClient,
    private readonly customerMeClient: HttpClient
  ) {}

  readonly create = async (
    customer: Customer
  ): Promise<SingleResponse<Customer>> =>
    this.baseHttpClient.create(
      customer,
      CUSTOMER_DESER.serialize,
      CUSTOMER_DESER.update,
      'customers'
    )

  readonly show = async (
    customerId?: number
  ): Promise<SingleResponse<Customer>> =>
    this.customerMeClient.show(new Customer(), CUSTOMER_DESER.update, '', {
      customerId
    })

  readonly update = async (
    customer: Customer
  ): Promise<SingleResponse<Customer>> =>
    this.customerMeClient.update(
      customer,
      CUSTOMER_DESER.serialize,
      CUSTOMER_DESER.update
    )

  readonly getRequiredIdentityDocuments = async (): Promise<
    ItemsResponse<RequiredDocument>
  > =>
    this.customerMeClient.indexSimple(
      REQUIRED_DOCUMENT_DESER.toParser(RequiredDocument),
      '/required-identity-documents',
      customerIdParam(this.customerMeClient.session)
    )

  readonly getSupportingDocuments = async (): Promise<
    ItemsResponse<SupportingDocument>
  > =>
    this.customerMeClient.indexSimple(
      SUPPORTING_DOCUMENT_DESER.toParser(SupportingDocument),
      '/supporting-documents',
      customerIdParam(this.customerMeClient.session)
    )
}
