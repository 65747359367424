import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class ExternalAffiliateCommission {
  accountExternalId = ''
  fundedAt: Date | null = null
  uploadedAt: Date | null = null
  userId = 0
  siteId = ''
}

export const EXTERNAL_AFFILIATE_COMMISSION_DESER: SchemaDeSer<ExternalAffiliateCommission> =
  new SchemaDeSerBuilder(ExternalAffiliateCommission)
    .ofString('accountExternalId')
    .ofDateTime('fundedAt')
    .ofDateTime('uploadedAt')
    .ofInt('userId')
    .ofString('siteId')
    .toDeSer()
