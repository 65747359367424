import { boolean, object, string } from 'yup'
import { buildSchema } from './util/build-schema'
import { isValidPhoneNumber } from './util/phone-number'

const schema = object({
  email: string().email().required(),
  familyMemberNames: string(),
  firstName: string().required(),
  hasIndustryAffiliation: boolean().required(),
  hasInstitutionalAssets: boolean().required(),
  hasListedAffiliation: boolean().required(),
  hasPoliticalAffiliation: boolean().required(),
  homePhoneNumber: string().test('validPhoneNumber', isValidPhoneNumber),
  industryAffiliationFirm: string().when('hasIndustryAffiliation', {
    is: true,
    then: fieldSchema => fieldSchema.required()
  }),
  isInvestmentAdviser: boolean().required(),
  lastName: string().required(),
  listedAffiliationSymbol: string().when('hasListedAffiliation', {
    is: true,
    then: fieldSchema => fieldSchema.required()
  }),
  middleName: string(),
  mobilePhoneNumber: string(),
  prefixName: string(),
  politicalOrganization: string().when('hasPoliticalAffiliation', {
    is: true,
    then: fieldSchema => fieldSchema.required()
  }),
  subjectToTaxWithholding: boolean().required(),
  suffixName: string(),
  taxNumber: string(),
  workPhoneNumber: string().test('validPhoneNumber', isValidPhoneNumber)
})

export const customerSchema = buildSchema('Customer', schema)
