import { number, object, string } from 'yup'
import { modelPortfolioSchema } from './model-portfolio'
import { bigNumber } from './schema/big-number'
import { buildSchema } from './util/build-schema'

const schema = object({
  accountNumber: string(),
  buyingPowerPercentage: bigNumber(),
  contractQuantity: number(),
  dollarAmount: bigNumber(),
  modelPortfolio: modelPortfolioSchema,
  unitCount: number()
})

export const accountPortfolioSubscriptionSchema = buildSchema(
  'AccountPortfolioSubscription',
  schema
)
