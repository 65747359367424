import { date, number } from "svelte-i18n"
import { derived } from "svelte/store"

const ABBREVIATED_MONTH_DAY: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "short",
}

const NUMERIC_DATE: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
}

const LONG_MONTH_DAY: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
}

const LONG_MONTH_DAY_YEAR: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "long",
  year: "numeric",
}

const DATE_TIME = {
  ABBREVIATED_MONTH_DAY,
  NUMERIC_DATE,
  LONG_MONTH_DAY,
  LONG_MONTH_DAY_YEAR,
}

const CURRENCY_USD: Intl.NumberFormatOptions = {
  currency: "USD",
  style: "currency",
}

const NUMBER = {
  CURRENCY_USD,
}

export const usd = derived(
  number,
  ($number) => (amount) => $number(amount, NUMBER.CURRENCY_USD)
)

export const abbreviatedMonthDay = derived(
  date,
  ($date) => (date_instance: Date) =>
    $date(date_instance, DATE_TIME.ABBREVIATED_MONTH_DAY)
)

export const numericDate = derived(
  date,
  ($date) => (date_instance: Date) =>
    $date(date_instance, DATE_TIME.NUMERIC_DATE)
)

export const longMonthDay = derived(
  date,
  ($date) => (date_instance: Date) =>
    $date(date_instance, DATE_TIME.LONG_MONTH_DAY)
)

export const longMonthDayYear = derived(
  date,
  ($date) => (date_instance: Date) =>
    $date(date_instance, DATE_TIME.LONG_MONTH_DAY_YEAR)
)
