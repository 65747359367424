import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { IRA_DETAIL_DESER, IraDetail } from './ira-detail'

export enum INTERNAL_CASH_TRANSFER_STATUSES {
  CANCEL_REQUESTED = 'Cancel Requested',
  CLEARING_CANCELED = 'Clearing Canceled',
  CLEARING_COMPLETED = 'Clearing Completed',
  CLEARING_ERROR = 'Clearing Error',
  CLEARING_REJECTED = 'Clearing Rejected',
  PENDING_SUBMISSION = 'Pending Submission to Clearing',
  SUBMITTED_TO_CLEARING = 'Submitted to Clearing'
}

export class InternalCashTransfer extends RestResource {
  accountNumber = ''
  amount = 0
  createdAt?: Date
  destinationAccountNumber = ''
  iraDetail: IraDetail | null = null
  isCancelable = false
  status = INTERNAL_CASH_TRANSFER_STATUSES.PENDING_SUBMISSION
}

export class InternalCashTransferEligibility {
  isEligible = false
  reason = ''
}

export const INTERNAL_CASH_TRANSFER_DESER: SchemaDeSer<InternalCashTransfer> =
  new SchemaDeSerBuilder(InternalCashTransfer)
    .ofString('id')
    .ofString('accountNumber')
    .ofFloat('amount')
    .ofDateTime('createdAt')
    .ofString('destinationAccountNumber')
    .ofNested('iraDetail', IRA_DETAIL_DESER, IraDetail)
    .ofBoolean('isCancelable')
    .ofString('status')
    .toDeSer()

export const INTERNAL_CASH_TRANSFER_ELIGIBILITY_DESER: SchemaDeSer<InternalCashTransferEligibility> =
  new SchemaDeSerBuilder(InternalCashTransferEligibility)
    .ofBoolean('isEligible')
    .ofString('reason')
    .toDeSer()
