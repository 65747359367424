<script>
  import PopoverDropdown from "/@/layout/popover/PopoverDropdown.svelte"
  import { themes, userPreferredThemeStore } from "/@/theme.js"
</script>

<div class="theme-field flex flex-col gap-double-extra-small">
  <span class="font-x-small-600-caps text-general-primary-label"> theme </span>
  <PopoverDropdown
    options={Object.values(themes)}
    value={themes[$userPreferredThemeStore]}
    on:change={({ detail }) => ($userPreferredThemeStore = detail.name)}
  />
</div>
