import { CustomerAccountAuthorityLevels } from './account'
import { RestResource } from './common'
import { Customer, CUSTOMER_DESER } from './customer'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class CustomerAuthority extends RestResource {
  authorityLevel = ''
  customer = new Customer()

  get isOwner() {
    return this.authorityLevel === CustomerAccountAuthorityLevels.OWNER
  }
}

export const CUSTOMER_AUTHORITY_DESER: SchemaDeSer<CustomerAuthority> =
  new SchemaDeSerBuilder(CustomerAuthority)
    .ofString('id')
    .ofString('authorityLevel')
    .ofNested('customer', CUSTOMER_DESER, Customer)
    .toDeSer()
