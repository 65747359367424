import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class DigitalAssetClearingAddress extends RestResource {
  accountNumber = ''
  address = ''
  asset = ''
}

export const DIGITAL_ASSET_CLEARING_ADDRESS_DESER: SchemaDeSer<DigitalAssetClearingAddress> =
  new SchemaDeSerBuilder(DigitalAssetClearingAddress)
    .ofString('accountNumber')
    .ofString('address')
    .ofString('asset')
    .toDeSer()
