import {
  DXLinkConnectionState,
  DXLinkFeed,
  DXLinkWebSocketClient,
  FeedContract,
} from "@dxfeed/dxlink-api"

import goog from "@tastyworks/boulangerie-bundle"
import { dxLinkMessageFactory } from "/@lib/boulangerie"

const DxEventType = goog.module.get("com.dough.dx.net.DxEventType")
const DxFeedNativeStreamer = goog.module.get(
  "com.dough.dx.net.DxFeedNativeStreamer"
)
const DxLinkAggregationPeriod = goog.module.get(
  "com.dough.dx.net.DxLinkAggregationPeriod"
)
const DXSymbolUtil = goog.module.get("com.dough.dx.util.DXSymbolUtil")
const SocketStateSupport_State = goog.module.get(
  "com.dough.net.SocketStateSupport.State"
)
const RequestState = goog.module.get("com.dough.util.RequestState")
const DxEventUtil = goog.module.get("tasty.js.dx.DxEventUtil")

export class WebDxFeedNativeStreamer extends DxFeedNativeStreamer {
  constructor() {
    super()

    this.client = null
    this.candleFeed = null
    this.marketFeed = null

    this.url = null
    this.token = null

    // Process these once the connection comes up.
    this.queuedMarketSubscriptions = []

    this.connectionStateListeners = []
    this.dxConnectionStateListener = (newState, _unusedOldState) => {
      const socketState = dxLinkToSocketState(newState)
      this.connectionStateListeners.forEach((lsnr) => lsnr.changed(socketState))

      // XXX: this.marketFeed can't really be null here by definition, but
      //      exercise an overabundance of caution, anyway.
      if (
        DXLinkConnectionState.CONNECTED === newState &&
        this.queuedMarketSubscriptions.length &&
        !!this.marketFeed
      ) {
        this.marketFeed.addSubscriptions(this.queuedMarketSubscriptions)
        this.queuedMarketFeedSubscriptions = []
      }
    }

    this.candleEventListener = null
    this.dxCandleEventListener = (events) => {
      if (!this.candleEventListener) {
        return
      }

      this.candleEventListener.handle(DxEventUtil.toCandleEvents(...events))
    }

    this.marketEventListener = null
    this.dxMarketEventsListener = (events) => {
      if (!this.marketEventListener) {
        return
      }

      for (const event of events) {
        const adapter = DxEventUtil.toMarketEventAdapter(event)
        if (!adapter) {
          continue
        }
        this.marketEventListener.handle(adapter)
      }
    }
  }

  connect(url, token = null) {
    this.url = url
    this.token = token

    this.client = new DXLinkWebSocketClient()
    this.client.connect(this.url)
    if (this.token) {
      this.client.setAuthToken(token)
    }

    // Candle data only.
    const candleFeedConfig = dxLinkMessageFactory.createFeedConfiguration(
      DxLinkAggregationPeriod.FASTEST,
      DxEventType.Candle
    ).jsonSource

    this.candleFeed = new DXLinkFeed(this.client, FeedContract.AUTO)
    this.candleFeed.addEventListener(this.dxCandleEventListener)
    this.candleFeed.configure(candleFeedConfig)

    // Every other event-type.
    const marketFeedConfig = dxLinkMessageFactory.createFeedConfiguration(
      DxLinkAggregationPeriod.FASTEST,
      ...DxEventType.values().filter((t) => DxEventType.Candle !== t)
    ).jsonSource

    this.marketFeed = new DXLinkFeed(this.client, FeedContract.AUTO)
    this.marketFeed.addEventListener(this.dxMarketEventsListener)
    this.marketFeed.configure(marketFeedConfig)

    // XXX: Last so feeds are setup when connection is signalled open.
    this.client.addConnectionStateChangeListener(this.dxConnectionStateListener)

    return RequestState.success()
  }

  close() {
    this.candleFeed?.close()
    this.candleFeed = null

    this.marketFeed?.close()
    this.marketFeed = null

    this.client?.disconnect()
    this.client?.removeConnectionStateChangeListener(
      this.dxConnectionStateListener
    )
    this.client = null

    return RequestState.success()
  }

  reconnect() {
    if (!this.client) {
      return RequestState.error("Unable to reconnect, streamer is closed.")
    }

    this.client.reconnect()
    return RequestState.success()
  }

  pause() {
    if (!this.client) {
      return RequestState.error("Unable to close, streamer is closed.")
    }

    this.client.disconnect()
    return RequestState.success()
  }

  isClosed() {
    return DXLinkConnectionState.CONNECTED !== this.client?.getConnectionState()
  }

  addConnectionStateListener(listener) {
    this.connectionStateListeners.push(listener)
    return listener
  }

  removeConnectionStateListener(listener) {
    const index = this.connectionStateListeners.indexOf(listener)
    if (-1 === index) {
      return false
    }
    this.connectionStateListeners.splice(index, 1)
    return true
  }

  setCandleEventListener(listener) {
    this.candleEventListener = listener
  }

  setMarketEventListener(listener) {
    this.marketEventListener = listener
  }

  subscribeToStockData(symbols) {
    return this.addMarketSubscriptions([
      ...createUnderlyingSubscriptions(symbols),
      ...createIvrSubscriptions(symbols),
    ])
  }

  unsubscribeFromStockData(symbols) {
    this.marketFeed.removeSubscriptions([
      ...createUnderlyingSubscriptions(symbols),
      ...createIvrSubscriptions(symbols),
    ])
    return RequestState.success()
  }

  subscribeToOptionData(symbols) {
    return this.addMarketSubscriptions(createOptionsSubscriptions(symbols))
  }

  unsubscribeFromOptionData(symbols) {
    this.marketFeed.removeSubscriptions(createOptionsSubscriptions(symbols))
    return RequestState.success()
  }

  subscribeToFuturesData(symbols) {
    return this.addMarketSubscriptions(createUnderlyingSubscriptions(symbols))
  }

  unsubscribeFromFuturesData(symbols) {
    this.marketFeed.removeSubscriptions(createUnderlyingSubscriptions(symbols))
    return RequestState.success()
  }

  subscribeToCryptoData(symbols) {
    return this.addMarketSubscriptions(createUnderlyingSubscriptions(symbols))
  }

  unsubscribeFromCryptoData(symbols) {
    this.marketFeed.removeSubscriptions(createUnderlyingSubscriptions(symbols))
    return RequestState.success()
  }

  addMarketSubscriptions(subs) {
    if (!this.marketFeed) {
      this.queuedMarketSubscriptions.push(...subs)
    } else {
      this.marketFeed.addSubscriptions(subs)
    }
    return RequestState.success()
  }

  subscribeToCandleData(symbol, fromTime) {
    this.candleFeed.addSubscriptions({
      type: DxEventType.Candle.name(),
      symbol,
      fromTime: fromTime.toNumber(), // java.lang.Long.
    })
    return RequestState.success()
  }

  unsubscribeFromCandleData(symbol, fromTime) {
    this.candleFeed.removeSubscriptions({
      type: DxEventType.Candle.name(),
      symbol,
      fromTime: fromTime.toNumber(), // java.lang.Long.
    })
    return RequestState.success()
  }
}

function dxLinkToSocketState(dxLinkState) {
  if (null === dxLinkState) {
    return null
  }

  switch (dxLinkState) {
    case DXLinkConnectionState.CONNECTED:
      return SocketStateSupport_State.OPEN
    case DXLinkConnectionState.CONNECTING:
      return SocketStateSupport_State.NONE
    case DXLinkConnectionState.NOT_CONNECTED:
      return SocketStateSupport_State.CLOSE
    default:
      throw new Error("Unknown state:", dxLinkState)
  }
}

// XXX: Order matters in javascript-land -- subscriptions are generally
//      first-serviced in the order they are subscribed. This reduces the
//      likelihood of issues in curve-mode rendering. Write-up in ATP-1067.
const UNDERLYING_EVENT_TYPES = [
  DxEventType.Trade,
  DxEventType.TradeETH,
  DxEventType.Quote,
  DxEventType.Greeks,
  DxEventType.Profile,
  DxEventType.Summary,
]

function createUnderlyingSubscriptions(symbols) {
  return createSubscriptions(symbols, UNDERLYING_EVENT_TYPES)
}

const IVR_EVENT_TYPES = [DxEventType.Trade, DxEventType.TradeETH]

function createIvrSubscriptions(symbols) {
  const ivrSymbols = DXSymbolUtil.toIvrSymbols(symbols)
  return createSubscriptions(ivrSymbols, IVR_EVENT_TYPES)
}

// XXX: Order matters in javascript-land -- subscriptions are generally
//      first-serviced in the order they are subscribed. This reduces the
//      likelihood of issues in curve-mode rendering. Write-up in ATP-1067.
const OPTION_EVENT_TYPES = [
  DxEventType.Trade,
  DxEventType.TradeETH,
  DxEventType.Quote,
  DxEventType.Greeks,
  DxEventType.Summary,
  DxEventType.TheoPrice,
]

function createOptionsSubscriptions(symbols) {
  return createSubscriptions(symbols, OPTION_EVENT_TYPES)
}

function createSubscriptions(symbols, eventTypes) {
  const subscriptions = []
  for (const symbol of symbols) {
    subscriptions.push(...eventTypes.map((t) => ({ type: t.name(), symbol })))
  }
  return subscriptions
}
