import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class PromotionReward {
  promotionId = 0
  qualifiedAt = new Date()
  grantedAt = new Date()
  customerId = 0
  qualifiedAccount = ''
  amount = 0
  extRewardId = 0
  extRewardClass = ''
  promoCodeId = 0
  createdAt = new Date()
  updatedAt = new Date()
}

export const PROMOTION_REWARD_DESER: SchemaDeSer<PromotionReward> =
  new SchemaDeSerBuilder(PromotionReward)
    .ofInt('promotionId')
    .ofDateTime('qualifiedAt')
    .ofDateTime('grantedAt')
    .ofInt('customerId')
    .ofString('qualifiedAccount')
    .ofFloat('amount')
    .ofInt('extRewardId')
    .ofString('extRewardClass')
    .ofInt('promoCodeId')
    .ofDateTime('createdAt')
    .ofDateTime('updatedAt')
    .toDeSer()
