import type { FuturesTradingRequest, SingleResponse } from '../../tastyworks'
import { FUTURES_TRADING_REQUEST_DESER } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class FuturesTradingRequestService {
  constructor(private readonly baseHttpClient: HttpClient) {}

  readonly create = async (
    futuresTradingRequest: FuturesTradingRequest
  ): Promise<SingleResponse<FuturesTradingRequest>> =>
    this.baseHttpClient.create(
      futuresTradingRequest,
      FUTURES_TRADING_REQUEST_DESER.serialize,
      FUTURES_TRADING_REQUEST_DESER.update,
      '/futures-trading-requests'
    )
}
