import {
  CUSTOMER_AUTHORITY_DESER,
  CustomerAuthority
} from '../../tastyworks/customer-authority'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class CustomerAuthorityService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string
  ): Promise<ItemsResponse<CustomerAuthority>> =>
    this.accountNumberClient.indexSimple(
      CUSTOMER_AUTHORITY_DESER.toParser(CustomerAuthority),
      'authorities',
      { accountNumber }
    )
}
