<script>
  import { AlertSeverity, toastAlert } from "/@/control/alert/index.ts"

  import {
    subscribeWebPush,
    unsubscribeWebPush,
    webPushEnabled,
  } from "/@/trading/web-push"

  $: action = $webPushEnabled ? "Disable" : "Enable"

  $: disabled = false

  async function handleClick(_event) {
    disabled = true
    try {
      await ($webPushEnabled ? unsubscribeWebPush() : subscribeWebPush())
    } catch (error) {
      console.log("Failed to toggle web push", error)
      toastAlert(AlertSeverity.ERROR, error.errorMessage)
    } finally {
      disabled = false
    }
  }
</script>

<button
  type="button"
  class="button"
  on:click|stopPropagation={handleClick}
  {disabled}
>
  {action} Push Notifications
</button>
