<script context="module" lang="ts">
  const SEARCH_DEBOUNCE_TIMEOUT = 500
</script>

<script lang="ts">
  import { _ } from "svelte-i18n"
  import { AwaitHashResponse } from "/@/account-management/control"
  import {
    PlaidInstitution,
    PlaidInstitutionResult,
  } from "/@/account-management/control/institution-search"
  import { Field, Form } from "/@/account-management/form"
  import { createForm } from "/@/account-management/form/util"
  import { DebounceTask } from "/@/util/task"
  import { createEventDispatcher, onMount } from "svelte"
  import { object, string } from "yup"
  import { createPromiseHash } from "/@/util/promise-hash"
  import InstitutionGrid from "./InstitutionGrid.svelte"
  import InstitutionResultList from "./InstitutionResultList.svelte"

  export let emptyMessage: string
  export let noInstitutionFoundLabel: string
  export let defaultInstitutions: PlaidInstitution[]
  export let institutionResultsPromise: Promise<PlaidInstitutionResult[]> = null

  const dispatch = createEventDispatcher()

  const context = createForm({
    initialScope: {
      search: "",
    },
    onSubmit,
    validationSchema: object({
      search: string(),
    }),
  })

  const { handleSubmit, scope } = context

  const task = new DebounceTask(SEARCH_DEBOUNCE_TIMEOUT, handleSubmit)

  function onSubmit() {
    dispatch("didSearch", $scope.search)
  }

  let debouncePromise = Promise.resolve(null)

  function handleSearch() {
    debouncePromise = task.scheduleAsPromise()
  }

  $: isBlankSearch = institutionResultsPromise === null
  $: promiseHash = createPromiseHash({
    debounce: debouncePromise,
    institutionResults: institutionResultsPromise,
  })

  onMount(() => {
    dispatch("didMount")
  })
</script>

<div
  class="
    institution-search-form
    flex
    h-full
    w-full
    flex-col
    gap-y-small
    overflow-hidden
    rounded
    border
    border-solid
    border-general-default
    bg-general-secondary-surface
    p-extra-large"
>
  <Form {context}>
    <Field
      label=""
      name="search"
      placeholder={$_("content.institutionSearch.searchPlaceholder")}
      on:change={handleSearch}
    />
  </Form>

  <div class="flex flex-grow overflow-y-scroll">
    <AwaitHashResponse {promiseHash} let:responseHash>
      <p slot="loading">{$_("content.institutionSearch.searching")}</p>

      {#if isBlankSearch}
        <InstitutionGrid {defaultInstitutions} on:didSelectInstitution />
      {:else}
        <InstitutionResultList
          {emptyMessage}
          institutionResults={responseHash.institutionResults}
          on:didSelectInstitution
        />
      {/if}
    </AwaitHashResponse>
  </div>

  <!-- TODO: [KT] micro-deposit workflow -->
  <button class="button-link button hidden" type="button"
    >{noInstitutionFoundLabel}</button
  >
</div>
