import { BaseResponse, parseErrorContainer } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class PreferencesService {
  private readonly httpClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.httpClient = baseHttpClient
  }

  readonly update = async (body: any): Promise<BaseResponse> => {
    const helper = await this.httpClient.patch('/preferences', body)

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
