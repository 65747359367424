const DENIED = "denied"
const GRANTED = "granted"

export async function checkNotificationPermitted() {
  if (
    !("Notification" in window) ||
    Notification.permission === DENIED // don't ask again
  ) {
    return false
  }
  const permission = await Notification.requestPermission()
  return permission === GRANTED
}

// TODO: will need to add URL-referencable icons for Notifications.
// SVG might be able to process web-fonts, but that might be too much for the notifications API to handle
export async function notify({ title, message, icon }) {
  if (!("Notification" in window)) return
  if (
    Notification.permission === GRANTED ||
    (await checkNotificationPermitted())
  ) {
    new Notification(title, { body: message, icon })
  }
}
