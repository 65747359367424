import _ from 'lodash'
import { RestResource } from '../common'
import type { SchemaDeSer } from '../deser'
import { SchemaDeSerBuilder } from '../deser'

export enum FuturesTradingRequestStatus {
  PENDING_JOINT_AGREEMENT = 'Pending Joint Agreement',
  PENDING = 'Pending',
  NEEDS_REVIEW = 'Needs Review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  FAILED = 'Failed',
  COMPLETE = 'Complete'
}

const PENDING_STATUSES = [
  FuturesTradingRequestStatus.PENDING_JOINT_AGREEMENT,
  FuturesTradingRequestStatus.PENDING,
  FuturesTradingRequestStatus.NEEDS_REVIEW,
  FuturesTradingRequestStatus.APPROVED
]

export class FuturesTradingRequest extends RestResource {
  static onInitialize = (_obj: FuturesTradingRequest) => {
    /* no-op */
  }

  constructor() {
    super()
    FuturesTradingRequest.onInitialize(this)
  }

  accountNumber = ''
  requestingCustomerId = 0
  createdAt?: Date
  status = ''
  approvingUserId = 0
  approvedAt?: Date
  rejectingUserId = 0
  rejectedAt?: Date
  isPendingJointAgreement = false
  isRequestingCustomer = true

  get isFailedOrRejected() {
    return this.isFailed || this.isRejected
  }

  get isFailed() {
    return this.status === FuturesTradingRequestStatus.FAILED
  }

  get isRejected() {
    return this.status === FuturesTradingRequestStatus.REJECTED
  }

  get isComplete() {
    return this.status === FuturesTradingRequestStatus.COMPLETE
  }

  get isPendingCompletion() {
    return _.includes(PENDING_STATUSES, this.status)
  }

  get isPendingJoint() {
    return this.status === FuturesTradingRequestStatus.PENDING_JOINT_AGREEMENT
  }

  get needsSecondaryFuturesAgreement() {
    return this.isPendingJointAgreement && !this.isRequestingCustomer
  }
}

export const FUTURES_TRADING_REQUEST_DESER: SchemaDeSer<FuturesTradingRequest> =
  new SchemaDeSerBuilder(FuturesTradingRequest)
    .ofString('id')
    .ofString('accountNumber')
    .ofString('requestingCustomerId')
    .ofDateTime('createdAt')
    .ofString('status')
    .ofString('approvingUserId')
    .ofDateTime('approvedAt')
    .ofString('rejectingUserId')
    .ofDateTime('rejectedAt')
    .ofBoolean('isPendingJointAgreement')
    .ofBoolean('isRequestingCustomer')
    .toDeSer()

export class FuturesAgreementRecord {
  static onInitialize = (_obj: FuturesAgreementRecord) => {
    /* no-op */
  }

  constructor() {
    FuturesAgreementRecord.onInitialize(this)
  }

  isRegistrationExempt = false
  registrationExemption = ''
  isRegistered = false
  registrationMember = ''
  hasFuturesIndustryAffiliation = false
  futuresIndustryFirm = ''
  isSoliciting = false
  isNfaPool = false
}

export const FUTURES_AGREEMENT_RECORD_DESER: SchemaDeSer<FuturesAgreementRecord> =
  new SchemaDeSerBuilder(FuturesAgreementRecord)
    .ofBoolean('isRegistrationExempt')
    .ofString('registrationExemption')
    .ofBoolean('isRegistered')
    .ofString('registrationMember')
    .ofBoolean('hasFuturesIndustryAffiliation')
    .ofString('futuresIndustryFirm')
    .ofBoolean('isSoliciting')
    .ofBoolean('isNfaPool')
    .toDeSer()
