import { BaseResponse, parseErrorContainer } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class EmailReconfirmationService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly update = async (tokenString: string): Promise<BaseResponse> => {
    const helper = await this.customerMeClient.patch('/email-reconfirmation', {
      token: tokenString
    })

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
