export enum StreamerMessageType {
  Any = '*' /* get all messages */,
  AcatRequest = 'AcatRequest',
  AccountBalance = 'AccountBalance',
  AchRelationship = 'AchRelationship',
  BankingPreRegistration = 'BankingPreRegistration',
  CashAward = 'CashAward',
  CurrentPosition = 'CurrentPosition',
  CustomerAccountCreatedMessage = 'CustomerAccountCreatedMessage',
  DividendReinvestmentRequest = 'DividendReinvestmentRequest',
  ExternalInstitution = 'ExternalInstitution',
  ExternalTransaction = 'ExternalTransaction',
  FeatureEntitlement = 'FeatureEntitlement',
  FullyPaidSecuritiesLendingRequest = 'FullyPaidSecuritiesLendingRequest',
  Order = 'Order',
  PendingCashEntry = 'PendingCashEntry',
  StockAward = 'StockAward',
  TradingStatus = 'TradingStatus'
}
