<script context="module">
  import goog from "/@lib/boulangerie"
  const UiAccount = goog.module.get("tasty.ui.boule.UiAccount")
  const UiAccountDirection = goog.module.get(
    "tasty.ui.boule.UiAccountDirection"
  )
  const UiAccountPrivacyAware = goog.module.get(
    "tasty.ui.boule.UiAccountPrivacyAware"
  )
</script>

<script>
  import { slide } from "svelte/transition"
  import { BouleNumericDirectionText, BoulePrivacyAwareText } from "/@/boule"
  import { getCssPropertyValueNumeric } from "/@/util"

  export let account
  export let hidden = false

  const delay = getCssPropertyValueNumeric("--transition-left-sidebar-time")
  const duration = getCssPropertyValueNumeric(
    "--transition-trading-account-selector-item-body"
  )
</script>

<div
  class="trading-account-selector-item-body flex flex-col"
  class:hidden
  in:slide|global={{ duration, delay }}
>
  <div class="flex justify-between">
    <span
      class="net-liq-label single-line label font-x-small-400 text-general-secondary-text"
    >
      Net Liq
    </span>
    <BoulePrivacyAwareText
      class="net-liq font-x-small-600 text-general-default-text"
      source={account}
      field={UiAccountPrivacyAware.NET_LIQ}
    />
  </div>

  <div class="flex justify-between">
    <span
      class="cash-label single-line label font-x-small-400 text-general-secondary-text"
    >
      Cash
    </span>
    <BoulePrivacyAwareText
      class="cash-value font-x-small-600 text-general-default-text"
      source={account}
      field={UiAccount.CASH_VALUE}
    />
  </div>

  <div class="flex justify-between">
    <span
      class="day-gain-label single-line label font-x-small-400 text-general-secondary-text"
    >
      P/L Day
    </span>
    <BouleNumericDirectionText
      class="day-gain font-x-small-600"
      source={account}
      field={UiAccountDirection.DAY_GAIN}
    />
  </div>

  <div class="flex justify-between">
    <span
      class="year-gain-label single-line label font-x-small-400 text-general-secondary-text"
    >
      P/L YTD
    </span>
    <BouleNumericDirectionText
      class="year-gain font-x-small-600"
      source={account}
      field={UiAccountDirection.YEAR_GAIN}
    />
  </div>

  <div class="flex justify-between">
    <span
      class="option-buying-power-label single-line label font-x-small-400 text-general-secondary-text"
    >
      Option BP
    </span>
    <BoulePrivacyAwareText
      class="option-buying-power font-x-small-600 text-general-default-text"
      source={account}
      field={UiAccount.OPTION_BUYING_POWER}
    />
  </div>

  <div class="flex justify-between">
    <span
      class="stock-buying-power-label single-line label font-x-small-400 text-general-secondary-text"
    >
      Stock BP
    </span>
    <BoulePrivacyAwareText
      class="stock-buying-power font-x-small-600 text-general-default-text"
      source={account}
      field={UiAccount.STOCK_BUYING_POWER}
    />
  </div>
</div>

<style lang="postcss">
  .trading-account-selector-item-body {
    padding: var(--double-extra-small-spacing);
  }
</style>
