<script context="module" lang="ts">
  import {
    defaultBanks,
    PlaidProducts,
  } from "/@/account-management/control/institution-search"

  const defaultInstitutions = defaultBanks
  const products = [PlaidProducts.IDENTITY, PlaidProducts.AUTH]
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"
  import { PLATFORM_NAMES } from "@tastyworks/tastyworks-api"
  import { twApiClient } from "/@lib/tastyworks-rest"
  import {
    fetchInstitutions,
    LinkEvents,
  } from "/@/account-management/control/institution-search"
  import ModalDialog from "/@/layout/modal/ModalDialog.svelte"

  import InstitutionSearchForm from "./InstitutionSearchForm.svelte"

  export let show = false
  export let lightbox = true
  export let height = "calc(14 * var(--vertical-meter))"
  export let width = "calc(9 * var(--horizontal-meter))"

  const dispatch = createEventDispatcher()

  let institutionResultsPromise = null
  let isInitializingLink = false

  function cancel() {
    dispatch("close", null)
  }

  function manual() {
    // XXX: Sentinel value to indicate manual ACH.
    dispatch("close", false)
  }

  function success(publicToken: string, metadata: any) {
    dispatch("close", { metadata, publicToken })
  }

  function isOpen(eventName: string) {
    return eventName === LinkEvents.OPEN
  }

  async function showPlaidLink({ detail }) {
    isInitializingLink = true

    const response = await twApiClient.achLinkTokenService.create(
      PLATFORM_NAMES.WEB
    )

    Plaid.create({
      onEvent: (eventName: string) => {
        if (isOpen(eventName)) {
          isInitializingLink = false
        }
      },
      onExit: cancel,
      onSuccess: success,
      token: response.data.linkToken,
    }).open(detail)
  }

  function handleSearch({ detail }) {
    if (detail === "") {
      reset()
      return
    }

    institutionResultsPromise = fetchInstitutions(detail, products)
  }

  function reset() {
    institutionResultsPromise = null
  }
</script>

<ModalDialog
  class="bank-search-dialog"
  {show}
  {lightbox}
  {height}
  {width}
  on:click-outside={cancel}
>
  <svelte:fragment slot="title">
    <h3 class="font-x-large-600">
      {$_("content.institutionSearch.bank.title")}
    </h3>
  </svelte:fragment>

  <svelte:fragment slot="body">
    {#if isInitializingLink}
      <!-- NOTE: [KT] Plaid displays its own spinner after a short while. -->
      <div class="relative inset-y-1/2">
        <div class="flex animate-pulse justify-center">One moment...</div>
      </div>
    {:else}
      <InstitutionSearchForm
        emptyMessage={$_("content.institutionSearch.bank.emptyMessage")}
        noInstitutionFoundLabel={$_(
          "content.institutionSearch.bank.noInstitutionFound"
        )}
        {defaultInstitutions}
        {institutionResultsPromise}
        on:didSearch={handleSearch}
        on:didSelectInstitution={showPlaidLink}
        on:didMount={reset}
      />
      <div
        class="
          flex
          flex-row
          items-center
          justify-center"
      >
        <button
          type="button"
          class="
            manual-ach-link
            cursor-pointer
            underline"
          on:click={manual}
        >
          {$_("content.institutionSearch.bank.noInstitutionFound")}
        </button>
      </div>
    {/if}
  </svelte:fragment>
</ModalDialog>
