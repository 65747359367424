import _ from 'lodash'
import type HttpClient from '../../tastyworks/http'
import type { PaginationParams } from '../../tastyworks/request'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { parseSingleResponse } from '../../tastyworks/response'
import { JsonBuilder } from '../../tastyworks/util/json'
import { Withdrawal, WITHDRAWAL_DESER } from '../../tastyworks/withdrawal'
import type { WithdrawalDryRunnable } from '../../tastyworks/withdrawal-dry-run'
import {
  WITHDRAWAL_DRY_RUN_DESER,
  WITHDRAWAL_DRY_RUNNABLE_DESER,
  WithdrawalDryRun
} from '../../tastyworks/withdrawal-dry-run'

export class WithdrawalService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params?: PaginationParams
  ): Promise<ItemsResponse<Withdrawal>> =>
    this.accountNumberClient.indexSimple(
      WITHDRAWAL_DESER.toParser(Withdrawal),
      'withdrawals',
      _.merge({ accountNumber }, params)
    )

  readonly create = async (
    withdrawal: Withdrawal
  ): Promise<SingleResponse<Withdrawal>> =>
    this.accountNumberClient.create(
      withdrawal,
      WITHDRAWAL_DESER.serialize,
      WITHDRAWAL_DESER.update,
      'withdrawals',
      { accountNumber: withdrawal.accountNumber }
    )

  readonly dryRun = async (
    withdrawalDryRunnable: WithdrawalDryRunnable
  ): Promise<SingleResponse<WithdrawalDryRun>> => {
    const builder = new JsonBuilder()
    WITHDRAWAL_DRY_RUNNABLE_DESER.serialize(withdrawalDryRunnable, builder)
    const helper = await this.accountNumberClient.postJson(
      'withdrawals/dry-run',
      builder.json,
      { accountNumber: withdrawalDryRunnable.accountNumber }
    )

    return parseSingleResponse<WithdrawalDryRun>(
      helper,
      WITHDRAWAL_DRY_RUN_DESER.toParser(WithdrawalDryRun)
    )
  }
}
