import {
  EQUIFAX_QUIZ_DESER,
  EQUIFAX_RESPONSE_DESER,
  EQUIFAX_RESULT_DESER,
  EquifaxQuiz,
  EquifaxResponse,
  EquifaxResult
} from '../../tastyworks/equifax'
import type HttpClient from '../../tastyworks/http'
import {
  parseErrorContainerFromData,
  SingleResponse,
  updateSingleResponse
} from '../../tastyworks/response'
import { JsonBuilder, type JsonMap } from '../../tastyworks/util/json'

export class EquifaxService {
  private readonly equifaxClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.equifaxClient = baseHttpClient.nested('equifax')
  }

  async getLastResult(): Promise<SingleResponse<EquifaxResult>> {
    return this.equifaxClient.show(
      new EquifaxResult(),
      EQUIFAX_RESULT_DESER.update
    )
  }

  async submitComparison(): Promise<SingleResponse<EquifaxResponse>> {
    const interactionType = { 'client-type': 'eIDcompare' }

    return this.submitEquifaxInteraction(interactionType)
  }

  async submitVerification(): Promise<SingleResponse<EquifaxResponse>> {
    const interactionType = { 'client-type': 'eIDverifier' }

    return this.submitEquifaxInteraction(interactionType)
  }

  private async submitEquifaxInteraction(
    interactionType: JsonMap
  ): Promise<SingleResponse<EquifaxResponse>> {
    const result = await this.equifaxClient.postJson('', interactionType)

    const equifaxResponse = updateSingleResponse(
      result,
      new EquifaxResponse(),
      EQUIFAX_RESPONSE_DESER.update
    )

    parseErrorContainerFromData(result, equifaxResponse)

    return equifaxResponse
  }

  async submitQuiz(
    quiz: EquifaxQuiz
  ): Promise<SingleResponse<EquifaxResponse>> {
    const jsonBuilder = new JsonBuilder()

    EQUIFAX_QUIZ_DESER.serialize(quiz, jsonBuilder)

    const result = await this.equifaxClient.postJson('quiz', jsonBuilder.json)

    return updateSingleResponse(
      result,
      new EquifaxResponse(),
      EQUIFAX_RESPONSE_DESER.update
    )
  }
}
