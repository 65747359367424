import {
  PUBLIC_DOCUMENT_DESER,
  PublicDocument
} from '../../tastyworks/document'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class RequiredAgreementDocumentsService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly index = async (): Promise<ItemsResponse<PublicDocument>> =>
    this.customerMeClient.indexSimple(
      PUBLIC_DOCUMENT_DESER.toParser(PublicDocument),
      'required-agreement-documents'
    )
}
