import * as combinators from './combinators'

/* eslint-disable prefer-template */
export const SSN = new RegExp(
  '^' +
    combinators.or(
      // Cannot be 000, 666, 9xx
      '[1-578][0-9]{2}',

      '0[1-9]{2}',
      '00[1-9]',
      '0[1-9]0',

      '6[0-57-9]{2}',
      '66[0-57-9]',
      '6[0-57-9]6'
    ) +
    '-?' +
    combinators.or(
      // Cannot be 00
      '[1-9][0-9]',
      '[0-9][1-9]'
    ) +
    '-?' +
    combinators.or(
      // Cannot be 0000
      '[1-9][0-9]{3}',
      '[0-9][1-9][0-9]{2}',
      '[0-9]{2}[1-9][0-9]',
      '[0-9]{3}[1-9]'
    ) +
    '$'
)

export const BANK_ACCOUNT_NUMBER = new RegExp(/^[\s\S]*\S[\s\S]$/)

export const ONE_TIME_PASSWORD = new RegExp('^[0-9]{6}$')

export const ROUTING_NUMBER = new RegExp('^[0-9]{9}$')

export const SWIFT_CODE = new RegExp(
  '[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3,4})?'
)

export const ZIP_CODE = new RegExp('^[0-9]{5}$')
