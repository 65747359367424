<script>import { useCollection } from "radix-svelte/internal/helpers";
import { createEventDispatcher } from "svelte";
import {
  focusNextInput,
  focusPrevInput,
  getDateInputCollectionContext
} from "./DateInput.svelte";
const collection = getDateInputCollectionContext();
const dispatch = createEventDispatcher();
export let length;
export let placeholder;
export let min;
export let max;
export let id;
export let label;
export let value;
export let partType;
export let index;
let charsTyped = 0;
function padStart(value2) {
  return value2.padStart(length, "0");
}
function padEnd(value2) {
  return value2.padEnd(length, "0");
}
function processInput(oldValue, newChar) {
  if (charsTyped === 0) {
    charsTyped = 1;
    return newChar;
  }
  let nextValue = parseInt(oldValue + newChar).toString();
  if (nextValue.length > length) {
    nextValue = nextValue.slice(1);
  } else {
    charsTyped++;
  }
  return nextValue;
}
function normalizeValue(value2) {
  if (value2.length === 0)
    return value2;
  const numValue = parseInt(value2);
  if (numValue < min) {
    value2 = min.toString();
  } else if (numValue > max) {
    value2 = max.toString();
  }
  return padStart(value2);
}
function shouldFocusNextInput(nextVal) {
  const possibleContinuation = parseInt(padEnd(nextVal));
  return charsTyped === length || partType !== "year" && possibleContinuation > max;
}
function handleBeforeInput(event) {
  const target = event.target;
  const updateValue = (newVal) => {
    dispatch("input", { target, value, index, type: "input" });
    value = target.textContent = newVal;
  };
  if (event.inputType.startsWith("delete")) {
    if (value) {
      charsTyped = 0;
      updateValue("");
    } else if (event.inputType === "deleteContentBackward") {
      focusPrevInput($collection, target);
    } else if (event.inputType === "deleteContentForward") {
      focusNextInput($collection, target);
    }
    return;
  }
  if (event.inputType !== "insertText" || isNaN(parseInt(event.data ?? ""))) {
    return;
  }
  const newValue = processInput(target.textContent ?? "", event.data ?? "");
  updateValue(padStart(newValue));
  if (shouldFocusNextInput(newValue)) {
    focusNextInput($collection, target);
  }
}
function handleBlur() {
  charsTyped = 0;
  value = this.textContent = normalizeValue(this.textContent ?? "");
}
</script>

<div
  role="textbox"
  contenteditable
  class="flex min-w-0 select-none bg-inherit text-center caret-transparent outline-none selection:bg-blue-700 empty:text-general-secondary-label focus:bg-blue-700 focus:text-white"
  inputmode="numeric"
  aria-label={label}
  {id}
  data-part-type={partType}
  data-placeholder={placeholder}
  data-min={padStart(min.toString())}
  data-max={padStart(max.toString())}
  on:beforeinput|preventDefault={handleBeforeInput}
  on:focus
  on:blur={handleBlur}
  use:useCollection={{ collection }}
>
  {value && padStart(value)}
</div>

<style>
  [contenteditable]:empty::after {
    content: attr(data-placeholder);
  }
</style>
