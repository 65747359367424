import { number, object, ref, string } from 'yup'
import { AcatHoldingEligibility, HoldingAssetType } from '../../asset-transfer'
import toStringValues from '../../util/enum'
import { buildSchema } from './util/build-schema'

const schema = object({
  acatRequestId: number().required(),
  amount: number().when('assetType', {
    is: HoldingAssetType.CASH.valueOf(),
    then: sch => sch.required().positive().max(ref('initialAmount')),
    otherwise: sch =>
      sch.when('initialAmount', {
        is: (amount: number) => amount > 0,
        then: innerSchema =>
          innerSchema
            .required()
            .positive()
            .integer('yup.acat.amount.noPartialShares')
            .min(0)
            .max(ref('initialAmount')),
        otherwise: innerSchema =>
          innerSchema
            .required()
            .negative()
            .integer('yup.acat.amount.noPartialShares')
            .min(ref('initialAmount'))
            .max(0)
      })
  }),
  assetType: string().required().oneOf(toStringValues(HoldingAssetType)),
  description: string(),
  eligibility: string()
    .required()
    .oneOf(toStringValues(AcatHoldingEligibility)),
  externalType: string(),
  id: number().required(),
  initialAmount: number().required(),
  isoCurrencyCode: string(),
  symbol: string(),
  transferAmount: number().required()
})

export const acatHoldingSchema = buildSchema('AcatHolding', schema)
