import { SchemaDeSerBuilder } from '../../tastyworks/deser'

export class AdminMarketData {
  symbol = ''
  ask = NaN
  bid = NaN
  last = NaN
}

export const ADMIN_MARKET_DATA_DESER = new SchemaDeSerBuilder(AdminMarketData)
  .ofString('symbol')
  .ofFloat('ask')
  .ofFloat('bid')
  .ofFloat('last')
  .toDeSer()
