import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import type { Params } from './request'

export class AccountDocument {
  name = ''
  slug = ''
  url = ''
  context = ''
  acceptanceType = ''
  s3Key = ''
  s3Bucket = ''
  version = ''
  id = 0
}

const ACCOUNT_DOCUMENT_DESER: SchemaDeSer<AccountDocument> =
  new SchemaDeSerBuilder(AccountDocument)
    .ofStrings(
      'name',
      'slug',
      'url',
      'context',
      'acceptanceType',
      's3Key',
      's3Bucket'
    )
    .ofInt('id')
    .toDeSer()

export const ACCOUNT_DOCUMENT_DESER_PARSER =
  ACCOUNT_DOCUMENT_DESER.toParser(AccountDocument)

export enum AccountDocumentRequestType {
  ALL = 'All',
  AUTOTRADE = 'Autotrade',
  CRYPTOCURRENCY = 'Cryptocurrency',
  FUTURES = 'Futures',
  FULLY_PAID_SECURITIES_LENDING = 'Fully Paid Securities Lending',
  PORTFOLIO_MARGIN = 'Portfolio Margin'
}

export interface AccountDocumentParams extends Params {
  accountType: string
  isMargin: boolean
  isAutoTrade?: boolean
  isCryptocurrency?: boolean
  isFutures?: boolean
  isProfessional?: boolean
  isPortfolioMargin?: boolean
  hasFullyPaidSecuritiesLending?: boolean
  includeDocumentsFor?: AccountDocumentRequestType
  regulatoryDomain?: string
}
