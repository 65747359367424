<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="revenue" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Revenue</title>
<path d="M8.157 10.435c0-1.818 1.564-3.148 3.409-3.148 1.412 0 2.706 0.77 3.187 2.092 0.151 0.415-0.063 0.874-0.478 1.025s-0.874-0.063-1.025-0.478c-0.215-0.592-0.834-1.039-1.683-1.039-1.112 0-1.809 0.757-1.809 1.548 0 0.208 0.081 0.378 0.427 0.586 0.386 0.231 0.93 0.4 1.612 0.604 0.018 0.005 0.035 0.011 0.053 0.016 0.613 0.184 1.346 0.404 1.922 0.749 0.633 0.38 1.204 0.992 1.204 1.958 0 1.818-1.564 3.148-3.409 3.148-1.448 0-2.705-0.886-3.178-2.068-0.164-0.41 0.035-0.876 0.446-1.040s0.876 0.035 1.040 0.446c0.223 0.557 0.879 1.062 1.692 1.062 1.112 0 1.809-0.757 1.809-1.548 0-0.208-0.081-0.378-0.427-0.586-0.385-0.231-0.93-0.4-1.612-0.605-0.018-0.005-0.035-0.011-0.053-0.016-0.613-0.184-1.346-0.403-1.922-0.749-0.633-0.38-1.204-0.992-1.204-1.958z"></path>
<path d="M11.565 5.548c0.442 0 0.8 0.358 0.8 0.8v1.739c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-1.739c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M11.565 15.983c0.442 0 0.8 0.358 0.8 0.8v1.739c0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8v-1.739c0-0.442 0.358-0.8 0.8-0.8z"></path>
<path d="M19.981 9.338c0.355 0.965 0.549 2.008 0.549 3.097 0 4.951-4.014 8.965-8.965 8.965s-8.965-4.014-8.965-8.965c0-4.951 4.014-8.965 8.965-8.965 1.089 0 2.132 0.194 3.097 0.549 0.205-0.369 0.467-0.702 0.776-0.986-1.194-0.492-2.501-0.764-3.873-0.764-5.614 0-10.165 4.551-10.165 10.165s4.551 10.165 10.165 10.165c5.614 0 10.165-4.551 10.165-10.165 0-1.371-0.271-2.679-0.764-3.872-0.284 0.308-0.616 0.571-0.986 0.776z"></path>
<path d="M18.087 2.6c-1.83 0-3.313 1.483-3.313 3.313s1.483 3.313 3.313 3.313c1.83 0 3.313-1.483 3.313-3.313s-1.483-3.313-3.313-3.313zM13.574 5.913c0-2.492 2.021-4.513 4.513-4.513s4.513 2.021 4.513 4.513-2.021 4.513-4.513 4.513c-2.493 0-4.513-2.021-4.513-4.513z"></path>
<path d="M16.183 5.913c0-0.331 0.269-0.6 0.6-0.6h2.609c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-2.609c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M18.087 4.009c0.331 0 0.6 0.269 0.6 0.6v2.609c0 0.331-0.269 0.6-0.6 0.6s-0.6-0.269-0.6-0.6v-2.609c0-0.331 0.269-0.6 0.6-0.6z"></path>
</svg>