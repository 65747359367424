import * as icons from "./lib"
import { transformModuleName } from "./util"

const exportedIcons = Object.keys(icons).reduce((acc, key) => {
  const moduleName = transformModuleName(key)
  acc[moduleName] = icons[key]
  return acc
}, {})


export default exportedIcons
