import { object, string } from 'yup'
import { STATE_REGIONS } from '../../customer'
import { ALL_COUNTRIES, USA_COUNTRY_CODE } from '../../util/country'
import { ZIP_CODE } from '../regex/constants'
import { buildSchema } from './util/build-schema'

const isDomestic = (country: string) => USA_COUNTRY_CODE === country

const schema = object({
  city: string().required(),
  country: string().required().oneOf(Array.from(ALL_COUNTRIES)),
  postalCode: string().when('country', {
    is: isDomestic,
    then: fieldSchema =>
      fieldSchema
        .required()
        .matches(ZIP_CODE, { message: 'yup.address.postalCode' }),
    otherwise: fieldSchema => fieldSchema.nullable()
  }),
  stateRegion: string().when('country', {
    is: isDomestic,
    then: fieldSchema =>
      fieldSchema.oneOf(Array.from(STATE_REGIONS)).required(),
    otherwise: fieldSchema => fieldSchema.nullable()
  }),
  streetOne: string().required(),
  streetTwo: string()
})

export const addressSchema = buildSchema('Address', schema)
