<script lang="ts">
  import { key } from "@tastyworks/svelte-forms-lib"
  import { ValidateYup } from "@tastyworks/tastyworks-api"
  import { cn } from "@tastyworks/ui-library"
  import { getContext } from "svelte"
  import { json } from "svelte-i18n"
  import {
    findFieldTranslationKey,
    getByPath,
    lookupOptionTranslation,
  } from "./util"
  import { FormContext } from "/@/account-management/form"

  const { optionTranslationKey } = ValidateYup

  const { form, validationSchema } = getContext<FormContext>(key)

  let cssClass = ""
  export { cssClass as class }
  export let name: string
  export let fieldTranslationKey = findFieldTranslationKey(
    validationSchema,
    name
  )
  const optionsPath = optionTranslationKey(fieldTranslationKey)

  $: value = getByPath($form, name)
  $: displayValue = lookupOptionTranslation(value, $json(optionsPath))
</script>

<span
  class={cn(
    `
    form-field-read-only
    font-small-500
    block
    min-h-9
    rounded
    p-2
    text-general-inactive-text`,
    cssClass
  )}
>
  {displayValue}
</span>
