import goog from "@tastyworks/boulangerie-bundle"

const ComplexOrderManagerUiCallbacks = goog.module.get(
  "tasty.ui.orderentry.complex.ComplexOrderManagerUiCallbacks"
)
const RequestState = goog.module.get("com.dough.util.RequestState")

export default class WebComplexOrderManagerUiCallbacks extends ComplexOrderManagerUiCallbacks {
  openNewBracket(_complexOrderEntry) {
    console.log("TODO openNewBrack")
    return RequestState.success()
  }

  openNewPairsTrading(_complexOrderEntry) {
    console.log("TODO openNewPairsTrading")
    return RequestState.success()
  }

  openEditPairsTrading(_complexOrderStatus) {
    console.log("TODO openEditPairsTrading")
    return RequestState.success()
  }
}
