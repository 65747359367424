import {
  JsonHelper,
  parseErrorContainer,
  SingleResponse,
  TwUser,
  USER_DESER_PARSER
} from '../../../tastyworks'
import HttpClient from '../../../tastyworks/http'

export class AdminUserService {
  private readonly adminUserClient: HttpClient
  private readonly adminEmailClient: HttpClient
  private readonly removeCallClient: HttpClient

  constructor(adminUserClient: HttpClient) {
    this.adminEmailClient = adminUserClient.nested('confirmation')
    this.removeCallClient = adminUserClient.nested('accounts')
    this.adminUserClient = adminUserClient.nested('users')
  }

  readonly getUser = async (
    userId: string
  ): Promise<SingleResponse<TwUser>> => {
    return this.adminUserClient
      .get(userId)
      .then(res => this.populateUserResult(res))
  }

  readonly passwordReset = async (userId: string) => {
    await this.adminUserClient.nested(userId).postJson('password-reset', {}, {})
  }

  readonly emailConfirmation = async (email: string) => {
    await this.adminEmailClient.postJson('', { email: email }, {})
  }
  readonly unlockUser = async (userId: string) => {
    await this.adminUserClient.nested(userId).patch('unlock', {}, {})
  }

  readonly kickSession = async (userId: string) => {
    await this.adminUserClient.nested(userId).doDelete('sessions', {}, {})
  }

  readonly resetOtp = async (
    userId: string
  ): Promise<SingleResponse<{ code: string; 'expires-at': string }>> => {
    return this.adminUserClient
      .nested(userId)
      .postJson('mobile/confirmation/reset', {}, {})
      .then(res => this.populateOtpResult(res))
  }

  readonly removeDayTradeCall = async (accountNumber: string) => {
    await this.removeCallClient
      .nested(accountNumber)
      .patch('/trading-status/remove-day-trade-call', {})
  }

  readonly fakeFunction = async (userId: string) => {
    await this.adminUserClient.nested(userId).doDelete('sessions', {}, {})
  }

  private readonly populateUserResult = (
    helper: JsonHelper
  ): SingleResponse<TwUser> => {
    const response = new SingleResponse<TwUser>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')

    response.data = USER_DESER_PARSER(data)

    return response
  }

  private readonly populateOtpResult = (
    helper: JsonHelper
  ): SingleResponse<{
    code: string
    'expires-at': string
  }> => {
    const response = new SingleResponse<{
      code: string
      'expires-at': string
    }>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }

    const data = helper.getChild('data')
    const code = data.getString('code')
    const expiresAt = data.getString('expires-at')

    response.data = { code: code, 'expires-at': expiresAt }

    return response
  }
}
