import { ItemsResponse, JsonBuilder } from '../../tastyworks'
import {
  ACCOUNT_BENEFICIARY_DESER,
  AccountBeneficiary,
  NEW_ACCOUNT_BENEFICIARY_DESER,
  UPDATE_ACCOUNT_BENEFICIARY_DESER
} from '../../tastyworks/account'
import type HttpClient from '../../tastyworks/http'
import { parseItemsResponse } from '../../tastyworks/response'

export class AccountBeneficiaryService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string
  ): Promise<ItemsResponse<AccountBeneficiary>> =>
    this.accountNumberClient.indexSimple(
      ACCOUNT_BENEFICIARY_DESER.toParser(AccountBeneficiary),
      'account-beneficiaries',
      { accountNumber }
    )

  readonly update = async (
    accountNumber: string,
    beneficiaries: AccountBeneficiary[]
  ): Promise<ItemsResponse<AccountBeneficiary>> => {
    const beneficiariesJson = beneficiaries.map(beneficiary => {
      let builder = new JsonBuilder()
      if (beneficiary.isCreated) {
        UPDATE_ACCOUNT_BENEFICIARY_DESER.serialize(beneficiary, builder)
      } else {
        NEW_ACCOUNT_BENEFICIARY_DESER.serialize(beneficiary, builder)
      }
      return builder.json
    })

    const response = await this.accountNumberClient.put(
      'account-beneficiaries',
      { 'account-beneficiaries': beneficiariesJson },
      { accountNumber }
    )

    return parseItemsResponse(
      response,
      ACCOUNT_BENEFICIARY_DESER.toParser(AccountBeneficiary)
    )
  }
}
