<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="cash" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Cash</title>
<path d="M2.8 15.129v-8.298c0-0.001 0.001-0.004 0.002-0.008 0.003-0.007 0.007-0.013 0.011-0.017s0.005-0.004 0.005-0.004 0-0 0.001-0 0.005-0.001 0.011-0.001h15.85c0 0 0.003 0 0.008 0.002 0.007 0.003 0.013 0.007 0.017 0.011s0.004 0.005 0.004 0.005 0 0 0 0.001 0.001 0.005 0.001 0.011v1.66h1.6v-1.66c0-0.991-0.826-1.63-1.63-1.63h-15.851c-0.991 0-1.63 0.826-1.63 1.63v8.299c0 0.991 0.826 1.63 1.63 1.63h1.66v-1.6h-1.659c-0-0-0.003-0-0.008-0.002-0.007-0.003-0.013-0.007-0.017-0.011s-0.004-0.005-0.004-0.005c0 0-0-0-0-0.001s-0.001-0.005-0.001-0.011z"></path>
<path d="M5.29 9.32v8.298c0 0.006 0.001 0.010 0.001 0.011s0 0.001 0 0.001 0 0.001 0.004 0.005 0.010 0.008 0.017 0.011c0.005 0.002 0.008 0.002 0.008 0.002h15.767c0.035 0 0.055-0.006 0.065-0.009s0.017-0.008 0.023-0.013c0.006-0.006 0.016-0.016 0.025-0.034v-8.272c0-0.006-0.001-0.010-0.001-0.011s-0-0.001-0-0.001-0-0.001-0.004-0.005-0.010-0.008-0.017-0.011c-0.005-0.002-0.008-0.002-0.008-0.002h-15.85c-0.006 0-0.010 0.001-0.011 0.001s-0.001 0-0.001 0-0.001 0-0.005 0.004-0.008 0.010-0.011 0.017c-0.002 0.005-0.002 0.008-0.002 0.008zM3.69 9.319c0-0.804 0.639-1.63 1.63-1.63h15.851c0.804 0 1.63 0.639 1.63 1.63v8.461l-0.015 0.078c-0.15 0.751-0.781 1.39-1.697 1.39h-15.768c-0.804 0-1.63-0.639-1.63-1.63v-8.299z"></path>
<path d="M13.203 11.579c-1.044 0-1.89 0.846-1.89 1.89s0.846 1.89 1.89 1.89c1.044 0 1.89-0.846 1.89-1.89s-0.846-1.89-1.89-1.89zM10.114 13.469c0-1.706 1.383-3.090 3.090-3.090s3.090 1.383 3.090 3.090-1.383 3.090-3.090 3.090c-1.706 0-3.090-1.383-3.090-3.090z"></path>
<path d="M18.081 7.89h3.007c0.707 0 1.43 0.562 1.43 1.43v3.007h-0.6c-2.167 0-3.837-1.773-3.837-3.837v-0.6zM19.35 9.090c0.226 0.96 0.982 1.739 1.967 1.968v-1.738c0-0.072-0.027-0.124-0.069-0.163-0.048-0.045-0.11-0.067-0.161-0.067h-1.737z"></path>
<path d="M19.349 17.848h1.738c0.072 0 0.124-0.027 0.163-0.069 0.045-0.048 0.067-0.11 0.067-0.161v-1.737c-0.96 0.226-1.739 0.982-1.968 1.967zM18.081 18.448c0-2.167 1.773-3.837 3.837-3.837h0.6v3.007c0 0.707-0.562 1.43-1.43 1.43h-3.007v-0.6z"></path>
<path d="M5.156 9.158c-0.045 0.048-0.067 0.11-0.067 0.161v1.743c0.998-0.218 1.754-0.975 1.973-1.973h-1.743c-0.072 0-0.124 0.027-0.163 0.069zM3.89 9.32c0-0.707 0.562-1.43 1.43-1.43h3.007v0.6c0 2.157-1.679 3.837-3.837 3.837h-0.6v-3.007z"></path>
<path d="M3.89 14.612h0.6c2.167 0 3.837 1.773 3.837 3.837v0.6h-3.007c-0.707 0-1.43-0.562-1.43-1.43v-3.007zM5.090 15.88v1.738c0 0.072 0.027 0.124 0.069 0.163 0.048 0.045 0.11 0.067 0.161 0.067h1.737c-0.226-0.96-0.982-1.739-1.967-1.968z"></path>
</svg>