<script context="module">
  import goog from "/@lib/boulangerie"
  const DataFormatUtil = goog.module.get("com.dough.trade.util.DataFormatUtil")
</script>

<script>
  export let stars = 0
  export let text = DataFormatUtil.liquidityRating(stars)

  let cssClass = ""
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }
</script>

<button
  aria-label="{stars} stars"
  type="button"
  class="liquidity-rating {cssClass}"
  style={cssStyle}
  title={text}
  on:click
>
  {text}
</button>

<style>
  .liquidity-rating {
    /* filled and empty are the same color */
    color: var(--color-icon-symbol-detail-liquidity-star-filled);
    letter-spacing: var(--double-extra-small-spacing);
    overflow: visible;
  }
</style>
