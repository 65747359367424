import goog from "/@lib/boulangerie"
import papaparse from "papaparse"
import dayjs from "dayjs"

type ArrayProxy = {
  jsArray: Array<string>
}

interface ICsvWriter {
  lines: string[][]
  writeRecord(strings: ArrayProxy): void
  new (): ICsvWriter // eslint-disable-line @typescript-eslint/no-misused-new
}

const CsvWriter = goog.module.get("com.dough.util.CsvWriter") as ICsvWriter

export type TransformationFunction = (datum: string) => string
export type TransformationMap = Map<number, TransformationFunction>
type ExportToCsvCallback = (csvWriter: PapaParseCsvWriter) => void

class PapaParseCsvWriter extends CsvWriter {
  lines = []

  constructor() {
    super()
  }

  override writeRecord(strings: ArrayProxy): void {
    this.lines.push(strings.jsArray.flat())
  }

  writeEmptyLine(): void {
    this.lines.push([])
  }

  createObjectUrl(): string {
    const csv = papaparse.unparse(this.lines)
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    })
    return URL.createObjectURL(blob)
  }
}

class CsvWriterTransformer extends PapaParseCsvWriter {
  constructor(private colsToReformat: TransformationMap) {
    super()
  }

  override writeRecord(strings: ArrayProxy): void {
    const rowData = strings.jsArray.flat()

    if (this.lines.length > 0) {
      for (const [index, transformer] of this.colsToReformat) {
        rowData.splice(index, 1, transformer(rowData[index]))
      }
    }

    this.lines.push(rowData)
  }
}

export function createCsvUrl(
  exportToCsvCallback: ExportToCsvCallback,
  transformMap: TransformationMap = null
) {
  const csvWriter = transformMap
    ? new CsvWriterTransformer(transformMap)
    : new PapaParseCsvWriter()

  exportToCsvCallback(csvWriter)
  return csvWriter.createObjectUrl()
}

export function createTransactionHistoryTransformerMap(): TransformationMap {
  const transctionMap = new Map()
  // we want to transform Date column, which is on index 0
  transctionMap.set(0, (isoDate: string) => {
    // RFC_3339 is datetime format to represent datetime compactly
    // Format 1 : YYYY-MM-DDTHH:mm:ssZZ which translates to 2023-09-07T14:34:43-0500
    // Format 2 : YYYY-MM-DDTHH:mm:ssZ which translates to 2023-09-07T19:34:43Z (same as Date#toISOString())
    // desktop uses Format 1, but js prefers format 2. we will force js to use format 1
    return dayjs(isoDate).format("YYYY-MM-DDTHH:mm:ssZZ")
  })

  return transctionMap
}
