import {
  ItemsResponse,
  JsonHelper,
  parseErrorContainer,
  PENDING_CASH_PARSER,
  pendingCashEntry,
  SingleResponse
} from '../../../tastyworks'
import {
  ADMIN_BALANCE_PARSER,
  AdminBalance
} from '../../../tastyworks/admin/admin-balances'
import HttpClient from '../../../tastyworks/http'

export class AdminBalancesService {
  private readonly adminBalanceClient: HttpClient

  constructor(adminBalanceClient: HttpClient) {
    this.adminBalanceClient = adminBalanceClient.nested('accounts')
  }

  readonly index = async (
    accountNumber: string
  ): Promise<SingleResponse<AdminBalance>> => {
    return this.adminBalanceClient
      .nested(accountNumber)
      .get('balances')
      .then(res => this.populateResult(res))
  }

  readonly pendingCash = async (
    accountNumber: string
  ): Promise<ItemsResponse<pendingCashEntry>> => {
    return this.adminBalanceClient
      .nested(accountNumber)
      .get('pending-cash-entries')
      .then(res => this.populatePendingCashResult(res))
  }

  private readonly populateResult = (
    helper: JsonHelper
  ): SingleResponse<AdminBalance> => {
    const response = new SingleResponse<AdminBalance>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')
    response.data = ADMIN_BALANCE_PARSER(data)
    return response
  }

  private readonly populatePendingCashResult = (
    helper: JsonHelper
  ): ItemsResponse<pendingCashEntry> => {
    const response = new ItemsResponse<pendingCashEntry>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')

    response.items = data.parseArray('items', PENDING_CASH_PARSER)
    return response
  }
}
