import { number, object, string } from 'yup'
import { BANK_ACCOUNT_NUMBER, ROUTING_NUMBER } from '../regex/constants'
import { buildSchema } from './util/build-schema'

const schema = object({
  amount: number().required().positive(),
  bankAccountNumber: string().matches(BANK_ACCOUNT_NUMBER, {
    message: 'yup.wireWithdrawal.bankAccountNumber',
    excludeEmptyString: true
  }),
  bankRoutingNumber: string().matches(ROUTING_NUMBER, {
    message: 'yup.wireWithdrawal.bankRoutingNumber',
    excludeEmptyString: true
  })
})

export const withdrawalSchema = buildSchema('Withdrawal', schema)
