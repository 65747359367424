import {
  AUTOTRADE_OPERATOR_DESER,
  AutotradeOperator
} from '../../tastyworks/autotrade-operator'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class AutotradeOperatorService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly index = async (): Promise<ItemsResponse<AutotradeOperator>> =>
    this.customerMeClient.indexSimple(
      AUTOTRADE_OPERATOR_DESER.toParser(AutotradeOperator),
      'autotrade-operators'
    )
}
