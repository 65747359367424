/*eslint @typescript-eslint/no-magic-numbers: 0*/
export function formatJsonDate(d: Date): string {
  const month = String(d.getUTCMonth() + 1).padStart(2, '0')
  const dayOfMonth = String(d.getUTCDate()).padStart(2, '0')
  const year = String(d.getUTCFullYear()).padStart(4, '0')
  return `${year}-${month}-${dayOfMonth}`
}

export function formatJsonDateTime(d: Date): string {
  const year = String(d.getUTCFullYear()).padStart(4, '0')
  const month = String(d.getUTCMonth() + 1).padStart(2, '0')
  const dayOfMonth = String(d.getUTCDate()).padStart(2, '0')
  const hours = String(d.getUTCHours()).padStart(2, '0')
  const minutes = String(d.getUTCMinutes()).padStart(2, '0')
  const seconds = String(d.getUTCSeconds()).padStart(2, '0')
  const millis = String(d.getUTCMilliseconds()).padStart(3, '0')
  return `${year}-${month}-${dayOfMonth}T${hours}:${minutes}:${seconds}.${millis}+00:00`
}
