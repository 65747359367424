import { parseErrorContainer } from '../../../tastyworks'
import {
  ADMIN_BALANCE_PARSER,
  AdminBalance
} from '../../../tastyworks/admin/admin-balances'
import HttpClient from '../../../tastyworks/http'
import { SingleResponse } from '../../../tastyworks/response'

export class BuyingPowerService {
  private readonly buyingPowerClient: HttpClient
  private readonly reloadBuyingPowerClient: HttpClient

  constructor(buyingPowerClient: HttpClient) {
    this.buyingPowerClient = buyingPowerClient.nested('accounts')
    this.reloadBuyingPowerClient = buyingPowerClient.nested('margin')
  }

  readonly adjustBuyingPower = async (
    accountNumber: string,
    buyingPower: string,
    note: string
  ): Promise<SingleResponse<AdminBalance>> => {
    let adjustmentEffect = ''
    if (parseInt(buyingPower) > 0) {
      adjustmentEffect = 'Credit'
    } else if (parseInt(buyingPower) < 0) {
      adjustmentEffect = 'Debit'
    } else {
      adjustmentEffect = 'None'
    }

    const helper = await this.buyingPowerClient.nested(accountNumber).patch(
      '/balances/buying-power-adjustment',
      {},
      {
        'buying-power-adjustment': Math.abs(parseInt(buyingPower)),
        'buying-power-adjustment-effect': adjustmentEffect,
        note: note
      }
    )

    const response = new SingleResponse<AdminBalance>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')
    response.data = ADMIN_BALANCE_PARSER(data)
    return response
  }

  readonly reloadBuyingPower = async (accountNumber: string) => {
    await this.reloadBuyingPowerClient
      .nested('accounts')
      .nested(accountNumber)
      .nested('load')
      .postJson('', {}, {})
  }
}
