import { object, string } from 'yup'
import { digitalAssetCustomerAddressSchema } from './digital-asset-customer-address'
import { bigNumber } from './schema/big-number'
import { buildSchema } from './util/build-schema'

const schema = object({
  accountNumber: string().required(),
  asset: string().required(),
  digitalAssetCustomerAddress: digitalAssetCustomerAddressSchema.pick(['id']),
  quantity: bigNumber().required().positive()
})

export const digitalAssetWithdrawalSchema = buildSchema(
  'DigitalAssetWithdrawal',
  schema
)
