import _ from 'lodash'
import {
  DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER,
  DigitalAssetCustomerAddress,
  type DigitalAssetCustomerAddressParams
} from '../../tastyworks/digital-asset-customer-address'
import type HttpClient from '../../tastyworks/http'
import {
  updateSingleResponse,
  type ItemsResponse,
  type SingleResponse
} from '../../tastyworks/response'
import {
  SUPPORTED_WITHDRAWAL_ASSET_DESER,
  SupportedWithdrawalAsset
} from '../../tastyworks/supported-digital-asset'

export class DigitalAssetCustomerAddressService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params?: DigitalAssetCustomerAddressParams
  ): Promise<ItemsResponse<DigitalAssetCustomerAddress>> =>
    this.accountNumberClient.indexSimple(
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER.toParser(
        DigitalAssetCustomerAddress
      ),
      '/digital-asset-customer-addresses',
      _.merge({ accountNumber }, params)
    )

  readonly create = async (
    digitalAssetCustomerAddress: DigitalAssetCustomerAddress
  ): Promise<SingleResponse<DigitalAssetCustomerAddress>> =>
    this.accountNumberClient.create(
      digitalAssetCustomerAddress,
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER.serialize,
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER.update,
      '/digital-asset-customer-addresses',
      {
        accountNumber: digitalAssetCustomerAddress.accountNumber
      }
    )

  readonly destroy = async (
    digitalAssetCustomerAddress: DigitalAssetCustomerAddress
  ): Promise<SingleResponse<DigitalAssetCustomerAddress>> => {
    const helper = await this.accountNumberClient.doDelete(
      '/digital-asset-customer-addresses/:digitalAssetCustomerAddressId',
      {
        accountNumber: digitalAssetCustomerAddress.accountNumber,
        digitalAssetCustomerAddressId: digitalAssetCustomerAddress.id
      }
    )

    return updateSingleResponse(
      helper,
      digitalAssetCustomerAddress,
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER.update
    )
  }

  readonly update = async (
    digitalAssetCustomerAddress: DigitalAssetCustomerAddress
  ): Promise<SingleResponse<DigitalAssetCustomerAddress>> =>
    this.accountNumberClient.update(
      digitalAssetCustomerAddress,
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER.serialize,
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER.update,
      '/digital-asset-customer-addresses/:digitalAssetCustomerAddressId',
      {
        digitalAssetCustomerAddressId: digitalAssetCustomerAddress.id,
        accountNumber: digitalAssetCustomerAddress.accountNumber
      }
    )

  readonly indexSupportedAssets = async (
    accountNumber: string
  ): Promise<ItemsResponse<SupportedWithdrawalAsset>> =>
    this.accountNumberClient.indexSimple(
      SUPPORTED_WITHDRAWAL_ASSET_DESER.toParser(SupportedWithdrawalAsset),
      '/digital-asset-customer-addresses/supported-assets',
      { accountNumber }
    )
}
