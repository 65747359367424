import { boolean, mixed, number, object, ref, string } from 'yup'
import {
  EMPLOYMENT_STATUS_VALUES,
  EMPLOYMENT_STATUSES,
  INCOME_VALUES,
  INVESTMENT_EXPERIENCES,
  MARITAL_STATUS_VALUES,
  NET_WORTH_VALUES
} from '../../customer'
import { buildSchema } from './util/build-schema'

const schema = object({
  annualNetIncome: number().required().oneOf(INCOME_VALUES),
  coveredOptionsTradingExperience: mixed<INVESTMENT_EXPERIENCES>()
    .required()
    .oneOf(Object.values(INVESTMENT_EXPERIENCES)),
  employerName: string().when('employmentStatus', {
    is: EMPLOYMENT_STATUSES.EMPLOYED,
    then: fieldSchema => fieldSchema.required()
  }),
  employmentStatus: string().required().oneOf(EMPLOYMENT_STATUS_VALUES),
  futuresTradingExperience: mixed<INVESTMENT_EXPERIENCES>()
    .required()
    .oneOf(Object.values(INVESTMENT_EXPERIENCES)),
  jobTitle: string().when('employmentStatus', {
    is: EMPLOYMENT_STATUSES.EMPLOYED,
    then: fieldSchema => fieldSchema.required()
  }),
  liquidNetWorth: number()
    .required()
    .oneOf(NET_WORTH_VALUES)
    .max(ref('netWorth'), 'yup.customer.suitability.liquidNetWorth.max'),
  maritalStatus: string().required().oneOf(MARITAL_STATUS_VALUES),
  occupation: string().when('employmentStatus', {
    is: EMPLOYMENT_STATUSES.EMPLOYED,
    then: fieldSchema => fieldSchema.required()
  }),
  netWorth: number().required().oneOf(NET_WORTH_VALUES),
  numberOfDependents: number(),
  stockTradingExperience: mixed<INVESTMENT_EXPERIENCES>()
    .required()
    .oneOf(Object.values(INVESTMENT_EXPERIENCES)),
  subjectToTaxWithholding: boolean().required(),
  uncoveredOptionsTradingExperience: mixed<INVESTMENT_EXPERIENCES>()
    .required()
    .oneOf(Object.values(INVESTMENT_EXPERIENCES))
})

export const customerSuitabilitySchema = buildSchema(
  'CustomerSuitability',
  schema
)
