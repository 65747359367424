import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import type { Params } from './request'

export enum AUTOTRADE_POSITION_STATUSES {
  CLOSED = 'Closed',
  OPEN = 'Open',
  SELF_MANAGED = 'Self Managed'
}

export class AutotradePosition extends RestResource {
  accountNumber = ''
  createdAt?: Date
  modelPortfolioId = 0
  status = AUTOTRADE_POSITION_STATUSES.OPEN
  underlyingSymbol = ''
  updatedAt?: Date

  get isSelfManaged() {
    return this.status === AUTOTRADE_POSITION_STATUSES.SELF_MANAGED
  }

  get isClosed() {
    return this.status === AUTOTRADE_POSITION_STATUSES.CLOSED
  }
}

export interface AutotradePositionParams extends Params {
  underlyingSymbol?: string
  status?: AUTOTRADE_POSITION_STATUSES
}

export const AUTOTRADE_POSITION_DESER: SchemaDeSer<AutotradePosition> =
  new SchemaDeSerBuilder(AutotradePosition)
    .ofString('accountNumber')
    .ofDateTime('createdAt')
    .ofInt('id')
    .ofInt('modelPortfolioId')
    .ofString('status')
    .ofString('underlyingSymbol')
    .ofDateTime('updatedAt')
    .toDeSer()
