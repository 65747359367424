import {
  EXTERNAL_TRANSACTION_DESER,
  ExternalTransaction
} from './cash-management'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import type { WithdrawalDryRunnable } from './withdrawal-dry-run'

export enum WIRE_BANK_ACCOUNT_TYPES {
  DOMESTIC = 'ABA',
  INTERNATIONAL = 'BIC'
}

export enum CHECK_DELIVERY_METHODS {
  OVERNIGHT = 'Overnight',
  STANDARD = 'Standard'
}

export class Withdrawal
  extends ExternalTransaction
  implements WithdrawalDryRunnable
{
  bankAccountNumber = ''
  bankAccountType?: WIRE_BANK_ACCOUNT_TYPES
  bankCity = ''
  bankCountry = ''
  bankName = ''
  bankRoutingNumber = ''
  deliveryMethod?: CHECK_DELIVERY_METHODS
  furtherCreditAccountNumber = ''
}

export const WITHDRAWAL_DESER: SchemaDeSer<Withdrawal> = new SchemaDeSerBuilder(
  Withdrawal
)
  .addAll(EXTERNAL_TRANSACTION_DESER.fields)
  .ofString('bankAccountNumber')
  .ofString('bankAccountType')
  .ofString('bankCity')
  .ofString('bankCountry')
  .ofString('bankName')
  .ofString('bankRoutingNumber')
  .ofString('deliveryMethod')
  .ofString('furtherCreditAccountNumber')
  .toDeSer()
