import BigNumber from 'bignumber.js'
import { RestResource } from './common'
import { SchemaDeSerBuilder, type SchemaDeSer } from './deser'
import {
  DIGITAL_ASSET_CLEARING_ADDRESS_DESER,
  DigitalAssetClearingAddress
} from './digital-asset-clearing-address'
import {
  DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER,
  DigitalAssetCustomerAddress
} from './digital-asset-customer-address'
import {
  DIGITAL_ASSET_DEPOSIT_RETURN_DESER,
  DigitalAssetDepositReturn
} from './digital-asset-deposit-return'

export enum DIGITAL_ASSET_DEPOSIT_STATUSES {
  NEEDS_MANUAL_RETURN = 'Needs Manual Return',
  RETURN_SUBMITTED_TO_CLEARING = 'Return Submitted to Clearing',
  RETURNED = 'Returned',
  SETTLED = 'Settled',
  UNDER_REVIEW = 'Under Review'
}

export class DigitalAssetDeposit extends RestResource {
  accountNumber = ''
  asset = ''
  createdAt?: Date
  digitalAssetCustomerAddress = new DigitalAssetCustomerAddress()
  digitalAssetClearingAddress = new DigitalAssetClearingAddress()
  digitalAssetDepositReturn?: DigitalAssetDepositReturn
  onChainTransactionHash = ''
  quantity = new BigNumber(0)
  status = DIGITAL_ASSET_DEPOSIT_STATUSES.UNDER_REVIEW
  updatedAt?: Date

  get isReturned() {
    return this.status === DIGITAL_ASSET_DEPOSIT_STATUSES.RETURNED
  }
}

export const DIGITAL_ASSET_DEPOSIT_DESER: SchemaDeSer<DigitalAssetDeposit> =
  new SchemaDeSerBuilder(DigitalAssetDeposit)
    .ofString('accountNumber')
    .ofString('asset')
    .ofDateTime('createdAt')
    .ofNested(
      'digitalAssetCustomerAddress',
      DIGITAL_ASSET_CUSTOMER_ADDRESS_DESER,
      DigitalAssetCustomerAddress
    )
    .ofNested(
      'digitalAssetClearingAddress',
      DIGITAL_ASSET_CLEARING_ADDRESS_DESER,
      DigitalAssetClearingAddress
    )
    .ofNested(
      'digitalAssetDepositReturn',
      DIGITAL_ASSET_DEPOSIT_RETURN_DESER,
      DigitalAssetDepositReturn
    )
    .ofInt('id')
    .ofString('onChainTransactionHash')
    .ofBigNumber('quantity')
    .ofString('status')
    .ofDateTime('updatedAt')
    .toDeSer()
