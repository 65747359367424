/**
 * Intended to provide a compact and relatively fast method of looking up
 * arbitrary information associated with a nested tree structure.
 *
 * Trees are intended to be configuration -- the expectation is that they
 * represent relatively small structures.
 *
 * Reserved words (arbitrary data may _not_ use these keys):
 *   - nodes: an Object representing named-child-nodes.
 *   - children: an Array of child-nodes (provided).
 *
 * Utility functions are provided to aid in locating nodes within Nodes.
 *
 * See tests for examples.
 */

export interface NodeSpec {
  nodes?: { [key: string]: NodeSpec }
  [key: string]: any
}

export interface Node {
  nodes?: { [key: string]: Node }
  children?: Node[]
  [key: string]: any
}

/** Returns a defined Node (tree) given a NodeSpec. */
export function defineTree(spec: NodeSpec) {
  const obj = {
    ...spec,
    nodes: spec.nodes
      ? Object.fromEntries(
          Object.entries(spec.nodes).map(([k, v]) => [k, defineTree(v)])
        )
      : undefined,
  }
  return new Proxy<typeof obj>(obj, {
    get: (target, prop) => {
      if ("children" === prop) {
        return target.nodes ? Object.values(target.nodes) : undefined
      }
      const value = Reflect.get(target, prop, target)
      return value ? value : target.nodes?.[prop]
    },
    set: () => false, // Read-only.
  })
}

/** Returns a list from root to the target (or null if not found). */
export function getStack(root: Node, target: Node) {
  if (root === target) {
    return [root]
  }

  for (const child of root.children ?? []) {
    const stack = getStack(child, target)
    if (!stack) continue
    return [root, ...stack]
  }

  return null
}
