import _ from 'lodash'
import { reach, Schema } from 'yup'

function findRootTranslationKey(validationSchema: Schema): string | undefined {
  return _.get(validationSchema.describe(), 'meta.rootTranslationKey') as
    | string
    | undefined
}

export function findFieldTranslationKey(
  validationSchema: Schema,
  fieldPath: string,
  baseTranslationKey?: string
): string | undefined {
  let schema = validationSchema
  let path = fieldPath
  let key: string | undefined =
    baseTranslationKey === undefined
      ? findRootTranslationKey(validationSchema)
      : baseTranslationKey

  fieldPath.split('.').forEach((current, index, array) => {
    schema = reach(schema, current) as Schema

    const rootTranslationKey = findRootTranslationKey(schema)

    if (rootTranslationKey && typeof rootTranslationKey === 'string') {
      path = array.slice(index + 1).join('.')
      key = rootTranslationKey
    }
  })

  return key === undefined ? path : `${key}.${path}`
}

export function optionTranslationKey(fieldTranslationKey: string) {
  return `${fieldTranslationKey}.options`
}
