import type { DividendReinvestmentDirection } from '../../tastyworks/dividend-reinvestment-request'
import DividendReinvestmentRequest, {
  DIVIDEND_REINVESTMENT_REQUEST_DESER
} from '../../tastyworks/dividend-reinvestment-request'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'
import { updateSingleResponse } from '../../tastyworks/response'
import type { ArrayMap } from '../../tastyworks/util/collection'
import { idKeyExtractor } from '../../tastyworks/util/json'

export class DividendReinvestmentRequestService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly indexByAccountNumber = async (
    accountNumber: string,
    dividendReinvestmentRequests: ArrayMap<string, DividendReinvestmentRequest>
  ): Promise<ItemsResponse<DividendReinvestmentRequest>> =>
    this.accountNumberClient.index(
      dividendReinvestmentRequests,
      DIVIDEND_REINVESTMENT_REQUEST_DESER.update,
      idKeyExtractor,
      'dividend-reinvestment-requests',
      { accountNumber }
    )

  readonly showLatest = async (
    accountNumber: string
  ): Promise<SingleResponse<DividendReinvestmentRequest>> =>
    this.accountNumberClient.show(
      new DividendReinvestmentRequest(),
      DIVIDEND_REINVESTMENT_REQUEST_DESER.update,
      '/dividend-reinvestment-requests/latest',
      { accountNumber }
    )

  readonly create = async (
    accountNumber: string,
    direction: DividendReinvestmentDirection
  ): Promise<SingleResponse<DividendReinvestmentRequest>> => {
    const dividendReinvestmentRequest = new DividendReinvestmentRequest()
    dividendReinvestmentRequest.direction = direction

    return this.accountNumberClient.create(
      dividendReinvestmentRequest,
      DIVIDEND_REINVESTMENT_REQUEST_DESER.serialize,
      DIVIDEND_REINVESTMENT_REQUEST_DESER.update,
      '/dividend-reinvestment-requests',
      { accountNumber }
    )
  }

  readonly show = async (
    accountNumber: string,
    id: number
  ): Promise<SingleResponse<DividendReinvestmentRequest>> => {
    const dividendReinvestmentRequest = new DividendReinvestmentRequest()
    return this.accountNumberClient.show(
      dividendReinvestmentRequest,
      DIVIDEND_REINVESTMENT_REQUEST_DESER.update,
      '/dividend-reinvestment-requests/:id',
      { accountNumber, id }
    )
  }

  // Cancel a dividend reinvestment request
  readonly destroy = async (
    dividendReinvestmentRequest: DividendReinvestmentRequest
  ): Promise<SingleResponse<DividendReinvestmentRequest>> => {
    const helper = await this.accountNumberClient.doDelete(
      '/dividend-reinvestment-requests/:id',
      {
        accountNumber: dividendReinvestmentRequest.accountNumber,
        id: dividendReinvestmentRequest.id
      }
    )
    return updateSingleResponse(
      helper,
      dividendReinvestmentRequest,
      DIVIDEND_REINVESTMENT_REQUEST_DESER.update
    )
  }
}
