<script context="module">
  import goog from "/@lib/boulangerie"

  const AccountPreferences = goog.module.get(
    "com.dough.preference.account.AccountPreferences"
  )
</script>

<script>
  import { appSession } from "/@lib/boulangerie"
  import boulePropertyStore from "/@/boule/control/boule-property-store.js"
  import CheckBoxField from "/@/trading/settings/control/CheckBoxField.svelte"
  import IncludedAccountsTable from "/@/trading/settings/control/IncludedAccountsTable.svelte"
  import SymbolSearchField from "/@/trading/settings/control/SymbolSearchField.svelte"

  const { accountPreferences } = appSession

  // XXX: Observable Property is a String.
  const betaWeightSymbol = boulePropertyStore(
    accountPreferences.betaWeightSymbolProperty().valueProperty()
  )

  function changeBetaWeightSecurity(security) {
    // XXX: Set a Security to allow AccountPreferences to do some cleaning.
    accountPreferences.betaWeightingSymbol = security
  }
</script>

<div
  class="
    accounts
    mr-extra-small
    grid
    grid-cols-1
    gap-double-extra-small"
>
  <CheckBoxField
    label="Privacy Mode"
    preferenceField={accountPreferences.privacyField()}
  />

  <CheckBoxField
    label="Show Futures Delta Using Equivalent ETF/Stock Symbol"
    preferenceField={accountPreferences.showDeltaEtfEquivalentForFuturesProperty()}
  />

  <hr class="my-extra-small border-divider-horizontal" />

  <SymbolSearchField
    label="Beta-Weighting Symbol:"
    value={$betaWeightSymbol}
    on:change={({ detail }) => changeBetaWeightSecurity(detail.security)}
  >
    <button
      class="
        h-full
        rounded
        bg-button-default
        px-extra-small"
      on:click={() =>
        changeBetaWeightSecurity(AccountPreferences.DEFAULT_BETA_SYMBOL)}
    >
      Reset to SPY
    </button>
  </SymbolSearchField>

  <hr class="my-extra-small border-divider-horizontal" />

  <IncludedAccountsTable />
</div>
