import type { ObjectSchema } from 'yup'

export function buildSchema<T extends ObjectSchema<any>>(
  className: string,
  schema: T
) {
  return schema.meta({
    rootTranslationKey: className
  })
}
