import { boolean, object, string } from 'yup'
import { buildSchema } from './util/build-schema'

const schema = object({
  futuresIndustryFirm: string().when('hasFuturesIndustryAffiliation', {
    is: true,
    then: fieldSchema => fieldSchema.required()
  }),
  hasFuturesIndustryAffiliation: boolean().required(),
  isNfaPool: boolean().required(),
  isRegistered: boolean().required(),
  isRegistrationExempt: boolean().required(),
  isSoliciting: boolean().required(),
  registrationExemption: string().when('isRegistrationExempt', {
    is: true,
    then: fieldSchema => fieldSchema.required()
  }),
  registrationMember: string().when('isRegistered', {
    is: true,
    then: fieldSchema => fieldSchema.required()
  })
})

export const futuresAgreementRecordSchema = buildSchema(
  'FuturesAgreementRecord',
  schema
)
