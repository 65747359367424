<script lang="ts">
  import { FormContext } from "/@/account-management/form"
  import { key } from "@tastyworks/svelte-forms-lib"
  import { BaseResponse } from "@tastyworks/tastyworks-api"
  import { createEventDispatcher, setContext } from "svelte"

  export let context: FormContext
  export let validationSchema = context.validationSchema
  export let translationKey = null
  export let id = null

  const dispatch = createEventDispatcher()

  export function handleFormSubmit() {
    dispatch("willSubmit")

    touchAll()

    return handleSubmit(null)
      .then((response: unknown) => {
        handleTwApiResponse(response)
        dispatch("didSubmit", response)
      })
      .catch((error: Error) => {
        dispatch("didRaiseError", error)
      })
  }

  const {
    errors,
    form,
    handleChange,
    handleReset,
    handleSubmit,
    initializeScope,
    isReadOnly,
    isSubmitting,
    isValid,
    scope,
    state,
    touchAll,
    touched,
    updateField,
    updateInitialValues,
    updateTouched,
    updateValidateField,
    validateField,
  } = context

  setContext(key, {
    errors,
    form,
    handleChange,
    handleReset,
    handleSubmit,
    initializeScope,
    isReadOnly,
    isSubmitting,
    isValid,
    scope,
    state,
    touchAll,
    touched,
    translationKey,
    updateField,
    updateInitialValues,
    updateTouched,
    updateValidateField,
    validateField,
    validationSchema,
  })

  function initializeFormValues() {
    if ($scope === null) {
      return
    }

    initializeScope($scope, context.updateInitialValues)
  }

  function handleTwApiResponse(response: unknown) {
    if (response instanceof BaseResponse) {
      if (response.isValid) {
        initializeFormValues()
      }
    }
  }
</script>

<!-- TODO: [KT] PR handleReset event.preventDefault upstream in the svelte form lib -->
<form
  on:submit|preventDefault={handleFormSubmit}
  on:reset|preventDefault={handleReset}
  {...$$restProps}
  {id}
>
  <slot
    {form}
    {errors}
    {touched}
    {state}
    {handleChange}
    {handleSubmit}
    {touchAll}
    {updateField}
    {updateInitialValues}
    {updateTouched}
    {updateValidateField}
    {validateField}
    {handleFormSubmit}
  />
</form>
