import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
export function cn(...inputs) {
    return twMerge(clsx(inputs));
}
export function slugFromPath(path) {
    return path.replace("/src/content/", "").replace(".md", "");
}
export function hexToHsl(hex) {
    if (hex) {
        const sanitizedHex = hex.replace("#", "");
        const red = parseInt(sanitizedHex.substring(0, 2), 16);
        const green = parseInt(sanitizedHex.substring(2, 4), 16);
        const blue = parseInt(sanitizedHex.substring(4, 6), 16);
        const normalizedRed = red / 255;
        const normalizedGreen = green / 255;
        const normalizedBlue = blue / 255;
        const max = Math.max(normalizedRed, normalizedGreen, normalizedBlue);
        const min = Math.min(normalizedRed, normalizedGreen, normalizedBlue);
        let hue, saturation, lightness;
        if (max === min) {
            hue = 0;
        }
        else if (max === normalizedRed) {
            hue = ((normalizedGreen - normalizedBlue) / (max - min)) % 6;
        }
        else if (max === normalizedGreen) {
            hue = (normalizedBlue - normalizedRed) / (max - min) + 2;
        }
        else {
            hue = (normalizedRed - normalizedGreen) / (max - min) + 4;
        }
        hue = Math.round(hue * 60);
        if (hue < 0) {
            hue += 360;
        }
        lightness = (max + min) / 2;
        if (max === min) {
            saturation = 0;
        }
        else if (lightness <= 0.5) {
            saturation = (max - min) / (max + min);
        }
        else {
            saturation = (max - min) / (2 - max - min);
        }
        saturation = Math.round(saturation * 100);
        lightness = Math.round(lightness * 100);
        return [hue, saturation, lightness];
    }
    return [0, 0, 0];
}
export function hexToRgb(hex) {
    if (hex) {
        const sanitizedHex = hex.replace("#", "");
        const red = parseInt(sanitizedHex.substring(0, 2), 16);
        const green = parseInt(sanitizedHex.substring(2, 4), 16);
        const blue = parseInt(sanitizedHex.substring(4, 6), 16);
        return [red, green, blue];
    }
    return [0, 0, 0];
}
