import { array, number, object, string } from 'yup'
import { modelPortfolioSchema } from './model-portfolio'
import { buildSchema } from './util/build-schema'

const schema = object({
  id: number(),
  modelPortfolios: array().of(modelPortfolioSchema),
  name: string(),
  website: string()
})

export const autotradeOperatorSchema = buildSchema('AutotradeOperator', schema)
