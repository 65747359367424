import type { Alpha3Code } from 'i18n-iso-countries'
import _ from 'lodash'
import type { TwSession } from '../tastyworks'
import {
  compareOptionsLevels,
  OPTIONS_LEVEL
} from '../tastyworks/options-level'
import { ACCOUNT_TYPE_DESER, AccountType } from './account-type'
import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { USA_COUNTRY_CODE } from './util/country'
import { US_STATE_OPTIONS } from './util/country-labels'
import DateHelper from './util/date'
import toStringValues from './util/enum'
import type { JsonHelper } from './util/json'

export class Address {
  static onInitialize = (_obj: Address) => {
    /* no-op */
  }

  constructor() {
    Address.onInitialize(this)
  }

  streetOne = ''
  streetTwo = ''
  city = ''
  stateRegion = ''
  postalCode = ''
  country: Alpha3Code = USA_COUNTRY_CODE

  get isForeigner(): boolean {
    return this.country !== USA_COUNTRY_CODE
  }

  equals(other: Address) {
    return (
      this.streetOne.trim() === other.streetOne.trim() &&
      this.streetTwo.trim() === other.streetTwo.trim() &&
      this.city.trim() === other.city.trim() &&
      this.stateRegion.trim() === other.stateRegion.trim() &&
      this.postalCode.trim() === other.postalCode.trim() &&
      this.country.trim() === other.country.trim()
    )
  }

  copyFrom(other: Address) {
    this.streetOne = other.streetOne
    this.streetTwo = other.streetTwo
    this.city = other.city
    this.stateRegion = other.stateRegion
    this.postalCode = other.postalCode
    this.country = other.country
  }
}

export function formatAddress(address: Address, twoLines?: boolean) {
  if (_.isNil(address)) {
    return ''
  }
  const newLineOrComma = twoLines ? '\n' : ', '
  const street = !!address.streetTwo
    ? `${address.streetOne} ${address.streetTwo}`
    : address.streetOne
  return `${street}${newLineOrComma}${address.city} ${address.stateRegion}, ${address.postalCode}, ${address.country}`
}

export const ADDRESS_DESER: SchemaDeSer<Address> = new SchemaDeSerBuilder(
  Address
)
  .ofString('streetOne')
  .ofNullableString('streetTwo')
  .ofString('city')
  .ofString('stateRegion')
  .ofString('postalCode')
  .ofString('country')
  .toDeSer()

export const STATE_REGIONS = Object.freeze(US_STATE_OPTIONS)

export const OCCUPATIONS = Object.freeze([
  'Accounting',
  'Administration & Office Management',
  'Agriculture',
  'Architecture & Engineering',
  'Art & Entertainment',
  'Education',
  'Financial Services',
  'Food Services',
  'Healthcare',
  'Legal',
  'Sales',
  'Technology',
  'Other'
])

export enum EMPLOYMENT_STATUSES {
  EMPLOYED = 'EMPLOYED',
  RETIRED = 'RETIRED',
  STUDENT = 'STUDENT',
  UNEMPLOYED = 'UNEMPLOYED'
}

export const EMPLOYMENT_STATUS_VALUES = toStringValues(EMPLOYMENT_STATUSES)

export enum INVESTMENT_EXPERIENCES {
  LIMITED = 'LIMITED',
  GOOD = 'GOOD',
  EXTENSIVE = 'EXTENSIVE'
}

export const INVESTMENT_EXPERIENCE_VALUES = toStringValues(
  INVESTMENT_EXPERIENCES
)

export enum MARITAL_STATUSES {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED'
}

export const MARITAL_STATUS_VALUES = toStringValues(MARITAL_STATUSES)

export const INCOME_VALUES = [
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  0, 25001, 50001, 100001, 200001, 300001, 500001, 1200001
]
export const NET_WORTH_VALUES = [
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  0, 50001, 100001, 200001, 500001, 1000001, 5000001
]

export const SUFFIX_NAMES = ['', 'JR', 'SR', 'I', 'II', 'III', 'IV', 'V']

export class CustomerSuitability extends RestResource {
  static onInitialize = (_obj: CustomerSuitability) => {
    /* no-op */
  }

  constructor() {
    super()

    CustomerSuitability.onInitialize(this)
  }

  jobTitle = ''
  occupation = ''
  employerName = ''
  employmentStatus: EMPLOYMENT_STATUSES | null = null

  maritalStatus: MARITAL_STATUSES | null = null
  numberOfDependents: number | null = 0

  annualNetIncome: number | null = null
  netWorth: number | null = null
  liquidNetWorth: number | null = null

  // NOTE: api allows nill
  stockTradingExperience?: INVESTMENT_EXPERIENCES = undefined
  coveredOptionsTradingExperience?: INVESTMENT_EXPERIENCES = undefined
  futuresTradingExperience?: INVESTMENT_EXPERIENCES = undefined
  uncoveredOptionsTradingExperience?: INVESTMENT_EXPERIENCES = undefined
}

export const SUITABILITY_DESER: SchemaDeSer<CustomerSuitability> =
  new SchemaDeSerBuilder(CustomerSuitability)
    .ofString('jobTitle')
    .ofString('occupation')
    .ofString('employerName')
    .ofString('employmentStatus')
    .ofString('maritalStatus')
    .ofInt('numberOfDependents')
    .ofInt('annualNetIncome')
    .ofInt('netWorth')
    .ofInt('liquidNetWorth')
    .ofString('stockTradingExperience')
    .ofString('coveredOptionsTradingExperience')
    .ofString('uncoveredOptionsTradingExperience')
    .ofString('futuresTradingExperience')
    .ofString('id')
    .toDeSer()

export class CustomerSuitabilityOptionsLevelChange extends RestResource {
  accountNumber = ''
  currentOptionsLevel = OPTIONS_LEVEL.NOT_SUITABLE
  suitableOptionsLevel = OPTIONS_LEVEL.NOT_SUITABLE

  get isDowngrade() {
    return (
      compareOptionsLevels(
        this.currentOptionsLevel,
        this.suitableOptionsLevel
      ) > 0
    )
  }
}

export const SUITABILITY_OPTIONS_LEVEL_CHANGE_DESER: SchemaDeSer<CustomerSuitabilityOptionsLevelChange> =
  new SchemaDeSerBuilder(CustomerSuitabilityOptionsLevelChange)
    .ofString('accountNumber')
    .ofString('currentOptionsLevel')
    .ofString('suitableOptionsLevel')
    .toDeSer()

export const SUITABILITY_OPTIONS_LEVEL_CHANGE_PARSER =
  SUITABILITY_OPTIONS_LEVEL_CHANGE_DESER.toParser(
    CustomerSuitabilityOptionsLevelChange
  )

export enum USA_CITIZENSHIP_TYPES {
  CITIZEN = 'Citizen',
  PERMANENT_RESIDENT = 'Permanent Resident',
  NONRESIDENT_ALIEN = 'Nonresident Alien',
  NONE = 'None'
}

export const USA_CITIZENSHIP_TYPE_VALUES = toStringValues(USA_CITIZENSHIP_TYPES)

export enum TAX_NUMBER_TYPES {
  SSN = 'SSN',
  EIN = 'EIN',
  ITIN = 'ITIN'
}

export const TAX_NUMBER_TYPE_VALUES = toStringValues(TAX_NUMBER_TYPES)

export const USA_CITIZENSHIP_COUNTRY = 'USA'

export const MINIMUM_AGE = 18

export enum VISA_TYPES {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  F1 = 'F1',
  H1B = 'H1B',
  L1 = 'L1',
  O1 = 'O1',
  TN1 = 'TN1'
}

export class Customer extends RestResource {
  static readonly MINIMUM_AGE = MINIMUM_AGE
  static onInitialize = (_obj: Customer) => {
    /* no-op */
  }

  constructor() {
    super()

    Customer.onInitialize(this)
  }

  firstName = ''
  middleName = ''
  lastName = ''
  prefixName = ''
  suffixName = ''

  mobilePhoneNumber? = ''
  homePhoneNumber = ''
  workPhoneNumber = ''

  isForeign: boolean = false

  address = new Address()
  mailingAddress = new Address()

  customerSuitability = new CustomerSuitability()

  citizenshipCountry = ''
  birthCountry = ''
  usaCitizenshipType: USA_CITIZENSHIP_TYPES | null = null
  visaType: VISA_TYPES | null = null
  visaExpirationDate: Date | null = null

  taxNumber = ''
  taxNumberType = TAX_NUMBER_TYPES.SSN
  email = ''
  birthDate: Date | null = new DateHelper().addYears(-MINIMUM_AGE).toDate()
  subjectToTaxWithholding: boolean | null = null

  hasIndustryAffiliation: boolean | null = null
  industryAffiliationFirm = ''

  hasPoliticalAffiliation: boolean | null = null
  politicalOrganization = ''
  familyMemberNames = ''

  hasListedAffiliation: boolean | null = null
  listedAffiliationSymbol = ''

  isInvestmentAdviser: boolean | null = null
  hasInstitutionalAssets: boolean | null = null

  hasPendingOrApprovedApplication = false
  permittedAccountTypes: AccountType[] = []

  regulatoryDomain = ''

  // Computed.
  get hasSameAddress() {
    return this.address.equals(this.mailingAddress)
  }

  get isUsCitizen() {
    return this.usaCitizenshipType === USA_CITIZENSHIP_TYPES.CITIZEN
  }

  get isPermanentResident() {
    return this.usaCitizenshipType === USA_CITIZENSHIP_TYPES.PERMANENT_RESIDENT
  }

  get isNonresidentAlien() {
    return this.usaCitizenshipType === USA_CITIZENSHIP_TYPES.NONRESIDENT_ALIEN
  }
}

export const CUSTOMER_DESER: SchemaDeSer<Customer> = new SchemaDeSerBuilder(
  Customer
)
  .ofString('id')
  .ofString('firstName')
  .ofString('middleName')
  .ofString('lastName')
  .ofString('prefixName')
  .ofString('suffixName')
  .ofString('mobilePhoneNumber')
  .ofBoolean('isForeign')
  .ofString('homePhoneNumber')
  .ofString('workPhoneNumber')
  .ofNested('address', ADDRESS_DESER, Address)
  .ofNested('mailingAddress', ADDRESS_DESER, Address)
  .ofNested('customerSuitability', SUITABILITY_DESER, CustomerSuitability)
  .ofString('citizenshipCountry')
  .ofString('birthCountry')
  .ofString('usaCitizenshipType')
  .ofString('visaType')
  .ofDate('visaExpirationDate')
  .ofString('taxNumber') // TODO: tax number deserialization? obfuscated tax number is returned in response
  .ofString('taxNumberType')
  .ofString('email')
  .ofDate('birthDate')
  .ofBoolean('subjectToTaxWithholding')
  .ofBoolean('hasIndustryAffiliation')
  .ofString('industryAffiliationFirm')
  .ofBoolean('hasPoliticalAffiliation')
  .ofString('politicalOrganization')
  .ofString('familyMemberNames')
  .ofBoolean('hasListedAffiliation')
  .ofString('listedAffiliationSymbol')
  .ofBoolean('isInvestmentAdviser')
  .ofBoolean('hasInstitutionalAssets')
  .ofBoolean('hasPendingOrApprovedApplication')
  .ofArray('permittedAccountTypes', ACCOUNT_TYPE_DESER, AccountType)
  .ofString('regulatoryDomain')
  .toDeSer()

export const DOCUMENT_SLUGS = {
  ADDRESS_DOCUMENT: 'address_document',
  ID_DOCUMENT: 'id_document',
  SIGNATURE_IMAGE: 'signature_image',
  SSN_DOCUMENT: 'ssn_document',
  VISA_DOCUMENT: 'visa_document'
}

export class RequiredDocument {
  slug = ''
}

export const REQUIRED_DOCUMENT_DESER: SchemaDeSer<RequiredDocument> =
  new SchemaDeSerBuilder(RequiredDocument).ofString('slug').toDeSer()

export class SupportingDocument {
  documentType = ''
  slug = ''
  isAvailable = ''
}

export const SUPPORTING_DOCUMENT_DESER: SchemaDeSer<SupportingDocument> =
  new SchemaDeSerBuilder(SupportingDocument)
    .ofString('slug')
    .ofString('documentType')
    .ofBoolean('isAvailable')
    .toDeSer()

export class SupportingDocumentRequest {
  id = 0
  message = ''
  supportingDocuments = []
}

export const SUPPORTING_DOCUMENT_REQUEST_DESER: SchemaDeSer<SupportingDocumentRequest> =
  new SchemaDeSerBuilder(SupportingDocumentRequest)
    .ofInt('id')
    .ofString('message')
    .ofArray(
      'supportingDocuments',
      SUPPORTING_DOCUMENT_DESER,
      SupportingDocument
    )
    .toDeSer()

export const SUPPORTING_DOCUMENT_REQUEST_PARSER =
  SUPPORTING_DOCUMENT_REQUEST_DESER.toParser(SupportingDocumentRequest)

// Response from submitting supporting document image
export class DocumentRecord {
  category = ''
  createdAt = new Date()
  documentDate = new Date()
  documentTypeName = ''
  externalId = ''
  historyVersion = ''
  latest = ''
  partitionKey = ''
  s3Bucket = ''
  s3Key = ''
  s3Version = ''
  s3InstructionVersion = ''
  slug = ''
  sortKey = ''
  version = ''
}

export const DOCUMENT_RECORD_DESER: SchemaDeSer<DocumentRecord> =
  new SchemaDeSerBuilder(DocumentRecord)
    .ofStrings(
      'category',
      'documentTypeName',
      'externalId',
      'historyVersion',
      'latest',
      'partitionKey',
      's3Bucket',
      's3Key',
      's3Version',
      's3InstructionVersion',
      'slug',
      'sortKey',
      'version'
    )
    .ofDateTime('createdAt')
    .ofDateTime('documentDate')
    .toDeSer()

export const DOCUMENT_RECORD_PARSER =
  DOCUMENT_RECORD_DESER.toParser(DocumentRecord)

export enum SupportingDocumentVerificationStatus {
  COMPLETE = 'Complete',
  FAILED = 'Failed',
  PENDING = 'Pending',
  UPLOADED = 'Uploaded',
  UPLOAD_FAILED = 'Upload Failed'
}

export enum SupportingDocumentVerificationType {
  ADDRESS = 'AddressVerification',
  IDENTITY = 'IdentityVerification'
}

function documentSlugFromSupportingDocumentVerificationType(type?: string) {
  switch (type) {
    case SupportingDocumentVerificationType.ADDRESS:
      return DOCUMENT_SLUGS.ADDRESS_DOCUMENT
    case SupportingDocumentVerificationType.IDENTITY:
      return DOCUMENT_SLUGS.ID_DOCUMENT
    default:
      throw new Error(`Unknown slug for verification type ${type}`)
  }
}

export class SupportingDocumentVerification {
  extTransactionNumber = ''
  status = SupportingDocumentVerificationStatus.PENDING
  supportingDocumentId = 0
  type?: SupportingDocumentVerificationType
  uuid = ''

  get isFailed() {
    return (
      this.status === SupportingDocumentVerificationStatus.FAILED ||
      this.status === SupportingDocumentVerificationStatus.UPLOAD_FAILED
    )
  }

  get isPending() {
    return this.status === SupportingDocumentVerificationStatus.PENDING
  }

  get isComplete() {
    return this.status === SupportingDocumentVerificationStatus.COMPLETE
  }

  get slug() {
    return documentSlugFromSupportingDocumentVerificationType(this.type)
  }
}

export const SUPPORTING_DOCUMENT_VERIFICATION_DESER: SchemaDeSer<SupportingDocumentVerification> =
  new SchemaDeSerBuilder(SupportingDocumentVerification)
    .ofString('extTransactionNumber')
    .ofString('status')
    .ofInt('supportingDocumentId')
    .ofString('type')
    .ofString('uuid')
    .toDeSer()

export const SUPPORTING_DOCUMENT_VERIFICATION_PARSER =
  SUPPORTING_DOCUMENT_VERIFICATION_DESER.toParser(
    SupportingDocumentVerification
  )

export const SUPPORTING_DOCUMENT_VERIFICATION_UPDATER =
  SUPPORTING_DOCUMENT_VERIFICATION_DESER.update

export const SUPPORTING_DOCUMENT_VERIFICATION_KEY_EXTRACTOR = (
  helper: JsonHelper
) =>
  documentSlugFromSupportingDocumentVerificationType(helper.getString('type'))

export class TrustedContact extends RestResource {
  static onInitialize = (_obj: TrustedContact) => {
    /* no-op */
  }

  constructor() {
    super()

    TrustedContact.onInitialize(this)
  }

  firstName = ''
  lastName = ''
  address = new Address()
  phoneNumber = ''
  birthDate = new DateHelper().addYears(-MINIMUM_AGE).toDate()
  email = ''
  get isDomestic() {
    return !this.address.isForeigner
  }
}

export const TRUSTED_CONTACT_DESER: SchemaDeSer<TrustedContact> =
  new SchemaDeSerBuilder(TrustedContact)
    .ofString('id')
    .ofString('firstName')
    .ofString('lastName')
    .ofNested('address', ADDRESS_DESER, Address)
    .ofString('phoneNumber')
    .ofDate('birthDate')
    .ofString('email')
    .toDeSer()

export interface RequestConfirmationEmailByEmail {
  email: string
}

export function customerIdParam(session: TwSession) {
  const customerId =
    session.isEmployeeDomain && !_.isNil(session.customer)
      ? session.customer.id
      : 'me'
  return { customerId }
}
