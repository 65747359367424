import {
  getByPath,
  recursivelyForEachPath,
  setByPath,
} from "/@/account-management/form/util"
import { Writable, writable } from "svelte/store"
import { reach, Schema } from "yup"

export interface Scope<T> extends Writable<T> {
  castRawValue(name, schema, rawValue): Promise<void>
  castRawValueAll(schema: Schema, form): Promise<void>
}

export function createScope<Type>(initialScope: Type): Scope<Type> {
  const { subscribe, set, update } = writable(initialScope)

  return {
    async castRawValue(schema, name, form): Promise<void> {
      update((existing) => {
        if (existing === null || existing === undefined) {
          return null
        }

        const fieldSchema = reach(schema, name) as Schema
        const value = fieldSchema.cast(getByPath(form, name), { assert: false })

        if (fieldSchema.isType(value)) {
          setByPath(existing, name, value)
        }

        return existing
      })
    },
    async castRawValueAll(schema, form): Promise<void> {
      recursivelyForEachPath((name) => {
        this.castRawValue(schema, name, form)
      }, schema)
    },
    set,
    subscribe,
    update,
  }
}
