import { Temporal } from "temporal-polyfill"

window.Temporal = Temporal

import {
  createCastleIoRequestToken,
  handleHttpNetworkError,
  handleInvalidSession,
  isAdmin,
} from "/@lib/shared"
import goog from "@tastyworks/boulangerie-bundle"
import { WebNavigationCallback } from "./navigation.js"
import WebWarningOrderStatusHandler from "./warning-order-status-handler.js"
import WebComplexOrderManagerUiCallbacks from "./complex-order-manager-ui-callbacks.js"
import { WebDxFeedNativeStreamer } from "./dxfeed/streamer.js"
import { JsTwStreamer } from "./tw-streamer.js"

const AsyncTaskServiceErrorListener = goog.module.get(
  "com.dough.util.AsyncTaskService.ErrorListener"
)
const DaggerApplicationConfig = goog.module.get(
  "tasty.js.config.DaggerApplicationConfig"
)
const InternetChecker = goog.module.get(
  "com.dough.ui.net.UiHttpExceptionHandler.InternetChecker"
)
const InvalidSessionHandler = goog.module.get(
  "com.dough.net.InvalidSessionHandler$impl"
)
const JsHttpExceptionHandlerCallback = goog.module.get(
  "tasty.js.net.JsHttpExceptionHandler.Callback"
)
const WebPlatformDetails = goog.module.get("tasty.js.config.WebPlatformDetails")
const BouleColumnWidthLookup = goog.module.get(
  "tasty.js.ui.BouleColumnWidthLookup"
)

const WebSocketCreator = goog.module.get("tasty.js.net.WebSocketCreator")
const TwEnvironment = goog.module.get(import.meta.env.VITE_TW_ENV)
const FollowFeedEnvironment = goog.module.get(
  import.meta.env.VITE_FOLLOW_FEED_ENV
)

class WebInvalidSessionHandler extends InvalidSessionHandler {
  onInvalidSession() {
    handleInvalidSession()
  }
}

class HttpExceptionCallback extends JsHttpExceptionHandlerCallback {
  logPromiseError(error) {
    console.error(error)
  }

  logWarning(/** ?string */ message, /** Throwable */ exception) {
    console.warn(message, exception)
  }

  logRuntimeException(/** ?string */ message, /** Throwable */ exception) {
    console.error(message, exception)
  }

  notifyUserOfNetWorkIssue() {
    handleHttpNetworkError()
  }

  notifyUserOfPossibleIpBan() {
    alert("IP Banned 🔨")
  }
}

class WebAsyncTaskServiceErrorListener extends AsyncTaskServiceErrorListener {
  onErrorException(/** * */ context, /** * */ target, /** Throwable */ e) {
    console.error("Async Exception", context, target, e)
  }

  onErrorRequestState(/** * */ context, /** RequestState */ state) {
    console.error("Request error", context, state, state.errorMessage)
  }
}

class WebInternetChecker extends InternetChecker {
  isInternetConnectionOkay() {
    return navigator.onLine
  }
}

class WebBouleColumnWidthLookup extends BouleColumnWidthLookup {
  getSmallWidth() {
    return 100
  }

  getMediumWidth() {
    return 120
  }

  getLargeWidth() {
    return 160
  }
}

class WebSocketCreatorImpl extends WebSocketCreator {
  create(url, protocol) {
    if (protocol) {
      return new WebSocket(url, protocol)
    }

    return new WebSocket(url)
  }
}

export const twEnvironment = new TwEnvironment()

if (twEnvironment === null) {
  throw new Error(
    `environment is not properly configured: ${import.meta.env.VITE_TW_ENV}`
  )
}

export const followFeedEnvironment = new FollowFeedEnvironment()

const twContext =
  DaggerApplicationConfig.m_builder__tasty_js_config_ApplicationConfig_Builder()
    .isAdmin(isAdmin() ?? false)
    .invalidSessionHandler(new WebInvalidSessionHandler())
    .httpExceptionHandlerCallback(new HttpExceptionCallback())
    .asyncExceptionHandler(new WebAsyncTaskServiceErrorListener())
    .twEnvironment(twEnvironment)
    .followFeedEnvironment(followFeedEnvironment)
    .platformDetails(
      WebPlatformDetails.create(__APP_VERSION__, createCastleIoRequestToken)
    )
    .internetChecker(new WebInternetChecker())
    .bouleColumnWidthLookup(new WebBouleColumnWidthLookup())
    .navigationCallback(new WebNavigationCallback())
    .warningOrderStatusHandler(new WebWarningOrderStatusHandler())
    .complexOrderManagerUiCallbacks(new WebComplexOrderManagerUiCallbacks())
    .webSocketCreator(new WebSocketCreatorImpl())
    .twWebSocketStreamer(new JsTwStreamer())
    .dxFeedNativeStreamer(new WebDxFeedNativeStreamer())
    .build()

// Allow for easier console debugging
window.twContext = twContext

export const {
  appSession,
  appTwSession,
  curveTradingManager,
  curveLegSlidersState,
  twLoginManager,
  twSessionManager,
  jsTwConnectionStatusHelper,
  fiftyPercentPopManager,
  futuresContractHelper,
  futuresRollEntryManager,
  manageOrderStatusActionMenuFactory,
  managePositionActionMenuFactory,
  orderEntryFocusManager,
  orderEntryLegSelectionTracker,
  orderEntryLoadingState,
  orderEntryManager,
  orderEntryMutationHelper,
  orderEntryOptionChainState,
  orderEntryPricingState,
  orderEntryStatisticsState,
  orderEntryStrategyState,
  orderEntryTimeInForceState,
  orderStatusesMonitor,
  optionChainService,
  optionChainsTableManager,
  portfolioAnalysisManager,
  positionManager,
  positionDetailManager,
  preferenceManager,
  proTableManager,
  proTraderTableConfigManager,
  selectedPositionsManager,
  tableExpirationDistributionStateFactory,
  transactionManager,
  yearToDateTableManager,
  unifiedAccountsManager,
  nestedWatchlistsManager,
  uiBouleRendererFactory,
  symbolSearchManager,
  quoteService,
  orderStatusRowManager,
  selectedAccountsManager,
  tradeSession,
  followManager,
  runLoop,
  lazyContext,
  tastyliveStreamService,
  capitalRequirementsManager,
  twFundamentalsService,
  twAmarginationDryRunManager,
  newsManager,
  refinitivNewsService,
  topNewsManager,
  dashboardWatchlistsManager,
  quoteAlertsManager,
  theoAccountProfitLossManager,
  orderStatusActivityPreferenceAdapter,
  twJournalService,
  marketTimeManager,
  journalOrderManager,
  quickAnalysisPopManager,
  dxLinkMessageFactory,
  spanScenariosManager,
  liquidityPoolsManager,
} = twContext
