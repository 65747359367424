import { toast } from "@zerodevx/svelte-toast"
import AlertMessage from "./AlertMessage.svelte"
import { AlertSeverity } from "./alert-severity"

const defaultToastOptions = {
  // defaults direct from svelte-toast docs
  // progress bars are globally hidden in global.css
  classes: [], // user-defined classes
  dismissable: true, // allow dismiss with close button
  duration: 4000, // duration of progress bar tween to the `next` value
  initial: 1, // initial progress bar value
  intro: { x: 256 }, // toast intro fly animation settings
  next: 0, // next progress value
  pausable: false, // pause progress bar tween on mouse hover
  reversed: false, // insert new toast to bottom of stack
  theme: {
    float: "right",
    clear: "right",
  }, // css var overrides
}

export function toastAlert(
  severity: AlertSeverity,
  message: string,
  // not seeing any other great solution to excess propety checks without importing SvelteToastOptions type (borked due to module syntax)
  options: any = {}
) {
  return toast.push({
    ...defaultToastOptions,
    ...options,
    classes: [severity],
    component: {
      props: {
        ...options?.component?.props,
        message: message,
        severity: severity,
      },
      src: AlertMessage,
    },
  })
}
