import { cva } from "class-variance-authority";
export { default as Button } from "./Button.svelte";
const variant = {
    default: "bg-button-default text-button-primary hover:bg-button-default-hover",
    confirmation: "bg-button-confirmation text-button-colored hover:bg-button-confirmation-hover",
    buy: "bg-button-buy text-button-colored hover:bg-button-buy-hover",
    call: "bg-button-call text-button-colored hover:bg-button-call-hover",
    cancel: "bg-button-cancel text-button-colored hover:bg-button-cancel-hover",
    sell: "bg-button-sell text-button-colored hover:bg-button-sell-hover",
    put: "bg-button-put text-button-colored hover:bg-button-put-hover",
    continue: "bg-button-continue text-button-colored hover:bg-button-continue-hover",
    reviewAndSend: "bg-button-review-and-send text-button-colored hover:bg-button-review-and-send-hover",
    assetType: "bg-button-asset-type text-button-colored hover:bg-button-asset-type-hover",
    upgrade: "bg-button-upgrade text-button-colored hover:bg-button-upgrade-hover",
    orderStatusWorking: "bg-button-order-status-working text-button-colored hover:bg-button-order-status-working-hover",
    // Pseudo buttons
    ghost: "hover:bg-accent hover:text-accent-foreground p-0 h-fit w-fit",
    // FIXME: keyboard focus + active state shows no underline, so user receives no interaction feedback
    link: "[disabled]:text-button-inactive text-general-hyperlink focus:underline hover:underline underline-offset-[3px] active:underline active:shadow-none focus:active:underline p-0 h-fit w-fit",
    // Status buttons
    success: "hover:bg-button-success-hover border border-alert-success text-alerts-confirmation",
    pending: "hover:bg-button-pending-hover border border-alert-pending text-alerts-pending",
    error: "hover:bg-button-error-hover border border-alert-error text-alerts-error",
    defaultOutline: "hover:bg-button-default border border-general-default text-general-primary-label",
};
export const buttonVariantKeys = Object.keys(variant);
export const buttonVariants = cva("focus-visible:ring-ring active:shadow-inner-top-medium font-medium-600 inline-flex h-10 items-center justify-center rounded px-4 py-2 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50", {
    variants: {
        variant,
    },
    defaultVariants: {
        variant: "default",
    },
});
