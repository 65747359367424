import { object, string } from 'yup'
import { buildSchema } from './util/build-schema'

const schema = object({
  asset: string().required()
})

export const digitalAssetClearingAddressSchema = buildSchema(
  'DigitalAssetClearingAddress',
  schema
)
