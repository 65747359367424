import {
  ClientDomain,
  JsonHelper,
  SingleResponse,
  toFormData,
  TwUser,
  type Logger
} from '../../../tastyworks'
import {
  ADMIN_LOGIN_PARSER,
  AdminTwLogin
} from '../../../tastyworks/admin/admin-session'
import HttpClient, {
  InvalidSessionError,
  toJsonHelper
} from '../../../tastyworks/http'
import {
  parseSingleResponse,
  updateSingleResponse
} from '../../../tastyworks/response'
import { USER_DESER } from '../../../tastyworks/user'

export class AdminSessionService {
  private readonly sessionsClient: HttpClient

  constructor(baseHttpClient: HttpClient, private readonly logger: Logger) {
    this.sessionsClient = baseHttpClient.nested('sessions')
  }

  readonly login = async (
    login: string,
    password: string,
    clientDomain: ClientDomain = ClientDomain.Customer,
    oneTimePassword?: string
  ): Promise<SingleResponse<AdminTwLogin>> => {
    const url = this.sessionsClient.baseUrl.toUrl('')
    const body = toFormData({ login, password, clientDomain })

    // Do a raw fetch request to bypass SessionErrorHandler
    const headers: HeadersInit = {}
    headers['X-Tastyworks-OTP'] = oneTimePassword ?? ''

    const fetchResponse = await this.sessionsClient.fetcher(url, {
      body,
      headers,
      method: 'POST'
    })

    const helper = await toJsonHelper(fetchResponse)

    const response = parseSingleResponse(helper, ADMIN_LOGIN_PARSER)

    return response
  }

  readonly validate = async (
    sessionToken: string
  ): Promise<SingleResponse<TwUser>> => {
    if (!sessionToken) {
      throw new Error('sessionToken required')
    }

    let helper: JsonHelper | null = null
    try {
      helper = await this.sessionsClient.postJson('validate', {})
    } catch (err) {
      if (!(err instanceof InvalidSessionError)) {
        throw err
      }
      helper = await toJsonHelper(err.response)
    }

    const user = new TwUser()
    const response = updateSingleResponse(helper, user, USER_DESER.update)
    user.sessionToken = sessionToken

    return response
  }

  readonly logout = async (sessionToken: string): Promise<boolean> => {
    if (!sessionToken) {
      return false
    }

    const url = this.sessionsClient.baseUrl.toUrl('')
    try {
      await this.sessionsClient.fetch(url, 'DELETE')
      return true
    } catch (error) {
      this.logger.warn('Error on logout', error)
      return false
    }
  }
}
