import { accountStreamer } from "/@lib/tastyworks-rest"
import _ from "lodash"
import goog from "@tastyworks/boulangerie-bundle"

const RequestState = goog.module.get("com.dough.util.RequestState")
const TwWebSocketStreamer = goog.module.get(
  "com.dough.tw.net.TwWebSocketStreamer"
)
const SocketState = goog.module.get("com.dough.net.SocketStateSupport.State")
const RequestStateShim = goog.module.get("tasty.js.util.JsRequestStateShim")

const JsTwStreamerManager = goog.module.get("tasty.js.tw.JsTwStreamerManager")

const $Long = goog.module.get("nativebootstrap.Long$impl")

function convertStreamerStateToSocketState(streamerState) {
  return SocketState.values()[streamerState + 1]
}

export class JsTwStreamer extends TwWebSocketStreamer {
  connected = false

  constructor() {
    super()
    this.socketStateObserversMap = {
      [SocketState.NONE]: [],
      [SocketState.OPEN]: [],
      [SocketState.CLOSE]: [],
      [SocketState.ERROR]: [],
    }

    this.accountStreamer = accountStreamer
    this.accountStreamer.addStreamerStateObserver((streamerState) => {
      const socketState = convertStreamerStateToSocketState(streamerState)
      this.socketStateObserversMap[socketState].forEach((observer) => {
        observer.observe()
      })
    })
  }

  get currentSocketState() {
    const streamerState = this.accountStreamer.streamerState
    return convertStreamerStateToSocketState(streamerState)
  }

  isClosed() /* boolean */ {
    return !this.connected
  }

  connect() /* RequestState */ {
    if (this.connected) {
      return RequestState.success()
    }

    this.connected = true
    return RequestStateShim.toRequestState(this.accountStreamer.start())
  }

  close() /* RequestState */ {
    this.accountStreamer.stop()

    return RequestState.success()
  }

  registerSocketStateObserver(state, observer) /* Observer<Event> */ {
    this.socketStateObserversMap[state].push(observer)
    if (state === this.currentSocketState) {
      observer.observe()
    }
    return observer
  }

  unregisterSocketStateObserver(state, observer) /* void */ {
    _.pull(this.socketStateObserversMap[state], observer)
  }

  subscribeToAccounts(_accounts) /* RequestState */ {
    return RequestStateShim.toRequestState(
      this.accountStreamer.subscribeToAccounts()
    )
  }

  subscribeMessageToUser() /* RequestState */ {
    this.accountStreamer.subscribeToUser()
    return RequestState.success()
  }

  subscribeMessageToQuoteAlerts() /* RequestState */ {
    this.accountStreamer.subscribeTo(TwWebSocketStreamer.QUOTE_ALERTS_SUBSCRIBE)
    return RequestState.success()
  }

  subscribeMessageToPublicWatchlists() /* RequestState */ {
    this.accountStreamer.subscribeTo(
      TwWebSocketStreamer.PUBLIC_WATCHLISTS_SUBSCRIBE
    )
    return RequestState.success()
  }

  addObserverWithHandler(handler) /* Observer<TwStreamerEvent> */ {
    this.accountStreamer.addMessageObserver(
      handler.eventType.name(),
      (type, dataHelper, timestamp, _accountNumber) => {
        const event = JsTwStreamerManager.toStreamerEvent(
          type,
          dataHelper.toJson(),
          timestamp
        )
        handler.observe(event)
      }
    )
  }

  addObserver(observer, eventTypes) /* Observer<TwStreamerEvent> */ {
    const handler = (type, dataHelper, timestampNumeric, _accountNumber) => {
      const timestampLong = $Long.fromNumber(timestampNumeric)
      const event = JsTwStreamerManager.toStreamerEvent(
        type,
        dataHelper.toJson(),
        timestampLong
      )
      observer.observe(event)
    }

    if (Array.isArray(eventTypes)) {
      for (const type of eventTypes) {
        this.accountStreamer.addMessageObserver(type.name(), handler)
      }
    } else {
      // Single item
      this.accountStreamer.addMessageObserver(eventTypes.name(), handler)
    }
  }
}
