import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { MODEL_PORTFOLIO_DESER, ModelPortfolio } from './model-portfolio'

export enum AUTOTRADE_OPERATOR_STATUSES {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}
export class AutotradeOperator extends RestResource {
  description?: string
  modelPortfolios: ModelPortfolio[] = []
  name = ''
  status = AUTOTRADE_OPERATOR_STATUSES.ACTIVE
  website?: string
}

export const AUTOTRADE_OPERATOR_DESER: SchemaDeSer<AutotradeOperator> =
  new SchemaDeSerBuilder(AutotradeOperator)
    .ofString('description')
    .ofInt('id')
    .ofArray('modelPortfolios', MODEL_PORTFOLIO_DESER, ModelPortfolio)
    .ofString('name')
    .ofString('status')
    .ofString('website')
    .toDeSer()
