<script lang="ts">
  import { isDark } from "/@/theme"
  import zhDarkLogo from "./dark-logo-compact.svg"
  import zhLightLogo from "./light-logo-compact.svg"

  export let height = "32px"
  export let width = "32px"

  let cssClass = ""
  export { cssClass as class }

  $: img = $isDark ? zhLightLogo : zhDarkLogo
</script>

<img
  class="zero-hash-logo-compact {cssClass}"
  {height}
  {width}
  src={img}
  alt={img}
/>
