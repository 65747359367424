import BigNumber from 'bignumber.js'
import { RestResource } from './common'
import { SchemaDeSerBuilder, type SchemaDeSer } from './deser'
import { ON_CHAIN_STATUS } from './digital-asset-withdrawal'

export class DigitalAssetDepositReturn extends RestResource {
  createdAt?: Date
  feeNotionalValue = new BigNumber(0)
  feeQuantity = new BigNumber(0)
  netNotionalValue = new BigNumber(0)
  netQuantity = new BigNumber(0)
  onChainStatus = ON_CHAIN_STATUS.PENDING
  onChainTransactionHash = ''
  updatedAt?: Date
}

export const DIGITAL_ASSET_DEPOSIT_RETURN_DESER: SchemaDeSer<DigitalAssetDepositReturn> =
  new SchemaDeSerBuilder(DigitalAssetDepositReturn)
    .ofDateTime('createdAt')
    .ofString('id')
    .ofBigNumber('feeNotionalValue')
    .ofBigNumber('feeQuantity')
    .ofBigNumber('netNotionalValue')
    .ofBigNumber('netQuantity')
    .ofString('onChainStatus')
    .ofString('onChainTransactionHash')
    .ofDateTime('updatedAt')
    .toDeSer()
