<script lang="ts">
  // WARNING: be careful what you import here, as this is used very early on in the app
  // e.g., importing media from "/@/layout" in this file caused builds to fail completely
  import { AlertSeverity, alertDetails } from "./alert-severity"
  import ZeroHashLogoCompact from "/@/assets/image/zh/ZeroHashLogoCompact.svelte"

  const DEFAULT_ICON = "Info"
  const DEFAULT_HEADING = "INFORMATION"

  export let icon = ""
  export let headingMessage = ""
  export let message = undefined
  export let isCrypto = false

  export let severity: AlertSeverity

  $: if (!icon) {
    icon = alertDetails[severity]?.icon ?? DEFAULT_ICON
  }
  $: if (!headingMessage) {
    headingMessage = alertDetails[severity]?.headingMessage ?? DEFAULT_HEADING
  }
</script>

<div class="flex flex-row items-start p-medium tablet:flex-col">
  <div
    class="alert-icon mr-medium flex flex-none tablet:mr-0 tablet:flex-row tablet:items-center"
  >
    {#if isCrypto}
      <ZeroHashLogoCompact />
    {:else}
      <span
        class="tw-icon-{icon} icon-triple-extra-large tablet:pt-0.5 tablet:icon-extra-large"
      />
    {/if}
    <span class="ml-2 self-center tablet:font-x-small-500 small:hidden"
      >{headingMessage}</span
    >
  </div>
  <div>
    <div
      class="heading font-xxx-small-700-caps mb-double-extra-small flex items-center tablet:hidden"
    >
      {headingMessage}
    </div>
    <p class="alert-body font-small-400 text-general-default-text">
      <slot>
        {message}
      </slot>
    </p>
  </div>
</div>

<style lang="postcss">
  .alert-icon,
  .heading {
    color: var(--toastHighlight);
  }
</style>
