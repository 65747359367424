import BigNumber from 'bignumber.js'
import type { AnyObject, Flags, Maybe, Message } from 'yup'
import { Schema } from 'yup'
import { isNilOrEmpty } from '../../../util/string'

/* eslint-disable no-redeclare */
export function bigNumber(): BigNumberSchema

export function bigNumber<
  T extends BigNumber,
  TContext extends Maybe<AnyObject> = AnyObject
>(): BigNumberSchema<T | undefined, TContext>

export function bigNumber() {
  return new BigNumberSchema()
}
/* eslint-enable no-redeclare */

interface BigNumberLocale {
  min?: Message<{ min: BigNumber }>
  max?: Message<{ max: BigNumber }>
  lessThan?: Message<{ less: BigNumber }>
  moreThan?: Message<{ more: BigNumber }>
  positive?: Message<{ more: BigNumber }>
  negative?: Message<{ less: BigNumber }>
  integer?: Message
  notType?: Message
}

const locale: Required<BigNumberLocale> = {
  min: ({ min }: { min: BigNumber }) => ({
    key: 'yup.common.number.min',
    values: { min: min.toString() }
  }),
  max: ({ max }: { max: BigNumber }) => ({
    key: 'yup.common.number.max',
    values: { max: max.toString() }
  }),
  lessThan: ({ less }: { less: BigNumber }) => ({
    key: 'yup.common.number.lessThan',
    values: { less: less.toString() }
  }),
  moreThan: ({ more }: { more: BigNumber }) => ({
    key: 'yup.common.number.moreThan',
    values: { more: more.toString() }
  }),
  positive: 'yup.common.number.positive',
  negative: 'yup.common.number.negative',
  integer: 'yup.common.number.integer',
  notType: 'yup.common.bigNumber.notType'
}

class BigNumberSchema<
  TType extends Maybe<BigNumber> = BigNumber | undefined,
  TContext = AnyObject,
  TDefault = undefined,
  TFlags extends Flags = ''
> extends Schema<TType, TContext, TDefault, TFlags> {
  constructor() {
    super({
      type: 'bigNumber',
      check: (value: any): value is NonNullable<TType> => {
        if (!BigNumber.isBigNumber(value)) {
          return false
        }

        return !value.isNaN()
      }
    })

    this.withMutation(() => {
      this.transform((value: BigNumber.Value, _originalValue, schema) => {
        if (isNilOrEmpty(value)) {
          return null
        }

        if (schema.isType(value)) {
          return value
        }

        return new BigNumber(value)
      })
    })
  }

  typeError(message = locale.notType) {
    return super.typeError(message)
  }

  min(min: BigNumber, message = locale.min) {
    return this.test({
      message,
      name: 'min',
      exclusive: true,
      params: { min },
      skipAbsent: true,
      test(value: Maybe<BigNumber>) {
        return value!.isGreaterThanOrEqualTo(this.resolve(min))
      }
    })
  }

  max(max: BigNumber, message = locale.max) {
    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: { max },
      skipAbsent: true,
      test(value: Maybe<BigNumber>) {
        return value!.isLessThanOrEqualTo(this.resolve(max))
      }
    })
  }

  lessThan(less: BigNumber, message = locale.lessThan) {
    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: { less },
      skipAbsent: true,
      test(value: Maybe<BigNumber>) {
        return value!.isLessThan(this.resolve(less))
      }
    })
  }

  moreThan(more: BigNumber, message = locale.moreThan) {
    return this.test({
      message,
      name: 'min',
      exclusive: true,
      params: { more },
      skipAbsent: true,
      test(value: Maybe<BigNumber>) {
        return value!.isGreaterThan(this.resolve(more))
      }
    })
  }

  positive(msg = locale.positive) {
    return this.moreThan(new BigNumber('0'), msg)
  }

  negative(msg = locale.negative) {
    return this.lessThan(new BigNumber('0'), msg)
  }

  integer(message = locale.integer) {
    return this.test({
      name: 'integer',
      message,
      skipAbsent: true,
      test: value => new BigNumber(value!).isInteger()
    })
  }
}
bigNumber.prototype = BigNumberSchema.prototype
