<script lang="ts">
  import { PlaidInstitution } from "/@/account-management/control/institution-search"
  import { createEventDispatcher } from "svelte"

  export let defaultInstitutions: PlaidInstitution[]

  const dispatch = createEventDispatcher()

  function handleClick(defaultInstitution: PlaidInstitution) {
    dispatch("didSelectInstitution", defaultInstitution.institutionId)
  }
</script>

<div
  class="
    institution-grid
    flex
    flex-row
    flex-wrap
    justify-center
    gap-extra-small"
>
  {#each defaultInstitutions as defaultInstitution}
    <button
      type="button"
      class="
        institution-grid-button
        flex
        h-20
        w-40
        cursor-pointer
        items-center
        justify-center
        rounded
        bg-button-institution-badge"
      on:click={() => handleClick(defaultInstitution)}
    >
      <img
        src={defaultInstitution.imgPath}
        alt={defaultInstitution.name}
        class="
          max-h-14
          w-28
          object-contain"
      />
    </button>
  {/each}
</div>
