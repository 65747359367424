import type HttpClient from '../../tastyworks/http'
import OpenApiAccessEntitlement, {
  OPEN_API_ACCESS_ENTITLEMENT_DESER,
  OpenApiAccessEntitlementAction
} from '../../tastyworks/open-api-access-entitlement'
import {
  ItemsResponse,
  parseSingleResponse,
  SingleResponse
} from '../../tastyworks/response'

export class OpenApiAccessEntitlementService {
  private readonly userMeClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.userMeClient = baseHttpClient.nested('/users/:userId', {
      userId: 'me'
    })
  }

  readonly index = async (): Promise<ItemsResponse<OpenApiAccessEntitlement>> =>
    this.userMeClient.indexSimple(
      OPEN_API_ACCESS_ENTITLEMENT_DESER.toParser(OpenApiAccessEntitlement),
      'open-api-access-entitlements'
    )

  readonly create = async (
    action: OpenApiAccessEntitlementAction
  ): Promise<SingleResponse<OpenApiAccessEntitlement>> => {
    const helper = await this.userMeClient.postJson(
      'open-api-access-entitlements',
      { action }
    )

    return parseSingleResponse(
      helper,
      OPEN_API_ACCESS_ENTITLEMENT_DESER.toParser(OpenApiAccessEntitlement)
    )
  }
}
