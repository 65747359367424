import { COLUMN_WIDTH, ROW_HEIGHT } from "/@/util/dimension.js"
import { px } from "/@/util/style-dimensions.js"
import goog from "/@lib/boulangerie"

const OptionType = goog.module.get("com.dough.trade.model.OptionType")
const PositionType = goog.module.get("com.dough.trade.model.PositionType")

// Trading table-mode dimensions.
export const TABLE_GUTTER_SIZE = COLUMN_WIDTH
export const TABLE_ROW_HEIGHT = ROW_HEIGHT
export const TABLE_STRIKES_COLUMN_WIDTH = COLUMN_WIDTH * 2

// CSS width of the strikes-column.
export const STRIKES_COLUMN_WIDTH = px(TABLE_STRIKES_COLUMN_WIDTH)

// CSS distance from left or right to strikes-column edge.
export const STRIKES_EDGE = `calc((100% - ${STRIKES_COLUMN_WIDTH}) / 2)`

// CSS gutter-size.
export const GUTTER_SIZE = px(TABLE_GUTTER_SIZE)

// Return CSS width of a column given the number of columns.
export function getColumnPxWidth(columns) {
  return `calc(${STRIKES_EDGE} / ${columns})`
}

// Return CSS left-offset for a column given number of columns, option-type,
// and position-type. This is somewhat fixed; the middle of the options-table
// always has the following layout (same fixed-columns in the centre):
//   | ... | call-bid | call-ask | strikes | put-bid | put-ask | ... |
export function getColumnPxOffsets(columns, optionType, positionType) {
  const columnWidth = getColumnPxWidth(columns)
  const optionTypeOffset =
    OptionType.CALL === optionType
      ? `calc((${columns} - 2) * ${columnWidth})`
      : `calc(${STRIKES_EDGE} + ${STRIKES_COLUMN_WIDTH})`
  const positionTypeOffset =
    PositionType.SHORT === positionType ? px(0) : columnWidth

  const left = `calc(${optionTypeOffset} + ${positionTypeOffset})`
  const right = `calc(100% - ${left} - ${columnWidth})`

  return { left, right }
}

// Number of pixels occupied by fixed-width elements.
const FIXED_WIDTH = 2 * TABLE_GUTTER_SIZE + TABLE_STRIKES_COLUMN_WIDTH

// Return the ideal number of columns (per side) given a width in pixels.
export function getIdealColumnCount(width) {
  return Math.floor((width - FIXED_WIDTH) / 2 / (1.5 * COLUMN_WIDTH))
}

// Return the number of custom columns to display (per side).
export function getCustomColumnCount(width) {
  const ideal = getIdealColumnCount(width) - 2 // bid and ask always present.
  return Math.max(ideal, 1) // Always show at least one.
}
