<script>
  import soundEffectsManager from "/@/trading/sound-effects-manager.js"
  import DropdownField from "./DropdownField.svelte"

  export let preferenceField
  export let label
  export let soundEffects

  let value = preferenceField.getValue()

  function previewSoundEffect() {
    soundEffectsManager.play(value, true)
  }

  function changeHandler() {
    value = preferenceField.getValue()
  }
</script>

<div
  class="
    sound-effect-field
    container
    grid
    grid-cols-2
    items-end
    gap-2"
  style:grid-template-columns="1fr calc(0.5 * var(--horizontal-meter))"
>
  <DropdownField
    {preferenceField}
    {label}
    options={soundEffects}
    on:change={changeHandler}
  />
  <button
    class="
      preview
      button-base
      tw-icon-Media-Play
      button
      h-8
      w-8
      cursor-pointer
      bg-general-tertiary-surface
      text-right
      text-icon-general-secondary-action
      icon-double-extra-large"
    on:click={previewSoundEffect}
  />
</div>
