import { SchemaDeSerBuilder } from './deser'
import type { SchemaDeSer } from './deser'

export class CompanyGeneralInformation {
  companyStatus = ''
  companyTypeCode = ''
  companyTypeValue = ''
  latestAvailableAnnual = ''
  latestAvailableInterim = ''
  lastModified = ''
  employeesAsOfDate = ''
  employeesAsOfValue = ''
  mostRecentExchangeAsOfDate = ''
  mostRecentExchangeAsOfValue = ''
  reportingCurrency = ''
  sharesAsOfDate = ''
  sharesAsOfValue = ''
  totalFloat = ''
}

export const COMPANY_GENERAL_DESER: SchemaDeSer<CompanyGeneralInformation> =
  new SchemaDeSerBuilder(CompanyGeneralInformation)
    .ofStrings(
      'companyStatus',
      'companyTypeCode',
      'companyTypeValue',
      'latestAvailableAnnual',
      'latestAvailableInterim',
      'lastModified',
      'employeesAsOfValue',
      'employeesAsOfDate',
      'mostRecentExchangeAsOfDate',
      'mostRecentExchangeAsOfValue',
      'reportingCurrency',
      'sharesAsOfDate',
      'sharesAsOfValue',
      'totalFloat'
    )
    .toDeSer()

export class Officer {
  age = 0
  firstName = ''
  lastName = ''
  middleInitial?: string
  rank = 0
  title = ''
}

export const OFFICER_DESER: SchemaDeSer<Officer> = new SchemaDeSerBuilder(
  Officer
)
  .ofStrings('age', 'firstName', 'lastName', 'middleInitial', 'rank', 'title')
  .toDeSer()

export class ContactInfo {
  city = ''
  country = ''
  countryCode = ''
  phoneNumber = ''
  postalCode = ''
  stateRegion = ''
  streetOne = ''
  streetTwo? = ''
  streetThree? = ''
}

export const CONTACT_INFO_DESER: SchemaDeSer<ContactInfo> =
  new SchemaDeSerBuilder(ContactInfo)
    .ofStrings(
      'city',
      'country',
      'countryCode',
      'phoneNumber',
      'postalCode',
      'stateRegion',
      'streetOne',
      'streetTwo',
      'streetThree'
    )
    .toDeSer()

export class Exchange {
  code = ''
  country = ''
  exchangeName = ''
}

export const EXCHANGE_DESER: SchemaDeSer<Exchange> = new SchemaDeSerBuilder(
  Exchange
)
  .ofStrings('code', 'country', 'exchangeName')
  .toDeSer()

export class PeerInfo {
  industry = ''
  reutersBusinessSectionScheme = ''
  sector = ''
  thomsonReutersBusinessClassification = ''
}

export const PEER_INFO_DESER: SchemaDeSer<PeerInfo> = new SchemaDeSerBuilder(
  PeerInfo
)
  .ofStrings(
    'industry',
    'reutersBusinessSectionScheme',
    'sector',
    'thomsonReutersBusinessClassification'
  )
  .toDeSer()

export class Ratios {
  enterpriseValue = ''
  exchangeRate = ''
  highPrice = ''
  latestClosePrice = ''
  lowPrice = ''
  marketCap = ''
  numberOfEmployees = ''
  reportingCurrency = ''
  priceCurrency = ''
  priceToBookValue = ''
  priceToEarningsExcludingExtraItems = ''
  pricingDate = ''
  quarterlyBookValuePerShare = ''
  quarterlyCashFlowPerShare = ''
  ttmCashflowPerShare = ''
  ttmDividendPerShare = ''
  ttmEbitda = ''
  ttmEpsExcludingExtraItems = ''
  ttmGrossMargin = ''
  ttmNetIncomeAvailableToCommon = ''
  ttmRevenue = ''
  ttmRevenuePerShare = ''
  ttmRoePercent = ''
  ttmPriceToRevenuePerShare = ''
  volumeTenDayAverage = ''
}

export const RATIOS_DESER: SchemaDeSer<Ratios> = new SchemaDeSerBuilder(Ratios)
  .ofStrings(
    'enterpriseValue',
    'exchangeRate',
    'highPrice',
    'latestClosePrice',
    'lowPrice',
    'marketCap',
    'numberOfEmployees',
    'reportingCurrency',
    'priceCurrency',
    'priceToBookValue',
    'priceToEarningsExcludingExtraItems',
    'pricingDate',
    'quarterlyBookValuePerShare',
    'quarterlyCashFlowPerShare',
    'ttmCashflowPerShare',
    'ttmDividendPerShare',
    'ttmEbitda',
    'ttmEpsExcludingExtraItems',
    'ttmGrossMargin',
    'ttmNetIncomeAvailableToCommon',
    'ttmRevenue',
    'ttmRevenuePerShare',
    'ttmRoePercent',
    'ttmPriceToRevenuePerShare'
  )
  .ofString('volumeTenDayAverage', 'volume-10-day-avg')
  .toDeSer()

export class Forecast {
  consensusRecommendation = ''
  consensusType = ''
  earningsBasis = ''
  fiscalYear = 0
  fiscalYearEndMonth = 0
  interimEndCalYear = 0
  interimEndMonth = 0
  projectedDps = ''
  projectedEps = ''
  projectedLongTermGrowthRate = ''
  projectedPriceToEarnings = ''
  projectedProfit = ''
  projectedQuarterEps = ''
  projectedQuarterSales = ''
  projectedSales = ''
  targetPrice = ''
}

export const FORECAST_DESER: SchemaDeSer<Forecast> = new SchemaDeSerBuilder(
  Forecast
)
  .ofStrings(
    'consensusRecommendation',
    'consensusType',
    'earningsBasis',
    'fiscalYear',
    'fiscalYearEndMonth',
    'interimEndCalYear',
    'interimEndMonth',
    'projectedDps',
    'projectedEps',
    'projectedLongTermGrowthRate',
    'projectedPriceToEarnings',
    'projectedProfit',
    'projectedQuarterEps',
    'projectedQuarterSales',
    'projectedSales',
    'targetPrice'
  )
  .toDeSer()

export class SnapshotReports {
  businessSummary = ''
  companyGeneralInformation = new CompanyGeneralInformation()
  companyName = ''
  contactInfo = new ContactInfo()
  exchange = new Exchange()
  financialSummary = ''
  forecast = new Forecast()
  officers: Array<Officer> = []
  peerInfo = new PeerInfo()
  ratios = new Ratios()
}

export const SNAPSHOT_REPORTS_DESER: SchemaDeSer<SnapshotReports> =
  new SchemaDeSerBuilder(SnapshotReports)
    .ofString('businessSummary')
    .ofNested(
      'companyGeneralInformation',
      COMPANY_GENERAL_DESER,
      CompanyGeneralInformation,
      'co-general-info'
    )
    .ofString('companyName')
    .ofNested('contactInfo', CONTACT_INFO_DESER, ContactInfo)
    .ofNested('exchange', EXCHANGE_DESER, Exchange)
    .ofString('financialSummary')
    .ofNested('forecast', FORECAST_DESER, Forecast)
    .ofArray('officers', OFFICER_DESER, Officer)
    .ofNested('peerInfo', PEER_INFO_DESER, PeerInfo)
    .ofNested('ratios', RATIOS_DESER, Ratios)
    .toDeSer()
