import { number, object, ref, string } from 'yup'
import { buildSchema } from './util/build-schema'

const MAX_AMOUNT = 250_000

const schema = object({
  accountNumber: string().required(),
  amount: number().required().positive().max(MAX_AMOUNT),
  destinationAccountNumber: string()
    .required()
    .notOneOf([ref('accountNumber')])
})

export const internalCashTransferSchema = buildSchema(
  'InternalCashTransfer',
  schema
)
