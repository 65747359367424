<script lang="ts">
  let cssClass = ""
  export { cssClass as class }
  export let showLabel = true

  const element = showLabel ? "label" : "div"
</script>

<div
  class="
      input-field-label
      flex
      flex-col
      items-stretch
      {cssClass}"
>
  <!-- NOTE: [KT] input contained within slot https://github.com/sveltejs/svelte/pull/5323 -->
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <svelte:element
    this={element}
    class="
        flex
        flex-col
        items-stretch
        gap-y-extra-small
        text-general-primary-label"
  >
    {#if showLabel}
      <slot name="label" />
    {/if}
    <slot name="input" />
  </svelte:element>

  <slot name="error" />
</div>
