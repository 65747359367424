import { SvelteComponent } from "svelte"
import { UserAnalyticsTag } from "/@/util/user-analytics"

export interface PopoverProps {
  [key: string]: any
}

export interface PopoverOption {
  // Component to use in rendering this option. Falsy values will default to
  // PopoverLabelOption.
  component?: typeof SvelteComponent<any>

  disabled?: boolean

  // The separator flag causes a horizontal top-border to render on the option
  // to distinguish groups of options.
  separator?: boolean

  userAnalyticsTag?: UserAnalyticsTag | null

  // Children. Will open another PopoverOptions if defined.
  options?: PopoverOption[]
}

// XXX: Turns out the mixed type-vars approach is required to make this usable
//      from [untyped] JavaScript.
export function asyncPopover<R>(
  PopoverComponent: typeof SvelteComponent<any>,
  props: PopoverProps = {},
  abortSignal: AbortSignal = null
) {
  const popover = new PopoverComponent({ props, target: document.body })

  return new Promise<R>((resolve, _reject) => {
    popover.$on("close", (event: CustomEvent) => {
      resolve(event.detail)
      popover.$destroy()
    })

    if (abortSignal) {
      abortSignal.addEventListener("abort", () => {
        resolve(null)
        popover.$destroy()
      })
    }
  })
}
