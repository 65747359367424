import { object, string } from 'yup'
import { ONE_TIME_PASSWORD } from '../regex/constants'
import { buildSchema } from './util/build-schema'

const schema = object({
  oneTimePassword: string().required().matches(ONE_TIME_PASSWORD, {
    message: 'yup.twoFactorMethod.oneTimePassword'
  })
})

export const twoFactorMethodSchema = buildSchema('TwoFactorMethod', schema)
