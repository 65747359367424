import {
  FINANCIALS_STATEMENT_DESER,
  FinancialStatement
} from '../../tastyworks/financial-statements'
import type HttpClient from '../../tastyworks/http'
import type { Params } from '../../tastyworks/request'
import type { SingleResponse } from '../../tastyworks/response'
import { parseSingleResponse } from '../../tastyworks/response'

export default class FinancialStatementService {
  readonly baseHttpClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.baseHttpClient = baseHttpClient
  }

  readonly show = async (
    symbol: string,
    exchange = '',
    instrumentType = ''
  ): Promise<SingleResponse<FinancialStatement>> => {
    const params: Params = {
      exchange,
      'instrument-type': instrumentType
    }

    const helper = await this.baseHttpClient.get(
      `/financial-statements/${symbol.toUpperCase()}`,
      params
    )

    return parseSingleResponse(
      helper,
      FINANCIALS_STATEMENT_DESER.toParser(FinancialStatement)
    )
  }
}
