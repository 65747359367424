import {
  AdditionalTradingPreferences,
  AdvancedTradingPreference,
} from "/@/account-management/model"
import { createPromiseHash } from "/@/util/promise-hash"
import { twApiClient } from "/@lib/tastyworks-rest"
import {
  Account,
  AdvancedTradingPermission,
  AdvancedTradingRequestEligibility,
  AdvancedTradingRequestMap,
  createAdvancedTradingRequestMap,
  FuturesTradingRequest,
  OPTIONS_LEVEL,
  TRADING_REQUEST_ELIGIBILITY_STATUSES,
  TRADING_REQUEST_TYPES,
  TradingStatus,
} from "@tastyworks/tastyworks-api"
import { derived, get, Readable, Writable, writable } from "svelte/store"
import { currentAccount } from "./account"
import { isIgEligibleTradingPreference } from "/@/account-management/ig/advanced-trading-preferences/ig-util"
import { IG_APP } from "/@lib/shared"
import { THIRD_PARTY_TRADING_REQUEST_TYPES } from "/@/account-management/model/advanced-trading-preference"

export const accountNumber: Writable<string | null> = writable(null)

export const advancedTradingPermissionPromise = derived(
  accountNumber,
  ($accountNumber) =>
    $accountNumber
      ? twApiClient.advancedTradingPermissionService.show($accountNumber)
      : null
)

export const advancedTradingRequestsPromise = derived(
  accountNumber,
  ($accountNumber) =>
    $accountNumber
      ? twApiClient.advancedTradingRequestService.indexByRequestType(
          $accountNumber
        )
      : null
)

export const advancedTradingRequestMap: Readable<AdvancedTradingRequestMap | null> =
  derived(
    advancedTradingRequestsPromise,
    ($advancedTradingRequestsPromise, set) => {
      if ($advancedTradingRequestsPromise) {
        set(createAdvancedTradingRequestMap())
        $advancedTradingRequestsPromise.then((response) => {
          set(response.data)
        })
      } else {
        set(null)
      }
    }
  )

export const customerAuthorityPromise = derived(
  accountNumber,
  ($accountNumber) =>
    $accountNumber
      ? twApiClient.customerAuthorityService.index($accountNumber)
      : null
)

export const tradingStatusPromise = derived(accountNumber, ($accountNumber) =>
  $accountNumber ? twApiClient.tradingStatusService.show($accountNumber) : null
)

export const futuresTradingRequestPromise = derived(
  accountNumber,
  ($accountNumber) =>
    $accountNumber
      ? twApiClient.accountFuturesTradingRequestService.show($accountNumber)
      : null
)

function determineFuturesEligibility(currentAccount: Account) {
  let status: TRADING_REQUEST_ELIGIBILITY_STATUSES
  const eligibility = new AdvancedTradingRequestEligibility()

  if (currentAccount.isClosed) {
    status = TRADING_REQUEST_ELIGIBILITY_STATUSES.UNAVAILABLE
  } else if (currentAccount.isCash && !currentAccount.isAnyIra) {
    status = TRADING_REQUEST_ELIGIBILITY_STATUSES.UNAVAILABLE
  } else if (currentAccount.isAccountTypeFuturesEligible) {
    status = TRADING_REQUEST_ELIGIBILITY_STATUSES.ELIGIBLE
  } else {
    status = TRADING_REQUEST_ELIGIBILITY_STATUSES.UNAVAILABLE
  }
  eligibility.status = status

  return eligibility
}

export const eligibilityPromiseHash = derived(
  [accountNumber, currentAccount],
  ([$accountNumber, $currentAccount]) => {
    if ($accountNumber) {
      const promises = Object.fromEntries(
        Object.values({
          ...TRADING_REQUEST_TYPES,
          ...AdditionalTradingPreferences,
        }).map((tradingPreferenceType) => {
          /*
              NOTE: [LW] - FUTURES_TRADING isn't supported through the eligibility or advanced-trading-preferences endpoints,
                           therefore we need to evaluate the eligibility separetely. An improvement in the future would be to
                           migrate this logic into the backend.
           */
          if (
            AdditionalTradingPreferences.FUTURES_TRADING ===
            tradingPreferenceType
          ) {
            return [
              AdditionalTradingPreferences.FUTURES_TRADING,
              { data: determineFuturesEligibility($currentAccount) },
            ]
          } else {
            return [
              tradingPreferenceType,
              twApiClient.advancedTradingRequestService.showEligibility(
                $accountNumber,
                tradingPreferenceType
              ),
            ]
          }
        })
      )

      return createPromiseHash(promises)
    }

    return null
  }
)

export const promiseHash = derived(
  [
    advancedTradingPermissionPromise,
    advancedTradingRequestsPromise,
    tradingStatusPromise,
    eligibilityPromiseHash,
    futuresTradingRequestPromise,
    customerAuthorityPromise,
  ],
  ($promises) =>
    $promises.every((p) => p)
      ? createPromiseHash({
          advancedTradingPermission: $promises[0],
          advancedTradingRequests: $promises[1],
          authorities: $promises[5],
          eligibility: $promises[3],
          futuresTradingRequest: $promises[4],
          tradingStatus: $promises[2],
        })
      : null
)

export function refresh() {
  const tmp = get(accountNumber)
  if (tmp) {
    accountNumber.set(null)
    accountNumber.set(tmp)
  }
}

export enum AdvancedTradingPreferenceState {
  ELIGIBLE = "eligible",
  ENABLED = "enabled",
  PENDING = "pending",
  REQUESTED = "requested",
  UNAVAILABLE = "unavailable",
  UPGRADE_OPTIONS_LEVEL = "upgrade-options-level",
  UPGRADE_IRA_OPTIONS_LEVEL = "upgrade-ira-options-level",
  UPGRADE_SUITABILITY = "upgrade-suitability",
  PENDING_JOINT_AGREEMENT = "pending-joint-agreement",
}

const ALWAYS_VISIBLE_REQUEST_TYPES = [
  TRADING_REQUEST_TYPES.CRYPTOCURRENCY_TRADING,
  AdditionalTradingPreferences.FUTURES_TRADING,
]

const STATE = {
  [TRADING_REQUEST_TYPES.CRYPTOCURRENCY_TRADING]: (
    preference: AdvancedTradingPreference,
    eligibility: AdvancedTradingRequestEligibility,
    tradingStatus: TradingStatus,
    _advancedTradingPermission: AdvancedTradingPermission,
    _currentAccount: Account
  ) => {
    if (tradingStatus?.isCryptocurrencyEnabled) {
      return AdvancedTradingPreferenceState.ENABLED
    } else if (preference?.isCreated) {
      return AdvancedTradingPreferenceState.REQUESTED
    } else if (eligibility?.isEligible) {
      return AdvancedTradingPreferenceState.ELIGIBLE
    } else if (eligibility?.needsSuitabilityUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_SUITABILITY
    } else if (eligibility?.needsOptionsLevelUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_OPTIONS_LEVEL
    }
    return AdvancedTradingPreferenceState.UNAVAILABLE
  },
  [TRADING_REQUEST_TYPES.EQUITY_OFFERING]: (
    preference: AdvancedTradingPreference,
    eligibility: AdvancedTradingRequestEligibility,
    tradingStatus: TradingStatus,
    _advancedTradingPermission: AdvancedTradingPermission,
    _currentAccount: Account
  ) => {
    if (tradingStatus?.isEquityOfferingEnabled) {
      return AdvancedTradingPreferenceState.ENABLED
    } else if (preference?.isCreated) {
      return AdvancedTradingPreferenceState.REQUESTED
    } else if (eligibility?.isEligible) {
      return AdvancedTradingPreferenceState.ELIGIBLE
    } else if (eligibility?.needsSuitabilityUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_SUITABILITY
    } else if (eligibility?.needsOptionsLevelUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_OPTIONS_LEVEL
    }
    return AdvancedTradingPreferenceState.UNAVAILABLE
  },
  [TRADING_REQUEST_TYPES.INTRADAY_FUTURES_MARGIN]: (
    preference: AdvancedTradingPreference,
    eligibility: AdvancedTradingRequestEligibility,
    _tradingStatus: TradingStatus,
    advancedTradingPermission: AdvancedTradingPermission,
    _currentAccount: Account
  ) => {
    if (advancedTradingPermission?.isIntradayFuturesMarginApproved) {
      return AdvancedTradingPreferenceState.ENABLED
    } else if (preference?.isCreated) {
      return AdvancedTradingPreferenceState.REQUESTED
    } else if (eligibility?.isEligible) {
      return AdvancedTradingPreferenceState.ELIGIBLE
    } else if (!IG_APP && eligibility?.needsSuitabilityUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_SUITABILITY
    } else if (!IG_APP && eligibility?.needsOptionsLevelUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_OPTIONS_LEVEL
    }
    return AdvancedTradingPreferenceState.UNAVAILABLE
  },
  [TRADING_REQUEST_TYPES.IRA_SHORT_CALL]: (
    _request: AdvancedTradingPreference,
    eligibility: AdvancedTradingRequestEligibility,
    tradingStatus: TradingStatus,
    _advancedTradingPermission: AdvancedTradingPermission,
    _currentAccount: Account
  ) => {
    if (OPTIONS_LEVEL.DEFINED_RISK_PLUS_NAKED === tradingStatus?.optionsLevel) {
      return AdvancedTradingPreferenceState.ENABLED
    } else if (eligibility?.isEligible) {
      return AdvancedTradingPreferenceState.ELIGIBLE
    } else if (eligibility?.needsSuitabilityUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_SUITABILITY
    } else if (eligibility?.needsOptionsLevelUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_OPTIONS_LEVEL
    }
    return AdvancedTradingPreferenceState.UNAVAILABLE
  },
  [TRADING_REQUEST_TYPES.PORTFOLIO_MARGIN]: (
    preference: AdvancedTradingPreference,
    eligibility: AdvancedTradingRequestEligibility,
    _tradingStatus: TradingStatus,
    advancedTradingPermission: AdvancedTradingPermission,
    _currentAccount: Account
  ) => {
    if (advancedTradingPermission?.isPortfolioMarginActivated) {
      return AdvancedTradingPreferenceState.ENABLED
    } else if (advancedTradingPermission?.isPortfolioMarginPending) {
      return AdvancedTradingPreferenceState.PENDING
    } else if (preference?.isCreated) {
      return AdvancedTradingPreferenceState.REQUESTED
    } else if (eligibility?.isEligible) {
      return AdvancedTradingPreferenceState.ELIGIBLE
    } else if (!IG_APP && eligibility?.needsSuitabilityUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_SUITABILITY
    } else if (!IG_APP && eligibility?.needsOptionsLevelUpgrade) {
      return AdvancedTradingPreferenceState.UPGRADE_OPTIONS_LEVEL
    }
    return AdvancedTradingPreferenceState.UNAVAILABLE
  },
  [AdditionalTradingPreferences.FUTURES_TRADING]: (
    preference: AdvancedTradingPreference,
    eligibility: AdvancedTradingRequestEligibility,
    tradingStatus: TradingStatus,
    _advancedTradingPermission: AdvancedTradingPermission,
    currentAccount: Account
  ) => {
    if (eligibility?.isUnavailable) {
      return AdvancedTradingPreferenceState.UNAVAILABLE
    } else if (preference?.futuresTradingRequest?.isRejected) {
      return AdvancedTradingPreferenceState.UNAVAILABLE
    } else if (preference?.futuresTradingRequest?.isFailed) {
      return AdvancedTradingPreferenceState.PENDING
    } else if (
      preference?.futuresTradingRequest?.needsSecondaryFuturesAgreement
    ) {
      return AdvancedTradingPreferenceState.ELIGIBLE
    } else if (preference?.futuresTradingRequest?.isPendingJoint) {
      return AdvancedTradingPreferenceState.PENDING_JOINT_AGREEMENT
    } else if (preference?.futuresTradingRequest?.isPendingCompletion) {
      return AdvancedTradingPreferenceState.PENDING
    } else if (
      !preference?.futuresTradingRequest?.isComplete &&
      preference.isCreated
    ) {
      return AdvancedTradingPreferenceState.PENDING
    } else if (preference?.futuresTradingRequest?.isComplete) {
      return AdvancedTradingPreferenceState.ENABLED
    } else if (
      tradingStatus?.isFuturesEligible &&
      currentAccount.isAccountTypeFuturesEligible
    ) {
      return AdvancedTradingPreferenceState.ELIGIBLE
    } else if (
      !IG_APP &&
      !tradingStatus?.isFuturesEligible &&
      currentAccount.isAnyIra
    ) {
      return AdvancedTradingPreferenceState.UPGRADE_IRA_OPTIONS_LEVEL
    } else if (
      !IG_APP &&
      !tradingStatus?.isFuturesEligible &&
      currentAccount.isAccountTypeFuturesEligible
    ) {
      return AdvancedTradingPreferenceState.UPGRADE_OPTIONS_LEVEL
    }

    return AdvancedTradingPreferenceState.UNAVAILABLE
  },
}

export function computeAdvancedTradingPreferenceState({
  preference,
  eligibility,
  tradingStatus,
  advancedTradingPermission,
  currentAccount,
}: {
  preference?: AdvancedTradingPreference
  eligibility?: AdvancedTradingRequestEligibility
  tradingStatus?: TradingStatus
  advancedTradingPermission?: AdvancedTradingPermission
  futuresTradingRequest?: FuturesTradingRequest
  currentAccount?: Account
} = {}) {
  // Fail closed.
  if (!preference) {
    return AdvancedTradingPreferenceState.UNAVAILABLE
  }

  return STATE[preference.tradingPreferenceType](
    preference,
    eligibility,
    tradingStatus,
    advancedTradingPermission,
    currentAccount
  )
}

export class AdvancedTradingPreferenceItem {
  constructor(
    readonly preference: AdvancedTradingPreference,
    readonly isAlwaysVisible: boolean,
    readonly state: AdvancedTradingPreferenceState,
    readonly reason: string
  ) {}

  get accountNumber() {
    return this.preference.accountNumber
  }

  get tradingPreferenceType() {
    return this.preference.tradingPreferenceType
  }

  get isEnabled() {
    return this.state === AdvancedTradingPreferenceState.ENABLED
  }

  get isEligibile() {
    return this.state === AdvancedTradingPreferenceState.ELIGIBLE
  }

  get isPending() {
    return this.state === AdvancedTradingPreferenceState.PENDING
  }

  get isPendingJointAgreement() {
    return this.state === AdvancedTradingPreferenceState.PENDING_JOINT_AGREEMENT
  }

  get isRequested() {
    return this.state === AdvancedTradingPreferenceState.REQUESTED
  }

  get isThirdParty() {
    return THIRD_PARTY_TRADING_REQUEST_TYPES.includes(
      this.preference.tradingPreferenceType
    )
  }

  get isUnavailable() {
    return this.state === AdvancedTradingPreferenceState.UNAVAILABLE
  }

  get needsSuitabilityUpgrade() {
    return this.state === AdvancedTradingPreferenceState.UPGRADE_SUITABILITY
  }

  get needsOptionsLevelUpgradeIra() {
    return (
      this.state === AdvancedTradingPreferenceState.UPGRADE_IRA_OPTIONS_LEVEL
    )
  }

  get needsOptionsLevelUpgrade() {
    return this.state === AdvancedTradingPreferenceState.UPGRADE_OPTIONS_LEVEL
  }

  get requiresUpgrade() {
    return (
      this.needsOptionsLevelUpgrade ||
      this.needsSuitabilityUpgrade ||
      this.needsOptionsLevelUpgradeIra
    )
  }
}

function getEligibleRequestTypes() {
  return Object.values({
    ...TRADING_REQUEST_TYPES,
    ...AdditionalTradingPreferences,
  }).filter((requestType) => {
    // NOTE: [KT] Equity offering is pending compliance review and implementation
    if (requestType === TRADING_REQUEST_TYPES.EQUITY_OFFERING) {
      return false
    }
    if (IG_APP) {
      return isIgEligibleTradingPreference(requestType)
    }
    return true
  })
}

// Should potentially return a promise so we can await this rather than the
// promiseHash, but we don't really have the same UI infrastructure for that,
// and it's harder to deal with api errors that way.
//TODO: [LW] - Investigate a cleaner way to handle the IG stuff. We could create a separate IG
//             store and pass it into AdvancedTradingPreferencesView as a prop
export const preferenceItems = derived(
  [promiseHash, accountNumber, currentAccount],
  async ([$promiseHash, $accountNumber, $currentAccount]) => {
    if ($promiseHash) {
      const {
        advancedTradingPermission,
        advancedTradingRequests,
        eligibility,
        tradingStatus,
        futuresTradingRequest,
      } = await $promiseHash

      return getEligibleRequestTypes().map(
        (tradingPreferenceType: TRADING_REQUEST_TYPES) => {
          const advancedTradingPreference = new AdvancedTradingPreference(
            tradingPreferenceType,
            $accountNumber,
            advancedTradingRequests.data,
            futuresTradingRequest.data
          )

          return new AdvancedTradingPreferenceItem(
            advancedTradingPreference,
            ALWAYS_VISIBLE_REQUEST_TYPES.includes(
              advancedTradingPreference.tradingPreferenceType
            ),
            computeAdvancedTradingPreferenceState({
              advancedTradingPermission: advancedTradingPermission?.data,
              currentAccount: $currentAccount,
              eligibility: eligibility[tradingPreferenceType]?.data,
              preference: advancedTradingPreference,
              tradingStatus: tradingStatus?.data,
            }),
            eligibility[tradingPreferenceType]?.data?.reason
          )
        }
      )
    }
    return null
  }
)
