<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="industrial" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Industrial</title>
<path d="M1.5 13.672c0-0.276 0.224-0.5 0.5-0.5h16c0.276 0 0.5 0.224 0.5 0.5v5.328c0 0.276-0.224 0.5-0.5 0.5h-16c-0.276 0-0.5-0.224-0.5-0.5v-5.328zM2.5 14.172v4.328h15v-4.328h-15z"></path>
<path d="M4.353 11.135c0.183-0.147 0.444-0.147 0.627 0l2.667 2.148c0.165 0.133 0.228 0.356 0.158 0.556s-0.26 0.334-0.472 0.334h-5.333c-0.212 0-0.401-0.134-0.472-0.334s-0.007-0.423 0.158-0.556l2.667-2.148zM3.418 13.172h2.497l-1.249-1.005-1.249 1.005z"></path>
<path d="M9.686 11.135c0.183-0.147 0.444-0.147 0.627 0l2.667 2.148c0.165 0.133 0.228 0.356 0.158 0.556s-0.259 0.334-0.471 0.334h-5.333c-0.212 0-0.401-0.134-0.472-0.334s-0.007-0.423 0.158-0.556l2.667-2.148zM8.751 13.172h2.497l-1.249-1.005-1.249 1.005z"></path>
<path d="M15.020 11.135c0.183-0.147 0.444-0.147 0.627 0l2.667 2.148c0.165 0.133 0.228 0.356 0.158 0.556s-0.26 0.334-0.472 0.334h-5.333c-0.212 0-0.401-0.134-0.471-0.334s-0.007-0.423 0.158-0.556l2.667-2.148zM15.333 12.166l-1.249 1.005h2.497l-1.249-1.005z"></path>
<path d="M1.5 15.5c0-0.276 0.224-0.5 0.5-0.5h6.233c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-6.233c-0.276 0-0.5-0.224-0.5-0.5z"></path>
<path d="M1.5 17.5c0-0.276 0.224-0.5 0.5-0.5h6.233c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-6.233c-0.276 0-0.5-0.224-0.5-0.5z"></path>
<path d="M9.175 15.144c0-0.276 0.224-0.5 0.5-0.5h6.617c0.276 0 0.5 0.224 0.5 0.5v3.856c0 0.276-0.224 0.5-0.5 0.5h-6.617c-0.276 0-0.5-0.224-0.5-0.5v-3.856zM10.175 15.644v2.856h5.617v-2.856h-5.617z"></path>
<path d="M18.167 5.586c0.013-0.266 0.233-0.475 0.499-0.475h2.667c0.267 0 0.486 0.209 0.499 0.475l0.667 13.389c0.007 0.137-0.043 0.27-0.137 0.369s-0.225 0.155-0.362 0.155h-4c-0.137 0-0.268-0.056-0.362-0.155s-0.144-0.233-0.137-0.369l0.667-13.389zM19.142 6.111l-0.617 12.389h2.949l-0.617-12.389h-1.715z"></path>
<path d="M17.584 3.722c0.093-0.139 0.249-0.222 0.416-0.222h4c0.167 0 0.323 0.083 0.416 0.222s0.11 0.315 0.046 0.469l-0.667 1.611c-0.077 0.187-0.26 0.309-0.462 0.309h-2.667c-0.202 0-0.385-0.122-0.462-0.309l-0.667-1.611c-0.064-0.154-0.047-0.33 0.046-0.469zM18.748 4.5l0.253 0.611h1.998l0.253-0.611h-2.504z"></path>
</svg>