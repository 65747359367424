import type { Parser } from '../deser'
import { parseUser } from '../session'
import TwUser from '../user'
import { JsonHelper } from '../util/json'

export class AdminTwLogin {
  sessionToken = ''
  user = new TwUser()
  email = ''
  regulatoryDomain = ['']
}

export const ADMIN_LOGIN_PARSER: Parser<AdminTwLogin> = (
  helper: JsonHelper
) => {
  const twLogin = new AdminTwLogin()

  twLogin.sessionToken = helper.getString('session-token')
  twLogin.user = parseUser(helper.getChild('user'))
  twLogin.user.sessionToken = twLogin.sessionToken
  twLogin.email = helper.getString('email')
  twLogin.regulatoryDomain = helper
    .getChild('regulatory-domain-context')
    .getPrimitiveArray('regulatory-domains')
    .map(x => x?.toString() ?? '')
  return twLogin
}
