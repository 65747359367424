import goog from "@tastyworks/boulangerie-bundle"

// This prevents the module from being hot reloaded.
// If changes are made, refresh the browser to observe them.
if (import.meta?.hot) {
  import.meta.hot.decline()
}

const RequestState = goog.module.get("com.dough.util.RequestState")
const RequestStateShim = goog.module.get("tasty.js.util.JsRequestStateShim")
RequestState.prototype.toPromise = function () {
  return RequestStateShim.toPromise(this)
}

const WrappedRequestState = goog.module.get(
  "com.dough.util.WrappedRequestState"
)
WrappedRequestState.prototype.toPromise = function () {
  return RequestStateShim.toPromiseFromWrapped(this)
}

// its necessary to guard from redefining prop for jest
function defineProperty(clazz, prop, descriptor) {
  // eslint-disable-next-line no-prototype-builtins
  if (!clazz.prototype.hasOwnProperty(prop)) {
    Object.defineProperty(clazz.prototype, prop, descriptor)
  }
}

;[
  [
    "com.dough.util.ArrayProxy",
    [
      "f_content__com_dough_util_ArrayProxy_",
      "f_array__java_util_ArrayListBase",
    ],
  ],
  [
    "com.dough.util.ArrayProxyMap",
    [
      "f_content__com_dough_util_ArrayProxyMap_",
      "f_array__java_util_ArrayListBase",
    ],
  ],
  ["java.util.Arrays.ArrayList", ["f_array__java_util_Arrays_ArrayList_"]],
  ["java.util.ArrayList", ["f_array__java_util_ArrayListBase"]],
  ["com.dough.util.ImmutableList", ["f_values__com_dough_util_ImmutableList_"]],
  [
    "java.util.Collections.UnmodifiableCollection",
    [
      "f_list__java_util_Collections_UnmodifiableList_",
      "f_array__java_util_ArrayListBase",
    ],
  ],
  [
    "java.util.Collections.UnmodifiableList",
    [
      "f_list__java_util_Collections_UnmodifiableList_",
      "f_wrapped__java_util_AbstractList_SubList_",
      "f_array__java_util_ArrayListBase",
    ],
  ],
  [
    "java.util.Collections.UnmodifiableRandomAccessList",
    // refer to jsArray because the concrete type varies based on whether or not the list is empty
    ["f_list__java_util_Collections_UnmodifiableList_", "jsArray"],
  ],
  ["java.util.Collections.SingletonList", []],
  ["java.util.Collections.EmptyList", null],
].forEach(([name, jsArrayPaths]) => {
  const clazz = goog.module.get(name)
  if (Symbol.iterator in clazz) {
    return
  }

  defineProperty(clazz, Symbol.iterator, {
    get: function () {
      return function* () {
        const iterator = this.iterator()
        while (iterator.hasNext()) {
          yield iterator.next()
        }
      }
    },
  })

  defineProperty(clazz, "forEach", {
    value: function (cb) {
      for (let i = 0; i < this.size(); ++i) {
        const item = this.getAtIndex(i)
        cb(item, i, this)
      }
    },
  })

  defineProperty(clazz, "map", {
    value: function (mapper) {
      const acc = new Array(this.size())
      for (let i = 0; i < this.size(); ++i) {
        const item = this.getAtIndex(i)
        acc[i] = mapper(item, i, this)
      }
      return acc
    },
  })

  defineProperty(clazz, "findIndex", {
    value: function (test) {
      for (let i = 0; i < this.size(); ++i) {
        const item = this.getAtIndex(i)
        if (test(item, i, this)) {
          return i
        }
      }
      return -1
    },
  })

  defineProperty(clazz, "includes", {
    value: function (item) {
      return this.contains(item)
    },
  })

  defineProperty(clazz, "length", {
    get: function () {
      return this.size()
    },
  })

  if (jsArrayPaths === null) {
    defineProperty(clazz, "jsArray", {
      get: function () {
        return []
      },
    })
  } else if (jsArrayPaths.length === 0) {
    defineProperty(clazz, "jsArray", {
      get: function () {
        const acc = []
        for (const item of this) {
          acc.push(item)
        }
        return acc
      },
    })
  } else if (jsArrayPaths.length === 1) {
    defineProperty(clazz, "jsArray", {
      get: function () {
        return this[jsArrayPaths[0]]
      },
    })
  } else if (jsArrayPaths.length === 2) {
    defineProperty(clazz, "jsArray", {
      get: function () {
        return this[jsArrayPaths[0]][jsArrayPaths[1]]
      },
    })
  } else {
    defineProperty(clazz, "jsArray", {
      get: function () {
        let iter = this // eslint-disable-line @typescript-eslint/no-this-alias
        for (const path of jsArrayPaths) {
          iter = iter[path]
        }
        return iter
      },
    })
  }
})

export default goog
export * from "./tw-context"
