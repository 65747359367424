import {
  ValidateYup,
  WITHHOLDING_TYPES,
  WITHHOLDING_TYPES_VALUES,
  calculateWithholdingAmount,
  isNilOrEmpty,
} from "@tastyworks/tastyworks-api"
import { boolean, number, object, ref, string } from "yup"

// XXX: API doesn't really support this.
export const FREQUENCY_OPTIONS = ["One Time"]

export const MIN_WITHHOLDING_AMOUNT = 0.01

export const MAX_TRANSFER_AMOUNT = 250000

const MIN_PERCENT_WITHHOLDING_AMOUNT = 0

const { buildSchema, internalCashTransferSchema, iraDetailSchema } = ValidateYup

export class InternalTransferForm {
  accountNumber = ""
  accountsValid = false
  amount = null
  destinationAccountNumber = null
  frequency = FREQUENCY_OPTIONS[0]
  contribution = {
    contributionReason: "",
    contributionYear: "",
  }
  distribution = {
    distributionReason: "",
    isStateWithholdingEnabled: false,
    isWithholdingEnabled: false,
  }
  hasFederalWithholding = false
  hasStateWithholding = false
  federalWithholding = 0
  federalWithholdingType = WITHHOLDING_TYPES.FIXED
  stateWithholding = 0
  stateWithholdingType = WITHHOLDING_TYPES.FIXED
  maxStateWithholding = 0
  maxFederalWithholding = 0
  maxTransferAmount = 0
  hasAgreedConversion = false
  isConversion = false
  isReview = false
  withholdingsEnabled = false
  get hasAccountsSelected() {
    return !(
      null === this.accountNumber || null === this.destinationAccountNumber
    )
  }
  get hasContribution() {
    return !isNilOrEmpty(this.contribution.contributionReason)
  }
  get hasDistribution() {
    return !isNilOrEmpty(this.distribution.distributionReason)
  }
  get federalWithholdingAmount() {
    if (!this.hasFederalWithholding) {
      return 0
    }
    return calculateWithholdingAmount(
      this.amount,
      this.federalWithholdingType,
      this.federalWithholding
    )
  }
  get stateWithholdingAmount() {
    if (!this.hasStateWithholding) {
      return 0
    }
    return calculateWithholdingAmount(
      this.amount,
      this.stateWithholdingType,
      this.stateWithholding
    )
  }
}

const contributionSchemaRequired = object({
  contributionReason: string().required(),
  contributionYear: string().required(),
})

const distributionSchemaRequired = object({
  distributionReason: string().required(),
  isStateWithholdingEnabled: boolean(),
  isWithholdingEnabled: boolean(),
})

const validationSchema = internalCashTransferSchema.concat(
  object({
    accountsValid: boolean().required().oneOf([true]),
    // To block continue for ineligible transfers.
    amount: number().required().positive().max(ref("maxTransferAmount")),
    contribution: object({
      contributionReason: iraDetailSchema.fields.contributionReason,
      contributionYear: iraDetailSchema.fields.contributionYear,
    })
      .nullable()
      .test(
        "contribution",
        "yup.iraDetail.contribution.required",
        function (contribution) {
          if (this.parent.isDestinationRetirementAccount) {
            return contributionSchemaRequired.isValidSync(contribution)
          }

          return true
        }
      ),
    distribution: object({
      distributionReason: iraDetailSchema.fields.distributionReason,
      isStateWithholdingEnabled: boolean(),
      isWithholdingEnabled: boolean(),
    })
      .nullable()
      .test(
        "distribution",
        "yup.iraDetail.distribution.required",
        function (distribution) {
          if (this.parent.isRetirementAccount) {
            return distributionSchemaRequired.isValidSync(distribution)
          }

          return true
        }
      ),
    federalWithholding: number().when("hasFederalWithholding", {
      is: true,
      then: (fieldSchema) =>
        fieldSchema
          .positive()
          .max(ref("maxFederalWithholding"))
          .when("federalWithholdingType", {
            is: WITHHOLDING_TYPES.PERCENT,
            otherwise: (schema) => schema.min(MIN_WITHHOLDING_AMOUNT),
            then: (schema) => schema.moreThan(MIN_PERCENT_WITHHOLDING_AMOUNT),
          }),
    }),
    federalWithholdingType: string().oneOf(WITHHOLDING_TYPES_VALUES),
    // XXX: Just allow the field; API doesn't support this.
    frequency: string().required(),
    hasAgreedConversion: boolean().when(["isConversion", "isReview"], {
      is: true,
      then: (schema) =>
        schema.required().oneOf([true], "yup.common.mixed.required"),
    }),
    hasConfirmedReviewed: boolean().when("withholdingsEnabled", {
      is: true,
      then: () =>
        boolean().required().oneOf([true], "yup.common.mixed.required"),
    }),
    hasFederalWithholding: boolean(),
    hasStateWithholding: boolean(),
    hasViewedIraDistributionRequest: boolean().when("withholdingsEnabled", {
      is: true,
      then: () =>
        boolean().required().oneOf([true], "yup.common.mixed.required"),
    }),
    isConversion: boolean(),
    isDestinationRetirementAccount: boolean().required(),
    isRetirementAccount: boolean().required(),
    isReview: boolean(),
    maxFederalWithholding: number(),
    maxStateWithholding: number(),
    maxTransferAmount: number(),
    stateWithholding: number().when("hasStateWithholding", {
      is: true,
      then: (fieldSchema) =>
        fieldSchema
          .positive()
          .max(ref("maxStateWithholding"))
          .when("stateWithholdingType", {
            is: WITHHOLDING_TYPES.PERCENT,
            otherwise: (schema) => schema.min(MIN_WITHHOLDING_AMOUNT),
            then: (schema) => schema.moreThan(MIN_PERCENT_WITHHOLDING_AMOUNT),
          }),
    }),
    stateWithholdingType: string().oneOf(WITHHOLDING_TYPES_VALUES),
    withholdingsEnabled: boolean(),
  })
)

export const internalTransferFormSchema = buildSchema(
  "InternalCashTransferForm",
  validationSchema
)
