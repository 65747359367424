import {
  createPermittedAccountTypeMap,
  PERMITTED_ACCOUNT_TYPE_KEY_EXTRACTOR,
  PERMITTED_ACCOUNT_TYPE_UPDATER,
  type PermittedAccountTypeMap
} from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'
import { SingleResponse, toArrayMap } from '../../tastyworks/response'

export class PermittedAccountTypeService {
  private readonly permittedAccountTypeClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.permittedAccountTypeClient = customerMeClient.nested(
      'permitted-account-types'
    )
  }

  readonly index = async (): Promise<
    SingleResponse<PermittedAccountTypeMap>
  > => {
    const permittedAccountTypeMap = createPermittedAccountTypeMap()
    const response = await this.permittedAccountTypeClient.index(
      permittedAccountTypeMap,
      PERMITTED_ACCOUNT_TYPE_UPDATER,
      PERMITTED_ACCOUNT_TYPE_KEY_EXTRACTOR
    )
    return toArrayMap(response, permittedAccountTypeMap)
  }
}
