import _ from 'lodash'
import type { ItemsResponse, SingleResponse } from '../../tastyworks'
import type { AutotradePositionParams } from '../../tastyworks/autotrade-position'
import {
  AUTOTRADE_POSITION_DESER,
  AutotradePosition
} from '../../tastyworks/autotrade-position'
import type HttpClient from '../../tastyworks/http'

export class AutotradePositionService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    params: AutotradePositionParams
  ): Promise<ItemsResponse<AutotradePosition>> =>
    this.accountNumberClient.indexSimple(
      AUTOTRADE_POSITION_DESER.toParser(AutotradePosition),
      'autotrade-positions',
      _.merge(
        {
          accountNumber
        },
        params
      )
    )

  readonly update = async (
    position: AutotradePosition
  ): Promise<SingleResponse<AutotradePosition>> =>
    this.accountNumberClient.update(
      position,
      AUTOTRADE_POSITION_DESER.serialize,
      AUTOTRADE_POSITION_DESER.update,
      'autotrade-positions/:id',
      {
        accountNumber: position.accountNumber,
        id: position.id
      }
    )
}
