<script context="module">
  import goog from "/@lib/boulangerie"
  const Observer = goog.module.get("com.dough.boule.Observer")
</script>

<script>
  import { onDestroy } from "svelte"
  export let bouleArray

  let jsArray = []
  $: manageArrayObserver(bouleArray)

  function handleBouleArrayEvent(event) {
    jsArray = event.source.jsArray
  }
  const bouleArrayObserver = Observer.$adapt(handleBouleArrayEvent)

  let oldBouleArray = null
  function manageArrayObserver(newBouleArray) {
    if (oldBouleArray) {
      oldBouleArray.removeArrayObserver(bouleArrayObserver)
    }

    if (newBouleArray) {
      newBouleArray.addArrayObserver(bouleArrayObserver)
      jsArray = newBouleArray.jsArray
    } else {
      jsArray = []
    }

    oldBouleArray = newBouleArray
  }

  onDestroy(() => {
    if (oldBouleArray) {
      oldBouleArray.removeArrayObserver(bouleArrayObserver)
    }
  })
</script>

{#each jsArray as item (item.hashCode())}
  <slot {item} />
{:else}
  <slot name="empty" />
{/each}
