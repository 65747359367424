import type { CommunicationGroupPreference } from '../../tastyworks/communication-preference'
import {
  COMM_PREFERENCE_DESER,
  COMM_PREFERENCE_PARSER
} from '../../tastyworks/communication-preference'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'

export class CommunicationPreferenceService {
  private readonly client: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.client = customerMeClient.nested('communication-preferences')
  }

  readonly index = async (): Promise<
    ItemsResponse<CommunicationGroupPreference>
  > => this.client.indexSimple(COMM_PREFERENCE_PARSER)

  readonly update = async (
    pref: CommunicationGroupPreference
  ): Promise<SingleResponse<CommunicationGroupPreference>> =>
    this.client.save(
      pref,
      COMM_PREFERENCE_DESER.serialize,
      COMM_PREFERENCE_DESER.update,
      pref.id
    )
}
