<script lang="ts">
  import { createEventDispatcher } from "svelte"

  export let selected: boolean
  export let id: string
  export let label: string
  export let index: number
  let cssClass = ""
  export { cssClass as class }
  export let value: any

  const dispatch = createEventDispatcher()

  function handleClick(event: MouseEvent) {
    if (selected) return
    dispatch("click", event)
  }
</script>

<button
  class="
    default-button
    button
    button-group-button
    flex-1
    rounded-none
    first-of-type:rounded-l
    last-of-type:rounded-r
    {cssClass}"
  {id}
  class:button-selected={selected}
  class:button-deselected={!selected}
  on:click={handleClick}
  data-index={index}
  type="button"
  role="radio"
  aria-checked={selected}
  tabindex="-1"
  {value}
>
  {label}
</button>
