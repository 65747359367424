import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import type { PaginationParams } from './request'
import { ArrayMap } from './util/collection'
import type { JsonKeyExtractor } from './util/json'

export class PublicDocument extends RestResource {
  name = ''
  url = ''
  version = ''
  slug = ''
}

export class CustomerAccountAgreement extends RestResource {
  accountOpeningApplicationId = 0
  agreedAt: Date | null = null
  acceptanceMethod = ''
  version = ''
  accountNumber = ''
  document = new PublicDocument()
}

export const PUBLIC_DOCUMENT_DESER: SchemaDeSer<PublicDocument> =
  new SchemaDeSerBuilder(PublicDocument)
    .ofString('name')
    .ofString('url')
    .ofString('version')
    .ofString('slug')
    .toDeSer()

export const CUSTOMER_ACCOUNT_AGREEMENT_DESER: SchemaDeSer<CustomerAccountAgreement> =
  new SchemaDeSerBuilder(CustomerAccountAgreement)
    .ofString('acceptanceMethod')
    .ofString('accountNumber')
    .ofString('version')
    .ofNested('document', PUBLIC_DOCUMENT_DESER, PublicDocument)
    .toDeSer()

// region Document
export class Document {
  name = ''
  url = ''
  version = ''

  constructor(readonly slug: string) {}
}

export const DOCUMENT_DESER: SchemaDeSer<Document> =
  new SchemaDeSerBuilder<Document>(Document.bind(null, ''))
    .ofString('name')
    .ofString('url')
    .ofString('version')
    .ofString('slug')
    .toDeSer()

export type DocumentMap = ArrayMap<string, Document>

export function createDocumentMap() {
  return ArrayMap.stringKey(Document, 'slug')
}

export const documentJsonKeyExtractor: JsonKeyExtractor<string> = helper =>
  helper.getString('slug')
// endregion

// region Statement
const PRIVATE_DOCUMENTS_FILENAME =
  /([0-9]{4}-[0-9]{2}-[0-9]{2})-.{8}-(statement|confirmation)\.pdf/

export class PrivateDocument extends RestResource {
  accountNumber = ''
  createdAt?: Date
  documentDate = ''
  documentTypeName = ''
  filename = ''
  historyVersion = ''
  remoteId = ''
  slug = ''
  sortKey = ''
  version = ''

  get processDate(): string {
    if (this.documentDate !== '') {
      return this.documentDate
    }
    if (PRIVATE_DOCUMENTS_FILENAME.test(this.documentTypeName)) {
      const result = PRIVATE_DOCUMENTS_FILENAME.exec(this.documentTypeName)
      return result?.[1] ?? ''
    }
    return ''
  }

  get urlPath() {
    if (this.sortKey !== '') {
      return `/accounts/${this.accountNumber}/documents/${this.sortKey}`
    }
    return `/accounts/${this.accountNumber}/documents/${this.documentTypeName}`
  }

  get isOriginal() {
    return this.historyVersion === '1.0.0'
  }
}

export const PRIVATE_DOCUMENT_DESER: SchemaDeSer<PrivateDocument> =
  new SchemaDeSerBuilder(PrivateDocument)
    .ofString('accountNumber')
    .ofDateTime('createdAt')
    .ofString('documentTypeName')
    .ofString('filename')
    .ofString('documentDate')
    .ofString('historyVersion')
    .ofString('id')
    .ofString('remoteId')
    .ofString('slug')
    .ofString('sortKey')
    .ofString('version')
    .toDeSer()

export const PRIVATE_DOCUMENT_PARSER =
  PRIVATE_DOCUMENT_DESER.toParser(PrivateDocument)

export interface GetAllPrivateDocumentsOptions extends PaginationParams {
  accountNumber: string
}

export enum TaxDocumentSlugs {
  IRS_1042_S_TAX = 'irs_1042_s_tax',
  IRS_1099_TAX = 'irs_1099_tax',
  IRS_1099B_CRYPTOCURRENCIES_TAX = 'irs_1099b_cryptocurrencies_tax',
  IRS_1099B_TAX = 'irs_1099b_tax',
  IRS_1099R_TAX = 'irs_1099r_tax',
  IRS_5498_TAX = 'irs_5498_tax'
}

export enum TaxWorksheetSlugs {
  GAIN_LOSS_TAX_WORKSHEET = 'gain_loss_tax_worksheet',
  NO_WASH_SALES_TAX_WORKSHEET = 'no_wash_sales_tax_worksheet',
  WITH_WASH_SALES_TAX_WORKSHEET = 'with_wash_sales_tax_worksheet'
}
