<script context="module">
  import goog, { symbolSearchManager } from "/@lib/boulangerie"
  import { boulePropertyStore } from "/@/boule"

  const SymbolSearchManager = goog.module.get("tasty.ui.SymbolSearchManager")
</script>

<script>
  const symbolSearchNotFound = boulePropertyStore(
    symbolSearchManager.notFoundHeaderTextProperty()
  )
</script>

<div
  class="
    symbol-search-empty-results
    mt-double-extra-large
    flex
    h-full
    flex-col
    items-center
    justify-center
    gap-y-small
    px-double-extra-large"
>
  <span class="header font-small-600">
    {$symbolSearchNotFound}
  </span>
  <span
    class="
      content
      font-x-small-400
      text-center
      text-general-secondary-text"
  >
    {SymbolSearchManager.STATIC_NOT_FOUND_MESSAGE_BODY}
  </span>
</div>
