import { mediaObserver, MediaQueries } from "svelte-media-observer"

/*
  NOTICE: keep in-sync with tailwind.config.js screens
  FIXME: CAM AccountDropdown.svelte has a hard-coded width 
          because importing this module there breaks tests
*/
const mediaQueries: MediaQueries<
  "small" | "normal" | "large" | "no-animations" | "mobile" | "tablet"
> = [
  ["small", "(min-width: 961px)"],
  ["normal", "(min-width: 1148px)"],
  ["large", "(min-width: 1416px)"],
  ["no-animations", "(prefers-reduced-motion: reduce)"],
  ["mobile", "(max-width: 480px)"],
  ["tablet", "(max-width: 960px)"],
]

const media = mediaObserver(mediaQueries)

export default media
