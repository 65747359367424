import {
  ADVANCED_TRADING_PERMISSION_DESER,
  AdvancedTradingPermission
} from '../../tastyworks/advanced-trading-permission'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'

export class AdvancedTradingPermissionService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = async (
    accountNumber: string
  ): Promise<SingleResponse<AdvancedTradingPermission>> =>
    this.accountNumberClient.show(
      new AdvancedTradingPermission(),
      ADVANCED_TRADING_PERMISSION_DESER.update,
      'advanced-trading-permission',
      { accountNumber }
    )
}
