import {
  ALLOCATION_METHODS,
  ModelPortfolio,
  ValidateYup,
  accountPortfolioSubscriptionSchema,
  autotradeOperatorSchema,
  bigNumber,
  toStringValues,
} from "@tastyworks/tastyworks-api"
import BigNumber from "bignumber.js"
import { boolean, object, string } from "yup"

const MAX_BUYING_POWER_PERCENT = new BigNumber(70)
const { buildSchema } = ValidateYup

export const ALLOCATION_METHOD_OPTIONS_VALUES =
  toStringValues(ALLOCATION_METHODS)

export class AutotradeAllocation {
  allocationMethod: ALLOCATION_METHODS | null = ALLOCATION_METHODS.UNIT_COUNT
  allocationAmount: BigNumber | null = null
  hasAgreed = false
  isEligible = false
  modelPortfolio = new ModelPortfolio()
}

const valuesSchema = object({
  allocationAmount: bigNumber()
    .typeError()
    .required()
    .positive()
    .when("allocationMethod", {
      is: ALLOCATION_METHODS.UNIT_COUNT,
      then: (schema) => schema.integer(),
    })
    .when("allocationMethod", {
      is: ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE,
      then: (schema) => schema.max(MAX_BUYING_POWER_PERCENT),
    }),
  allocationMethod: string().oneOf(ALLOCATION_METHOD_OPTIONS_VALUES),
})

const schema = accountPortfolioSubscriptionSchema.concat(
  object({
    autotradeOperator: autotradeOperatorSchema,
    hasAgreed: boolean().required().oneOf([true], "yup.common.mixed.required"),
    hasViewedBrokerAutotradeLimitedTradingAuthorizationAndAgreement: boolean()
      .required()
      .oneOf([true], "yup.common.mixed.required"),
    hasViewedSecDocument: boolean()
      .required()
      .oneOf([true], "yup.common.mixed.required"),
    isEligible: boolean().required().oneOf([true], "yup.common.mixed.required"),
  }).concat(valuesSchema)
)

export const autotradeAllocationSchema = buildSchema(
  "AutotradeAllocation",
  schema
)

export const allocationValuesSchema = buildSchema(
  "AllocationValue",
  valuesSchema
)
