import WAValidator from '@tastyworks/wallet-address-validator'
import { isNilOrEmpty } from '../../../util/string'

interface WalletValidation {
  valid: boolean
  message?: string
}

export function isValidWallet(asset: string, value: string): WalletValidation {
  if (isNilOrEmpty(asset)) {
    return {
      message: 'yup.digitalAssetCustomerAddress.address.asset.required',
      valid: false
    }
  }

  const coin = asset.split('/')[0]

  if (!WAValidator.isCurrencySupported(coin)) {
    return { valid: true }
  }

  const valid = WAValidator.validate(value, coin)

  return { valid }
}
