/**
 * Input object for createPromiseHash. It's an object of keys to either Promise<Value> or Value.
 */
export type PromiseHashValue<T> = Promise<{
  [key in keyof T]: T[key] extends Promise<any> ? Awaited<T[key]> : T[key]
}>

/**
 * Takes an input object of values and/or promises, unwraps the promises and wraps the entire object in a promise,
 * allowing you to await the single promise instead of each individual promise.
 * @param promiseHash object containing either keys to values or keys to promises that resolve to values or a mixture of both
 * @returns the original object wrapped in a promise (with individual promises unwrapped)
 */
export async function createPromiseHash<
  V,
  T extends Record<string, Promise<V> | V>
>(promiseHash: T) {
  let resolvedValues = {}

  await Promise.all(
    Object.keys(promiseHash).map(async (key) => {
      resolvedValues[key] = await promiseHash[key]
    })
  )

  return resolvedValues as PromiseHashValue<T>
}
