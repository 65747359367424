<script context="module">const triggerContext = collectionContext();
export const getTriggerCollection = triggerContext.getContext;
function nextTab(tabs, index) {
  return index === tabs.length - 1 ? tabs[0] : tabs[index + 1];
}
function prevTab(tabs, index) {
  return index === 0 ? tabs[tabs.length - 1] : tabs[index - 1];
}
</script>

<script>import { useActions } from "../../../internal/helpers";
import { collectionContext } from "../../../internal/helpers/collectionContext";
import { cn } from "../../../utils";
import { getTabsRootContext } from "./TabsRoot.svelte";
const rootCtx = getTabsRootContext();
export const triggerCollection = triggerContext.setContext();
let className = void 0;
export { className as class };
$:
  ({ dir, orientation } = $rootCtx);
$:
  nextKey = orientation === "vertical" ? "ArrowDown" : dir === "rtl" ? "ArrowLeft" : "ArrowRight";
$:
  prevKey = orientation === "vertical" ? "ArrowUp" : dir === "rtl" ? "ArrowRight" : "ArrowLeft";
const listeners = /* @__PURE__ */ new Map();
triggerCollection.subscribe((triggers) => {
  const enabledTriggers = triggers.filter((t) => !t.dataset.disabled);
  triggers.forEach((trigger, index) => {
    const prevCallback = listeners.get(index);
    if (prevCallback) {
      trigger.removeEventListener("keydown", prevCallback);
    }
    const enabledIdx = enabledTriggers.indexOf(trigger);
    const listener = (e) => {
      if (e.key === nextKey) {
        e.preventDefault();
        nextTab(enabledTriggers, enabledIdx)?.focus();
      } else if (e.key === prevKey) {
        e.preventDefault();
        prevTab(enabledTriggers, enabledIdx)?.focus();
      }
    };
    listeners.set(index, listener);
    trigger.addEventListener("keydown", listener);
  });
});
</script>

<div
  class={cn(
    "inline-flex items-center justify-center rounded bg-muted p-1 text-muted-foreground data-[orientation=horizontal]:h-10 data-[orientation=vertical]:h-fit",
    className
  )}
  role="tablist"
  data-orientation={$rootCtx.orientation}
  {...$$restProps}
  use:useActions={$$restProps.use}
>
  <slot />
</div>
