<script context="module">
  import goog from "/@lib/boulangerie"

  const BracketOrderPreferences = goog.module.get(
    "com.dough.preference.trading.BracketOrderPreferences"
  )
  const PreferenceUtil = goog.module.get("com.dough.preference.PreferenceUtil")
</script>

<script>
  import { appSession } from "/@lib/boulangerie"

  import TradeAssetType from "./partial/TradeAssetType.svelte"

  const { tradingPreferences } = appSession
  const { bracketOrderFutures } = tradingPreferences

  const defaultQuantity = {
    label: "Default futures order quantity",
    options: PreferenceUtil.FUTURE_ORDER_QUANTITIES.jsArray,
    preferenceField: tradingPreferences.defaultFutureOrderQuantityField(),
  }
  const quantityIncrement = {
    label: "Futures order quantity increment",
    options: PreferenceUtil.FUTURE_ORDER_QUANTITY_INCREMENTS.jsArray,
    preferenceField: tradingPreferences.futureOrderQuantityIncrementField(),
  }
  const maxQuantity = {
    label: "Futures order maximum quantity",
    options: PreferenceUtil.FUTURE_ORDER_MAX_QUANTITIES.jsArray,
    preferenceField: tradingPreferences.futureOrderMaxQuantityField(),
  }
  const bracketProfitTarget = {
    label: "Default futures bracket order profit target %",
    options: BracketOrderPreferences.FUTURES_PERCENT_INCREMENTS.jsArray,
    preferenceField: bracketOrderFutures.profitTargetPercentField(),
  }
  const bracketStopLoss = {
    label: "Default futures bracket order stop loss %",
    options: BracketOrderPreferences.FUTURES_PERCENT_INCREMENTS.jsArray,
    preferenceField: bracketOrderFutures.stopLossPercentField(),
  }
</script>

<TradeAssetType
  class="trade-futuress"
  {defaultQuantity}
  {quantityIncrement}
  {maxQuantity}
  {bracketProfitTarget}
  {bracketStopLoss}
/>
