import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
// eslint-disable-next-line import/no-internal-modules
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import _ from 'lodash'
import type { Parser, Updater } from '../deser'
import { formatJsonDate, formatJsonDateTime } from '../format/date'
import type { ArrayMap } from './collection'
import type { CostEffect } from './cost'
import { Cost, parseCostEffect } from './cost'
import DateHelper from './date'
import type { Creator } from './meta-prog'
import { parseInteger } from './number'

dayjs.extend(customParseFormat)

export type BasicJsonValue = boolean | number | string | null | undefined
export type JsonValue = BasicJsonValue | JsonArray | JsonMap
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface JsonMap {
  [key: string]: JsonValue | undefined
}
export type JsonArray = JsonValue[]

export type JsonKeyExtractor<K> = (helper: JsonHelper) => K

export interface DateOptions {
  defaultValue?: Date
  format?: string
}

export function stringJsonKeyExtractor(
  jsonKey: string
): JsonKeyExtractor<string> {
  return (helper: JsonHelper) => helper.getString(jsonKey)
}

export const idKeyExtractor: JsonKeyExtractor<string> =
  stringJsonKeyExtractor('id')

export class JsonHelper {
  constructor(private readonly json: JsonMap) {}

  get fieldNames(): string[] {
    return Object.keys(this.json)
  }

  getString(key: string, defaultValue = ''): string {
    const val = _.get(this.json, key)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val) {
      return defaultValue
    }

    if (typeof val === 'string') {
      return val
    }

    return String(val)
  }

  getInt(key: string, defaultValue = 0): number {
    const val = this.json[key]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val) {
      return defaultValue
    }

    const valType = typeof val
    if (valType === 'number') {
      return val as number
    }

    if (valType === 'string') {
      return parseInteger(val as string)
    }

    throw new Error(`getInt doesn't support type ${valType}`)
  }

  getFloat(key: string, defaultValue = 0): number {
    const val = this.json[key]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val) {
      return defaultValue
    }

    const valType = typeof val
    if (valType === 'number') {
      return val as number
    }

    if (valType === 'string') {
      return parseFloat(val as string)
    }

    throw new Error(`getFloat doesn't support type ${valType}`)
  }

  getBigNumber(key: string, defaultValue = 0): BigNumber {
    const val = this.json[key]

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val) {
      return new BigNumber(defaultValue)
    }

    const valType = typeof val

    if (valType === 'string' || valType === 'number') {
      return new BigNumber(val as string)
    }

    throw new Error(`getBigNumber doesn't support type ${valType}`)
  }

  getDate(key: string, options: DateOptions = {}): Date | null {
    const val = this.json[key]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val) {
      return options.defaultValue ?? null
    }

    const date: Date = !_.isEmpty(options.format)
      ? dayjs(val as string, options.format).toDate()
      : new Date(val as string)

    return new DateHelper(date).inUTC().toStartOfTradingTime().toDate()
  }

  getDateTime(key: string, options: DateOptions = {}): Date | null {
    const val = this.json[key]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val) {
      return options.defaultValue ?? null
    }

    if (!_.isEmpty(options.format)) {
      return dayjs(val as string, options.format).toDate()
    }

    return new Date(val as string)
  }

  getBoolean(key: string, defaultValue = false): boolean {
    const val = this.json[key]
    const valType = typeof val
    if (valType === 'boolean') {
      return val as boolean
    }

    if (valType === 'string') {
      return val === 'true'
    }

    return defaultValue
  }

  getChild(key: string): JsonHelper {
    const jsonElement = this.json[key]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return new JsonHelper((jsonElement ?? {}) as JsonMap)
  }

  getChildren(key: string): JsonHelper[] {
    const val = this.json[key]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val || !Array.isArray(val)) {
      return []
    }

    const sourceArray = val as JsonMap[]
    const children: JsonHelper[] = new Array<JsonHelper>(sourceArray.length)

    for (let i = 0; i < sourceArray.length; i += 1) {
      children[i] = new JsonHelper(sourceArray[i])
    }

    return children
  }

  getPrimitiveArray(key: string): JsonArray {
    const value = this.json[key]
    if (!Array.isArray(value)) {
      return []
    }
    if (value.some(element => _.isObject(element))) {
      return []
    }

    return value
  }

  hasField(key: string): boolean {
    return this.json.hasOwnProperty(key) && this.json[key] !== null
  }

  updateObject<T>(key: string, target: T, updater: Updater<T>): void {
    if (!this.hasField(key)) {
      return
    }

    const helper = this.getChild(key)
    updater(target, helper)
  }

  parseObject<T>(
    key: string,
    creator: Creator<T>,
    updater: Updater<T>
  ): T | null {
    if (!this.hasField(key)) {
      return null
    }

    const target = new creator()
    const helper = this.getChild(key)
    updater(target, helper)
    return target
  }

  updateArrayMap<K, V>(
    arrayKey: string,
    targetArrayMap: ArrayMap<K, V>,
    updater: Updater<V>,
    jsonKeyExtractor: JsonKeyExtractor<K>
  ): void {
    const val = this.json[arrayKey]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val || !Array.isArray(val)) {
      return
    }

    const sourceArray = val as JsonMap[]
    const updatedKeys = new Set<string>()
    for (const source of sourceArray) {
      const helper = new JsonHelper(source)
      const targetKey = jsonKeyExtractor(helper)
      const target = targetArrayMap.findByKeyElseCreate(targetKey)
      updater(target, helper)
      updatedKeys.add(targetArrayMap.stringifier(targetKey))
    }

    const keysToDelete = new Map<string, K>()
    targetArrayMap.keys.forEach(key => {
      keysToDelete.set(targetArrayMap.stringifier(key), key)
    })

    updatedKeys.forEach(key => keysToDelete.delete(key))

    keysToDelete.forEach(key => {
      targetArrayMap.deleteByKey(key)
    })
  }

  parseArray<T>(key: string, parser: Parser<T>): T[] {
    const val = this.json[key]
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!val || !Array.isArray(val)) {
      return []
    }

    const sourceArray = val as JsonMap[]
    const targetArray = new Array<T>(sourceArray.length)

    for (let i = 0; i < sourceArray.length; i += 1) {
      const helper = new JsonHelper(sourceArray[i])
      const target = parser(helper)
      targetArray[i] = target
    }

    return targetArray
  }

  getCost(valueField: string, effectField = `${valueField}-effect`): Cost {
    const value = this.getFloat(valueField)
    const effect = this.getCostEffect(effectField)
    return Cost.create(value, effect)
  }

  getCostEffect(key: string): CostEffect {
    return parseCostEffect(this.getString(key))
  }

  toJson(): object {
    return this.json
  }
}

export class JsonBuilder {
  public constructor(public readonly json: JsonMap = {}) {}

  public add(key: string, value: JsonValue, serializeEmpty = false): this {
    if ((_.isNil(value) || value === '') && !serializeEmpty) {
      return this
    }

    this.json[key] = value
    return this
  }

  public addDate(key: string, date: Date | null): this {
    if (date === null) {
      return this
    }

    const value = formatJsonDate(date)
    return this.add(key, value)
  }

  public addDateTime(key: string, date: Date | null): this {
    if (date === null) {
      return this
    }

    const value = formatJsonDateTime(date)
    return this.add(key, value)
  }
}
