export type Delegate = () => void

export type Disposer = () => void

export interface RunLoop {
  next(delegate: Delegate): void

  later(time: number, delegate: Delegate): Disposer
}

// eslint-disable only-arrow-functions
export interface RunLooper {
  schedule(): void

  cancel(): void

  now(): void
}

function debounce(
  runLoop: RunLoop,
  delay: number,
  delegate: () => void
): RunLooper {
  let timestamp = 0
  let scheduled: Disposer | null = null

  const later = function () {
    const last = Date.now() - timestamp

    if (last < delay && last >= 0) {
      scheduled = runLoop.later(delay - last, later)
    } else {
      scheduled = null
      runLoop.next(delegate)
    }
  }

  const schedule = function () {
    timestamp = Date.now()
    if (scheduled === null) {
      scheduled = runLoop.later(delay, later)
    }
  }

  const cancel = function () {
    if (scheduled === null) {
      return
    }

    scheduled()
    scheduled = null
  }

  return {
    schedule,
    cancel,
    now: delegate
  }
}

export function throttle(
  runLoop: RunLoop,
  wait: number,
  delegate: () => void
): RunLooper {
  let previous = 0
  let scheduled: Disposer | null = null

  const later = function () {
    if (scheduled === null) {
      return // Cancel requested but already scheduled to run
    }

    runLoop.next(delegate)
    previous = Date.now()
    scheduled = null
  }

  const schedule = function () {
    const now = Date.now()
    const remaining = wait - (now - previous)
    if (remaining <= 0 || remaining > wait) {
      if (scheduled !== null) {
        scheduled()
        scheduled = null
      }

      runLoop.next(delegate)
      previous = now
    } else if (scheduled === null) {
      scheduled = runLoop.later(remaining, later)
    }
  }

  const cancel = function () {
    if (scheduled === null) {
      return
    }

    scheduled()
    scheduled = null
  }

  return {
    schedule,
    cancel,
    now: delegate
  }
}

export function scheduleOnce(runLoop: RunLoop, delegate: Delegate): RunLooper {
  let isScheduled = false

  const runnable = function () {
    if (isScheduled) {
      delegate()
      isScheduled = false
    }
  }

  const schedule = function () {
    if (!isScheduled) {
      isScheduled = true
      runLoop.next(runnable)
    }
  }

  const cancel = function () {
    isScheduled = false
  }

  return {
    schedule,
    cancel,
    now: delegate
  }
}

export class RunLoopHelper {
  constructor(readonly runLoop: RunLoop) {}

  readonly debounce = (delay: number, delegate: Delegate): RunLooper =>
    debounce(this.runLoop, delay, delegate)

  readonly throttle = (wait: number, delegate: Delegate): RunLooper =>
    throttle(this.runLoop, wait, delegate)

  readonly later = (time: number, delegate: Delegate): Disposer =>
    this.runLoop.later(time, delegate)

  readonly next = (delegate: Delegate): void => {
    this.runLoop.next(delegate)
  }

  readonly scheduleOnce = (delegate: Delegate): RunLooper =>
    scheduleOnce(this.runLoop, delegate)
}
