<script>
  import { appSession, appTwSession } from "/@lib/boulangerie"
  import CheckBoxField from "/@/trading/settings/control/CheckBoxField.svelte"
  import DropdownField from "/@/trading/settings/control/DropdownField.svelte"
  import StatisticPreferences from "/@/boule/control/StatisticPreferences.svelte"

  const { orderEntryViewPreferences } = appSession
  const { orderEntry } = appTwSession.remoteWebPreferences
</script>

<div class="trade-display grid grid-cols-1 gap-small">
  <div
    class="
      grid
      grid-cols-2
      gap-x-medium
      gap-y-extra-small"
  >
    <CheckBoxField
      label="Show Positions"
      preferenceField={orderEntryViewPreferences.showPositionViewProperty()}
    />
    <CheckBoxField
      label="Show Working Orders"
      preferenceField={orderEntryViewPreferences.showWorkingViewProperty()}
    />

    <DropdownField
      label="Price Range Overlay"
      preferenceField={orderEntryViewPreferences.rangeOverlayTypeProperty()}
    />
  </div>

  <div class="-mx-2 my-2 border-b border-b-divider-horizontal" />

  <StatisticPreferences
    preferenceField={orderEntry.statisticFields}
    options={orderEntry.statisticOptions}
  />
</div>
