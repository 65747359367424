import { Balance, BALANCE_DESER } from '../../tastyworks/balance'
import { SchemaDeSerBuilder } from '../../tastyworks/deser'
import type { Params } from '../request'

export interface buyingPowerParams extends Params {
  buyingPower: string
  note: string
}
export class AdminBalance extends Balance {
  buyingPowerAdjustment = 0
  longFuturesValue = 0
  pendingMarginInterest = 0
  longFuturesOptionsValue = 0
  shortFuturesValue = 0
  shortFuturesOptionsValue = 0
  cashBalance = 0

  get totalLongPositions() {
    return (
      +this.longStockValue +
      +this.longFuturesOptionsValue +
      +this.longFuturesValue +
      +this.longOptionValue
    )
  }

  get totalShortPositions() {
    return (
      +this.shortFuturesOptionsValue +
      +this.shortFuturesValue +
      +this.shortOptionValue +
      +this.shortStockValue
    )
  }

  get totalAllPositions(): number {
    return this.totalLongPositions - this.totalShortPositions
  }
}

export const ADMIN_BALANCE_DESER = new SchemaDeSerBuilder(AdminBalance)
  .addAll(BALANCE_DESER.fields)
  .ofFloat('longFuturesValue')
  .ofFloat('buyingPowerAdjustment')
  .ofFloat('pendingMarginInterest')
  .ofFloat('longFuturesOptionsValue')
  .ofFloat('shortFuturesValue')
  .ofFloat('shortFuturesOptionsValue')
  .ofFloat('isSnapshot')
  .ofFloat('cashBalance')

  .toDeSer()

export const ADMIN_BALANCE_PARSER = ADMIN_BALANCE_DESER.toParser(AdminBalance)

export class pendingCashEntry {
  id = ''
  accountNumber = ''
  amount = ''
  effect = ''
  expirationDate = ''
  releasedAt = ''
  releasedByUserId = ''
  description = ''
  apexCashActivityId = ''
}

export const PENDING_CASH_ENTRY_DESER = new SchemaDeSerBuilder(pendingCashEntry)
  .ofString('id')
  .ofString('accountNumber')
  .ofString('amount')
  .ofString('effect')
  .ofString('expirationDate')
  .ofString('releasedAt')
  .ofString('releasedByUserId')
  .ofString('description')
  .ofString('apexCashActivityId')

  .toDeSer()

export const PENDING_CASH_PARSER =
  PENDING_CASH_ENTRY_DESER.toParser(pendingCashEntry)
