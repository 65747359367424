<script lang="ts">
  import { AlertSeverity, toastAlert } from "/@/control/alert"
  import ModalConfirm from "/@/layout/modal/ModalConfirm.svelte"
  import { _ } from "svelte-i18n"
  import { DX_LAYOUTS_KEY } from "/@lib/shared"
  import { resetChart } from "/@lib/shared"

  export let showConfirmModal = false

  function handleResetClick() {
    showConfirmModal = true
  }

  function handleModalClose({ detail }: CustomEvent) {
    showConfirmModal = false

    if (detail === true) {
      localStorage.removeItem(DX_LAYOUTS_KEY)
      resetChart()
      toastAlert(AlertSeverity.CONFIRM, $_("content.chart.confirmMessage"))
    }
  }
</script>

<div class="reset-to-default-button flex">
  <button on:click={handleResetClick} class="button-status bg-button-default">
    Reset to Default
  </button>
</div>

<ModalConfirm
  bind:show={showConfirmModal}
  title={$_("content.chart.confirmModalTitle")}
  message={$_("content.chart.confirmModalMessage")}
  confirmLabel={$_("content.confirm.confirmLabel")}
  cancelLabel={$_("content.confirm.cancelLabel")}
  on:close={handleModalClose}
/>
