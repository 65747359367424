<script context="module">
  import goog from "/@lib/boulangerie"
  const SoundEffect = goog.module.get("com.dough.ui.model.SoundEffect")

  const GENERAL_OPTIONS = SoundEffect.GENERAL_OPTIONS.jsArray
  const NOTIFICATION_OPTIONS = SoundEffect.NOTIFICATION_OPTIONS.jsArray
</script>

<script>
  import { appSession } from "/@lib/boulangerie"
  import CheckBoxField from "/@/trading/settings/control/CheckBoxField.svelte"
  import SoundEffectField from "/@/trading/settings/control/SoundEffectField.svelte"

  const { soundPreferences } = appSession
</script>

<div class="sound grid grid-cols-1 gap-small">
  <CheckBoxField
    label="Sound Enabled"
    preferenceField={soundPreferences.soundEnabledProperty()}
  />
  <div
    class="
      grid
      grid-cols-2
      gap-x-medium
      gap-y-extra-small"
  >
    <SoundEffectField
      label="Market Open"
      soundEffects={GENERAL_OPTIONS}
      preferenceField={soundPreferences.marketOpenProperty()}
    />
    <SoundEffectField
      label="Market Close"
      soundEffects={GENERAL_OPTIONS}
      preferenceField={soundPreferences.marketCloseProperty()}
    />
    <SoundEffectField
      label="Order Fill"
      soundEffects={NOTIFICATION_OPTIONS}
      preferenceField={soundPreferences.orderFillProperty()}
    />
    <SoundEffectField
      label="Quote Alert"
      soundEffects={NOTIFICATION_OPTIONS}
      preferenceField={soundPreferences.quoteAlertProperty()}
    />
    <SoundEffectField
      label="Chart Alert"
      soundEffects={NOTIFICATION_OPTIONS}
      preferenceField={soundPreferences.chartAlertProperty()}
    />
  </div>
</div>
