import {
  ItemsResponse,
  JsonHelper,
  parseErrorContainer,
  SecurityQuestion
} from '../../../tastyworks'
import HttpClient from '../../../tastyworks/http'
import { SECURITY_QUESTION_DESER } from '../../../tastyworks/user'

export class AdminCredentialService {
  private readonly adminCredentialClient: HttpClient

  constructor(baseHttpClient: HttpClient) {
    this.adminCredentialClient = baseHttpClient
  }

  readonly fetchSecurityQuestions = async (
    userId: string
  ): Promise<ItemsResponse<SecurityQuestion>> =>
    this.adminCredentialClient
      .nested('users')
      .get(`${userId}/security-questions`)
      .then(res => this.parseSecurityQuestions(res))

  private readonly parseSecurityQuestions = (
    helper: JsonHelper
  ): ItemsResponse<SecurityQuestion> => {
    const response = new ItemsResponse<SecurityQuestion>()

    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    const data = helper.getChild('data')
    response.items = data.parseArray(
      'items',
      SECURITY_QUESTION_DESER.toParser(SecurityQuestion)
    )

    return response
  }
}
