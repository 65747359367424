import {
  DIGITAL_ASSET_ELIGIBILITY_DESER,
  DIGITAL_ASSET_PERMISSION_DESER,
  DigitalAssetPermission,
  DigitalAssetPermissionEligibility,
  DigitalAssetPermissionTypes
} from '../../tastyworks/digital-asset-permission'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'

export class DigitalAssetPermissionService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly showEligibility = async (
    accountNumber: string
  ): Promise<SingleResponse<DigitalAssetPermissionEligibility>> =>
    this.accountNumberClient.show(
      new DigitalAssetPermissionEligibility(),
      DIGITAL_ASSET_ELIGIBILITY_DESER.update,
      '/digital-asset-permission/opt-in/eligibility',
      { accountNumber, type: DigitalAssetPermissionTypes.TRANSFER }
    )

  readonly show = async (
    accountNumber: string
  ): Promise<SingleResponse<DigitalAssetPermission>> =>
    this.accountNumberClient.show(
      new DigitalAssetPermission(),
      DIGITAL_ASSET_PERMISSION_DESER.update,
      '/digital-asset-permission',
      { accountNumber }
    )

  readonly create = async (
    digitalAssetPermission: DigitalAssetPermission
  ): Promise<SingleResponse<DigitalAssetPermission>> =>
    this.accountNumberClient.create(
      digitalAssetPermission,
      DIGITAL_ASSET_PERMISSION_DESER.serialize,
      DIGITAL_ASSET_PERMISSION_DESER.update,
      '/digital-asset-permission/opt-in',
      {
        type: DigitalAssetPermissionTypes.TRANSFER,
        accountNumber: digitalAssetPermission.accountNumber
      }
    )
}
