<script>
  import { positionManager } from "/@lib/boulangerie"
  import ColumnPreference from "/@/boule/control/ColumnPreference.svelte"

  const sortedSelectableColumns =
    positionManager.columnsManager.sortedSelectableColumns
</script>

<ColumnPreference
  class="positions-columns"
  columnManager={positionManager.columnsManager}
  columnTypes={sortedSelectableColumns.jsArray}
/>
