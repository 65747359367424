import Route from "/@/util/route"

export const ROUTE_PREFIX = "/manage/my-money/cryptocurrency-transfers"

const LANDING = new Route(ROUTE_PREFIX, "/")
const ENABLE = new Route(ROUTE_PREFIX, "/enable")
const TWO_FACTOR = new Route(ROUTE_PREFIX, "/enable/2fa/:step")
const CRYPTOCURRENCY_TRADING = new Route(
  ROUTE_PREFIX,
  "/enable/cryptocurrency-trading"
)
const WALLETS = new Route(ROUTE_PREFIX, "/wallets")
const ADD_WALLET = new Route(ROUTE_PREFIX, "/wallets/add")
const DEPOSITS = new Route(ROUTE_PREFIX, "/deposits")
const WITHDRAWALS = new Route(ROUTE_PREFIX, "/withdrawals")
const WITHDRAWALS_HISTORY = new Route(ROUTE_PREFIX, "/withdrawals-history")
const DEPOSITS_HISTORY = new Route(ROUTE_PREFIX, "/deposits-history")
const CONFIRM_YOUR_IDENTITY = new Route(ROUTE_PREFIX, "/confirm-your-identity")

export const routes = {
  ADD_WALLET,
  CONFIRM_YOUR_IDENTITY,
  CRYPTOCURRENCY_TRADING,
  DEPOSITS,
  DEPOSITS_HISTORY,
  ENABLE,
  LANDING,
  TWO_FACTOR,
  WALLETS,
  WITHDRAWALS,
  WITHDRAWALS_HISTORY,
}
