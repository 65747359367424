import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { CostEffect } from './util/cost'

export class WithdrawalFee {
  type = ''
  value = 0
  valueEffect = CostEffect.NoCost
}

export const WITHDRAWAL_FEE_DESER: SchemaDeSer<WithdrawalFee> =
  new SchemaDeSerBuilder(WithdrawalFee)
    .ofString('type')
    .ofFloat('value')
    .ofString('valueEffect')
    .toDeSer()
