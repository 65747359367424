import { SchemaDeSerBuilder } from './deser'
import type { SchemaDeSer } from './deser'

export class FinancialStatementEntry {
  fiscalDate = ''
  fiscalQuarter? = ''
  filingType = ''
  fiscalYear = ''
  netIncome = ''
  revenue = ''
  totalCash = ''
  totalDebt = ''
}

export class FinancialStatement {
  annual: Array<FinancialStatementEntry> = []
  interim: Array<FinancialStatementEntry> = []
}

export const FINANCIALS_STATEMENT_ENTRY_DESER: SchemaDeSer<FinancialStatementEntry> =
  new SchemaDeSerBuilder(FinancialStatementEntry)
    .ofStrings(
      'fiscalDate',
      'fiscalQuarter',
      'filingType',
      'fiscalYear',
      'netIncome',
      'revenue',
      'totalCash',
      'totalDebt'
    )
    .toDeSer()

export const FINANCIALS_STATEMENT_DESER: SchemaDeSer<FinancialStatement> =
  new SchemaDeSerBuilder(FinancialStatement)
    .ofArray(
      'annual',
      FINANCIALS_STATEMENT_ENTRY_DESER,
      FinancialStatementEntry
    )
    .ofArray(
      'interim',
      FINANCIALS_STATEMENT_ENTRY_DESER,
      FinancialStatementEntry
    )
    .toDeSer()
