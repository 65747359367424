import type { PrivateDocument } from '../../tastyworks/document'
import { PRIVATE_DOCUMENT_PARSER } from '../../tastyworks/document'
import type HttpClient from '../../tastyworks/http'
import type { ItemsResponse } from '../../tastyworks/response'

export class ConfirmationDocumentService {
  private readonly client: HttpClient

  constructor(accountNumberClient: HttpClient) {
    this.client = accountNumberClient
      .nested('documents')
      .nested('confirmations')
  }

  readonly index = async (
    accountNumber: string
  ): Promise<ItemsResponse<PrivateDocument>> =>
    this.client.indexSimple(PRIVATE_DOCUMENT_PARSER, '', { accountNumber })
}
