import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum SubTypes {
  TREASURY_BILL = 'Treasury Bill',
  TREASURY_BOND = 'Treasury Bond',
  TREASURY_NOTE = 'Treasury Note'
}

export class Bond extends RestResource {
  active = false
  description = ''
  faceValue = 0
  instrumentSubType?: SubTypes
  issueDate?: Date
  marginEligible = false
  maturityDate?: Date
  symbol = ''
}

export const BOND_DESER: SchemaDeSer<Bond> = new SchemaDeSerBuilder(Bond)
  .ofBoolean('active')
  .ofString('description')
  .ofInt('faceValue')
  .ofString('id')
  .ofString('instrumentSubType')
  .ofDate('issueDate')
  .ofBoolean('marginEligible')
  .ofDate('maturityDate')
  .ofString('symbol')
  .toDeSer()
