<script lang="ts">
  import { key } from "@tastyworks/svelte-forms-lib"
  import { getContext } from "svelte"
  import { _ } from "svelte-i18n"
  import ErrorMessage from "./ErrorMessage.svelte"
  import { findFieldTranslationKey, getByPath } from "./util"
  import { FormContext } from "/@/account-management/form"
  import IconCheckbox from "/@/control/IconCheckbox.svelte"
  import { UserAnalyticsTag } from "/@/util/user-analytics"

  const { form, isReadOnly, validationSchema, updateValidateField } =
    getContext<FormContext>(key)

  let cssClass = ""
  export { cssClass as class }
  export let name: string
  export let fieldTranslationKey = findFieldTranslationKey(
    validationSchema,
    name
  )
  export let label = $_(fieldTranslationKey)
  export let disabled = false
  export let userAnalyticsTag: UserAnalyticsTag = null
  $: value = getByPath($form, name)

  function toggleChecked({ detail }) {
    value = detail
    updateValidateField(name, value)
  }
</script>

<div
  class="
    form-checkbox
    {name}
    {cssClass}
    font-medium-500"
>
  {#if $isReadOnly}
    {label}

    {#if value}
      <!-- TODO: ugly placeholder for read-only styled selection. -->
      ✔️
    {:else if false === value}
      ✖️
    {/if}
  {:else}
    <label
      class="
        inline-flex
        cursor-pointer
        items-start
        gap-x-double-extra-small
        text-general-default-text"
    >
      <IconCheckbox
        class={name}
        checked={value}
        {userAnalyticsTag}
        {disabled}
        on:click={toggleChecked}
      />

      <slot>
        {label}
      </slot>
    </label>

    <ErrorMessage {name} />
  {/if}
</div>
