import { BaseResponse, parseErrorContainer } from '../../tastyworks'
import type { Params } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class EmailConfirmationService {
  constructor(private readonly httpClient: HttpClient) {}

  readonly update = async (tokenString: string): Promise<BaseResponse> => {
    const params: Params = {
      token: tokenString
    }
    const helper = await this.httpClient.patch('/confirmation', {}, params)

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
