<script lang="ts">
  let cssClass = ""
  export { cssClass as class }
  export let showLabel = true
</script>

<div
  class="
      input-field-label
      grid
      grid-cols-1
      {cssClass}"
>
  <!-- NOTE: [KT] input contained within slot https://github.com/sveltejs/svelte/pull/5323 -->
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label
    class="
        col-span-1
        grid
        grid-cols-4
        gap-x-extra-large
        text-general-primary-label"
  >
    {#if showLabel}
      <span class="col-span-full small:col-span-3">
        <slot name="label" />
      </span>
    {/if}
    <span class="col-span-full small:col-span-1">
      <slot name="input" />
    </span>
  </label>

  <div class="col-span-full inline-flex justify-end">
    <slot name="error" />
  </div>
</div>
