<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...$$restProps} data-tw-icon="profile" on:click on:mouseover on:mouseenter on:mouseleave on:keydown><slot />
<title>Profile</title>
<path d="M5.574 2.8c-0.803 0-1.455 0.652-1.455 1.456s0.652 1.456 1.455 1.456c0.803 0 1.455-0.651 1.455-1.456s-0.652-1.456-1.455-1.456zM2.519 4.256c0-1.687 1.368-3.056 3.055-3.056s3.055 1.368 3.055 3.056c0 1.687-1.368 3.056-3.055 3.056s-3.055-1.368-3.055-3.056z"></path>
<path d="M5.556 7.321c-2.078 0-3.756 1.685-3.756 3.757 0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8c0-2.953 2.392-5.357 5.356-5.357 2.963 0 5.356 2.394 5.356 5.357 0 0.442-0.358 0.8-0.8 0.8s-0.8-0.358-0.8-0.8c0-2.080-1.677-3.757-3.756-3.757z"></path>
<path d="M14.352 4.869c0-0.331 0.269-0.6 0.6-0.6h8.048c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-8.048c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M0.4 14.957c0-0.331 0.269-0.6 0.6-0.6h22c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-22c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M0.4 20c0-0.331 0.269-0.6 0.6-0.6h11c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-11c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M14.938 20c0-0.331 0.269-0.6 0.6-0.6h7.462c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-7.462c-0.331 0-0.6-0.269-0.6-0.6z"></path>
<path d="M14.352 9.913c0-0.331 0.269-0.6 0.6-0.6h8.048c0.331 0 0.6 0.269 0.6 0.6s-0.269 0.6-0.6 0.6h-8.048c-0.331 0-0.6-0.269-0.6-0.6z"></path>
</svg>