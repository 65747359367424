import type { ItemsResponse, SingleResponse } from '../../tastyworks'
import {
  DIGITAL_ASSET_CLEARING_ADDRESS_DESER,
  DigitalAssetClearingAddress
} from '../../tastyworks/digital-asset-clearing-address'
import type HttpClient from '../../tastyworks/http'
import {
  SUPPORTED_DIGITAL_ASSET_DESER,
  SupportedDigitalAsset
} from '../../tastyworks/supported-digital-asset'

export class DigitalAssetClearingAddressService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = async (
    asset: string,
    accountNumber: string
  ): Promise<SingleResponse<DigitalAssetClearingAddress>> =>
    this.accountNumberClient.show(
      new DigitalAssetClearingAddress(),
      DIGITAL_ASSET_CLEARING_ADDRESS_DESER.update,
      '/digital-asset-clearing-addresses',
      { accountNumber, asset }
    )

  readonly create = async (
    digitalAssetClearingAddress: DigitalAssetClearingAddress
  ): Promise<SingleResponse<DigitalAssetClearingAddress>> =>
    this.accountNumberClient.create(
      digitalAssetClearingAddress,
      DIGITAL_ASSET_CLEARING_ADDRESS_DESER.serialize,
      DIGITAL_ASSET_CLEARING_ADDRESS_DESER.update,
      'digital-asset-clearing-addresses',
      { accountNumber: digitalAssetClearingAddress.accountNumber }
    )

  readonly indexSupportedAssets = async (
    accountNumber: string
  ): Promise<ItemsResponse<SupportedDigitalAsset>> =>
    this.accountNumberClient.indexSimple(
      SUPPORTED_DIGITAL_ASSET_DESER.toParser(SupportedDigitalAsset),
      '/digital-asset-clearing-addresses/supported-assets',
      { accountNumber }
    )
}
