import dayjs from 'dayjs'
import type { SchemaDeSer, Updater } from './deser'
import { SchemaDeSerBuilder } from './deser'
import type { Params } from './request'
import { ArrayMap } from './util/collection'
import DateHelper from './util/date'

export const PLAID_AGGREGATOR_SOURCE = 'Plaid'

export enum HoldingAssetType {
  ASSET = 'Asset',
  CASH = 'Cash',
  OPTION = 'Option',
  UNKNOWN = 'Unknown'
}

export enum HOLDING_TYPE {
  CASH = 'cash',
  DERIVATIVE = 'derivative',
  EQUITY = 'equity',
  ETF = 'etf',
  FIXED_INCOME = 'fixed income',
  LOAN = 'loan',
  MUTUAL_FUND = 'mutual fund',
  OTHER = 'other'
}

export const UNACCEPTED_TYPES = [
  HOLDING_TYPE.FIXED_INCOME,
  HOLDING_TYPE.LOAN,
  HOLDING_TYPE.MUTUAL_FUND
]

export interface ConnectionParams {
  institutionId?: number
  username: string
  passcode: string
}

export enum TransferType {
  PARTIAL = 'PARTIAL_TRANSFER_RECEIVER',
  FULL = 'FULL_TRANSFER'
}

export enum ConnectionState {
  GOOD = 'good',
  INCORRECT_CREDENTIALS = 'incorrect_credentials',
  CHALLENGES = 'challenges',
  USER_CONFIG = 'user_config',
  RESYNC = 'resync',
  POSTPONED = 'postponed',
  MAINTENANCE = 'maintenance',
  NO_ACCOUNTS = 'no_accounts',
  INSTITUTION_UNAVAILABLE = 'institution_unavailable',
  DELETED = 'deleted',
  ERROR = 'error',
  SYNCING = 'syncing',
  SYNC_REQUIRED = 'sync_required' // Status before we submit the sync request
}

export enum SyncState {
  QUEUED = 'queued',
  AUTHENTICATING = 'authenticating',
  FETCHING_ACCOUNTS = 'fetching_accounts',
  FETCHING_HOLDINGS = 'fetching_holdings',
  FETCHING_TRANSACTIONS = 'fetching_transactions',
  FETCHING_SECURITIES = 'fetching_securities',
  LOADING_HOLDINGS = 'loading_holdings',
  LOADING_TRANSACTIONS = 'loading_transactions',
  LOADING_SECURITIES = 'loading_securities',
  ANALYZING = 'analyzing',
  DONE = 'done' // Internal TW state
}

export enum AcatHoldingEligibility {
  ADMIN_REJECT = 'Admin rejected',
  ELIGIBLE = 'Eligible',
  INSTITUTION_UNIQUE = 'Unique to institution',
  LOW_PRICE = 'Low priced security',
  OTC = 'OTC security',
  QUOTE_MISSING = 'Quote missing',
  TYPE_NOT_ACCEPTED = 'Type not accepted',
  UNKNOWN_SYMBOL = 'Unknown symbol'
}

export class Holding {
  acatRequestId = null
  externalAccountId = 0
  externalSecurityId = ''
  description = ''
  externalType = ''
  amount = 0
  closePrice = 0
  symbol = ''
  isoCurrencyCode = ''
  eligibility = ''
  assetType = ''
  transferAmount = 0
  rootSymbol = ''
  expirationDate: Date | null = null
  optionType = ''
  strikePrice = 0

  get isEligible() {
    return this.eligibility === AcatHoldingEligibility.ELIGIBLE
  }

  get isCash() {
    return this.assetType === HoldingAssetType.CASH
  }

  get isAsset() {
    return this.assetType === HoldingAssetType.ASSET
  }

  get isOption() {
    return this.assetType === HoldingAssetType.OPTION
  }

  get isUnknown() {
    return this.assetType === HoldingAssetType.UNKNOWN
  }
}

const HOLDING_DESER: SchemaDeSer<Holding> = new SchemaDeSerBuilder(Holding)
  .ofInt('acatRequestId')
  .ofInt('externalAccountId')
  .ofString('externalSecurityId')
  .ofString('description')
  .ofString('externalType')
  .ofFloat('amount')
  .ofFloat('closePrice')
  .ofString('symbol')
  .ofString('isoCurrencyCode')
  .ofString('eligibility')
  .ofString('assetType')
  .ofFloat('transferAmount')
  .ofString('rootSymbol')
  .ofDate('expirationDate')
  .ofString('optionType')
  .ofFloat('strikePrice')
  .toDeSer()

export class AcatHolding {
  static onInitialize = (_obj: AcatHolding) => {
    /* no-op */
  }

  constructor() {
    AcatHolding.onInitialize(this)
  }

  acatRequestId: number | null = null
  transferAmount = 0
  id: number | null = null
  amount = 0
  initialAmount = 0
  assetType: HoldingAssetType | null = null
  description = ''
  eligibility: AcatHoldingEligibility | null = null
  externalType = ''
  isoCurrencyCode = ''
  symbol = ''

  get isEligible() {
    return this.eligibility === AcatHoldingEligibility.ELIGIBLE
  }

  get isCash() {
    return this.assetType === HoldingAssetType.CASH
  }

  get isAsset() {
    return this.assetType === HoldingAssetType.ASSET
  }

  get isOption() {
    return this.assetType === HoldingAssetType.OPTION
  }

  get isUnknown() {
    return this.assetType === HoldingAssetType.UNKNOWN
  }

  get isUnacceptableType() {
    return UNACCEPTED_TYPES.some(type => type === this.externalType)
  }
}

export const ACAT_HOLDING_DESER: SchemaDeSer<AcatHolding> =
  new SchemaDeSerBuilder(AcatHolding)
    .ofInt('id')
    .ofInt('acatRequestId')
    .ofFloat('transferAmount')
    .ofFloat('amount')
    .ofString('assetType')
    .ofString('description')
    .ofString('eligibility')
    .ofString('externalType')
    .ofString('isoCurrencyCode')
    .ofString('symbol')
    .toDeSer()

export const ACAT_HOLDING_UPDATER: Updater<AcatHolding> = (
  acatHolding,
  helper
) => {
  acatHolding.initialAmount = helper.getFloat('amount')
  ACAT_HOLDING_DESER.update(acatHolding, helper)
}

export enum ACAT_STATUSES {
  PRE_SEND = 'PRE_SEND',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  UNDEFINED = 'UNDEFINED',
  INITIATE_REQUEST = 'INITIATE_REQUEST',
  REQUEST = 'REQUEST',
  REQUEST_PAST = 'REQUEST_PAST',
  REQUEST_ADJUST = 'REQUEST_ADJUST',
  REQUEST_ADJUST_PAST = 'REQUEST_ADJUST_PAST',
  REQUEST_REJECT = 'REQUEST_REJECT',
  REVIEW = 'REVIEW',
  REVIEW_ADJUST_DELIVERER = 'REVIEW_ADJUST_DELIVERER',
  REVIEW_ERROR = 'REVIEW_ERROR',
  REVIEW_ACCELERATE = 'REVIEW_ACCELERATE',
  REVIEW_ADJUST_RECEIVER_ACCELERATE = 'REVIEW_ADJUST_RECEIVER_ACCELERATE',
  SETTLE_PREP = 'SETTLE_PREP',
  SETTLE_CLOSE = 'SETTLE_CLOSE',
  CLOSE_PURGE = 'CLOSE_PURGE',
  PARTIAL_REQUEST = 'PARTIAL_REQUEST',
  MEMO_PURGE_PARTIAL_TRANSFER_REQUEST_RECEIVER = 'MEMO_PURGE_PARTIAL_TRANSFER_REQUEST_RECEIVER',
  REJECT = 'REJECT',
  SYSTEM_PURGE = 'SYSTEM_PURGE',
  TRANSFER_COMPLETE = 'TRANSFER_COMPLETE',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED'
}

const TERMINAL_ACAT_STATES = Object.freeze([
  ACAT_STATUSES.REQUEST_PAST,
  ACAT_STATUSES.REQUEST_ADJUST_PAST,
  ACAT_STATUSES.CLOSE_PURGE,
  ACAT_STATUSES.MEMO_PURGE_PARTIAL_TRANSFER_REQUEST_RECEIVER,
  ACAT_STATUSES.REJECT,
  ACAT_STATUSES.SYSTEM_PURGE,
  ACAT_STATUSES.TRANSFER_COMPLETE,
  ACAT_STATUSES.ERROR,
  ACAT_STATUSES.CANCELED
])

export const EXPECTED_ACAT_DURATION = 10 // days

export class AcatRequest {
  static onInitialize = (_obj: AcatRequest) => {
    /* no-op */
  }

  constructor(readonly id: string) {
    AcatRequest.onInitialize(this)
  }

  acatsControlNumber = ''
  accountNumber = ''
  direction = ''
  transferType = ''
  currentState = ''
  previousState = ''
  tifId = ''
  notes = ''
  processingCaseId = ''
  processingErrors = ''
  encryptedExternalAccountNumber = ''
  truncatedExternalAccountNumber = ''
  externalAccountIdentifier = ''
  encryptedTaxNumber = ''
  externalAccountTitle = ''
  externalAccountType = ''
  externalAccountTypeConfidence = ''
  participantNumber = ''
  requestComments = ''
  externalInstitutionName = ''
  customerId = ''
  createdAt = new Date()
  updatedAt = new Date()
  reviewNotes = ''
  reviewedAt = new Date()
  reviewedBy = ''
  acatHoldings = ArrayMap.stringKey(AcatHolding, 'id')
  holdings = ArrayMap.stringKey(Holding, 'id')
  expectedSettlementDate: Date | null = null

  isComplete = false
  isPendingJointAgreement = false
  isRejected = false

  get isTerminal(): boolean {
    return TERMINAL_ACAT_STATES.includes(this.currentState as ACAT_STATUSES)
  }

  get isProcessing(): boolean {
    return !(this.isComplete || this.isRejected)
  }

  get isError(): boolean {
    switch (this.currentState) {
      case ACAT_STATUSES.REQUEST_PAST:
      case ACAT_STATUSES.REQUEST_ADJUST_PAST:
      case ACAT_STATUSES.CLOSE_PURGE:
      case ACAT_STATUSES.MEMO_PURGE_PARTIAL_TRANSFER_REQUEST_RECEIVER:
      case ACAT_STATUSES.ERROR:
        return true
      default:
        return false
    }
  }

  get isPresend() {
    return this.currentState === ACAT_STATUSES.PRE_SEND
  }

  get isCancelable(): boolean {
    return this.isProcessing
  }

  get daysSincePlaced(): number {
    // eslint-disable-next-line
    return dayjs().diff(this.createdAt, 'days')
  }

  get progress(): number {
    return this.daysSincePlaced >= this.estimatedDurationDays
      ? 1
      : this.daysSincePlaced / this.estimatedDurationDays
  }

  get estimatedDurationDays(): number {
    // eslint-disable-next-line
    return dayjs(this.estimatedCompletionDate).diff(this.createdAt, 'days')
  }

  get estimatedCompletionDate(): Date {
    if (this.expectedSettlementDate !== null) {
      return this.expectedSettlementDate
    } else {
      const helper = new DateHelper(this.createdAt)
      let daysCount = 0
      // Only include business days
      while (daysCount < EXPECTED_ACAT_DURATION) {
        helper.addDays(1)
        if (!helper.isWeekend()) {
          daysCount += 1
        }
      }
      return helper.toDate()
    }
  }

  get estimatedDaysRemaining(): number {
    // eslint-disable-next-line
    return dayjs(this.estimatedCompletionDate).diff(dayjs(), 'days')
  }
}

export const ACAT_REQUEST_DESER: SchemaDeSer<AcatRequest> =
  new SchemaDeSerBuilder<AcatRequest>(AcatRequest.bind(null, '0'))
    .ofString('acatsControlNumber')
    .ofString('accountNumber')
    .ofString('direction')
    .ofString('transferType')
    .ofString('currentState')
    .ofString('previousState')
    .ofString('tifId')
    .ofString('notes')
    .ofString('processingCaseId')
    .ofString('processingErrors')
    .ofString('encryptedExternalAccountNumber')
    .ofString('truncatedExternalAccountNumber')
    .ofString('externalAccountIdentifier')
    .ofString('encryptedTaxNumber')
    .ofString('externalAccountTitle')
    .ofString('externalAccountType')
    .ofString('externalAccountTypeConfidence')
    .ofString('participantNumber')
    .ofString('requestComments')
    .ofString('externalInstitutionName')
    .ofString('customerId')
    .ofDateTime('createdAt')
    .ofDateTime('updatedAt')
    .ofString('reviewNotes')
    .ofDateTime('reviewedAt')
    .ofString('reviewedBy')
    .ofDate('expectedSettlementDate')
    .ofBoolean('isComplete')
    .ofBoolean('isPendingJointAgreement')
    .ofBoolean('isRejected')
    .toDeSer()

export const ACAT_REQUEST_UPDATER: Updater<AcatRequest> = (
  acatRequest,
  helper
) => {
  ACAT_REQUEST_DESER.update(acatRequest, helper)
  helper.getChildren('acat-holdings').forEach(holdingHelper => {
    const acatHoldingId = holdingHelper.getString('id')
    const acatHolding =
      acatRequest.acatHoldings.findByKeyElseCreate(acatHoldingId)
    ACAT_HOLDING_DESER.update(acatHolding, holdingHelper)
  })

  helper.getChildren('holdings').forEach(holdingHelper => {
    const holdingId = holdingHelper.getString('id')
    const holding = acatRequest.holdings.findByKeyElseCreate(holdingId)
    HOLDING_DESER.update(holding, holdingHelper)
  })
}

export type AcatRequestMap = ArrayMap<string, AcatRequest>
export function createAcatRequestMap() {
  return ArrayMap.stringKey(AcatRequest, 'id')
}

export class ExternalAccount {
  id: number | null = null
  isSynced = false
  isSyncing = false
  mask = ''
  name = ''
  institutionName = ''
}

const EXTERNAL_ACCOUNT_DESER: SchemaDeSer<ExternalAccount> =
  new SchemaDeSerBuilder(ExternalAccount)
    .ofInt('id')
    .ofBoolean('isSynced')
    .ofBoolean('isSyncing')
    .ofString('mask')
    .ofString('name')
    .toDeSer()

export class AssetTransferExternalInstitution {
  static onInitialize = (_obj: AssetTransferExternalInstitution) => {
    /* no-op */
  }

  constructor() {
    AssetTransferExternalInstitution.onInitialize(this)
  }

  id: number | null = null
  name = ''
  externalAccounts = ArrayMap.stringKey(ExternalAccount, 'id')
}

export const ASSET_TRANSFER_EXTERNAL_INSTITUTION_DESER: SchemaDeSer<AssetTransferExternalInstitution> =
  new SchemaDeSerBuilder(AssetTransferExternalInstitution)
    .ofInt('id')
    .ofString('name')
    .toDeSer()

export const ASSET_TRANSFER_EXTERNAL_INSTITUTION_UPDATER: Updater<
  AssetTransferExternalInstitution
> = (externalInstitution, helper) => {
  ASSET_TRANSFER_EXTERNAL_INSTITUTION_DESER.update(externalInstitution, helper)
  helper.getChildren('external-accounts').forEach(accountHelper => {
    const accountId = accountHelper.getString('id')
    const account =
      externalInstitution.externalAccounts.findByKeyElseCreate(accountId)
    account.institutionName = externalInstitution.name
    EXTERNAL_ACCOUNT_DESER.update(account, accountHelper)
  })
}

export interface AssetTransferExternalInstitutionParams extends Params {
  publicToken: string
  externalInstitutionId: string
  externalAccountId: string
}

export interface AcatTransferParams {
  accountNumber: string
  transferType: TransferType
  externalAccountId: number
  holdings: AcatHolding[]
  deliveringAccountNumber: string
  deliveringAccountTitle: string
  deliveringAccountType: string
}
