import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum OpenApiAccessEntitlementAction {
  Register = 'Register',
  Unregister = 'Unregister'
}

export enum OpenApiAccessEntitlementStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Rejected = 'Rejected',
  Requested = 'Requested'
}

export default class OpenApiAccessEntitlement extends RestResource {
  status = ''

  get isRejected() {
    return this.status === OpenApiAccessEntitlementStatus.Rejected
  }

  get isEnabled() {
    return this.status === OpenApiAccessEntitlementStatus.Enabled
  }

  get isDisabled() {
    return this.status === OpenApiAccessEntitlementStatus.Disabled
  }
}

export const OPEN_API_ACCESS_ENTITLEMENT_DESER: SchemaDeSer<OpenApiAccessEntitlement> =
  new SchemaDeSerBuilder(OpenApiAccessEntitlement).ofString('status').toDeSer()
