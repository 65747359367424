import { derived } from "svelte/store"
import { location } from "svelte-spa-router"

const CRYPTOCURRENCY_TRANSFERS_BASE_PATH =
  "/manage/my-money/cryptocurrency-transfers"
const cryptoRoutes = Object.freeze([CRYPTOCURRENCY_TRANSFERS_BASE_PATH])

export const isCryptocurrencyTransfersEnable = derived(
  location,
  ($location) => $location === `${CRYPTOCURRENCY_TRANSFERS_BASE_PATH}/enable`
)

const isCryptocurrencyTradingEnable = derived(
  location,
  ($location) =>
    $location ===
    `${CRYPTOCURRENCY_TRANSFERS_BASE_PATH}/enable/cryptocurrency-trading`
)

export const isCryptocurrencyTransfers = derived(
  [isCryptocurrencyTransfersEnable, isCryptocurrencyTradingEnable, location],
  ([
    $isCryptocurrencyTransfersEnable,
    $isCryptocurrencyTradingEnable,
    $location,
  ]) =>
    !$isCryptocurrencyTransfersEnable &&
    !$isCryptocurrencyTradingEnable &&
    !!cryptoRoutes.find((route) => $location.startsWith(route))
)
