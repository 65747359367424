<script context="module">
  import goog from "/@lib/boulangerie"
  const CapitalRequirementsColumnType = goog.module.get(
    "tasty.ui.boule.table.CapitalRequirementsColumnType"
  )
</script>

<script>
  import { capitalRequirementsManager } from "/@lib/boulangerie"
  import ColumnPreference from "/@/boule/control/ColumnPreference.svelte"
</script>

<ColumnPreference
  class="cap-req-report"
  columnManager={capitalRequirementsManager.columnsManager}
  columnTypes={CapitalRequirementsColumnType.values()}
/>
