import _ from 'lodash'
import type HttpClient from '../../tastyworks/http'
import type { IraConstraintTransferMethods } from '../../tastyworks/ira-constraint'
import {
  IRA_CONSTRAINTS_DESER,
  IraConstraints
} from '../../tastyworks/ira-constraint'
import type { Params } from '../../tastyworks/request'
import { recursiveDasherizeKeys } from '../../tastyworks/request'
import type { SingleResponse } from '../../tastyworks/response'

export interface DepositIraConstraintParams extends Params {
  transferMethod: IraConstraintTransferMethods
}
export class DepositIraConstraintService {
  constructor(private readonly accountNumberClient: HttpClient) {}
  readonly show = async (
    accountNumber: string,
    params: DepositIraConstraintParams
  ): Promise<SingleResponse<IraConstraints>> =>
    this.accountNumberClient.show(
      new IraConstraints(),
      IRA_CONSTRAINTS_DESER.update,
      'deposit-ira-constraints',
      _.merge({ accountNumber }, recursiveDasherizeKeys(params))
    )
}
