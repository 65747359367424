import { SchemaDeSerBuilder } from './deser'
import type { SchemaDeSer } from './deser'

export class ReferralBody {
  code = ''
  expirationDate = new Date()
  startDate = new Date()
}

export class ReferralUsed {
  referralUsed = new ReferralBody()
}

const REFERRAL_BODY_DESER: SchemaDeSer<ReferralBody> = new SchemaDeSerBuilder(
  ReferralBody
)
  .ofString('code')
  .ofDateTime('expirationDate')
  .ofDateTime('startDate')
  .toDeSer()

export const REFERRAL_USED_DESER: SchemaDeSer<ReferralUsed> =
  new SchemaDeSerBuilder(ReferralUsed)
    .ofNested('referralUsed', REFERRAL_BODY_DESER, ReferralBody)
    .toDeSer()
