import type { SessionErrorHandler } from '../tastyworks'
import HttpClient from '../tastyworks/http'
import type Logger from '../tastyworks/logger'
import { AdminBalancesService } from './services/admin/admin-balances'
import { BuyingPowerService } from './services/admin/admin-buying-power'
import { AdminCredentialService } from './services/admin/admin-credential'
import { AdminCustomerAccountService } from './services/admin/admin-customer-acount'
import { AdminMarketDataService } from './services/admin/admin-market-data'
import { AdminNotesService } from './services/admin/admin-notes'
import { AdminOrderEventsService } from './services/admin/admin-order-events'
import { AdminOrdersService } from './services/admin/admin-orders'
import { AdminPositionsService } from './services/admin/admin-positions'
import { AdminSessionService } from './services/admin/admin-session'
import { AdminUserService } from './services/admin/admin-users'
import type { TwRestSession } from './session'

export class AdminClient {
  private readonly baseHttpClient: HttpClient

  readonly baseUrl: string

  readonly twSession: TwRestSession

  readonly AdminBalancesService: AdminBalancesService

  readonly AdminCredentialService: AdminCredentialService

  readonly AdminCustomerAccountService: AdminCustomerAccountService

  readonly AdminNotesService: AdminNotesService

  readonly AdminOrderEventsService: AdminOrderEventsService

  readonly AdminOrdersService: AdminOrdersService

  readonly AdminSessionService: AdminSessionService

  readonly AdminPositionsService: AdminPositionsService

  readonly AdminUserService: AdminUserService

  readonly BuyingPowerService: BuyingPowerService

  readonly AdminMarketDataService: AdminMarketDataService

  constructor(
    baseUrl: string,
    logger: Logger,
    fetcher: typeof fetch,
    sessionErrorHandler: SessionErrorHandler,
    twSession: TwRestSession
  ) {
    this.baseUrl = baseUrl
    this.twSession = twSession

    this.baseHttpClient = new HttpClient(
      logger,
      fetcher,
      baseUrl,
      this.twSession,
      sessionErrorHandler
    )

    this.AdminBalancesService = new AdminBalancesService(this.baseHttpClient)

    this.AdminCredentialService = new AdminCredentialService(
      this.baseHttpClient
    )

    this.AdminCustomerAccountService = new AdminCustomerAccountService(
      this.baseHttpClient
    )

    this.AdminMarketDataService = new AdminMarketDataService(
      this.baseHttpClient
    )

    this.AdminNotesService = new AdminNotesService(this.baseHttpClient)

    this.AdminOrderEventsService = new AdminOrderEventsService(
      this.baseHttpClient
    )

    this.AdminOrdersService = new AdminOrdersService(this.baseHttpClient)

    this.AdminPositionsService = new AdminPositionsService(this.baseHttpClient)

    this.AdminSessionService = new AdminSessionService(
      this.baseHttpClient,
      logger
    )

    this.AdminUserService = new AdminUserService(this.baseHttpClient)
    this.BuyingPowerService = new BuyingPowerService(this.baseHttpClient)
  }
}
