export enum TaxFormAvailability {
  ONLINE = "ONLINE",
  MAILED = "MAILED",
}

export class TaxForm {
  constructor(name: string, datesAvailable?: string, note?: string) {
    this.datesAvailable = datesAvailable
    this.name = name
    this.note = note
  }

  datesAvailable?: string
  name: string
  note?: string
}

export class TaxCalendarEvent {
  availability: TaxFormAvailability
  description: string
  date: Date
  form: TaxForm

  constructor(
    availability: TaxFormAvailability,
    description: string,
    date: Date,
    form: TaxForm
  ) {
    this.availability = availability
    this.description = description
    this.date = date
    this.form = form
  }
}
