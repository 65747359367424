import type HttpClient from '../../tastyworks/http'
import type { Ohlc, TIME_BACK } from '../../tastyworks/net-liq-history'
import { OHLC_PARSER } from '../../tastyworks/net-liq-history'
import type { ItemsResponse } from '../../tastyworks/response'

export class NetLiqHistoryService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly index = async (
    accountNumber: string,
    timeBack: TIME_BACK,
    useTimescale = true
  ): Promise<ItemsResponse<Ohlc>> =>
    this.accountNumberClient.indexSimple(OHLC_PARSER, '/net-liq/history', {
      accountNumber,
      timeBack,
      useTimescale
    })
}
