export class AccountOpeningStatus {
  private _hasAccounts: boolean
  private _hasCustomer: boolean

  constructor() {}

  get isActiveClient() {
    return this._hasAccounts && this._hasCustomer
  }

  get hasCustomer() {
    return this._hasCustomer
  }

  set hasCustomer(hasCustomer: boolean) {
    this._hasCustomer = hasCustomer
  }

  set hasAccounts(hasAccounts: boolean) {
    this._hasAccounts = hasAccounts
  }
}
