import type HttpClient from '../../tastyworks/http'
import {
  EQUITY_OFFERING_DESER,
  EquityOffering
} from '../../tastyworks/instrument'
import type { PaginationParams } from '../../tastyworks/request'
import type { ItemsResponse, SingleResponse } from '../../tastyworks/response'

interface EquityOfferingParams extends PaginationParams {
  symbol?: string[]
  marketplace?: string
  externalId?: string
  active?: boolean
}

export class EquityOfferingService {
  constructor(private readonly baseHttpClient: HttpClient) {}

  readonly index = async (
    params?: EquityOfferingParams
  ): Promise<ItemsResponse<EquityOffering>> =>
    this.baseHttpClient.indexSimple(
      EQUITY_OFFERING_DESER.toParser(EquityOffering),
      'instruments/equity-offerings',
      params
    )

  readonly show = async (
    symbol: string
  ): Promise<SingleResponse<EquityOffering>> =>
    this.baseHttpClient.show(
      new EquityOffering(),
      EQUITY_OFFERING_DESER.update,
      'instruments/equity-offerings/active/:symbol',
      { symbol }
    )
}
