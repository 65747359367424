import {
  EXPIRATION_MONITORING_ELIGIBILITY_DESER,
  EXPIRATION_MONITORING_ENTRY_DESER,
  ExpirationMonitoringEligibility,
  ExpirationMonitoringEntry
} from '../../tastyworks/expiration-monitoring'
import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'

export class ExpirationMonitoringEntryService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly showEligibility = (
    accountNumber: string
  ): Promise<SingleResponse<ExpirationMonitoringEligibility>> =>
    this.accountNumberClient.show(
      new ExpirationMonitoringEligibility(),
      EXPIRATION_MONITORING_ELIGIBILITY_DESER.update,
      'expiration-monitoring-entries/eligibility',
      { accountNumber }
    )

  readonly create = async (
    accountNumber: string
  ): Promise<SingleResponse<ExpirationMonitoringEntry>> =>
    this.accountNumberClient.create(
      new ExpirationMonitoringEntry(),
      EXPIRATION_MONITORING_ENTRY_DESER.serialize,
      EXPIRATION_MONITORING_ENTRY_DESER.update,
      'expiration-monitoring-entries',
      { accountNumber }
    )
}
