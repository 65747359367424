import camelCase from "lodash.camelcase"
import { px } from "./style-dimensions.js"

/**
 * Fit an element to a height and width (normally viewport).
 *
 * @param {HTMLElement} element - Element to fit.
 * @param {number} height - Height in px.
 * @param {number} width - Width in px.
 */
export function fitElementStyle(element, height, width) {
  if (!element) return {}

  const style = {}

  if (element.offsetHeight > height) {
    // Too tall.
    style.height = px(height)
  }

  if (element.offsetWidth > width) {
    // Too wide.
    style.width = px(width)
  }

  // Calculate translation with new height/width.
  // Assumption: the top and left are positioned on-screen.
  const { top, left } = element.getBoundingClientRect()
  const bottom = top + Math.min(element.offsetHeight, height)
  const right = left + Math.min(element.offsetWidth, width)

  // Stack transforms.
  const transforms = []

  if (bottom > height) {
    // Bottom out-of-bounds.
    transforms.push(`translateY(${px(height - bottom)})`)
  }

  if (right > width) {
    // Right out-of-bounds.
    transforms.push(`translateX(${px(width - right)})`)
  }

  if (transforms.length) {
    style.transform = transforms.join(" ")
  }

  return style
}

/**
 * Format an object as a CSS string (used by style attribute).
 *
 * @param {Object} style - Object (keys and values) representing style.
 */
export function formatStyle(style) {
  const styles = Object.entries(style).map(([k, v]) => `${k}: ${v}`)
  return styles.length ? styles.join("; ") + ";" : ""
}

/**
 * Merge style objects.
 *
 * @param {Object} styles - Objects (keys and values) representing styles.
 *
 * Last style wins, except in the case of transforms which are stacked.
 */
export function mergeStyles(...styles) {
  const merged = {}
  for (const style of styles) {
    for (const [key, value] of Object.entries(style)) {
      if ("transform" === key && merged.transform) {
        // Stack transforms.
        merged.transform += ` ${style.transform}`
      } else {
        // Override with most recent value.
        merged[key] = value
      }
    }
  }
  return merged
}

const DEFAULT_CSS_TARGET_ELEMENT = document.body

export function getCssPropertyValue(
  property,
  targetEl = DEFAULT_CSS_TARGET_ELEMENT
) {
  return window.getComputedStyle(targetEl).getPropertyValue(property).trim()
}

export function getCssProperties(...properties) {
  const style = window.getComputedStyle(DEFAULT_CSS_TARGET_ELEMENT)
  const acc = {}

  for (const property of properties) {
    const camelCaseKey = camelCase(property)
    const value = style.getPropertyValue(property)
    acc[camelCaseKey] = value
  }

  return acc
}

export function getCssPropertyValueNumeric(property, targetEl = document.body) {
  const raw = getCssPropertyValue(property, targetEl)
  return parseFloat(raw)
}

export function cssIf(flag, cssClass) {
  return flag ? cssClass : ""
}

function hexToRgbValues(hex) {
  return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
    .exec(hex)
    .map((x) => parseInt("0x" + x))
    .slice(1, 4)
}

export function mergeHexColors(hexColor0, hexColor1) {
  const [r0, g0, b0] = hexToRgbValues(hexColor0)
  const [r1, g1, b1] = hexToRgbValues(hexColor1)

  const red = Math.floor((r0 + r1) / 2)
  const green = Math.floor((g0 + g1) / 2)
  const blue = Math.floor((b0 + b1) / 2)

  return (
    "#" +
    ("00" + red.toString(16)).slice(-2) +
    ("00" + green.toString(16)).slice(-2) +
    ("00" + blue.toString(16)).slice(-2)
  )
}
