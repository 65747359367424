import type HttpClient from '../../tastyworks/http'
import {
  IRA_CONSTRAINTS_DESER,
  IraConstraints,
  IraConstraintTransferMethods
} from '../../tastyworks/ira-constraint'
import type { SingleResponse } from '../../tastyworks/response'

export class WithdrawalIraConstraintService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = (
    accountNumber: string,
    transferMethod: IraConstraintTransferMethods
  ): Promise<SingleResponse<IraConstraints>> => {
    const iraConstraints = new IraConstraints()
    return this.accountNumberClient.show(
      iraConstraints,
      IRA_CONSTRAINTS_DESER.update,
      'withdrawal-ira-constraints',
      { accountNumber, transferMethod }
    )
  }
}
