<svelte:options namespace="svg" />

<script context="module">
  import goog from "/@lib/boulangerie"
  const BouleColor = goog.module.get("tasty.ui.account.BouleColor")

  const colors = [
    BouleColor.RED,
    BouleColor.PURPLE,
    BouleColor.ORANGE,
    BouleColor.BLUE,
    BouleColor.YELLOW,
  ]
  const rotationAngle = 360 / colors.length
</script>

<script>
  let cssClass
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }
</script>

<svg
  class="multi-account-icon relative {cssClass}"
  viewBox="0 0 24 24"
  style={cssStyle}
>
  {#each colors as color, index}
    <circle
      style:fill="rgba({color.red}, {color.green}, {color.blue}, {color.alpha})"
      style:transform="rotate({index * rotationAngle}deg)"
      r="4px"
      cx="12px"
      cy="5px"
    />
  {/each}
</svg>

<style>
  svg {
    display: inline-block;
    height: var(--normal-font-size);
    width: var(--normal-font-size);
  }
  circle {
    transform-origin: center;
  }
</style>
