import type HttpClient from '../../tastyworks/http'
import type { SingleResponse } from '../../tastyworks/response'
import {
  TRADING_STATUS_DESER,
  TradingStatus
} from '../../tastyworks/trading-status'

export class TradingStatusService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = async (
    accountNumber: string
  ): Promise<SingleResponse<TradingStatus>> =>
    this.accountNumberClient.show(
      new TradingStatus(),
      TRADING_STATUS_DESER.update,
      'trading-status',
      { accountNumber }
    )
}
