import type { SingleResponse } from '../../tastyworks'
import { Customer, CUSTOMER_DESER } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'
import { updateSingleResponse } from '../../tastyworks/response'

export class EntityMigrationService {
  constructor(private readonly customerMeClient: HttpClient) {}

  readonly create = async (
    entityId: string,
    email: string
  ): Promise<SingleResponse<Customer>> => {
    const result = await this.customerMeClient.postJson(
      'entities/:entityId/migrate',
      { email },
      { entityId }
    )
    return updateSingleResponse(result, new Customer(), CUSTOMER_DESER.update)
  }
}
