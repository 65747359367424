import type { SingleResponse } from '../../tastyworks'
import {
  customerIdParam,
  TRUSTED_CONTACT_DESER,
  TrustedContact
} from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class TrustedContactService {
  private readonly customerMeTrustedContactClient: HttpClient

  constructor(private readonly customerMeClient: HttpClient) {
    this.customerMeTrustedContactClient =
      customerMeClient.nested('trusted-contact')
  }

  readonly show = async (): Promise<SingleResponse<TrustedContact>> =>
    this.customerMeTrustedContactClient.show(
      new TrustedContact(),
      TRUSTED_CONTACT_DESER.update,
      '',
      customerIdParam(this.customerMeClient.session)
    )

  readonly create = async (
    trustedContact: TrustedContact
  ): Promise<SingleResponse<TrustedContact>> =>
    this.customerMeTrustedContactClient.create(
      trustedContact,
      TRUSTED_CONTACT_DESER.serialize,
      TRUSTED_CONTACT_DESER.update
    )

  readonly update = async (
    trustedContact: TrustedContact
  ): Promise<SingleResponse<TrustedContact>> =>
    this.customerMeTrustedContactClient.update(
      trustedContact,
      TRUSTED_CONTACT_DESER.serialize,
      TRUSTED_CONTACT_DESER.update
    )
}
