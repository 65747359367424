import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class QuantityDecimalPrecision {
  instrumentType = ''
  symbol = ''
  value = 0
  minimumIncrementPrecision = 0
}

export const QUANTITY_DECIMAL_PRECISION_DESER: SchemaDeSer<QuantityDecimalPrecision> =
  new SchemaDeSerBuilder(QuantityDecimalPrecision)
    .ofString('instrumentType')
    .ofString('symbol')
    .ofInt('value')
    .ofInt('minimumIncrementPrecision')
    .toDeSer()
