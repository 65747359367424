<svelte:options immutable />

<script>
  import { getContext } from "svelte"
  import { VISIBLE_COLUMN_METAS } from "./BouleTable.svelte"
  import BouleTableHeaderCell from "./BouleTableHeaderCell.svelte"
  import { BouleSmallCollectionView } from "/@/boule/control"
  import { px } from "/@/util/style-dimensions.js"

  export let rowHeight
  export let cssClass
  export { cssClass as class }

  let cssCellClass
  export { cssCellClass as cellClass }

  const visibleColumnMetas = getContext(VISIBLE_COLUMN_METAS)
</script>

<div
  class="boule-table-row boule-table-header {cssClass}"
  style={`height: ${px(rowHeight)}`}
>
  <slot>
    <BouleSmallCollectionView
      bouleArray={visibleColumnMetas}
      let:item={columnMeta}
    >
      <slot name="table-header-cell">
        <BouleTableHeaderCell {columnMeta} class={cssCellClass} />
      </slot>
    </BouleSmallCollectionView>
  </slot>
</div>

<style lang="postcss">
  .boule-table-header {
    position: relative;
    overflow-x: hidden;
    background-color: var(--color-background-table-column-header);
  }
</style>
