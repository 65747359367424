import { setPointerControls } from "svelte-gestures"

const EVENT_NAME = "dragpan"
const DELAY = 200

export function dragPan(node) {
  let startTime
  let target
  let startX
  let startY

  function onDown(_activeEvents, event) {
    startTime = Date.now()
    target = event.target
    startX = event.clientX
    startY = event.clientY
  }

  function onMove(activeEvents, event) {
    if (activeEvents.length !== 1 || Date.now() - startTime < DELAY) {
      return
    }

    const x = event.clientX
    const y = event.clientY
    const offsetX = Math.round(startX - x)
    const offsetY = Math.round(startY - y)

    node.dispatchEvent(
      new CustomEvent(EVENT_NAME, {
        detail: { offsetX, offsetY, target },
      })
    )
  }

  function onUp(_activeEvents, _event) {}

  return setPointerControls(EVENT_NAME, node, onMove, onDown, onUp)
}
