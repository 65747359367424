import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export enum COMMUNICATION_GROUP {
  ACCOUNT = 'Account Communications',
  TRADE = 'Trade Communications',
  MARKETING = 'Marketing Communications',
  REFERRAL = 'Referral Communications',
  CASH = 'Cash Communications'
}

export enum COMMUNICATION_OPTIONS {
  PUSH = 'Push',
  EMAIL = 'Email',
  EMAIL_AND_PUSH = 'Email & push',
  NEITHER = 'Neither'
}

export class CommunicationGroupPreference extends RestResource {
  static onInitialize = (_obj: CommunicationGroupPreference) => {
    /* no-op */
  }

  constructor() {
    super()

    CommunicationGroupPreference.onInitialize(this)
  }

  communicationGroupName = ''
  email = false
  externallyVisible = true
  optional = true
  pushNotification = false
  sms = false
}

export const COMM_PREFERENCE_DESER: SchemaDeSer<CommunicationGroupPreference> =
  new SchemaDeSerBuilder(CommunicationGroupPreference)
    .ofString('id')
    .ofString('communicationGroupName')
    .ofBoolean('email')
    .ofBoolean('externallyVisible')
    .ofBoolean('optional')
    .ofBoolean('pushNotification')
    .ofBoolean('sms')
    .toDeSer()

export const COMM_PREFERENCE_PARSER = COMM_PREFERENCE_DESER.toParser(
  CommunicationGroupPreference
)
