<script context="module">
  import goog from "/@lib/boulangerie"
  const TwSymbolUtil = goog.module.get("com.dough.trade.util.TwSymbolUtil")

  export function translateSecurity(security) {
    if (!security) {
      return ""
    }

    return TwSymbolUtil.INSTANCE.translate(security.underlyingSecurity)
  }
</script>

<script>
  export let security
  let cssClass = ""
  export { cssClass as class }
</script>

<span class="underlying-symbol {cssClass}">
  {translateSecurity(security.underlyingSecurity)}
</span>
