import { RestResource } from './common'
import { SchemaDeSerBuilder, type SchemaDeSer } from './deser'

export enum DigitalAssetPermissionTypes {
  TRANSFER = 'Transfer'
}

export class DigitalAssetPermission extends RestResource {
  accountNumber = ''
  isDepositsEnabled = false
  isWithdrawalsEnabled = false

  get isTransfersEnabled() {
    return this.isDepositsEnabled || this.isWithdrawalsEnabled
  }
}

export const DIGITAL_ASSET_PERMISSION_DESER: SchemaDeSer<DigitalAssetPermission> =
  new SchemaDeSerBuilder(DigitalAssetPermission)
    .ofString('accountNumber')
    .ofBoolean('isDepositsEnabled')
    .ofBoolean('isWithdrawalsEnabled')
    .toDeSer()

export enum DigitalAssetPermissionEligibilityStatuses {
  COMPLETE = 'Complete',
  FAILED = 'Failed',
  INCOMPLETE = 'Incomplete',
  INELIGIBLE = 'Ineligible',
  PENDING = 'Pending'
}

export class DigitalAssetPermissionEligibility extends RestResource {
  identityVerificationStatus: DigitalAssetPermissionEligibilityStatuses =
    DigitalAssetPermissionEligibilityStatuses.INCOMPLETE
  tradingStatus: DigitalAssetPermissionEligibilityStatuses =
    DigitalAssetPermissionEligibilityStatuses.INCOMPLETE
  twoFactorAuthenticationStatus: DigitalAssetPermissionEligibilityStatuses =
    DigitalAssetPermissionEligibilityStatuses.INCOMPLETE

  get isTransferEligibilityComplete() {
    return (
      this.identityVerificationStatus ===
        DigitalAssetPermissionEligibilityStatuses.COMPLETE &&
      this.tradingStatus ===
        DigitalAssetPermissionEligibilityStatuses.COMPLETE &&
      this.twoFactorAuthenticationStatus ===
        DigitalAssetPermissionEligibilityStatuses.COMPLETE
    )
  }

  get isEligibleForCryptocurrencyTrading() {
    return (
      this.tradingStatus !==
      DigitalAssetPermissionEligibilityStatuses.INELIGIBLE
    )
  }
}

export const DIGITAL_ASSET_ELIGIBILITY_DESER: SchemaDeSer<DigitalAssetPermissionEligibility> =
  new SchemaDeSerBuilder(DigitalAssetPermissionEligibility)
    .ofString('identityVerificationStatus')
    .ofString('tradingStatus')
    .ofString('twoFactorAuthenticationStatus')
    .toDeSer()
