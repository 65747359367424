import TabsContent from "./TabsContent.svelte";
import TabsList from "./TabsList.svelte";
import TabsRoot from "./TabsRoot.svelte";
import TabsTrigger from "./TabsTrigger.svelte";
export const Tabs = {
    Root: TabsRoot,
    Content: TabsContent,
    List: TabsList,
    Trigger: TabsTrigger,
};
export var TAB_STATES;
(function (TAB_STATES) {
    TAB_STATES["ACTIVE"] = "active";
    TAB_STATES["INACTIVE"] = "inactive";
})(TAB_STATES || (TAB_STATES = {}));
