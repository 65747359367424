import {
  ItemsResponse,
  JsonHelper,
  parseErrorContainer
} from '../../../tastyworks'
import {
  ADMIN_MARKET_DATA_DESER,
  AdminMarketData
} from '../../../tastyworks/admin/admin-market-data'
import HttpClient from '../../../tastyworks/http'

export class AdminMarketDataService {
  private readonly adminMarketDataClient: HttpClient

  constructor(adminMarketDataClient: HttpClient) {
    this.adminMarketDataClient = adminMarketDataClient.nested('market-data')
  }

  readonly index = async (
    symbols: string[]
  ): Promise<ItemsResponse<AdminMarketData>> =>
    this.adminMarketDataClient
      .get('', { symbols })
      .then(res => this.populateResult(res))

  private readonly populateResult = (
    helper: JsonHelper
  ): ItemsResponse<AdminMarketData> => {
    const response = new ItemsResponse<AdminMarketData>()
    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }
    response.items = helper
      .getChild('data')
      .parseArray('items', ADMIN_MARKET_DATA_DESER.toParser())
    return response
  }
}
