import type { SingleResponse } from '../../tastyworks'
import {
  FUTURES_TRADING_REQUEST_DESER,
  FuturesTradingRequest
} from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class AccountFuturesTradingRequestService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = async (
    accountNumber: string
  ): Promise<SingleResponse<FuturesTradingRequest | null>> =>
    this.accountNumberClient.show(
      new FuturesTradingRequest(),
      FUTURES_TRADING_REQUEST_DESER.update,
      '/futures-trading-request',
      { accountNumber }
    )

  readonly createJoint = async (
    accountNumber: string,
    futuresTradingRequest: FuturesTradingRequest
  ): Promise<SingleResponse<FuturesTradingRequest>> =>
    this.accountNumberClient.create(
      futuresTradingRequest,
      FUTURES_TRADING_REQUEST_DESER.serialize,
      FUTURES_TRADING_REQUEST_DESER.update,
      'futures-trading-request',
      {
        accountNumber
      }
    )
}
