import { setLocale } from 'yup'

export const translations = {
  array: {
    min: ({ min }: { min: number }) => ({
      key: 'yup.common.array.min',
      values: { min }
    }),
    max: ({ max }: { max: number }) => ({
      key: 'yup.common.array.max',
      values: { max }
    })
  },
  boolean: {
    isValue: 'yup.common.boolean.isValue'
  },
  date: {
    min: ({ min }: { min: Date | string }) => ({
      key: 'yup.common.date.min',
      values: { min }
    }),
    max: ({ max }: { max: Date | string }) => ({
      key: 'yup.common.date.max',
      values: { max }
    })
  },
  mixed: {
    default: 'yup.common.mixed.default',
    defined: 'yup.common.mixed.defined',
    notNull: 'yup.common.mixed.notNull',
    notOneOf: ({ values }: { values: string[] }) => ({
      key: 'yup.common.mixed.notOneOf',
      values: { values }
    }),
    notType: ({ type }: { type: string }) => ({
      key: 'yup.common.mixed.notType',
      values: { type }
    }),
    oneOf: ({ values }: { values: string[] }) => ({
      key: 'yup.common.mixed.oneOf',
      values: { values }
    }),
    required: 'yup.common.mixed.required'
  },
  number: {
    integer: 'yup.common.number.integer',
    min: ({ min }: { min: number }) => ({
      key: 'yup.common.number.min',
      values: { min }
    }),
    max: ({ max }: { max: number }) => ({
      key: 'yup.common.number.max',
      values: { max }
    }),
    lessThan: ({ less }: { less: number }) => ({
      key: 'yup.common.number.lessThan',
      values: { less }
    }),
    moreThan: ({ more }: { more: number }) => ({
      key: 'yup.common.number.moreThan',
      values: { more }
    }),
    negative: 'yup.common.number.negative',
    positive: 'yup.common.number.positive'
  },
  object: {
    noUnknown: 'yup.common.object.noUnknown'
  },
  string: {
    email: 'yup.common.string.email',
    length: ({ length }: { length: number }) => ({
      key: 'yup.common.string.length',
      values: { length }
    }),
    lowercase: 'yup.common.string.lowercase',
    max: ({ max }: { max: number }) => ({
      key: 'yup.common.string.max',
      values: { max }
    }),
    min: ({ min }: { min: number }) => ({
      key: 'yup.common.string.min',
      values: { min }
    }),
    trim: 'yup.common.string.trim',
    uppercase: 'yup.common.string.uppercase',
    url: 'yup.common.string.url',
    uuid: 'yup.common.string.uuid'
  }
}

setLocale(translations)
