<script>
  import ComboboxField from "/@/trading/settings/control/ComboboxField.svelte"

  export let defaultQuantity
  export let quantityIncrement
  export let maxQuantity
  export let bracketProfitTarget
  export let bracketStopLoss

  // Optional.
  export let profitTarget = null

  let cssClass = ""
  export { cssClass as class }
</script>

<div
  class="
    trade-asset-type
    grid
    grid-cols-2
    gap-x-extra-large
    gap-y-medium
    {cssClass}"
>
  <ComboboxField class="default-quantity" {...defaultQuantity} />
  <ComboboxField class="quantity-increment" {...quantityIncrement} />

  <ComboboxField class="max-quantity" {...maxQuantity} />
  {#if !!profitTarget}
    <ComboboxField class="profit-target" {...profitTarget} />
  {:else}
    <div class="empty-space" />
  {/if}

  <ComboboxField class="bracket-profit-target" {...bracketProfitTarget} />
  <ComboboxField class="bracket-stop-loss" {...bracketStopLoss} />
</div>
