import _ from 'lodash'
import type { ItemsResponse, PaginationParams } from '../../tastyworks'
import { PRIVATE_DOCUMENT_DESER, PrivateDocument } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export interface TaxDocumentParams extends PaginationParams {
  taxYear: number
}

export class TaxDocumentService {
  constructor(private readonly accountNumberClient: HttpClient) {
    this.accountNumberClient = accountNumberClient.nested('documents')
  }

  readonly index = async (
    accountNumber: string,
    params?: TaxDocumentParams
  ): Promise<ItemsResponse<PrivateDocument>> =>
    this.accountNumberClient.indexSimple(
      PRIVATE_DOCUMENT_DESER.toParser(PrivateDocument),
      '/taxes',
      _.merge({ accountNumber }, params)
    )
}
